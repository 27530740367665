import { animate, style, transition, trigger } from '@angular/animations';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, race, Subject } from 'rxjs';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';

import { AuthManager } from '@statera/sdk/auth';
import { AlertManager, AlertNotification } from '@statera/sdk/alert';
import { Notification } from '@statera/sdk/notification';
import { UserStatus } from '@statera/sdk/common';

@Component({
  selector: 'app-alert-requires-action-container',
  templateUrl: 'alert-requires-action-container.component.html',
  styleUrls: ['alert-requires-action-container.component.scss'],
  animations: [
    trigger('animateAlertContainer', [
      transition(':enter', [
        style({opacity: 0}),
        animate('0.2s ease-in', style({opacity: 1})),
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('0.2s ease-out', style({opacity: 0})),
      ]),
    ]),
    trigger('animateAlert', [
      transition(':enter', [
        style({transform: 'translateY(-4px)', opacity: 0}),
        animate('0.2s ease-in', style({transform: 'translateX(0)', opacity: 1})),
      ]),
      transition(':leave', [
        style({transform: 'translateY(0)', opacity: 1}),
        animate('0.2s ease-out', style({transform: 'translateY(4px)', opacity: 0})),
      ]),
    ]),
  ],
})
export class AlertRequiresActionContainerComponent implements OnInit, OnDestroy {
  private static readonly _alertAnimationEnterDuration: number = 250;
  private static readonly _alertAnimationLeaveDuration: number = 250;

  alert: AlertNotification;

  shouldShowAlert: boolean;

  private _showingTimeoutId;
  private _hiddingTimeoutId;

  private readonly _authManager: AuthManager;
  private readonly _alertManager: AlertManager;
  private readonly _ngZone: NgZone;
  private readonly _destroy: Subject<void>;

  constructor(
    authManager: AuthManager,
    alertManager: AlertManager,
    ngZone: NgZone,
  ) {
    this._authManager = authManager;
    this._alertManager = alertManager;
    this._ngZone = ngZone;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this._authManager
      .getStoredStartupInfo()
      .pipe(
        take(1),
        tap(info => {
          let alertNotificationTracker = () => this._alertManager.notifyUserAboutRequiresActionAlert();
          if (info && (!info.id || info.userStatus !== UserStatus.Activated)) {
            alertNotificationTracker = () => of(null);
          }

          this._trackNotification(alertNotificationTracker);
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private _trackNotification(alertNotificationTracker: () => Observable<Notification<AlertNotification>>): void {
    alertNotificationTracker()
      .pipe(
        tap(notification => this._ngZone
          .run(() => this._processNotification(notification)),
        ),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  private _processNotification(notification: Notification<AlertNotification>): void {
    this.alert = null;
    this.shouldShowAlert = false;

    if (!notification || !notification.payload) {
      return null;
    }

    this.alert = notification.payload;
    this.shouldShowAlert = true;

    if (this._showingTimeoutId) {
      clearTimeout(this._showingTimeoutId);
      this._showingTimeoutId = null;
    }

    if (this._hiddingTimeoutId) {
      clearTimeout(this._hiddingTimeoutId);
      this._hiddingTimeoutId = null;
    }

    this.alert
      .reference
      .showing
      .pipe(
        take(1),
        tap(() => {
          this._ngZone.runOutsideAngular(() => {
            this._showingTimeoutId = setTimeout(
              () => this.alert?.reference?.fireShown(),
              AlertRequiresActionContainerComponent._alertAnimationEnterDuration
            );
          });
        }),
        takeUntil(
          race(this._destroy, notification.dequeued),
        ),
      )
      .subscribe();

    this.alert
      .reference
      .hiding
      .pipe(
        take(1),
        tap(() => {
          this.shouldShowAlert = false;

          this._ngZone.runOutsideAngular(() => {
            this._hiddingTimeoutId = setTimeout(
              () => this.alert?.reference?.fireHidden(),
              AlertRequiresActionContainerComponent._alertAnimationLeaveDuration
            );
          });
        }),
        takeUntil(
          race(this._destroy, notification.dequeued),
        ),
      )
      .subscribe();

    this.alert
      .reference
      .hidden
      .pipe(
        take(1),
        tap(() => this._alertManager
          .dequeueAlertNotification(this.alert),
        ),
        takeUntil(
          race(this._destroy, notification.dequeued),
        ),
      )
      .subscribe();
  }
}
