import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Configuration,
  ICreateOrUpdateFloorPlanProViewModel,
  ICreateLeaseFloorPlanViewModel, ICreatePlanProjectViewModel,
  IFileViewModel, IFloorPlanProInvitationViewModel,
  IFloorPlanProViewModel, IInviteFloorPlanProViewModel,
  ILeaseFloorPlanViewModel,
  IPlanAnchorCommentViewModel,
  IPlanAnchorImageMarkerViewModel,
  IPlanAnchorViewModel,
  IPlanProjectViewModel,
  IPlanViewModel, PlanAnchorStatus, IFloorPlanViewModel,
} from '@statera/sdk/common';
import {Observable} from 'rxjs';

@Injectable()
export class FloorPlanRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  addAnchor(anchor: IPlanAnchorViewModel): Observable<IPlanAnchorViewModel> {
    return this._httpClient.post<IPlanAnchorViewModel>(
      `${this._configuration.apiEndpoint}/floorplan/CreateFloorPlanAnchor`,
      anchor,
    );
  }

  addComment(comment: IPlanAnchorCommentViewModel, token?: string) {
    let params = new HttpParams();
    if (token) {
      params = params.set('token', token);
    }
    return this._httpClient.post<IPlanAnchorCommentViewModel>(
      `${this._configuration.apiEndpoint}/floorplan/CreateFloorPlanAnchorComment`,
      comment,
      {params}
    );
  }

  updateAnchor(anchor: IPlanAnchorViewModel): Observable<void> {
    return this._httpClient.post<void>(`${this._configuration.apiEndpoint}/floorplan/UpdateFloorPlanAnchor`, anchor);
  }

  deleteAnchor(anchor: IPlanAnchorViewModel): Observable<void> {
    return this._httpClient.post<void>(`${this._configuration.apiEndpoint}/floorplan/DeleteFloorPlanAnchor`, anchor);
  }

  updatePlan(model: IPlanViewModel): Observable<IPlanViewModel> {
    return this._httpClient.post<IPlanViewModel>(
      `${this._configuration.apiEndpoint}/floorplan/UpdatePlan`,
      model,
    );
  }

  uploadFloorPlanAnchorImage(file: File): Observable<IFileViewModel> {
    const body = new FormData();

    body.append('file', file);

    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
    });

    return this._httpClient
      .post<IFileViewModel>(`${this._configuration.apiEndpoint}/floorplan/UploadFloorPlanAnchorImage`, body, {headers});
  }

  getLeaseFloorPlans(leaseId: number = null): Observable<Array<ILeaseFloorPlanViewModel>> {
    return this._httpClient.get<Array<ILeaseFloorPlanViewModel>>(
      `${this._configuration.apiEndpoint}/floorplan/GetLeaseFloorPlans${leaseId ? `?id=${leaseId}` : ''}`,
    );
  }

  updateAnchorAmount(
    anchor: IPlanAnchorViewModel,
    token?: string
  ): Observable<void> {
    let params = new HttpParams();
    if (token) {
      params = params.set('token', token);
    }
    return this._httpClient.post<void>(
      `${this._configuration.apiEndpoint}/floorplan/UpdateAnchorAmount`,
      anchor,
      {params}
    );
  }

  createFloorPlanAnchorImageMarker(marker: IPlanAnchorImageMarkerViewModel):
    Observable<IPlanAnchorImageMarkerViewModel> {
    return this._httpClient
      .post<IPlanAnchorImageMarkerViewModel>(
        `${this._configuration.apiEndpoint}/floorplan/CreateFloorPlanAnchorImageMarker`,
        marker,
      );
  }

  updateFloorPlanAnchorImageMarker(marker: IPlanAnchorImageMarkerViewModel):
    Observable<IPlanAnchorImageMarkerViewModel> {
    return this._httpClient
      .put<IPlanAnchorImageMarkerViewModel>(
        `${this._configuration.apiEndpoint}/floorplan/UpdateFloorPlanAnchorImageMarker`,
        marker,
      );
  }

  deleteFloorPlanAnchorImageMarker(marker: IPlanAnchorImageMarkerViewModel):
    Observable<IPlanAnchorImageMarkerViewModel> {
    let params = new HttpParams();

    if (marker && marker.id) {
      params = params.set('id', marker.id.toString(10));
    }

    return this._httpClient.delete<IPlanAnchorImageMarkerViewModel>(
      `${this._configuration.apiEndpoint}/floorplan/DeleteFloorPlanAnchorImageMarker`,
      {params},
    );
  }

  createLeaseFloorPlan(leaseId: number): Observable<IPlanViewModel> {
    const createLeaseFloorPlanModel = <ICreateLeaseFloorPlanViewModel>{
      leaseId
    };
    return this._httpClient.post<IPlanViewModel>(
      `${this._configuration.apiEndpoint}/floorplan/PostLeaseFloorPlan`,
      createLeaseFloorPlanModel,
    );
  }

  getChildPlanProjects(planProjectId?: number, token?: string): Observable<Array<IPlanProjectViewModel>> {
    let params = new HttpParams();

    if (planProjectId) {
      params = params.set('parentPlanProjectId', planProjectId.toString(10));
    }

    if (token) {
      params = params.set('token', token);
    }

    return this._httpClient.get<Array<IPlanProjectViewModel>>(
      `${this._configuration.apiEndpoint}/plans/projects`,
      {
        params
      }
    );
  }

  getPlanProject(planProjectId: number, token?: string): Observable<IPlanProjectViewModel> {
    let params = new HttpParams();
    if (token) {
      params = params.set('token', token);
    }
    return this._httpClient.get<IPlanProjectViewModel>(
      `${this._configuration.apiEndpoint}/plans/projects/${planProjectId}`,
      {params}
    );
  }

  createPlanProject(createPlanProjectViewModel: ICreatePlanProjectViewModel): Observable<IPlanProjectViewModel> {
    return this._httpClient.post<IPlanProjectViewModel>(
      `${this._configuration.apiEndpoint}/plans/projects`,
      createPlanProjectViewModel,
    );
  }

  createFloorPlanPro(createFloorPlanProViewModel: ICreateOrUpdateFloorPlanProViewModel): Observable<IFloorPlanProViewModel> {
    return this._httpClient.post<IFloorPlanProViewModel>(
      `${this._configuration.apiEndpoint}/plans/pro`,
      createFloorPlanProViewModel,
    );
  }

  updateFloorPlanPro(createFloorPlanProViewModel: ICreateOrUpdateFloorPlanProViewModel): Observable<IFloorPlanProViewModel> {
    return this._httpClient.put<IFloorPlanProViewModel>(
      `${this._configuration.apiEndpoint}/plans/pro`,
      createFloorPlanProViewModel,
    );
  }

  getFloorPlanPro(floorPlanProId: number, token?: string): Observable<IFloorPlanProViewModel> {
    let params = new HttpParams();
    if (token) {
      params = params.set('token', token);
    }
    return this._httpClient.get<IFloorPlanProViewModel>(
      `${this._configuration.apiEndpoint}/plans/pro/${floorPlanProId}`,
      {params}
    );
  }

  updateStatusAsync(id: number, status: PlanAnchorStatus): Observable<void> {
    return this._httpClient.post<void>(
      `${this._configuration.apiEndpoint}/floorplan/UpdateFloorPlanAnchorStatus?id=${id}&status=${status}`,
      null,
    );
  }

  inviteToFloorPlanProOrProject(inviteFloorPlanPro: IInviteFloorPlanProViewModel): Observable<IFloorPlanProInvitationViewModel> {
    return this._httpClient.post<IFloorPlanProInvitationViewModel>(
      `${this._configuration.apiEndpoint}/plans/pro/invite`,
      inviteFloorPlanPro,
    );
  }

  deleteFloorPlan(floorPlan: IFloorPlanViewModel): Observable<void> {
    return this._httpClient.post<void>(`${this._configuration.apiEndpoint}/floorplan/DeleteFloorPlan`, floorPlan);
  }

  getFloorPlansProByPlanProjectId(planProjectId?: number): Observable<Array<IFloorPlanProViewModel>> {
    return this._httpClient.get<Array<IFloorPlanProViewModel>>(
      `${this._configuration.apiEndpoint}/plans/pro/projects/${planProjectId}`,
    );
  }
}
