import { Injectable, NgZone } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Configuration } from '@statera/sdk/common';
import { LoggerService } from '@statera/sdk/logger';

import { WebsocketPool } from './websocket.connection';
import { WebsocketResponse } from './websocket.model';

@Injectable({
  providedIn: 'root',
})
export class WebsocketClient {
  private readonly _configuration: Configuration;
  private readonly _websocketPool: WebsocketPool;

  constructor(ngZone: NgZone, configuration: Configuration, loggerService: LoggerService) {
    this._configuration = configuration;
    this._websocketPool = new WebsocketPool(ngZone, loggerService);
  }

  connectionTerminated(): Observable<Error> {
    return this._websocketPool
      .terminationObserver
      .pipe(
        take(1),
      );
  }

  listen<T>(endpoint: string, method: string): Observable<WebsocketResponse<T>> {
    return this._websocketPool
      .connect<T>(endpoint, {
        reconnectionAttemptsInterval: this._configuration.websocketReconnectionAttemptsInterval ?? 2000,
        reconnectionAttemptsLimit: this._configuration.websocketReconnectionAttemptsLimit ?? 10,
      })
      .pipe(
        switchMap(connection => connection.listen(method))
      );
  }
}
