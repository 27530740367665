import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardModule } from '../dashboard/dashboard.module';
import { DocumentViewerModule } from '../document-viewer/document-viewer.module';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import { NegotiateGuard } from './guards/negotiate.guard';
import { RedirectToAvailabilitiesGuard } from './guards/redirect-to-availabilities.guard';
import { TenantRouterModule } from './tenant.router';
import { InlineSVGModule } from 'ng-inline-svg';
import * as dx from 'devextreme-angular';
import { SharedModule } from '../shared/shared.module';
import { DevExtremeModule } from 'devextreme-angular';
import { TenantPagesService } from '../user/services/tenant-pages.service';
import { LeaseTenantCancellationComponent } from './components/lease-cancellation/lease-tenant-cancellation.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InlineSVGModule.forRoot(),
    TenantRouterModule,
    dx.DxFileUploaderModule,
    dx.DxListModule,
    dx.DxTabsModule,
    SharedModule,
    dx.DxTooltipModule,
    DevExtremeModule,
    dx.DxAccordionModule,
    dx.DxSelectBoxModule,
    dx.DxTextBoxModule,
    InfrastructureModule,
    DashboardModule,
    DocumentViewerModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LeaseTenantCancellationComponent,
  ],
  providers: [
    TenantPagesService,
    NegotiateGuard,
    RedirectToAvailabilitiesGuard,
  ],
  entryComponents: [
    LeaseTenantCancellationComponent
  ],
  exports: [
    LeaseTenantCancellationComponent,
  ],
})
export class TenantModule {
}
