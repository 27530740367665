import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Configuration, IStateraUserClaimsSimplifiedViewModel } from '@statera/sdk/common';
import { StateraUserClaimStore } from './statera-user-claim.store';

@Injectable()
export class StateraUserClaimRepository {
  private readonly _configuration: Configuration;
  private readonly _stateraUserClaimStore: StateraUserClaimStore;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, stateraUserClaimStore: StateraUserClaimStore, httpClient: HttpClient) {
    this._configuration = configuration;
    this._stateraUserClaimStore = stateraUserClaimStore;
    this._httpClient = httpClient;
  }

  requestStateraUserClaims(): Observable<IStateraUserClaimsSimplifiedViewModel> {
    return this._httpClient
      .get<IStateraUserClaimsSimplifiedViewModel>(`${this._configuration.apiEndpoint}/statera-user-claims`);
  }

  getStoredStateraUserClaims(): Observable<IStateraUserClaimsSimplifiedViewModel> {
    return this._stateraUserClaimStore.stateraUserClaims.asObservable();
  }

  getStoredStateraUserClaimsSync(): IStateraUserClaimsSimplifiedViewModel {
    return this._stateraUserClaimStore.stateraUserClaims.getValue();
  }

  setStoredStateraUserClaims(stateraUserClaims: IStateraUserClaimsSimplifiedViewModel): void {
    this._stateraUserClaimStore.stateraUserClaims.next(stateraUserClaims);
  }
}
