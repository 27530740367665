import * as models from '@statera/sdk/common';

export type Login = models.ILoginViewModel;
export type AuthenticateResult = models.IAuthenticateResultViewModel;
export type PhoneNumber = models.IPost2faCodeViewModel;
export type VerifyPhoneNumberRequest = models.IVerifyPhoneNumberViewModel;
export type RegisterRequest = models.IRegisterViewModel;
export type PasswordResetRequest = models.IPasswordResetRequestViewModel;
export type PasswordResetData = models.IPasswordResetViewModel;
export type Post2FaCodeRequest = models.IPost2faCodeViewModel;

export type InfoFlag = number;

export namespace InfoFlag {
  export const HasIncompleteProfile = 1 << 1;
  export const HasUnconfirmedEmail = 1 << 2;
  export const HasIncompleteCompanyInfo = 1 << 3;
  export const HasLease = 1 << 4;
  export const HasPortfolioInvitation = 1 << 5;

  export function hasFlag(flags: InfoFlag, flag: InfoFlag): boolean {
    if (!flags) {
      return false;
    }

    return (flags & flag) !== 0;
  }
}

export interface StartupInfo extends models.IStartupInfoViewModel {
  infoFlags: InfoFlag;
}

export interface UserInfo {
  userName: string;
  displayName: string;
}

export type Role = models.Role;
export const Role = models.Role;
