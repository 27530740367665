import { Injectable } from '@angular/core';
import { LoggerService, LoggerTopic } from '@statera/sdk/logger';
import { FeatureCollection, Point } from 'geojson';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { BuildingRepository } from './building.repository';

import * as models from './building.model';
import {BuildingAttachmentType} from '@statera/sdk/common';

@Injectable()
export class BuildingManager {
  private readonly _buildingRepository: BuildingRepository;
  private readonly _loggerService: LoggerService;

  constructor(buildingRepository: BuildingRepository, loggerService: LoggerService) {
    this._buildingRepository = buildingRepository;
    this._loggerService = loggerService;
  }

  /**
   * Requests management
   */

  requestBuilding(buildingId: number): Observable<models.BuildingViewModel> {
    return this._buildingRepository
      .getBuilding(buildingId)
      .pipe(
        catchError(error => {
          this._buildingRepository.setStoredBuildingError(error);

          return throwError(error);
        }),
      );
  }

  requestBuildingAvailableBuildingUnits(buildingId: number, exclude?: Array<number>): Observable<Array<models.BuildingUnitViewModel>> {
    return this._buildingRepository
      .getAvailableBuildingUnits(buildingId, exclude)
      .pipe(
        catchError(error => {
          this._buildingRepository.setStoredBuildingAvailableUnitsError(error);

          return throwError(error);
        }),
      );
  }

  requestBuildingSitePlans(buildingId: number): Observable<Array<models.PlanViewModel>> {
    return this._buildingRepository
      .getBuildingSitePlans(buildingId)
      .pipe(
        catchError(error => {
          this._buildingRepository.setStoredBuildingSitePlansError(error);

          return throwError(error);
        }),
      );
  }

  requestBuildingPointsOfInterest(buildingId: number): Observable<FeatureCollection<Point>> {
    return this._buildingRepository
      .getBuildingPointsOfInterest(buildingId)
      .pipe(
        catchError(error => {
          this._buildingRepository.setStoredBuildingPointsOfInterestError(error);

          return throwError(error);
        }),
      );
  }

  requestBuildingCentralBusinessDistricts(buildingId: number): Observable<FeatureCollection<Point>> {
    return this._buildingRepository
      .getBuildingCentralBusinessDistricts(buildingId)
      .pipe(
        catchError(error => {
          this._buildingRepository.setStoredBuildingCentralBusinessDistrictsError(error);

          return throwError(error);
        }),
      );
  }

  requestBuildingDemographicsReports(buildingId: number): Observable<Array<models.DemographicsReport>> {
    return this._buildingRepository
      .getDemographicsReports(buildingId)
      .pipe(
        catchError(error => {
          this._buildingRepository.setStoredBuildingDemographicsReportsError(error);

          return throwError(error);
        }),
      );
  }

  requestBuildingsFunds(): Observable<Array<string>> {
    return this._buildingRepository.getBuildingsFunds();
  }

  requestBuildingsCities(companyId?: number): Observable<Array<string>> {
    return this._buildingRepository.getBuildingsCities(companyId);
  }

  /**
   * Store management
   */

  getBuilding(): Observable<models.BuildingViewModel> {
    return this._buildingRepository.getStoredBuilding();
  }

  setBuilding(building: models.BuildingViewModel): void {
    this._buildingRepository.setStoredBuilding(building);

    this._loggerService.info(LoggerTopic.Building, `Building changed`, building);
  }

  getBuildingAvailableUnits(): Observable<Array<models.BuildingUnitViewModel>> {
    return this._buildingRepository.getStoredBuildingAvailableUnits();
  }

  setBuildingAvailableUnits(availableUnits: Array<models.BuildingUnitViewModel>): void {
    this._buildingRepository.setStoredBuildingAvailableUnits(availableUnits);

    this._loggerService.info(LoggerTopic.Building, `Building available units changed`, availableUnits);
  }

  getBuildingSitePlans(): Observable<Array<models.PlanViewModel>> {
    return this._buildingRepository.getStoredBuildingSitePlans();
  }

  setBuildingSitePlans(sitePlans: Array<models.PlanViewModel>): void {
    this._buildingRepository.setStoredBuildingSitePlans(sitePlans);

    this._loggerService.info(LoggerTopic.Building, `Building site plans changed`, sitePlans);
  }

  getBuildingPointsOfInterest(): Observable<FeatureCollection<Point>> {
    return this._buildingRepository.getStoredBuildingPointsOfInterest();
  }

  setBuildingPointsOfInterest(pointsOfInterest: FeatureCollection<Point>): void {
    this._buildingRepository.setStoredBuildingPointsOfInterest(pointsOfInterest);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building points of interest changed`,
      pointsOfInterest,
    );
  }

  getBuildingCentralBusinessDistricts(): Observable<FeatureCollection<Point>> {
    return this._buildingRepository.getStoredBuildingCentralBusinessDistricts();
  }

  setBuildingCentralBusinessDistricts(centralBusinessDistricts: FeatureCollection<Point>): void {
    this._buildingRepository.setStoredBuildingCentralBusinessDistricts(centralBusinessDistricts);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building central business districts changed`,
      centralBusinessDistricts,
    );
  }

  getBuildingDemographicsReports(): Observable<Array<models.DemographicsReport>> {
    return this._buildingRepository.getStoredBuildingDemographicsReports();
  }

  setBuildingDemographicsReports(demographicsReports: Array<models.DemographicsReport>): void {
    this._buildingRepository.setStoredBuildingDemographicsReports(demographicsReports);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building demographics reports changed`,
      demographicsReports,
    );
  }

  getBuildingDemographicsReportsSelectedReportIndex(): Observable<number> {
    return this._buildingRepository.getStoredBuildingDemographicsReportsSelectedReportIndex();
  }

  setBuildingDemographicsReportsSelectedReportIndex(index: number): void {
    this._buildingRepository.setStoredBuildingDemographicsReportsSelectedReportIndex(index);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building demographics reports selected report index changed`,
      index,
    );
  }

  getBuildingDemographicsReportsSelectedAreaIndex(): Observable<number> {
    return this._buildingRepository.getStoredBuildingDemographicsReportsSelectedAreaIndex();
  }

  setBuildingDemographicsReportsSelectedAreaIndex(index: number): void {
    this._buildingRepository.setStoredBuildingDemographicsReportsSelectedAreaIndex(index);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building demographics reports selected area index changed`,
      index,
    );
  }

  getBuildingDemographicsReportsSelectedReportShouldUseLargerScope(): Observable<boolean> {
    return this._buildingRepository.getStoredBuildingDemographicsReportsSelectedReportShouldUseLargerScope();
  }

  setBuildingDemographicsReportsSelectedReportShouldUseLargerScope(shouldUseLargerScope: boolean): void {
    this._buildingRepository.setStoredBuildingDemographicsReportsSelectedReportShouldUseLargerScope(shouldUseLargerScope);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building demographics reports selected report should use larger scope changed`,
      shouldUseLargerScope,
    );
  }

  getBuildingDemographicsReportsIsMapLayerActive(): Observable<boolean> {
    return this._buildingRepository.getStoredBuildingDemographicsReportsIsMapLayerActive();
  }

  setBuildingDemographicsReportsIsMapLayerActive(isMapLayerActive: boolean): void {
    this._buildingRepository.setStoredBuildingDemographicsReportsIsMapLayerActive(isMapLayerActive);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building demographics reports is map layer active changed`,
      isMapLayerActive,
    );
  }

  getBuildingPointsOfInterestSelectedPointIndex(): Observable<number> {
    return this._buildingRepository.getStoredBuildingPointsOfInterestSelectedPointIndex();
  }

  setBuildingPointsOfInterestSelectedPointIndex(index: number): void {
    this._buildingRepository.setStoredBuildingPointsOfInterestSelectedPointIndex(index);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building points of interest selected point index changed`,
      index,
    );
  }

  getBuildingPointsOfInterestIsMapLayerActive(): Observable<boolean> {
    return this._buildingRepository.getStoredBuildingPointsOfInterestIsMapLayerActive();
  }

  setBuildingPointsOfInterestIsMapLayerActive(isMapLayerActive: boolean): void {
    this._buildingRepository.setStoredBuildingPointsOfInterestIsMapLayerActive(isMapLayerActive);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building points of interest is map layer active changed`,
      isMapLayerActive,
    );
  }

  getBuildingCentralBusinessDistrictsSelectedDistrictIndex(): Observable<number> {
    return this._buildingRepository.getStoredBuildingCentralBusinessDistrictsSelectedDistrictIndex();
  }

  setBuildingCentralBusinessDistrictsSelectedDistrictIndex(index: number): void {
    this._buildingRepository.setStoredBuildingCentralBusinessDistrictsSelectedDistrictIndex(index);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building central business districts selected district index changed`,
      index,
    );
  }

  getBuildingCentralBusinessDistrictsSelectedAreaIndex(): Observable<number> {
    return this._buildingRepository.getStoredBuildingCentralBusinessDistrictsSelectedAreaIndex();
  }

  setBuildingCentralBusinessDistrictsSelectedAreaIndex(index: number): void {
    this._buildingRepository.setStoredBuildingCentralBusinessDistrictsSelectedAreaIndex(index);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building central business districts selected area index changed`,
      index,
    );
  }

  getBuildingCentralBusinessDistrictsIsMapLayerActive(): Observable<boolean> {
    return this._buildingRepository.getStoredBuildingCentralBusinessDistrictsIsMapLayerActive();
  }

  setBuildingCentralBusinessDistrictsIsMapLayerActive(isMapLayerActive: boolean): void {
    this._buildingRepository.setStoredBuildingCentralBusinessDistrictsIsMapLayerActive(isMapLayerActive);

    this._loggerService.info(
      LoggerTopic.Building,
      `Building central business districts is map layer active changed`,
      isMapLayerActive,
    );
  }

  /**
   * Other management
   */

  findInPortfolios(buildingId: number, portfolios: Array<models.PortfolioViewModel>): models.BuildingViewModel {
    let building = (
      <models.BuildingViewModel>{
        address: <models.AddressViewModel>{},
      }
    );

    if (buildingId) {
      const portfolio = portfolios.find(x => !!x.buildings.find(b => b.id === buildingId));
      if (portfolio) {
        building = portfolio.buildings.find(b => b.id === buildingId);
        if (building && !building.address) {
          building.address = <models.AddressViewModel>{};
        }
      }
    }

    return building;
  }

  getBuildingPicture(building: models.BuildingViewModel | models.BuildingUnitViewModel): string {
    const noImageUrl = 'assets/img/nofoto.png';
    if (!building || !building.attachments) {
      return noImageUrl;
    }
    const pictureAttachments =
      (<Array<models.BuildingAttachment | models.BuildingAttachmentViewModel>>building?.attachments)?.filter(
        x => x.buildingAttachmentType === BuildingAttachmentType.Picture) ?? [];
    const [pictureAttachment] = pictureAttachments;
    if (!pictureAttachment?.file?.url) {
      return noImageUrl;
    }

    return pictureAttachment.file.url;
  }
}
