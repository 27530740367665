<div class="w-columns-comments-head">
  <button class="controls-back"
          (click)="closeComments.emit()">
    <span class="icon-back"></span>
  </button>

  <h1 class="b-header">
    Comments
  </h1><!--b-header-->

  <div class="b-badge"
       [class.b-badge-no-term]="!leaseTermConfiguration">
    {{leaseTermConfiguration ? leaseTermConfiguration?.description : 'no term selected'}}
  </div><!--label-->
</div><!--w-columns-comments-head-->

  <div>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <dx-tabs class="col-chat-tabs" itemTemplate="tabTemplate"
               [items]="tabs" [(selectedIndex)]="selectedTabIndex"
               (onSelectionChanged)="selectionChanged($event)">
        <div *dxTemplate="let itemData of 'tabTemplate'; let itemIndex = index">
          <span class="tab-title">{{itemData.text}}</span>
        </div>
      </dx-tabs>
    </div>
  </div>

<ng-container *ngIf="leaseTermConfiguration; else noSelectedTermTemplate">
  <div class="w-columns-comments-body">
    <div class="avatar-list">
      <app-avatar-list *ngIf="commentProfiles" [avatarList]="avatarItems" [context]="selectedTabIndex ? 'chat-external' : 'chat-internal'">
      </app-avatar-list>
    </div>

    <div class="d-flex flex-column col-chat-chat">
      <app-messages *appCheckAccess="StateraClaimType.Collabo_Comments; stateraClaimValue: StateraClaimValue.Read; leaseId:lease.id"
                    [messageManager]="termCommentManager" [allowAdd]="allowAdd" [allowEdit]="true" [allowDelete]="true"
                    [context]="MessengerContext.Comment"
                    [disabled]="termCommentManager?.isDisabled(lease)">
      </app-messages>
    </div>
  </div><!--w-columns-comments-body-->
</ng-container>
<ng-template #noSelectedTermTemplate>
  <div class="d-flex flex-column justify-content-center align-items-center flex-grow-1 no-message h-100">
    <span class="no-message__icon" inlineSVG="assets/img/sign-icons.svg#comment-icon"></span>
    <span class="no-message__text">No term selected</span>
    <span class="no-message__conv">Select a term to leave a comment</span>
  </div>
</ng-template>
