import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AlertNotification, AlertNotificationKind, AlertType } from '@statera/sdk/alert';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  private static readonly _autocloseTimeout: number = 15_000;

  @Input() alert: AlertNotification;

  AlertType: typeof AlertType = AlertType;
  AlertNotificationKind: typeof AlertNotificationKind = AlertNotificationKind;

  isButtonsDisabled: boolean;

  autoCloseTimeoutId;

  private readonly _keypressHandler: (event: KeyboardEvent) => void;

  private readonly _router: Router;
  private readonly _ngZone: NgZone;
  private readonly _domSanitizer: DomSanitizer;
  private readonly _destroy: Subject<void>;

  constructor(router: Router, ngZone: NgZone, domSanitizer: DomSanitizer) {
    this._router = router;
    this._ngZone = ngZone;
    this._domSanitizer = domSanitizer;
    this._destroy = new Subject<void>();

    this._keypressHandler = event => this._handleEscapeKeyPress(event);
  }

  ngOnInit(): void {
    if (!this.alert || !this.alert.reference) {
      return;
    }

    this.alert.reference.show();

    if (this.alert.autoclose) {
      const autoclose = () => this._ngZone
        .runOutsideAngular(() => {
          this.autoCloseTimeoutId = setTimeout(
            () => this._ngZone.run(() => this.hide()),
            this.alert.autocloseTimeout || AlertComponent._autocloseTimeout
          );
        });

      let waitUntilVisibleTimeoutId;
      const waitUntilVisible = () => this._ngZone
        .runOutsideAngular(() => {
          if (waitUntilVisibleTimeoutId) {
            clearTimeout(waitUntilVisibleTimeoutId);
          }

          if (!document.hidden) {
            autoclose();
            return;
          }

          waitUntilVisibleTimeoutId = setTimeout(
            () => {
              if (document.hidden) {
                waitUntilVisible();
                return;
              }

              autoclose();
            },
            250
          );
        });

      waitUntilVisible();
    }

    document.addEventListener('keydown', this._keypressHandler);
  }

  ngOnDestroy(): void {
    if (this.autoCloseTimeoutId) {
      clearTimeout(this.autoCloseTimeoutId);
      this.autoCloseTimeoutId = null;
    }

    document.removeEventListener('keydown', this._keypressHandler);

    this._destroy.next();
    this._destroy.complete();
  }

  getAlertClassName(): string {
    if (!this.alert || !this.alert.kind) {
      return null;
    }

    switch (this.alert.kind) {
      case AlertNotificationKind.Success:
        return 'alert-success';

      case AlertNotificationKind.Info:
        return 'alert-info';

      case AlertNotificationKind.Warning:
        return 'alert-warning';

      case AlertNotificationKind.Error:
        return 'alert-error';

      case AlertNotificationKind.Confirm:
        return 'alert-confirm';
    }

    return null;
  }

  confirm(): void {
    if (!this.alert || !this.alert.reference) {
      return;
    }

    this.isButtonsDisabled = this.alert.shouldDisableButtons;

    this.alert
      .reference
      .confirm()
      .pipe(
        tap(() => {
          if (!this.alert.closeOnButtonClick) {
            return;
          }

          this.isButtonsDisabled = false;

          this.hide();
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  decline(): void {
    if (!this.alert || !this.alert.reference) {
      return;
    }

    this.isButtonsDisabled = this.alert.shouldDisableButtons;

    this.alert
      .reference
      .decline()
      .pipe(
        tap(() => {
          if (!this.alert.closeOnButtonClick) {
            return;
          }

          this.isButtonsDisabled = false;

          this.hide();
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  hide(): void {
    if (!this.alert || !this.alert.reference) {
      return;
    }

    this.alert.reference.hide();
  }

  navigate(): void {
    if (!this.alert || !this.alert.navigation) {
      return;
    }

    const { navigation } = this.alert;

    this.alert.reference.hide();

    this._router.navigate([navigation.path], { queryParams: navigation.queryParams });
  }

  isMessageContainsHTMLTags(message: string): boolean {
    return /<[a-z][\s\S]*>/i.test(message);
  }

  getSanitizedHTML(message: string): SafeHtml {
    return this._domSanitizer.bypassSecurityTrustHtml(message);
  }

  private _handleEscapeKeyPress(event: KeyboardEvent): void {
    if (!event || (event.key !== 'Escape' && event.code !== 'Escape')) {
      return;
    }

    event.preventDefault();

    this.hide();
  }
}
