import { Injectable } from '@angular/core';

import { ChatChannelType, TermCommentType } from '@statera/sdk/common';
import { LoggerService, LoggerTopic } from '@statera/sdk/logger';
import { Notification, NotificationRepository, NotificationTopic } from '@statera/sdk/notification';
import { WebsocketClient, WebsocketRepository } from '@statera/sdk/websocket';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
  Alert,
  AlertGroup,
  AlertNotification,
  AlertNotificationKind,
  AlertNotificationReference,
  AlertType,
  LeaseTermType,
} from './alert.model';

import { AlertRepository } from './alert.repository';
import { AlertTransformer } from './alert.transformer';

const LEASE_TERM_NAMES: {} = {
  [LeaseTermType.ExpansionOption]: 'ExpansionOption',
  [LeaseTermType.Commencement]: 'Commencement',
  [LeaseTermType.Term]: 'Term',
  [LeaseTermType.TenantSquareFootage]: 'TenantSquareFootage',
  [LeaseTermType.FreeRent]: 'FreeRent',
  [LeaseTermType.BaseRentalRate]: 'RentalRate',
  [LeaseTermType.SecurityDeposit]: 'SecurityDeposit',
  [LeaseTermType.RealEstateTaxesCamExpenses]: 'RealEstateTaxesCamExpenses',
  [LeaseTermType.RentalRateAnnualEscalation]: 'RentalRateAnnualEscalation',
  [LeaseTermType.TenantImprovements]: 'TenantImprovements',
  [LeaseTermType.LandlordMaintenance]: 'LandlordMaintenance',
  [LeaseTermType.TenantMaintenance]: 'TenantMaintenance',
  [LeaseTermType.SelfHelp]: 'SelfHelp',
  [LeaseTermType.Assignment]: 'Assignment',
  [LeaseTermType.RenewalOption]: 'RenewalOption',
  [LeaseTermType.EstoppelCertificate]: 'EstoppelCertificate',
  [LeaseTermType.TerminationOption]: 'TerminationOption',
  [LeaseTermType.Hvac]: 'Hvac',
  [LeaseTermType.Insurance]: 'Insurance',
  [LeaseTermType.HoldoverProvision]: 'HoldoverProvision',
  [LeaseTermType.Parking]: 'Parking',
  [LeaseTermType.Utilities]: 'Utilities',
  [LeaseTermType.SpaceUse]: 'SpaceUse',
  [LeaseTermType.CodeCompliance]: 'CodeCompliance',
  [LeaseTermType.ConditionOfPremises]: 'ConditionOfPremises',
  [LeaseTermType.Signage]: 'Signage',
};

@Injectable()
export class AlertManager {
  private readonly _loggerService: LoggerService;
  private readonly _alertRepository: AlertRepository;
  private readonly _alertTransformer: AlertTransformer;
  private readonly _websocketRepository: WebsocketRepository;
  private readonly _websocketClient: WebsocketClient;
  private readonly _notificationRepository: NotificationRepository;

  constructor(
    loggerService: LoggerService,
    alertRepository: AlertRepository,
    alertTransformer: AlertTransformer,
    websocketRepository: WebsocketRepository,
    websocketClient: WebsocketClient,
    notificationRepository: NotificationRepository
  ) {
    this._loggerService = loggerService;
    this._alertRepository = alertRepository;
    this._alertTransformer = alertTransformer;
    this._websocketRepository = websocketRepository;
    this._websocketClient = websocketClient;
    this._notificationRepository = notificationRepository;
  }

  markAlertRead(alert: Alert): Observable<void> {
    return this._alertRepository.markAlertRead(alert);
  }

  getAlertGroup(): Observable<AlertGroup> {
    const alertGroupObserver = new Subject<AlertGroup>();
    const destroyObserver = new Subject<void>();

    this._alertRepository
      .getAlertGroup()
      .pipe(
        take(1),
        tap(alertGroup => alertGroupObserver.next(alertGroup)),
        takeUntil(destroyObserver),
      )
      .subscribe();

    this._websocketRepository
      .getRealtimeAlertMessage()
      .pipe(
        switchMap(() => this.getAlertGroup()),
        tap(alertGroup => alertGroupObserver.next(alertGroup)),
        takeUntil(destroyObserver),
      )
      .subscribe();

    return alertGroupObserver.pipe(
      finalize(() => {
        destroyObserver.next();
        destroyObserver.complete();
      }),
    );
  }

  getAlertsThatRequiresAction(): Observable<Array<Alert>> {
    const alertsThatRequiresActionObserver = new Subject<Array<Alert>>();
    const destroyObserver = new Subject<void>();

    this._alertRepository
      .getAlertsThatRequireAction()
      .pipe(
        take(1),
        tap(alerts => alertsThatRequiresActionObserver.next(alerts)),
        takeUntil(destroyObserver),
      )
      .subscribe();

    this._websocketRepository
      .getRealtimeAlertMessage()
      .pipe(
        switchMap(() => this._alertRepository.getAlertsThatRequireAction()),
        tap(alerts => alertsThatRequiresActionObserver.next(alerts)),
        takeUntil(destroyObserver),
      )
      .subscribe();

    return alertsThatRequiresActionObserver.pipe(
      finalize(() => {
        destroyObserver.next();
        destroyObserver.complete();
      }),
    );
  }

  changeIsRequiresAction(alertId: number, isRequiresAction: boolean): Observable<Alert> {
    return this._alertRepository
      .changeIsRequiresAction(alertId, isRequiresAction)
      .pipe(
        map(alert => this._alertTransformer
          .transformAlert(alert)
        )
      );
  }

  queueAlertNotification(alertNotification: AlertNotification): AlertNotificationReference {
    alertNotification = this._fillAlertNotificationWithDefaultValues(alertNotification);

    const notification = new Notification(alertNotification.id, alertNotification);

    if (alertNotification.kind === AlertNotificationKind.Confirm) {
      this._notificationRepository.pushNotification(NotificationTopic.AlertRequiresAction, notification);
      return alertNotification.reference;
    }

    this._notificationRepository.pushNotification(NotificationTopic.Alert, notification);

    return alertNotification.reference;
  }

  dequeueAlertNotification(alertNotification: AlertNotification): void {
    this._alertRepository.addDequeuedAlertId(alertNotification.id);

    if (alertNotification.kind === AlertNotificationKind.Confirm) {
      this._notificationRepository.removeNotification(NotificationTopic.AlertRequiresAction, alertNotification.id);
      return;
    }

    this._notificationRepository.removeNotification(NotificationTopic.Alert, alertNotification.id);
  }

  isNotifyingUserAboutNewAlertBlocked(): Observable<boolean> {
    return this._notificationRepository
      .getNotifications(NotificationTopic.AlertRequiresAction)
      .pipe(
        map(notifications => Boolean(notifications && notifications.length)),
      );
  }

  notifyUserAboutNewAlert(): Observable<Notification<AlertNotification>> {
    const destroyObserver = new Subject<void>();

    this.getAlertGroup()
      .pipe(
        tap(alertGroup => this._notifyUserAboutNewAlerts(alertGroup)),
        takeUntil(destroyObserver),
      )
      .subscribe();

    return this._notificationRepository
      .getNotifications(NotificationTopic.Alert)
      .pipe(
        map(notifications => {
          if (!notifications || !notifications.length) {
            return null;
          }

          return notifications[0];
        }),
        finalize(() => {
          destroyObserver.next();
          destroyObserver.complete();
        }),
      );
  }

  notifyUserAboutRequiresActionAlert(): Observable<Notification<AlertNotification>> {
    const destroyObserver = new Subject<void>();

    this._websocketClient
      .connectionTerminated()
      .pipe(
        tap(() => this._notifyUserAboutWebsocketTermination()),
        takeUntil(destroyObserver),
      )
      .subscribe();

    this.getAlertsThatRequiresAction()
      .pipe(
        tap(alerts => this._notifyUserAboutNewRequiresActionAlerts(alerts)),
        takeUntil(destroyObserver),
      )
      .subscribe();

    return this._notificationRepository
      .getNotifications(NotificationTopic.AlertRequiresAction)
      .pipe(
        map(notifications => {
          if (!notifications || !notifications.length) {
            return null;
          }

          return notifications[0];
        }),
        finalize(() => {
          destroyObserver.next();
          destroyObserver.complete();
        }),
      );
  }

  private _notifyUserAboutWebsocketTermination(): void {
    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._fillAlertNotificationWithDefaultValues(<AlertNotification>{
      id: 'connection-lost',
      kind: AlertNotificationKind.Confirm,
      title: 'Ooops! Connection Lost',
      message: `Slow or no connection. Please reload page.`,
      alertType: AlertType.WebSocketConnectionTerminated,
      closable: false,
      autoclose: false,
      confirmButtonText: 'Reload',
      shouldShowDeclineButton: false,
      reference: alertNotificationReference,
    });

    this.queueAlertNotification(alertNotification);
  }

  private _fillAlertNotificationWithDefaultValues(alertNotification: AlertNotification): AlertNotification {
    let defaultAlertNotification = <AlertNotification>{
      id: alertNotification.id || Date.now(),
      kind: alertNotification.kind || AlertNotificationKind.Info,
      reference: alertNotification.reference || new AlertNotificationReference(),
      closable: true,
      autoclose: true,
    };

    if (alertNotification.kind === AlertNotificationKind.Confirm) {
      defaultAlertNotification = {
        ...defaultAlertNotification,
        closable: true,
        autoclose: false,
        confirmButtonText: 'Yes',
        declineButtonText: 'No',
        shouldShowConfirmButton: true,
        shouldShowDeclineButton: true,
        closeOnButtonClick: true,
        shouldDisableButtons: true,
      };
    }

    return {
      ...defaultAlertNotification,
      ...alertNotification,
    };
  }

  private _getAlertNotification(
    alertNotificationKind: AlertNotificationKind,
    alert: Alert,
    reference: AlertNotificationReference,
    options: AlertNotification = <AlertNotification>{},
  ): AlertNotification {
    return <AlertNotification>{
      id: alert.id,
      senderName: alert.createdBy ? alert.createdBy.displayName : null,
      senderCompany: alert.createdBy ? alert.createdBy.company ? alert.createdBy.company.name : null : null,
      senderRole: alert.leaseUserRole,
      leaseId: alert.leaseId,
      projectId: alert.projectId,
      projectType: alert.projectType,
      buildingId: alert.buildingId,
      buildingAddress: alert.leaseBuilding?.address?.displayString,
      leaseRequestId: alert.leaseRequestId,
      termName: LEASE_TERM_NAMES[alert.leaseTermType],
      kind: alertNotificationKind || AlertNotificationKind.Info,
      reference: reference,
      alertType: alert.alertSettingTemplate && alert.alertSettingTemplate.alertType,
      title: alert.title,
      message: alert.text,
      closable: true,
      autoclose: true,
      shouldDisableButtons: true,
      chatType: this._getMessageChatType(alert),
      ...options,
    };
  }

  private _getConfirmAlertNotification(
    alert: Alert,
    reference: AlertNotificationReference,
    options: AlertNotification = <AlertNotification>{},
  ): AlertNotification {
    return <AlertNotification>{
      id: alert.id,
      senderName: alert.createdBy ? alert.createdBy.displayName : null,
      senderCompany: alert.createdBy ? alert.createdBy.company ? alert.createdBy.company.name : null : null,
      senderRole: alert.leaseUserRole,
      leaseId: alert.leaseId,
      projectId: alert.projectId,
      projectType: alert.projectType,
      buildingId: alert.buildingId,
      buildingAddress: alert.leaseBuilding?.address?.displayString,
      leaseRequestId: alert.leaseRequestId,
      termName: LEASE_TERM_NAMES[alert.leaseTermType],
      kind: AlertNotificationKind.Confirm,
      reference: reference,
      alertType: alert.alertSettingTemplate && alert.alertSettingTemplate.alertType,
      title: alert.title,
      message: alert.text,
      closable: true,
      autoclose: false,
      shouldShowConfirmButton: true,
      shouldShowDeclineButton: true,
      closeOnButtonClick: true,
      shouldDisableButtons: true,
      chatType: this._getMessageChatType(alert),
      ...options,
    };
  }

  private _getMessageChatType(alert: Alert): string | null {
    if (alert.chatChannelType != null) {
      switch (alert.chatChannelType) {
        case ChatChannelType.LeaseLandlordTeam:
        case ChatChannelType.LeaseTenantTeam: return 'Internal';
        case ChatChannelType.Lease: return 'External';
      }
      return null;
    }

    if (alert.leaseTermType != null) {
      switch (alert.termCommentType) {
        case TermCommentType.LandlordTeam:
        case TermCommentType.TenantTeam: return 'Internal';
        case TermCommentType.BothTeams: return 'External';
      }
    }

    return null;
  }

  private _notifyUserAboutNewAlerts(alertGroup: AlertGroup): void {
    if (!alertGroup) {
      this._loggerService.warning(LoggerTopic.Alert, `Alert group is null`);
      return;
    }

    let alert = alertGroup.lastAlert;
    if (!alert) {
      this._loggerService.info(LoggerTopic.Alert, `Alerts not listed`);
      return;
    }

    if (alert.isRead) {
      this._loggerService.info(LoggerTopic.Alert, `Alert already read`, { alert });
      return;
    }

    const dequeuedAlertIds = this._alertRepository.getDequeuedAlertIds();
    if (dequeuedAlertIds.includes(alert.id)) {
      return;
    }

    alert = this._alertTransformer.transformAlert(alert);

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getAlertNotification(AlertNotificationKind.Info, alert, alertNotificationReference);

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .shown
      .pipe(
        take(1),
        tap(() => this._loggerService
          .debug(LoggerTopic.Alert, `Alert shown`, { alert })
        ),
        switchMap(() => this.markAlertRead(alert)),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        take(1),
        tap(() => this._loggerService
          .debug(LoggerTopic.Alert, `Alert hidden`, { alert })
        ),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    this._notificationRepository.pushNotification<AlertNotification>(NotificationTopic.Alert, notification);
  }

  private _notifyUserAboutNewRequiresActionAlerts(alerts: Array<Alert>): void {
    if (!alerts || !alerts.length) {
      this._loggerService.info(LoggerTopic.Alert, `Alerts not listed`);
      return;
    }

    const dequeuedAlertIds = this._alertRepository.getDequeuedAlertIds();

    // Exclude already dequeued alerts
    alerts = alerts.filter(x => !dequeuedAlertIds.includes(x.id));

    const buildingUnitListingUpdatePricePromptAlerts = alerts.filter(x =>
      x.alertSettingTemplate &&
      x.alertSettingTemplate.alertType === AlertType.BuildingUnitListingUpdatePricePrompt
    );

    if (buildingUnitListingUpdatePricePromptAlerts && buildingUnitListingUpdatePricePromptAlerts.length) {
      this._loggerService.info(
        LoggerTopic.Alert,
        `Building unit listing update price prompt alerts received`,
        [...buildingUnitListingUpdatePricePromptAlerts]
      );

      this._notifyUserAboutNewBuildingUnitListingUpdatePricePromptAlerts(buildingUnitListingUpdatePricePromptAlerts);
    }

    const inquiriesAndRequestsUpdatePromptAlerts = alerts.filter(x =>
      x.alertSettingTemplate &&
      x.alertSettingTemplate.alertType === AlertType.InquiriesAndRequestsUpdatePrompt
    );

    if (inquiriesAndRequestsUpdatePromptAlerts && inquiriesAndRequestsUpdatePromptAlerts.length) {
      this._loggerService.info(
        LoggerTopic.Alert,
        `Inquiries and requests update prompt alerts received`,
        [...inquiriesAndRequestsUpdatePromptAlerts]
      );

      this._notifyUserAboutNewInquiriesAndRequestsUpdatePromptAlerts(inquiriesAndRequestsUpdatePromptAlerts);
    }

    const financialsRequestAlerts = alerts.filter(x =>
      x.alertSettingTemplate &&
      x.alertSettingTemplate.alertType === AlertType.FinancialsRequest
    );

    if (financialsRequestAlerts && financialsRequestAlerts.length) {
      this._loggerService.info(
        LoggerTopic.Alert,
        `Financials request alerts received`,
        [...financialsRequestAlerts]
      );

      this._notifyUserAboutNewFinancialsRequestAlerts(financialsRequestAlerts);
    }

    const financialsReviewRequestAlerts = alerts.filter(x =>
      x.alertSettingTemplate &&
      x.alertSettingTemplate.alertType === AlertType.FinancialsReviewRequest
    );

    if (financialsReviewRequestAlerts && financialsReviewRequestAlerts.length) {
      this._loggerService.info(
        LoggerTopic.Alert,
        `Financials review request alerts received`,
        [...financialsReviewRequestAlerts]
      );

      this._notifyUserAboutNewFinancialsReviewRequestAlerts(financialsReviewRequestAlerts);
    }

    const renewalOrRestructureRequestAlerts = alerts.filter(x =>
      x.alertSettingTemplate &&
      x.alertSettingTemplate.alertType === AlertType.NewRenewalOrRestructureRequest
    );

    if (renewalOrRestructureRequestAlerts && renewalOrRestructureRequestAlerts.length) {
      this._loggerService.info(
        LoggerTopic.Alert,
        `Renewal/Restructure request alerts received`,
        [...renewalOrRestructureRequestAlerts]
      );

      this._notifyUserAboutNewRenewalOrRestructureRequestAlerts(renewalOrRestructureRequestAlerts);
    }

    const coBrokerInvitationByBrokerAlerts = alerts.filter(x =>
      x.alertSettingTemplate &&
      x.alertSettingTemplate.alertType === AlertType.CoBrokerInvitationByBroker
    );

    if (coBrokerInvitationByBrokerAlerts && coBrokerInvitationByBrokerAlerts.length) {
      this._loggerService.info(
        LoggerTopic.Alert,
        `CoBrokerInvitationByBrokerAlerts request alerts received`,
        [...coBrokerInvitationByBrokerAlerts]
      );

      this._notifyCoBrokerInvitationAlerts(coBrokerInvitationByBrokerAlerts);
    }

    const tourAlerts = alerts
      .filter(x =>
        x.alertSettingTemplate &&
        (
          x.alertSettingTemplate.alertType === AlertType.TourCreatedOtherTeamAlert ||
          x.alertSettingTemplate.alertType === AlertType.TourUpdatedOtherTeamAlert
        )
      );

    if (tourAlerts && tourAlerts.length) {
      this._loggerService
        .info(LoggerTopic.Alert, `Tour created/updated (to other team) alerts received`, alerts);

      this._notifyTourCreatedUpdatedOtherTeamAlerts(tourAlerts);
    }
  }

  private _notifyUserAboutNewInquiriesAndRequestsUpdatePromptAlerts(alerts: Array<Alert>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    let alert = alerts.shift();
    if (!alert) {
      this._loggerService.warning(LoggerTopic.Alert, `Alert is null`);
      return;
    }

    const buildingId = alert.buildingId;
    if (!buildingId) {
      this._loggerService.warning(LoggerTopic.Alert, `Can't open confirm alert because it doesn't contain building id`, alert);
      return;
    }

    alert = this._alertTransformer.transformAlert(alert);

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Modify',
        declineButtonText: 'Keep',
        closeOnButtonClick: true,
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService.error(LoggerTopic.Alert, `Something went wrong`, err);
          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .declined
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService.error(LoggerTopic.Alert, `Something went wrong`, err);
          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        tap(() => {
          this._loggerService.info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService.info(LoggerTopic.Alert, `Showing next inquiries and requests update prompt alert`);

          this._notifyUserAboutNewInquiriesAndRequestsUpdatePromptAlerts(alerts);
        }),
        take(1),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    this._notificationRepository.pushNotification<AlertNotification>(NotificationTopic.AlertRequiresAction, notification);
  }

  private _notifyUserAboutNewBuildingUnitListingUpdatePricePromptAlerts(alerts: Array<Alert>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    let alert = alerts.shift();
    if (!alert) {
      this._loggerService.warning(LoggerTopic.Alert, `Alert is null`);
      return;
    }

    const buildingId = alert.buildingId;
    if (!buildingId) {
      this._loggerService.warning(LoggerTopic.Alert, `Can't open confirm alert because it doesn't contain building id`, alert);
      return;
    }

    alert = this._alertTransformer.transformAlert(alert);

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Modify',
        declineButtonText: 'Keep',
        closeOnButtonClick: true,
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService.error(LoggerTopic.Alert, `Something went wrong`, err);
          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .declined
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService.error(LoggerTopic.Alert, `Something went wrong`, err);
          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        take(1),
        tap(() => {
          this._loggerService.info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService.info(LoggerTopic.Alert, `Showing next building unit listing update price prompt alert`);

          this._notifyUserAboutNewBuildingUnitListingUpdatePricePromptAlerts(alerts);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    this._notificationRepository.pushNotification<AlertNotification>(NotificationTopic.AlertRequiresAction, notification);
  }

  private _notifyUserAboutNewFinancialsRequestAlerts(alerts: Array<Alert>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    let alert = alerts.shift();
    if (!alert) {
      this._loggerService.warning(LoggerTopic.Alert, `Alert is null`);
      return;
    }

    alert = this._alertTransformer.transformAlert(alert);

    const leaseRequestId = alert.leaseRequestId;
    if (!leaseRequestId) {
      this._loggerService.warning(LoggerTopic.Alert, `Can't open alert because it doesn't contain lease request id`, alert);
      return;
    }

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Upload',
        declineButtonText: 'Cancel',
        closeOnButtonClick: false,
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .hidden
      .pipe(
        take(1),
        tap(() => {
          this._loggerService.info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService.info(LoggerTopic.Alert, `Showing next financial request alert`);

          this._notifyUserAboutNewFinancialsRequestAlerts(alerts);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    this._notificationRepository.pushNotification<AlertNotification>(NotificationTopic.AlertRequiresAction, notification);
  }

  private _notifyUserAboutNewFinancialsReviewRequestAlerts(alerts: Array<Alert>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    let alert = alerts.shift();
    if (!alert) {
      this._loggerService.warning(LoggerTopic.Alert, `Alert is null`);
      return;
    }

    const leaseId = alert.leaseId;
    if (!leaseId) {
      this._loggerService.warning(LoggerTopic.Alert, `Can't open confirm dialog because alert doesn't contain lease id`, alert);
      return;
    }

    alert = this._alertTransformer.transformAlert(alert);

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Review',
        declineButtonText: 'Cancel',
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService.error(LoggerTopic.Alert, `Something went wrong`, err);
          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        take(1),
        tap(() => {
          this._loggerService.info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService.info(LoggerTopic.Alert, `Showing next financial review request alert`);

          this._notifyUserAboutNewFinancialsReviewRequestAlerts(alerts);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    this._notificationRepository.pushNotification<AlertNotification>(NotificationTopic.AlertRequiresAction, notification);
  }

  private _notifyUserAboutNewRenewalOrRestructureRequestAlerts(alerts: Array<Alert>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    let alert = alerts.shift();
    if (!alert) {
      this._loggerService.warning(LoggerTopic.Alert, `Alert is null`);
      return;
    }

    const leaseId = alert.leaseId;
    if (!leaseId) {
      this._loggerService.warning(LoggerTopic.Alert, `Can't open confirm dialog because alert doesn't contain lease id`, alert);
      return;
    }

    alert = this._alertTransformer.transformAlert(alert);

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Review',
        declineButtonText: 'Cancel',
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService.error(LoggerTopic.Alert, `Something went wrong`, err);
          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        take(1),
        tap(() => {
          this._loggerService.info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService.info(LoggerTopic.Alert, `Showing next renewal/restructure request alert`);

          this._notifyUserAboutNewRenewalOrRestructureRequestAlerts(alerts);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    this._notificationRepository.pushNotification<AlertNotification>(NotificationTopic.AlertRequiresAction, notification);
  }

  private _notifyCoBrokerInvitationAlerts(alerts: Array<Alert>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    let alert = alerts.shift();
    if (!alert) {
      this._loggerService.warning(LoggerTopic.Alert, `Alert is null`);
      return;
    }

    const leaseId = alert.leaseId;
    if (!leaseId) {
      this._loggerService.warning(LoggerTopic.Alert, `Can't open confirm dialog because alert doesn't contain lease id`, alert);
      return;
    }

    alert = this._alertTransformer.transformAlert(alert);

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: 'Yes',
        declineButtonText: 'No',
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .declined
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        take(1),
        tap(() => {
          this._loggerService.info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService.info(LoggerTopic.Alert, `Showing next co-broker invitation alert`);

          this._notifyCoBrokerInvitationAlerts(alerts);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    this._notificationRepository.pushNotification<AlertNotification>(NotificationTopic.AlertRequiresAction, notification);
  }

  private _notifyTourCreatedUpdatedOtherTeamAlerts(alerts: Array<Alert>): void {
    if (!alerts || !alerts.length) {
      return;
    }

    const alert = alerts.shift();
    if (!alert) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Alert is null`);

      return;
    }

    const leaseId = alert.leaseId;
    if (!leaseId) {
      this._loggerService
        .warning(LoggerTopic.Alert, `Can't open confirm dialog because alert doesn't contain lease id`, alert);

      return;
    }

    const alertNotificationReference = new AlertNotificationReference();

    const alertNotification = this._getConfirmAlertNotification(
      alert,
      alertNotificationReference,
      <AlertNotification>{
        confirmButtonText: (
          alert.alertSettingTemplate.alertType === AlertType.TourUpdatedOtherTeamAlert ?
            'Pick Slots' :
            'Review'
        ),
        declineButtonText: 'Cancel',
      },
    );

    const notification = new Notification(alert.id, alertNotification);

    alertNotificationReference
      .confirmed
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .declined
      .pipe(
        take(1),
        switchMap(() => this.changeIsRequiresAction(alert.id, false)),
        catchError(err => {
          this._loggerService
            .error(LoggerTopic.Alert, `Something went wrong`, err);

          return throwError(err);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    alertNotificationReference
      .hidden
      .pipe(
        take(1),
        tap(() => {
          this._loggerService
            .info(LoggerTopic.Alert, `Alert hidden`, alert);

          if (!alerts || !alerts.length) {
            return;
          }

          this._loggerService
            .info(LoggerTopic.Alert, `Showing next tour created/updated (to other team) alert`);

          this._notifyTourCreatedUpdatedOtherTeamAlerts(alerts);
        }),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    this._notificationRepository
      .pushNotification<AlertNotification>(NotificationTopic.Alert, notification);
  }
}
