import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AlertNotification, AlertType } from '@statera/sdk/alert';
import { LoggerService, LoggerTopic } from '@statera/sdk/logger';
import { Notification, NotificationInterceptor } from '@statera/sdk/notification';

@Injectable()
export class InquiriesAndRequestsUpdatePromptInterceptor implements NotificationInterceptor<AlertNotification> {
  private readonly _router: Router;
  private readonly _loggerService: LoggerService;

  constructor(router: Router, loggerService: LoggerService) {
    this._router = router;
    this._loggerService = loggerService;
  }

  intercept(notification: Notification<AlertNotification>): Notification<AlertNotification> {
    const alert = notification.payload;
    if (!alert) {
      return notification;
    }

    if (alert.alertType !== AlertType.InquiriesAndRequestsUpdatePrompt) {
      return notification;
    }

    alert
      .reference
      .confirmed
      .pipe(
        tap(() => {
          const {origin} = location;
          location.assign([origin, `/v2/inquiries-and-requests?leaseId=${alert.leaseId}`].filter(Boolean).join(''));
        }),
        take(1),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    return notification;
  }
}
