<button type="button"
        class="b-btn-icon-info position-absolute"
        [class.edit-mode-btn]="editMode"
        (click)="$event.preventDefault()"
        #burndownTooltip>
  <span class="icon-info"
        inlineSVG="assets/img/signs-icons.svg#icon-tooltip">
  </span>
</button>
<dx-tooltip class="v2-default-tooltip"
            position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend"
            [target]="burndownTooltip">
  <div>
    Building Level MLA
  </div>
</dx-tooltip>
<dx-scroll-view>
  <form name="form" novalidate
        class="form"
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <div class="form-content">
      <div class="form-content-column">
        <div class="building-cart">
          <div>
            <!-- Start: Name -->
            <div class="building-cart-title">
              <i inlineSVG="assets/img/sign-icons.svg#unit-icon"></i>
              <p>
                {{building.name}}
              </p>
            </div>
            <!-- End: Name -->
            <!-- Start: Size -->
            <ng-container *ngIf="building.totalBuildingSize; else noBuildingUnitSizeTemplate">
              <p class="building-cart-size">
                Unit size: {{building.totalBuildingSize | safeNumber}} SF
              </p>
            </ng-container>
            <ng-template #noBuildingUnitSizeTemplate>
              <p class="building-cart-size">
                Unit size: N/A
              </p>
            </ng-template>
            <!-- End: Size -->
          </div>
        </div>
        <p class="form-title">
          Investment/Return
        </p>
        <!-- Start: Investment Basis -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Investment Basis
            </p>
            <div class="dx-field-value">
              <div class="test">
                <app-input-field name="investment-basis" placeholder="(e.g. 50)"
                                 className="no-invalid-message" width="75px"
                                 format="#,##0.##" prefix="$" suffix="PSF" min="0" step="0"
                                 errorMessage="Investment Basis is required"
                                 [value]="buildingBudget.investmentBasis"
                                 (valueChange)="buildingBudget.investmentBasis = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Investment Basis -->
        <!-- Start: Investment Date -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Investment Date
            </p>
            <div class="dx-field-value">
              <div>
                <dx-date-box type="date" name="investment-date"
                             class="real-estate-input" width="250px"
                             placeholder="Insert investment date"
                             dateSerializationFormat="yyyy-MM-dd" pickerType="calendar"
                             [(ngModel)]="buildingBudget.investmentDate">
                  <dx-validator>
                    <dxi-validation-rule type="required" class="error-message" message="INVESTMENT DATE IS REQUIRED">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-date-box>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Investment Date -->
        <!-- Start: Budgeted CapEx -->
        <div>
          <div class="dx-field">
            <p class="form-text pt-2">
              Budgeted CapEx
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="budgeted-cap-ex" placeholder="(e.g. 5.00)"
                                 className="no-invalid-message" width="87px"
                                 format="#,##0.##" prefix="$" suffix="PSF" min="0" step="0"
                                 errorMessage="Budgeted CapEx is required"
                                 [value]="buildingBudget.budgetedCapex"
                                 (valueChange)="buildingBudget.budgetedCapex = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Budgeted CapEx -->

        <div *ngIf="buildingBudget.investmentBasis && buildingBudget.budgetedCapex">
          <div class="dx-field">
            <p class="form-text">
              Total Cost: {{(buildingBudget.investmentBasis + buildingBudget.budgetedCapex) | safeCurrency}}
            </p>
          </div>
        </div>

        <div>
          <div class="dx-field">
            <p class="form-text">
              <dx-check-box name="has-capital-items" id="add-capital-items-check-box"
                            class="mb-2"
                            [(ngModel)]="buildingBudget.hasCapitalItems">
              </dx-check-box>
              <label for="add-capital-items-check-box">
                Add Capital Items
              </label>
            </p>
            <div class="dx-field-value"
                 *ngIf="buildingBudget.hasCapitalItems">
              <div>
                <dx-tag-box name="capital-items" placeholder="Type in Capital Items" required
                            class="no-invalid-message real-estate-input mb-0"
                            [acceptCustomValue]="true" [openOnFieldClick]="false" [multiline]="true"
                            [(ngModel)]="buildingBudget.capitalItems">
                  <dx-validator>
                    <dxi-validation-rule type="required" message="Capital Items is required">
                    </dxi-validation-rule>
                  </dx-validator>
                </dx-tag-box>
              </div>
            </div>
          </div>
        </div>
        <!-- Start: Going In Cap Rate -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Going In Cap Rate
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="going-in-cap-rate" placeholder="(e.g. 1)"
                                 className="no-invalid-message" width="68px"
                                 format="#0.##" suffix="%" min="0" step="0"
                                 errorMessage="Going In Cap Rate is required"
                                 [value]="buildingBudget.goingInCapRate"
                                 (valueChange)="buildingBudget.goingInCapRate = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Going In Cap Rate -->
        <!-- Start: Exit Cap Rate -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Exit Cap Rate
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="exit-cap-rate" placeholder="(e.g. 1)"
                                 className="no-invalid-message" width="68px"
                                 format="#0.##" suffix="%" min="0" step="0"
                                 errorMessage="Exit Cap Rate is required"
                                 [value]="buildingBudget.exitCapRate"
                                 (valueChange)="buildingBudget.exitCapRate = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Exit Cap Rate -->
        <!-- Start: Yield-On-Cash -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Yield-On-Cash
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="yield-on-cash" placeholder="(e.g. 1)"
                                 className="no-invalid-message" width="68px"
                                 format="#0.##" suffix="%" min="0" step="0"
                                 errorMessage="Yield-On-Cash is required"
                                 [value]="buildingBudget.yieldOnCash"
                                 (valueChange)="buildingBudget.yieldOnCash = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Yield-On-Cash -->
      </div>
      <div class="form-content-column">
        <p class="form-title">
          MLA
        </p>
        <!-- Start: Market Rent -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Market Rent
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="market-rate" placeholder="(e.g. 5.50)"
                                 className="no-invalid-message" width="87px"
                                 format="#,##0.##" prefix="$" suffix="PSF/Y" min="0" step="0"
                                 errorMessage="Market rate is required"
                                 [value]="buildingBudget.marketRate"
                                 (valueChange)="buildingBudget.marketRate = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Market Rent -->
        <!-- Start: Market Rent Growth -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Market Rent Growth
            </p>
            <div class="dx-field-value">
              <div>
                <table class="form-table">
                  <thead>
                  <tr>
                    <th>Year</th>
                    <th>%</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let marketRateGrowth of buildingBudget.marketRateGrowths; let index = index;">
                    <td>
                      {{marketRateGrowth.year}}
                    </td>
                    <td>
                      <app-input-field [name]="'market-rate-growth-' + index" placeholder="(e.g. 1)"
                                       className="no-invalid-message py-0" width="68px" [rounded]="true"
                                       format="#0.##" suffix="%" min="0" step="0"
                                       errorMessage="Market Rate Growth is required"
                                       [value]="marketRateGrowth.value"
                                       (valueChange)="marketRateGrowth.value = $event;">
                      </app-input-field>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td>Average</td>
                    <td>
                      {{getAverageMarketRateGrowth() | safeNumber: '1.2-2'}}%
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Market Rent Growth -->
        <!-- Start: Lease Term -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Lease Term
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="lease-term" placeholder="(e.g. 5)"
                                 className="no-invalid-message" width="68px"
                                 format="#0" suffix="months" min="0" step="0"
                                 errorMessage="Lease Term is required"
                                 [value]="buildingBudget.leaseTerm"
                                 (valueChange)="buildingBudget.leaseTerm = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Lease Term -->
        <!-- Start: Escalations -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Escalations
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="escalations" placeholder="(e.g. 2.5)"
                                 className="no-invalid-message" width="80px"
                                 format="#0.##" suffix="%" min="0" step="0"
                                 errorMessage="Escalations is required"
                                 [value]="buildingBudget.escalations"
                                 (valueChange)="buildingBudget.escalations = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Escalations -->
        <!-- Start: Free Rent (Renewal) -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Free Rent (Renewal)
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="free-rent-renewal" placeholder="(e.g. 1)"
                                 className="no-invalid-message" width="68px"
                                 format="#0" suffix="months" min="0" step="0"
                                 errorMessage="Free Rent is required"
                                 [value]="buildingBudget.freeRentRenewal"
                                 (valueChange)="buildingBudget.freeRentRenewal = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Free Rent (Renewal) -->
        <!-- Start: Free Rent (New Deal) -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Free Rent (New Deal)
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="free-rent-new-deal" placeholder="(e.g. 1)"
                                 className="no-invalid-message" width="68px"
                                 format="#0" suffix="months" min="0" step="0"
                                 errorMessage="Free Rent is required"
                                 [value]="buildingBudget.freeRentNewDeal"
                                 (valueChange)="buildingBudget.freeRentNewDeal = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Free Rent (New Deal) -->
      </div>
      <div class="form-content-column">
        <!-- Start: Tenant Improvements (Renewal) -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Tenant Improvements (Renewal)
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="tenant-improvements-renewal" placeholder="(e.g. 2.45)"
                                 className="no-invalid-message" width="87px"
                                 format="#,##0.##" prefix="$" suffix="PSF" min="0" step="0"
                                 errorMessage="Tenant Improvements is required"
                                 [value]="buildingBudget.tenantImprovementsRenewal"
                                 (valueChange)="buildingBudget.tenantImprovementsRenewal = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Tenant Improvements (Renewal) -->
        <!-- Start: Tenant Improvements (New Deal) -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Tenant Improvements (New Deal)
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="tenant-improvements-new-deal" placeholder="(e.g. 2.45)"
                                 className="no-invalid-message" width="87px"
                                 format="#,##0.##" prefix="$" suffix="PSF" min="0" step="0"
                                 errorMessage="Tenant Improvements is required"
                                 [value]="buildingBudget.tenantImprovementsNewDeal"
                                 (valueChange)="buildingBudget.tenantImprovementsNewDeal = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Tenant Improvements (New Deal) -->
        <!-- Start: Downtime -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Downtime
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="downtime" placeholder="(e.g. 2)"
                                 className="no-invalid-message" width="68px"
                                 format="#0" suffix="months" min="0" step="0"
                                 errorMessage="Downtime is required"
                                 [value]="buildingBudget.downtime"
                                 (valueChange)="buildingBudget.downtime = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Downtime -->
        <!-- Start: OpEx -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              OpEx
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="op-ex" placeholder="(e.g. 2)"
                                 className="no-invalid-message" width="87px"
                                 format="#,##0.##" prefix="$" suffix="PSF" min="0" step="0"
                                 errorMessage="OpEx is required"
                                 [value]="buildingBudget.opEx"
                                 (valueChange)="buildingBudget.opEx = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: OpEx -->
        <!-- Start: Renewal Probability -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Renewal Probability
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="renewal-probability" placeholder="(e.g. 50)"
                                 className="no-invalid-message" width="75px"
                                 format="#,##0.##" suffix="%" min="0" step="0"
                                 errorMessage="Renewal Probability is required"
                                 [value]="buildingBudget.renewalProbability"
                                 (valueChange)="buildingBudget.renewalProbability = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Renewal Probability -->
        <p class="form-title">
          LEASING COMMISSIONS (RENEWAL)
        </p>
        <!-- Start: Landlord Rep -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Landlord Rep
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="landlord-rep-renewal" placeholder="(e.g. 5.0)"
                                 className="no-invalid-message" width="80px"
                                 format="#,##0.##" suffix="%" min="0" step="0"
                                 errorMessage="Landlord Rep is required"
                                 [value]="buildingBudget.landlordRepRenewal"
                                 (valueChange)="buildingBudget.landlordRepRenewal = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Landlord Rep -->
        <!-- Start: Tenant Rep -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Tenant Rep
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="tenant-rep-renewal" placeholder="(e.g. 5.0)"
                                 className="no-invalid-message" width="80px"
                                 format="#,##0.##" suffix="%" min="0" step="0"
                                 errorMessage="Tenant Rep is required"
                                 [value]="buildingBudget.tenantRepRenewal"
                                 (valueChange)="buildingBudget.tenantRepRenewal = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Tenant Rep -->
        <p class="form-title">
          LEASING COMMISSIONS (NEW DEAL)
        </p>
        <!-- Start: Landlord Rep -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Landlord Rep
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="landlord-rep-new-deal" placeholder="(e.g. 5.0)"
                                 className="no-invalid-message" width="80px"
                                 format="#,##0.##" suffix="%" min="0" step="0"
                                 errorMessage="Landlord Rep is required"
                                 [value]="buildingBudget.landlordRepNewDeal"
                                 (valueChange)="buildingBudget.landlordRepNewDeal = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Landlord Rep -->
        <!-- Start: Tenant Rep -->
        <div>
          <div class="dx-field">
            <p class="form-text">
              Tenant Rep
            </p>
            <div class="dx-field-value">
              <div>
                <app-input-field name="tenant-rep-new-deal" placeholder="(e.g. 5.0)"
                                 className="no-invalid-message" width="80px"
                                 format="#,##0.##" suffix="%" min="0" step="0"
                                 errorMessage="Tenant Rep is required"
                                 [value]="buildingBudget.tenantRepNewDeal"
                                 (valueChange)="buildingBudget.tenantRepNewDeal = $event;">
                </app-input-field>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Tenant Rep -->
      </div>
    </div>
    <!-- Start: Action Buttons -->
    <div class="form-actions main-actions">
        <div class="form-action-button">
          <dx-button type="normal"
                     text="Cancel"
                     (click)="close()">
          </dx-button>
        </div>
        <div class="form-action-button">
          <dx-button type="success"
                     text="Save"
                     [useSubmitBehavior]="true">
          </dx-button>
        </div>
    </div>
    <!-- End: Action Buttons -->
  </form>
</dx-scroll-view>
