<div class="term-actions-buttons-container">

  <ng-container *ngIf="isAcceptButtonVisible">
    <button type="button" class="accept"
            [disabled]="isButtonsDisabled"
            (click)="handleAcceptButtonClick($event)">
        <span class="text-uppercase">
          Accept
        </span>
    </button>
  </ng-container>

  <ng-container *ngIf="isCounterButtonVisible">
    <button type="button" class="counter"
            [disabled]="isButtonsDisabled"
            (click)="handleCounterButtonClick($event);">
        <span class="text-uppercase">
          Counter
        </span>
    </button>
    <ng-content select="[multipleOptionsView]"></ng-content>
  </ng-container>

  <ng-container *ngIf="isModifyButtonVisible">
    <button type="button" class="modify"
            [disabled]="isButtonsDisabled"
            (click)="handleModifyButtonClick($event);">
      <span class="text-uppercase">
        Modify
      </span>
    </button>
  </ng-container>

  <ng-container *ngIf="isChooseOptionButtonVisible">
    <button type="button" class="choose-option"
            [disabled]="isButtonsDisabled"
            (click)="handleChooseOptionButtonClick($event)">
      <span class="text-uppercase">
        Choose option
      </span>
    </button>
  </ng-container>

  <ng-container *ngIf="isPreviousButtonVisible">
    <button type="button" class="reset"
            [disabled]="isButtonsDisabled"
            (click)="handlePreviousButtonClick($event);">
      <span inlineSVG="/assets/img/renew.svg"></span>
      <span class="btn-text">
        Reset to Previous Response
      </span>
    </button>
  </ng-container>

  <ng-container *ngIf="isOkButtonVisible">
    <button type="button" class="ok"
            [disabled]="isButtonsDisabled"
            (click)="handleOkButtonClick($event);">
      <span class="text-uppercase">
        Ok
      </span>
    </button>
  </ng-container>
</div>
