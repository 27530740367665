import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';
import { TermManager } from '@statera/sdk/term';

import { AlertService } from '../../../../../alert/services/alert.service';
import { AuthService } from '../../../../../auth/services/auth.service';
import { LeaseService } from '../../../../../shared/services/lease.service';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ProjectAccessService } from '../../../../../shared/services/project-access.service';
import { TermsPageService } from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';

import { BaseTermDirective } from '../base-term.directive';

interface TenantMeteredUtilityOption {
  id: string;
  text: string;
}

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent extends BaseTermDirective<models.IUtilitiesTermViewModel> implements OnInit {
  protected alertService: AlertService;
  protected termsPageService: TermsPageService;
  protected termManager: TermManager;

  private _projectAccessService: ProjectAccessService;
  private _authService: AuthService;

  UtilitiesTermType: typeof models.UtilitiesTermType = models.UtilitiesTermType;

  tenantMeteredOptions: Array<TenantMeteredUtilityOption> = [
    {
      id: 'water consumption',
      text: 'water consumption',
    },
    {
      id: 'electric',
      text: 'electric',
    },
    {
      id: 'gas',
      text: 'gas',
    },
    {
      id: 'sewer',
      text: 'sewer',
    },
    {
      id: 'trash removal',
      text: 'trash removal',
    },
  ];
  tenantMeteredItems: Array<TenantMeteredUtilityOption> = [];

  private readonly _destroy$: Subject<void>;

  options: any = [];
  greenLeaseOptions = [
    {
      name:
        'Tenant shall, upon request, deliver to Landlord (or, at Landlord\'s option, execute and deliver to Landlord ' +
        'an instrument enabling Landlord to obtain from utility provider) any data about energy consumption at the Building by Tenant.',
      value: models.UtilitiesTermGreenLeaseOption.Required,
    },
    {
      name: 'Energy Usage item not required.',
      value: models.UtilitiesTermGreenLeaseOption.NotRequired,
    },
  ];

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,
    authService: AuthService,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this._authService = authService;
    this._projectAccessService = projectAccessService;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit() {
    super.ngOnInit();
    this._initializeOptions();

    if (this.leaseTerm.tenantMeteredUtilities && this.leaseTerm.tenantMeteredUtilities.trim().length > 0) {
      this.tenantMeteredItems = this.leaseTerm.tenantMeteredUtilities
        .split(', ')
        .map(x => this.tenantMeteredOptions.find(o => o.id === x))
        .filter(x => !!x);
    }
  }

  saveLeaseTerm($event) {
    if (this.leaseTerm.utilitiesType === models.UtilitiesTermType.LandlordPaysExcept) {
      this.leaseTerm.tenantMeteredUtilities = this.tenantMeteredItems.map(x => x.id).join(', ');
    }

    super.saveLeaseTerm(this.leaseTerm);
    $event.preventDefault();
  }

  private _initializeOptions() {
    if (this.project?.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      this.options = [
        {
          name: 'To be addressed in the Lease document.',
          value: models.UtilitiesTermType.ToBeAddressedInLease,
        },
        {
          name:
            'Tenant shall be separately metered for all energy consumption for tenant\'s lighting and incidental ' +
            'uses. Landlord shall pay for all costs associated with metering Tenant\'s space.',
          value: models.UtilitiesTermType.LandlordPays,
        },
        {
          name: 'Landlord shall pay for all utilities, which shall be included in the Base Rental Rate.',
          value: models.UtilitiesTermType.LandlordPaysBaseRentalRateIncluded,
        },
        {
          name: 'Landlord shall pay for all utilities, except Tenant shall be separately metered for (INSERT UTILITIES)',
          value: models.UtilitiesTermType.LandlordPaysExcept,
        },
        {
          name: 'Custom',
          value: models.UtilitiesTermType.Custom,
        },
      ];

      return;
    }

    this.options = [
      {
        name: 'Per current lease',
        value: models.UtilitiesTermType.PerCurrentLease,
      },
      {
        name: 'To be addressed in the Lease document.',
        value: models.UtilitiesTermType.ToBeAddressedInLease,
      },
      {
        name:
          'Tenant shall be separately metered for all energy consumption for tenant\'s lighting and incidental ' +
          'uses. Landlord shall pay for all costs associated with metering Tenant\'s space.',
        value: models.UtilitiesTermType.LandlordPays,
      },
      {
        name: 'Landlord shall pay for all utilities, which shall be included in the Base Rental Rate.',
        value: models.UtilitiesTermType.LandlordPaysBaseRentalRateIncluded,
      },
      {
        name: 'Landlord shall pay for all utilities, except Tenant shall be separately metered for (INSERT UTILITIES)',
        value: models.UtilitiesTermType.LandlordPaysExcept,
      },
      {
        name: 'Custom',
        value: models.UtilitiesTermType.Custom,
      },
    ];
  }
}
