import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { DialogRefService } from '../../../dialog/services/dialog-ref.service';
import { DialogService } from '../../../dialog/services/dialog.service';

import { TourScheduleDialogComponent } from '../tour-schedule-dialog/tour-schedule-dialog.component';

import * as models from '../../../infrastructure/models/generated';

import { TourClientVersionCompatibility } from '../../models/tour-client-version-compatibility.model';
import { take, takeUntil, tap } from 'rxjs/operators';
import { AlertService } from '../../../alert/services/alert.service';
import { AlertMessagesManager } from '@statera/sdk/alert';

const moment = TourClientVersionCompatibility.moment;

@Component({
  selector: 'app-tour-scheduled-info',
  templateUrl: './tour-scheduled-info.component.html',
})
export class TourScheduledInfoComponent implements OnDestroy {
  readonly TourIconsAssetPath = TourClientVersionCompatibility.TourIconsAssetPath;

  @Input() landlordCompany: models.ICompanyViewModel;
  @Input() tenantCompany: models.ICompanyViewModel;
  @Input() buildingUnit: models.IBuildingUnitViewModel;
  @Input() building: models.IBuildingViewModel;
  @Input() leaseTeam: models.LeaseTeam;
  @Input() projectId: number;
  @Input() counterPartCompany: models.ICompanyViewModel;
  @Input() counterPartUser: models.IProfileViewModel;
  @Input() tour?: models.ITourViewModel;
  @Input() tour$?: Subject<models.ITourViewModel>;

  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _dialogService: DialogService;

  private readonly _destroy: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    dialogRefService: DialogRefService,
    dialogService: DialogService,
  ) {
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogRefService = dialogRefService;
    this._dialogService = dialogService;

    this._destroy = new Subject<void>();
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  close() {
    this._dialogRefService.hide();
  }

  isLandlord(): boolean {
    return this.leaseTeam === models.LeaseTeam.LandlordTeam;
  }

  getOtherPartyLogoUrl(): string {
    const company = this.getOtherPartyCompany();
    return company.logo?.thumbnailUrl ?? '/assets/img/nofoto.png';
  }

  getOtherPartyCompany(): models.ICompanyViewModel {
    return this.isLandlord()
      ? this.tenantCompany
      : this.landlordCompany;
  }

  getSlotDurationExpression(slot: string): string {
    return [
      slot,
      moment(slot, 'LT')
        .add(30, 'minutes')
        .format('LT')
    ].join(' — ');
  }

  edit(): void {
    const alertReference = this._alertService
      .pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmTourAlternateDateText(),
      });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this.close();

          this._dialogService
            .show(TourScheduleDialogComponent, {
              width: 1240,
              height: 661,
              containerClassNames: ['no-border'],
              injectableData: {
                tenantCompany: this.tenantCompany,
                landlordCompany: this.landlordCompany,
                buildingUnit: this.buildingUnit,
                building: this.building,
                counterPartUser: this.counterPartUser,
                counterPartCompany: this.counterPartCompany,
                leaseTeam: this.leaseTeam,
                projectId: this.projectId,
                tour: this.tour,
                tour$: this.tour$,
                shouldRejectTourRequest: true,
              },
            });
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }
}
