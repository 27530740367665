import { ICompanyFeatureToggleViewModel } from '@statera/sdk/common';

export type CompanyFeatureToggles = ICompanyFeatureToggleViewModel;

export enum Feature {
  ColaboFeature = 'isColaboFeatureEnabled',
  ProjectScheduleFeature = 'isProjectScheduleFeatureEnabled',
  FloorPlanFeature = 'isFloorPlanFeatureEnabled',
  PlanProFeature = 'isPlanProEnabled',
}
