<div class="alert-container-backdrop"
     *ngIf="alert"
     @animateAlertContainer>
  <div class="alert-container">
    <app-alert *ngIf="alert && shouldShowAlert"
               [alert]="alert"
               @animateAlert>
    </app-alert>
  </div>
</div>
