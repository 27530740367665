<div class="manage-team">
  <div class="manage-team-header">
    <div>
      <h1 class="manage-team-header-title">
        <ng-container [ngSwitch]="mode">
          <ng-container *ngSwitchCase="ManageTeamDialogModes.Lease">
            Manage Team Members
          </ng-container>
          <ng-container *ngSwitchCase="ManageTeamDialogModes.Portfolio">
            <ng-container *ngIf="isLoading || !teamMembers?.length; else managePortfolioHeader">
              Assign a leasing team to portfolio
            </ng-container>
            <ng-template #managePortfolioHeader>
              Manage portfolio team members
            </ng-template>
          </ng-container>
          <ng-container *ngSwitchCase="ManageTeamDialogModes.Buildings">
            <ng-container *ngIf="isLoading || !teamMembers?.length; else managePortfolioHeader">
              Assign a leasing team to {{buildings.length | i18nPlural: ({ '=1': 'building', 'other': '# buildings' })}}
            </ng-container>
            <ng-template #managePortfolioHeader>
              Manage building team members
            </ng-template>
          </ng-container>
        </ng-container>
      </h1>
      <div class="manage-team-header-address">
        <ng-container [ngSwitch]="mode">
          <ng-container *ngSwitchCase="ManageTeamDialogModes.Lease">
            <div class="d-flex align-items-center">
              <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#location"></i>
              <ng-container *ngIf="lease.building?.address?.displayString; else noAddress">
                {{lease.building.address.displayString.trim()}},
                {{lease.buildingUnit?.name || 'Unit #' + lease.buildingUnitId}}
              </ng-container>
              <ng-template #noAddress>
                {{lease.building?.name || 'Building #'+ lease.buildingId}},
                {{lease.buildingUnit?.name || 'Unit #' + lease.buildingUnitId}}
              </ng-template>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="ManageTeamDialogModes.Portfolio">
            <div class="d-flex align-items-center">
              <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#briefcase"></i>
              {{portfolio.name}}
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="ManageTeamDialogModes.Buildings">
            <div class="d-flex align-items-center"
                 *ngFor="let building of buildings">
              <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#location"></i>
              <ng-container *ngIf="building?.address?.displayString; else noAddress">
                {{building.address.displayString.trim()}}
              </ng-container>
              <ng-template #noAddress>
                {{building.name || 'Building #'+ building.id}}
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="manage-team-header__btn">

        <button type="button"
                *ngIf="hasAccessToWrite()"
                (click)="showInviteTeamMemberDialog()">
            <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#plus"></i>
          <span>Invite a Member</span>

        </button>


        <a role="button" href="javascript:void(0);" class="manage-team-close-button"
           (click)="close()">
          <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#cross-close"></i>
        </a>

    </div>
  </div>

  <ng-container *ngIf="hasAccessToRead(); else noAccessTpl">
    <div class="manage-team-loader py-5"
         *ngIf="isLoading; else tableTpl">
      <p class="text-center text-muted">
        Loading...
      </p>
    </div>

    <ng-template #tableTpl>
      <div class="manage-team-table-wrapper"
           *ngIf="teamMembers?.length; else noTeamMembersTemplate">

          <div class="manage-team-table">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th class="w-0">Role</th>
                  <th>Last seen</th>
                  <ng-container [ngSwitch]="mode">
                    <ng-container *ngSwitchCase="ManageTeamDialogModes.Lease">
                      <th class="w-0"
                          *appCheckAccess="StateraClaimType.Team_Member; stateraClaimValue: StateraClaimValue.Write; leaseId: lease.id">
                      </th>
                    </ng-container>
                    <ng-container *ngSwitchCase="ManageTeamDialogModes.Portfolio">
                      <th class="w-0"
                          *appCheckAccess="StateraClaimType.Team_Member; stateraClaimValue: StateraClaimValue.Write; portfolioId: portfolio.id">
                      </th>
                    </ng-container>
                    <ng-container *ngSwitchCase="ManageTeamDialogModes.Buildings">
                      <th class="w-0" *ngIf="hasAccessToWrite()">
                      </th>
                    </ng-container>
                  </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let teamMember of teamMembers; let index = index;">
                  <td>
                    <div class="d-flex flex-row flex-nowrap align-items-center mx-n2">
                      <div class="px-2">
                        <img class="rounded-circle object-fit-cover" width="32" height="32"
                             [src]="teamMember.avatar?.url || teamMember.avatar?.thumbnailUrl || 'assets/img/avatar.png'"
                             [alt]="teamMember.fullName"/>
                      </div>
                      <div class="px-2">
                        <p class="mb-0">
                          {{teamMember.fullName}}
                        </p>
<!--                        <p class="mb-0 text-muted text-14">-->
<!--                          {{teamMember.companyName}}-->
<!--                        </p>-->
                      </div>
                    </div>
                  </td>
                  <td>
                    {{teamMember.emailAddress}}
                  </td>
                  <td>
                    <div class="manage-team-roles">
                      <div class="manage-team-role"
                           *ngFor="let role of teamMember.roles">
                        {{transformUserRole(role)}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <ng-container *ngIf="teamMember.userInvitationRequest as invitationRequest; else existingUserTpl">
                      <ng-container *ngIf="invitationRequest.requestStatus === RequestStatuses.Pending || invitationRequest.requestStatus === RequestStatuses.UserAcceptance; else existingUserTpl">
                        <ng-container *ngIf="invitationRequest.requestStatus === RequestStatuses.Pending">
                          <p class="pending_text">
                            Invitation pending
                          </p>
                          <a class="resend_text" role="button" href="javascript:void(0);"
                             (click)="resendInvitationRequest(teamMember)">
                            Resend
                          </a>
                        </ng-container>
                        <ng-container *ngIf="invitationRequest.requestStatus === RequestStatuses.UserAcceptance">
                          <p class="mb-0 text-nowrap">
                            Invitation sent
                          </p>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-template #existingUserTpl>
                      <ng-container *ngIf="teamMember.lastActivityDateTime; else noActivityTpl">
                        {{teamMember.lastActivityDateTime | safeDate: 'MMM d, yyyy'}}
                      </ng-container>
                      <ng-template #noActivityTpl>
                        –
                      </ng-template>
                    </ng-template>
                  </td>
                  <ng-container [ngSwitch]="mode">
                    <ng-container *ngSwitchCase="ManageTeamDialogModes.Lease">
                      <td *appCheckAccess="StateraClaimType.Team_Member; stateraClaimValue: StateraClaimValue.Write; leaseId: lease.id">
                        <ng-container *ngIf="teamMember.userInvitationRequest?.id">
                          <button type="button" class="remove-team-member__btn" (click)="removeTeamMember(teamMember)"  #removeTeamMemberTriggerElement>

                            <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#remove-member"></i>

                          </button>
                          <dx-tooltip class="v2-default-tooltip"
                                      position="bottom"
                                      showEvent="dxhoverstart" hideEvent="dxhoverend"
                                      [target]="removeTeamMemberTriggerElement">
                            Remove from the team
                          </dx-tooltip>
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="ManageTeamDialogModes.Portfolio">
                      <td *appCheckAccess="StateraClaimType.Team_Member; stateraClaimValue: StateraClaimValue.Write; portfolioId: portfolio.id">
                        <ng-container *ngIf="teamMember.userInvitationRequest?.id">

                          <button type="button" class="remove-team-member__btn" (click)="removeTeamMember(teamMember)"  #removeTeamMemberTriggerElement>

                            <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#remove-member"></i>

                          </button>

                          <dx-tooltip class="v2-default-tooltip"
                                      position="bottom"
                                      showEvent="dxhoverstart" hideEvent="dxhoverend"
                                      [target]="removeTeamMemberTriggerElement">
                            Remove from the team
                          </dx-tooltip>
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="ManageTeamDialogModes.Buildings">
                      <td *ngIf="hasAccessToWrite()">
                        <ng-container *ngIf="teamMember.userInvitationRequest?.id">
                          <button type="button" class="remove-team-member__btn" (click)="removeTeamMember(teamMember)"  #removeTeamMemberTriggerElement>

                            <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#remove-member"></i>

                          </button>
                          <dx-tooltip class="v2-default-tooltip"
                                      position="bottom"
                                      showEvent="dxhoverstart" hideEvent="dxhoverend"
                                      [target]="removeTeamMemberTriggerElement">
                            Remove from the team
                          </dx-tooltip>
                        </ng-container>
                      </td>
                    </ng-container>
                  </ng-container>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

      </div>
      <ng-template #noTeamMembersTemplate>
        <div class="manage-team-no-members">

          <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#no-user"></i>

          <h5>
            No Team Members Yet :(
          </h5>
          <p>
            Invite team members to get started
          </p>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>
  <ng-template #noAccessTpl>
    <div class="manage-team-loader">
      <p class="my-5 text-center text-muted">
        You don't have access to view team members
      </p>
    </div>
  </ng-template>
</div>
