import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertStore {
  readonly dequeuedAlertIds: Array<number | string>;

  constructor() {
    this.dequeuedAlertIds = new Array<number | string>(0);
  }
}
