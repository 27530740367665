import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DxScrollViewComponent} from 'devextreme-angular';
import {Subject} from 'rxjs';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {AlertManager, AlertMessagesManager, AlertNotificationKind} from '@statera/sdk/alert';
import {Role} from '@statera/sdk/auth';
import {LeaseActivity, LeaseManager} from '@statera/sdk/lease';
import {ProjectManager} from '@statera/sdk/project';
import {TermManager} from '@statera/sdk/term';
import {Feature} from '@statera/sdk/feature-toggling';

import {AuthService} from '../../../auth/services/auth.service';
import {DialogService} from '../../../dialog/services/dialog.service';
import {LeaseService} from '../../../shared/services/lease.service';
import {ProjectAccessService} from '../../../shared/services/project-access.service';
import {ProjectService} from '../../../shared/services/project.service';

import { TourScheduledInfoComponent } from '../../../tour/components/tour-scheduled-info/tour-scheduled-info.component';
import { TourScheduleDialogComponent } from '../../../tour/components/tour-schedule-dialog/tour-schedule-dialog.component';

import * as models from '../../../infrastructure/models/generated';
import {ProjectStatus} from '../../../infrastructure/models/generated';

class ReadBy {
  text: string;
  userId: number;
  avatarUrl: string;
  name: string | undefined;
  unread: boolean;
  date: Date | undefined;
}

@Component({
  selector: 'app-term-list',
  templateUrl: './term-list.component.html',
  styleUrls: ['./term-list.component.scss'],
})
export class TermListComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('scrollView') scrollView: DxScrollViewComponent;

  @Input() lease: models.ILeaseViewModel;
  @Input() project: models.IProjectViewModel;
  @Input() leaseTeam?: models.ILeaseTeamViewModel;

  @Output() cancelNegotiationByTenant = new EventEmitter<void>();
  @Output() cancelNegotiationByLandlord = new EventEmitter<void>();

  currentProject: models.IProjectViewModel;
  tour?: models.ITourViewModel;
  termConfigurations: Array<models.ILeaseTermConfiguration>;
  leaseTermConfiguration: models.ILeaseTermConfiguration;
  ProjectStatus = models.ProjectStatus;

  isClosedProject: boolean;
  shouldShowExpandColaboButton: boolean;
  shouldShowConvertToDealButton: boolean;
  shouldShowRequestTourButton: boolean;
  shouldShowLeaseAnalysisButton: boolean;
  shouldShowCancelNegotiationButton: boolean;
  isTwoPaneViewAccessible: boolean;
  isConvertToDealButtonDisabled: boolean;
  isRequestTourButtonDisabled: boolean;

  dummy: string;
  read: string;
  readBy: ReadBy;

  TermStatus: typeof models.TermStatus;
  LeaseTermType: typeof models.LeaseTermType;
  TenantSquareFootageTermType: typeof models.TenantSquareFootageTermType;
  RentalRateEscalationTermType: typeof models.EscalationTermType;
  StateraClaimType: typeof models.StateraClaimTypeAsEnum;
  StateraClaimValue: typeof models.StateraClaimValueAsEnum;
  Feature: typeof Feature = Feature;

  private readonly _activatedRoute: ActivatedRoute;
  private readonly _router: Router;
  private readonly _projectManager: ProjectManager;
  private readonly _termManager: TermManager;
  private readonly _authService: AuthService;
  private readonly _projectService: ProjectService;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _projectAccessService: ProjectAccessService;
  private readonly _leaseManager: LeaseManager;
  private readonly _alertManager: AlertManager;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogService: DialogService;

  private readonly _destroy: Subject<void>;

  constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    projectManager: ProjectManager,
    termManager: TermManager,
    authService: AuthService,
    projectService: ProjectService,
    changeDetectorRef: ChangeDetectorRef,
    projectAccessService: ProjectAccessService,
    leaseManager: LeaseManager,
    alertManager: AlertManager,
    alertMessagesManager: AlertMessagesManager,
    dialogService: DialogService,
  ) {
    this._activatedRoute = activatedRoute;
    this._router = router;
    this._projectManager = projectManager;
    this._termManager = termManager;
    this._authService = authService;
    this._projectService = projectService;
    this._changeDetectorRef = changeDetectorRef;
    this._projectAccessService = projectAccessService;
    this._leaseManager = leaseManager;
    this._alertManager = alertManager;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogService = dialogService;

    this._destroy = new Subject<void>();

    this.TermStatus = models.TermStatus;
    this.LeaseTermType = models.LeaseTermType;
    this.TenantSquareFootageTermType = models.TenantSquareFootageTermType;
    this.RentalRateEscalationTermType = models.EscalationTermType;
    this.StateraClaimType = models.StateraClaimTypeAsEnum;
    this.StateraClaimValue = models.StateraClaimValueAsEnum;
    this.Feature = Feature;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    let project = null;
    if (
      changes.project &&
      (
        changes.project.isFirstChange() ||
        (
          changes.project.previousValue !== changes.project.currentValue
        )
      )
    ) {
      project = changes.project.currentValue;
    }

    this._setDataForCurrentProject(project, this._authService.startupInfo);
    this._setProject(this.currentProject);
  }

  ngOnDestroy(): void {
    this._changeDetectorRef.detach();

    this._destroy.next();
    this._destroy.complete();
  }

  isAbstractLease(): boolean {
    return this.lease && this.lease.isAbstractLease;
  }

  isAcceptedInLoi(): boolean {
    if (this.isNewDeal()) {
      return this.project.projectStatus === models.ProjectStatus.Completed;
    }

    return this.isAbstractLease();
  }

  hasAbstractLease(): boolean {
    return this.lease && typeof this.lease.abstractLeaseId === 'number';
  }

  isNewDeal(): boolean {
    return this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDeal;
  }

  isLandlord(): boolean {
    return this._projectAccessService.getTurnRole(this.lease) === Role.Landlord;
  }

  isTenant(): boolean {
    return this._projectAccessService.getTurnRole(this.lease) === Role.Tenant;
  }

  hasSecurityDepositTermBurnDownOption(): boolean {
    const securityDepositTerm = <models.ISecurityDepositTermViewModel>this.lease.securityDepositTerm;
    if (!securityDepositTerm) {
      return false;
    }
    return securityDepositTerm.hasBurnDownScheduleValues;
  }

  isBaseRentalRate(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isBaseRentalRate(term);
  }

  isTenantSquareFootagePhaseInTermValue(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isTenantSquareFootagePhaseInTermValue(term);
  }

  isRentalRateEscalationCustomOption(term: models.ILeaseTermViewModel): boolean {
    return this._termManager.isRentalRateEscalationCustomOption(term);
  }

  showCurrentLeaseLanguage(lease: models.ILeaseViewModel): boolean {
    return this._leaseManager.showCurrentLeaseLanguage(lease);
  }

  showThirdPartyLease(lease: models.ILeaseViewModel): boolean {
    return this._leaseManager.showThirdPartyLease(lease);
  }

  isInquiryProject(): boolean {
    return this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDealInquiry;
  }

  ready(isReady: boolean) {
    if (isReady) { // when last item rendered
      this.scrollView.instance.scrollTo(0); // show scrollbar of scrollview
      this.dummy = '';
    }
    return '';
  }

  getTitle(): string {
    if (!this.lease) {
      return '';
    }

    const addressParts = [];
    if (this.lease?.building?.address?.addressLine1) {
      addressParts.push(this.lease?.building?.address?.addressLine1);
    }

    if (this.lease?.building?.address?.addressLine2) {
      addressParts.push(this.lease?.building?.address?.addressLine2);
    }

    if (this.lease?.buildingUnit?.name) {
      addressParts.push(this.lease?.buildingUnit?.name);
    }

    return  addressParts
        .filter(part => !!part)
        .join(', ');
  }

  getAddress() {
    if (!this.lease) {
      return '';
    }
    const address = this.lease.building.address;

    return [address.city, address.stateCode, address.zipCode].filter(Boolean).join(', ');
  }

  getRequestTourButtonTooltip(): string {
    if (!this.tour) {
      return this._authService.startupInfo.role === Role.Tenant
        ? 'Request a Tour'
        : 'Invite to Tour';
    }

    if (this.tour.status === models.TourStatus.WaitingForApproval) {
      const leaseTeam = this._leaseManager
        .getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);
      const canEditTourRequest =
        leaseTeam === models.LeaseTeam.LandlordTeam && this.tour.isApprovedByLandlord ||
        leaseTeam === models.LeaseTeam.TenantTeam && this.tour.isApprovedByTenant;

      return canEditTourRequest
        ? 'Edit Tour Info'
        : 'Approve Tour';
    }

    if (this.tour.status === models.TourStatus.Scheduled) {
      return 'Tour Scheduled';
    }

    return 'Request a Tour';
  }

  getTermStatusName(leaseTermType: models.LeaseTermType): string {
    const leaseTermStatus = this._termManager.getStatusByLeaseTermType(leaseTermType, this.lease);

    if (leaseTermStatus == null) {
      return models.TermStatus[models.TermStatus.Draft];
    }
    return models.TermStatus[leaseTermStatus];
  }

  getStatusClass(leaseTermType: models.LeaseTermType): string {
    return this.getTermStatusName(leaseTermType).toString().toLowerCase();
  }

  getTerm(termConfig: models.ILeaseTermConfiguration): models.ILeaseTermViewModel {
    if (!this.lease || !termConfig) {
      return null;
    }

    return this._termManager.getCurrentAbstractTerm(this.lease, termConfig.leaseTermType);
  }

  getTermDisplayValue(term: models.ILeaseTermViewModel): string {
    if (!term) {
      return;
    }

    return this._termManager.getTermDetailedDisplayValue(term);
  }

  getTermAbstractExpirationDate(): Date | string {
    if (!this.lease) {
      return null;
    }

    if (this.lease.isAbstractLease) {
      return this.lease.expirationAbstractValue;
    }

    return this.lease.expirationOriginalAbstractValue;
  }

  getTermStatus(leaseTermConfiguration: models.ILeaseTermConfiguration): models.TermStatus {
    const termStatus = this._termManager.getStatusByLeaseTermType(leaseTermConfiguration.leaseTermType, this.lease);
    if (!termStatus) {
      return models.TermStatus.Draft;
    }

    return termStatus;
  }

  getTenantSquareFootageTermType(): models.TenantSquareFootageTermType {
    const tenantSquareFootageTerm = <models.ITenantSquareFootageTermViewModel>this.lease.tenantSquareFootageTerm;
    if (!tenantSquareFootageTerm) {
      return null;
    }

    return tenantSquareFootageTerm.tenantSquareFootageTermType;
  }

  getRentalRateEscalationTermType(): models.EscalationTermType {
    const rentalRateEscalationTerm = <models.IRentalRateAnnualEscalationTermViewModel>this.lease.rentalRateAnnualEscalationTerm;
    if (!rentalRateEscalationTerm) {
      return null;
    }

    return rentalRateEscalationTerm.escalationTermType;
  }

  getBaseRentalRateSchedulePreview(term: models.ILeaseTermViewModel): Array<models.IBaseRentalScheduleRowViewModel> {
    return this._termManager.getBaseRentalRateSchedulePreview(term);
  }

  getBaseRentalRateScheduleCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getBaseRentalRateScheduleCustomTable(term);
  }

  getTenantSquareFootagePhaseInCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getTenantSquareFootagePhaseInCustomTable(term);
  }

  getTenantSquareFootagePhaseInResults(term: models.ILeaseTermViewModel): Array<models.ITenantSquareFootagePhaseInResultViewModel> {
    return this._termManager.getTenantSquareFootagePhaseInResults(term);
  }

  getRentalRateEscalationCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getRentalRateEscalationCustomTable(term);
  }

  getRealEstateTaxesCustomTable(term: models.ILeaseTermViewModel): models.ILeaseTermCustomTableViewModel {
    return this._termManager.getRealEstateTaxesCustomTable(term);
  }

  getRentalRateEscalationCustomOption(term: models.ILeaseTermViewModel): Array<models.IRentalRateAnnualEscalationTermCustomResult> {
    return this._termManager.getRentalRateEscalationCustomOption(term);
  }

  getBurnDownScheduleResults(term: models.ILeaseTermViewModel): Array<models.IBurnDownScheduleViewModel> {
    return (<models.ISecurityDepositTermViewModel>term).burnDownScheduleValues;
  }

  getUnitMetrics(baseRentalRateViewModel: models.IBaseRentalRateViewModel): models.BaseRentalRateUnitMetrics {
    return this._termManager.getBaseRentalRateUnitMetrics(baseRentalRateViewModel);
  }

  handleExpandColaboClick(): void {
    if (this.isTwoPaneViewAccessible) {
      this._router.navigate(['colabo', 'terms'], {queryParams: {leaseId: this.lease.id}});
    } else {
      if (this.scrollView && this.scrollView.instance) {
        this.scrollView.instance.scrollTo(0);
      }
    }
  }

  handleConvertToDealClick(): void {
    const alertReference = this._alertManager.queueAlertNotification({
      kind: AlertNotificationKind.Confirm,
      message: this._alertMessagesManager.getConfirmInquiryConvertToDealAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => this._projectService.convertInquiryToNewDeal(this.project.id)),
        tap((project) => {
          const urlTree = this._router
            .createUrlTree(
              [
                'v2',
                'wizard',
                project.lease.id
              ],
              {},
            );

          const url = this._router.serializeUrl(urlTree);

          window.location.href = url;
        }),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  handleRequestTourClick(): void {
    const leaseTeam = this._leaseManager
      .getUserLeaseTeam(
        this.lease,
        this._authService.userId,
        this._authService.role,
      );

    const tourSubject = new Subject<models.ITourViewModel>();
    tourSubject
      .pipe(
        tap((tour) => this.tour = tour),
        take(1),
        takeUntil(this._destroy),
      )
      .subscribe();

    if (this.tour?.status === models.TourStatus.Scheduled) {
      this._dialogService.show(TourScheduledInfoComponent, {
        width: 330,
        injectableData: {
          tenantCompany: this.lease.tenantCompany,
          landlordCompany: this.lease.landlordCompany,
          buildingUnit: this.lease.buildingUnit,
          building: this.lease.building,
          tour: this.tour,
          tour$: tourSubject,
          leaseTeam: leaseTeam,
          projectId: this.project.id,
        },
      });
      return;
    }

    const counterPartUser = this.isTenant()
      ? this.leaseTeam.landlordTeam[0]?.user
      : this.leaseTeam.tenantTeam[0]?.user;
    const counterPartCompany = counterPartUser.company;

    const openTourScheduleDialog = () => {
      this._dialogService
        .show(TourScheduleDialogComponent, {
          width: 1240,
          height: 661,
          injectableData: {
            tenantCompany: this.lease.tenantCompany,
            landlordCompany: this.lease.landlordCompany,
            buildingUnit: this.lease.buildingUnit,
            building: this.lease.building,
            leaseTeam: leaseTeam,
            projectId: this.project.id,
            counterPartUser: counterPartUser,
            counterPartCompany: counterPartCompany,
            tour: this.tour,
            tour$: tourSubject,
          },
        });
    };

    if (this.tour?.status === models.TourStatus.WaitingForApproval) {
      const canEditTourRequest =
        leaseTeam === models.LeaseTeam.LandlordTeam && this.tour.isApprovedByLandlord ||
        leaseTeam === models.LeaseTeam.TenantTeam && this.tour.isApprovedByTenant;

      if (canEditTourRequest) {
        const alertReference = this._alertManager.queueAlertNotification({
          kind: AlertNotificationKind.Confirm,
          message: this._alertMessagesManager.getConfirmTourAlternateDateText(),
        });

        alertReference
          .confirmed
          .pipe(
            tap(() => {
              openTourScheduleDialog();
            }),
            take(1),
            takeUntil(this._destroy),
          )
          .subscribe();

        return;
      }
    }

    openTourScheduleDialog();
  }

  handleLeaseAnalysisClick(): void {
    const urlTree = this._router
      .createUrlTree([
        'v2',
        'leases',
        this.lease.id,
        'lease-analysis',
      ]);

    window.location.href = this._router.serializeUrl(urlTree);
  }

  handleProjectScheduleClick(): void {
    if (this.isLandlord()) {
      const urlTree = this._router
        .createUrlTree([
          'landlord',
          'buildings',
          'person-desktop',
          this.lease.id
        ], { queryParams: { target: 'timeline' } });

      window.location.href = this._router.serializeUrl(urlTree);
      return;
    }

    const url = this._router
      .createUrlTree(
        [
          'v2',
          'colabo',
          this.currentProject.id,
          'project-schedule'
        ],
        {},
      );

    window.location.href = this._router.serializeUrl(url);
  }

  handleCancelNegotiationClick(): void {
    if (this.isLandlord()) {
      this.cancelNegotiationByLandlord.emit();
    }

    if (this.isTenant()) {
      this.cancelNegotiationByTenant.emit();
    }
  }

  getOpponentCompany(): models.ICompanyViewModel {
    if (!this.lease) {
      return null;
    }

    const leaseTeam = this._leaseManager.getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);

    switch (leaseTeam) {
      case models.LeaseTeam.TenantTeam:
        return this.lease.landlordCompany;

      case models.LeaseTeam.LandlordTeam:
        return this.lease.tenantCompany;

      default:
        return null;
    }
  }

  private _getRead(leaseActivities: Array<LeaseActivity> = null) {
    if (leaseActivities) {
      const leaseTeam = this._leaseManager.getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);
      const leaseActivity = leaseActivities.find(x => x.leaseTeam !== leaseTeam);
      if (leaseActivity) {
        this.readBy = {
          text: 'Read by',
          userId: leaseActivity.createdBy.id,
          avatarUrl: leaseActivity.createdBy?.avatar?.url,
          unread: false,
          name: `${leaseActivity.createdBy.firstName} ${leaseActivity.createdBy.lastName}`,
          date: new Date(leaseActivity.lastReadOn)
        };
      } else {
        this.read = 'Unread';
        this.readBy = {
          text: this.read,
          userId: undefined,
          avatarUrl: '',
          unread: true,
          name: '',
          date: undefined
        };
      }
    }
  }

  private _setProject(project: models.IProjectViewModel) {
    this.read = '';
    if (project && project.projectState) {
      const isCurrentTurn = this._projectAccessService.checkAccessToRenewalProject(
        this.project.projectState.renewalProjectTemplateItemType,
        this.project,
        this.lease
      );
      if (!isCurrentTurn) {
        this._leaseManager
          .getLeaseActivities(project.leaseId, project.projectStateId)
          .pipe(
            takeUntil(this._destroy)
          )
          .subscribe(leaseActivities => this._getRead(leaseActivities));
        return;
      }
    }
  }

  private _setDataForCurrentProject(project: models.IProjectViewModel, info: models.IStartupInfoViewModel) {
    if (!project) {
      return;
    }
    this.isClosedProject = this._projectService.isClosed(project);

    this.termConfigurations = this._termManager
      .getLeaseTermConfigurations(
        info.lists.leaseTermConfigurations,
        project,
        this.lease,
        true /** electedTermsOnly */
      );

    this.currentProject = project;

    if (project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDealInquiry) {
      this.isTwoPaneViewAccessible = false;
    } else {
      this.isTwoPaneViewAccessible = !!project.projectState;
    }

    this.shouldShowExpandColaboButton = this.lease && this.isTwoPaneViewAccessible;
    this.shouldShowConvertToDealButton = (
      project?.projectTypeId === models.ProjectTypeEnum.NewDealInquiry &&
      project?.projectState?.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp
    );
    this.shouldShowLeaseAnalysisButton = (
      this.lease &&
      this.isTwoPaneViewAccessible &&
      project?.projectStatus === ProjectStatus.Active &&
      project?.projectState?.renewalProjectTemplateItemType >= 30000
    );
    this.shouldShowCancelNegotiationButton = project?.projectStatus === ProjectStatus.Active;

    this.isConvertToDealButtonDisabled = this.isLandlord();

    this._projectManager
      .getProjectTours(this.project.id)
      .pipe(
        tap((tours) => {
          this.tour = tours.find((t) => t.status !== models.TourStatus.Archived);

          this.shouldShowRequestTourButton = (
            project?.projectStatus === ProjectStatus.Active &&
            (
              project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDeal ||
              project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDealInquiry
            ) &&
            (this.isTenant() || this.isLandlord())
          );

          if (this.tour?.status === models.TourStatus.Scheduled) {
            this.isRequestTourButtonDisabled = false;
            return;
          }

          if (!this.tour) {
            this.isRequestTourButtonDisabled = !this.isTenant();
            return;
          }

          this.isRequestTourButtonDisabled = false;
        }),
        switchMap(() => this._activatedRoute.queryParams),
        tap((params) => {
          if (params?.['openTourRequestDialog']) {
            this.handleRequestTourClick();
          }
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }
}
