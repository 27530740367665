import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthManager, InfoFlag } from '@statera/sdk/auth';

@Injectable()
export class RedirectToAvailabilitiesGuard implements CanActivate {
  private readonly _authManager: AuthManager;

  constructor(authManager: AuthManager) {
    this._authManager = authManager;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this._authManager
      .getStartupInfo()
      .pipe(
        map(info => {
          const hasLease = InfoFlag.hasFlag(info?.infoFlags, InfoFlag.HasLease);
          const hasPortfolioInvitation = InfoFlag.hasFlag(info?.infoFlags, InfoFlag.HasPortfolioInvitation);
          if (!hasLease && hasPortfolioInvitation) {
            // TODO: Change to UrlTree after ClientV2 is finished

            const {origin, search} = location;

            const path = '/v2/available-units';

            location.assign([origin, path, search].filter(Boolean).join(''));

            return false;
          }

          return true;
        }),
        catchError(() => of(null)), // ignore error
      );
  }
}
