<div class="floor-plan">
  <!-- Start: Header -->
  <div class="floor-plan-header">
    <div class="floor-plan-title">
      <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#floorPlaneScheme"></i>
      <h2>
        Floor Plan: Add Tenant Improvements
      </h2>
    </div>
    <div class="floor-plan-close">
      <a role="button"
         (click)="close()">
        <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#closeFloorPlan"></i>
      </a>
    </div>
  </div>
  <!-- End: Header -->
  <!-- Start: Body -->
  <div class="floor-plan-body">
    <!-- Start: Editor -->
    <div class="floor-plan-editor"
         #containerElement>
      <div class="floor-plan-editor-selector"
           [class.has-editor]="allowChangeMarkers()">
        <dx-select-box id="id"
                       displayExpr="plan.name"
                       [items]="floorPlans" [value]="selectedFloorPlan"
                       (onSelectionChanged)="onLeasePlanSelected($event)">
        </dx-select-box>
      </div>

      <ng-container *ngIf="getFloorPlanImage() as floorPlanImage">
        <app-plan-viewer [image]="floorPlanImage" [scale]="floorPlanImageScale" [isDragEnabled]="true"
                         [allowChangeMarkers]="allowChangeMarkers()"
                         [width]="containerElement.offsetWidth"
                         [height]="containerElement.offsetHeight"
                         [markerColorChange]="markerColorChange$"
                         [markerCreationEnd]="markerCreationEnd$" [markerDeletionEnd]="markerDeletionEnd$" [markerChangingEnd]="markerChangingEnd$"
                         [(selectedMarkerId)]="selectedAnchorId"
                         [(hoveredMarkerId)]="hoveredAnchorId"
                         [suggestions]="getSuggestions()"
                         (scaleChange)="handleScaleChange($event)"
                         (markerCreated)="handlePlanMarkerCreate($event)"
                         (markerChanged)="handlePlanMarkerChange($event)"
                         (markerDeleted)="handlePlanMarkerDelete($event)"
                         (imageMarkerCreated)="handleImageMarkerCreate($event)"
                         (imageMarkerChanged)="handleImageMarkerChange($event)"
                         (imageMarkerDeleted)="handleImageMarkerDelete($event)"
                         (planChanged)="handlePlanChange($event)"
                         (markerCommentAdded)="handleAnchorCommentAdded($event)"
                         (markerCommentChanged)="handleAnchorCommentChanged($event)"
                         (costChanged)="onAmountChanged($event)"
                         (statusChanged)="changeStatus($event)"
                         [mode]="PlanViewerMode.Marker">
        </app-plan-viewer>
      </ng-container>
    </div>
    <!-- End: Editor -->
    <!-- Start: Details -->
    <div class="floor-plan-details">
      <!-- Start: Plan Info -->
      <div class="floor-plan-details-info">
        <h3 class="floor-plan-details-info-title">
          Tenant Improvements
        </h3>
        <p class="floor-plan-details-info-description">
          In this module you can mark up the Improvements, add detailed descriptions and costs for reference.
        </p>
      </div>
      <!-- End: Plan Info -->
      <!-- Start: Anchors -->
      <div class="floor-plan-details-anchors">
        <!-- Start: Empty State -->
        <ng-container *ngIf="!anchors || !anchors.length; else anchorsTemplate">
          <div class="floor-plan-details-anchors-empty-state">
            <div class="floor-plan-details-anchors-empty-state-inner">
              <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#no-improvements"></i>
              <h3>No Improvements Added</h3>
              <p>
                Add the proposed Tenant Improvements to the Floor Plan using one of the tools in the Tool Panel.
              </p>
            </div>
          </div>
        </ng-container>
        <!-- End: Empty State -->
        <ng-template #anchorsTemplate>
          <dx-scroll-view width="100%" height="100%">
            <!-- Start: Anchor -->
            <a role="button" class="floor-plan-details-anchor"
               *ngFor="let anchor of anchors; let index = index"
               [class.active]="selectedAnchorId === anchor?.id"
               [class.rejected]="anchor.status === AnchorStatus.Rejected"
               (click)="selectedAnchorId = anchor?.id"
               (mouseover)="hoveredAnchorId = anchor?.id"
               (mouseleave)="hoveredAnchorId = null">
              <!-- Start: Anchor Header -->
              <div class="floor-plan-details-anchor-header">
                <div class="floor-plan-details-anchor-header-title">
                  <span>{{index + 1}}</span>
                  <h3>
                    {{anchor?.name}}
                  </h3>
                </div>
                <div class="floor-plan-details-anchor-header-coast">
                  <ng-container *ngIf="anchor?.amount as amount; else noAmountTemplate">
                    {{amount | currency}}
                  </ng-container>
                  <ng-template #noAmountTemplate>
                    ???
                  </ng-template>
                </div>
              </div>
              <!-- End: Anchor Header -->
              <!-- Start: Anchor Body -->
              <div class="floor-plan-details-anchor-body">
                <!-- Start: Anchor Attributes -->
                <ul class="floor-plan-details-anchor-attributes">
                  <li *ngIf="getBuildingArea(anchor?.buildingArea) as buildingAreaTypeName">
                    <i inlineSVG="assets/icons/inlineSVG/floorPlanSVG.svg#interior-office"></i>
                    <span>{{buildingAreaTypeName}}</span>
                  </li>
                  <li>
                    <span>{{anchor?.comments?.length | i18nPlural: {'=1': '1 comment', 'other': '# comments'} }}</span>
                  </li>
                </ul>
                <!-- End: Anchor Attributes -->
                <div class="floor-plan-details-anchor-last-update"
                     *ngIf="getLastCommentDate(anchor) as lastCommentDate">
                  {{lastCommentDate | timeAgo}}
                </div>
              </div>
              <!-- End: Anchor Body -->
            </a>
            <!-- End: Anchor -->
          </dx-scroll-view>
        </ng-template>
      </div>
      <!-- End: Anchors -->
      <!-- Start: Total -->
      <div class="floor-plan-details-total"
           *ngIf="getTotalAmountCosts() as totalCost;">
        <div>
          Total cost of Improvements:
        </div>
        <div>
          <ng-container *ngIf="totalCost.sum as total; else noTotalTemplate">
            ≈{{total | currency}}
          </ng-container>
          <ng-template #noTotalTemplate>
            ???
          </ng-template>
        </div>
      </div>
      <!-- End: Total -->
    </div>
    <!-- End: Details -->
  </div>
  <!-- End: Body -->
</div>
