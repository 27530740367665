import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import {Subject} from 'rxjs';
import {take, takeUntil, tap} from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import {BuildingManager} from '@statera/sdk/building';
import {LeaseManager} from '@statera/sdk/lease';
import {ProjectManager} from '@statera/sdk/project';
import {TermManager} from '@statera/sdk/term';

import {SafeNumberPipe} from '../../../../../infrastructure/pipes/safe-number.pipe';

import {AlertService} from '../../../../../alert/services/alert.service';
import {LeaseService} from '../../../../../shared/services/lease.service';
import {ProjectService} from '../../../../../shared/services/project.service';
import {ProjectAccessService} from '../../../../../shared/services/project-access.service';
import {TermsPageService} from '../../../../services/terms-page.service';

import * as models from '../../../../../infrastructure/models/generated';
import { ITenantSquareFootageVacantUnitViewModel, TenantSquareFootageTermType } from '../../../../../infrastructure/models/generated';

import {BaseTermDirective} from '../base-term.directive';
import {
  TenantSquareFootagePhaseInTableComponent
} from './tenant-square-footage-phase-in-table/tenant-square-footage-phase-in-table.component';

interface RadioOption<T> {
  name: string;
  value: T;
  disabled?: boolean;
  visible?: boolean;
}

interface TermOption extends RadioOption<models.TenantSquareFootageTermType> {
  visibleInProjectTypes: Array<models.ProjectTypeEnum>;
}

@Component({
  selector: 'app-tenant-square-footage',
  templateUrl: 'tenant-square-footage.component.html',
  styleUrls: ['tenant-square-footage.component.scss'],
})
export class TenantSquareFootageComponent
  extends BaseTermDirective<models.ITenantSquareFootageTermViewModel>
  implements OnInit, OnChanges, OnDestroy {
  private static readonly _options: Array<TermOption> = [
    {
      name: 'Existing Square Footage',
      value: models.TenantSquareFootageTermType.ExistingSquareFootage,
      visibleInProjectTypes: [
        models.ProjectTypeEnum.Renewal,
        models.ProjectTypeEnum.RenewalInitiatedByLandlord,
        models.ProjectTypeEnum.Restructure,
        models.ProjectTypeEnum.NewDeal
      ],
    },
    {
      name: 'Custom',
      value: models.TenantSquareFootageTermType.Custom,
      visibleInProjectTypes: [
        models.ProjectTypeEnum.Renewal,
        models.ProjectTypeEnum.RenewalInitiatedByLandlord,
        models.ProjectTypeEnum.NewDeal
      ],
    },
    {
      name: 'Phase-in',
      value: models.TenantSquareFootageTermType.PhaseIn,
      visibleInProjectTypes: [
        models.ProjectTypeEnum.Renewal,
        models.ProjectTypeEnum.RenewalInitiatedByLandlord,
        models.ProjectTypeEnum.Restructure,
        models.ProjectTypeEnum.NewDeal
      ],
    },
    {
      name: 'Expand',
      value: models.TenantSquareFootageTermType.Expand,
      visibleInProjectTypes: [
        models.ProjectTypeEnum.Restructure
      ],
    },
    {
      name: 'Relocate',
      value: models.TenantSquareFootageTermType.Relocate,
      disabled: true,
      visibleInProjectTypes: [
        models.ProjectTypeEnum.Restructure,
      ],
    },
    {
      name: 'Downsize',
      value: models.TenantSquareFootageTermType.Downsize,
      disabled: false,
      visibleInProjectTypes: [
        models.ProjectTypeEnum.Restructure,
      ],
    },
    {
      name: 'Existing Unit Size',
      value: models.TenantSquareFootageTermType.ExistingUnitSize,
      disabled: false,
      visibleInProjectTypes: [
        models.ProjectTypeEnum.NewDeal,
        models.ProjectTypeEnum.NewDealInquiry,
      ],
    },
  ];

  private static readonly _expandRadioOptions: Array<RadioOption<number>> = [
    {
      name: 'Select one or multiple vacant units',
      value: 0,
    },
    {
      name: 'Select custom',
      value: 1,
    }
  ];


  private static readonly _relocateRadioOptions: Array<RadioOption<number>> = [
    {
      name: 'Select one or multiple vacant units',
      value: 0,
    },
    {
      name: 'Select custom',
      value: 1,
    }
  ];

  @ViewChild(NgForm) form: NgForm;
  @ViewChild(TenantSquareFootagePhaseInTableComponent) tenantSquareFootagePhaseInTableComponent: TenantSquareFootagePhaseInTableComponent;

  tenantSquareFootageTermType: typeof models.TenantSquareFootageTermType;

  options: Array<TermOption>;

  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;

  vacantUnits: Array<models.IBuildingUnitViewModel>;
  vacantUnitsDataSource: DataSource;

  expandRadioOptions: Array<RadioOption<number>>;
  expandRadioOption: number;

  relocateRadioOptions: Array<RadioOption<number>>;
  relocateRadioOption: number;

  handleSubmit: boolean;

  private readonly _buildingManager: BuildingManager;
  private readonly _safeNumberPipe: SafeNumberPipe;
  private readonly _destroy$: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    projectService: ProjectService,
    projectAccessService: ProjectAccessService,
    leaseService: LeaseService,
    leaseManager: LeaseManager,
    termManager: TermManager,
    projectManager: ProjectManager,

    buildingManager: BuildingManager,
    safeNumberPipe: SafeNumberPipe,
  ) {
    super(
      alertService,
      alertMessagesManager,
      termsPageService,
      projectService,
      projectAccessService,
      leaseService,
      leaseManager,
      termManager,
      projectManager,
    );

    this.options = TenantSquareFootageComponent._options;

    this.expandRadioOptions = TenantSquareFootageComponent._expandRadioOptions;
    this.expandRadioOption = 0;

    this.relocateRadioOptions = TenantSquareFootageComponent._relocateRadioOptions;
    this.relocateRadioOption = 0;

    this.tenantSquareFootageTermType = models.TenantSquareFootageTermType;

    this._buildingManager = buildingManager;
    this._safeNumberPipe = safeNumberPipe;
    this._destroy$ = new Subject<void>();

    this.selectedVacantUnitsValueFormatter = this.selectedVacantUnitsValueFormatter.bind(this);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.leaseTerm) {
      this.leaseTerm = <models.ITenantSquareFootageTermViewModel>{};
    }

    this._initialize();

    this.vacantUnits = [];

    this.vacantUnitsDataSource = new DataSource({
      key: 'id',
      store: this.vacantUnits,
    });

    this._buildingManager
      .requestBuildingAvailableBuildingUnits(this.lease.buildingId, [this.lease.buildingUnitId])
      .pipe(
        tap((availableUnits) => {
          this.vacantUnits = availableUnits;

          this.vacantUnitsDataSource.load();

          this.options = this.options.map(option => {
            if (
              option.value === models.TenantSquareFootageTermType.Expand ||
              option.value === models.TenantSquareFootageTermType.Relocate
            ) {
              return {
                ...option,
                disabled: !availableUnits || !availableUnits.length
              };
            }

            return option;
          });
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    if (this.accordionOpened$) {
      this.accordionOpened$
        .pipe(
          tap(() => this._initialize()),
          takeUntil(this._destroy$),
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (
      changes.leaseTerm &&
      changes.leaseTerm.previousValue &&
      changes.leaseTerm.currentValue &&
      !this.termManager.isSameTerms(
        <models.ILeaseTermViewModel>changes.leaseTerm.previousValue,
        <models.ILeaseTermViewModel>changes.leaseTerm.currentValue,
      )
    ) {
      this._initialize();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isTermWillBeAddressedInFollowingStages(): boolean {
    return (
      this.leaseTerm.termStatus === models.TermStatus.Ready &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant
    );
  }

  isButtonsVisible(): boolean {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      this.leaseTerm.termStatus !== models.TermStatus.Draft &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease
      )
    ) {
      return true;
    }

    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.hasMultiplyOptions &&
      this.lease.term.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    return super.isButtonsVisible();
  }

  isAcceptButtonVisible(): boolean {
    if (
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return false;
    }

    if (this.isOkButtonVisible()) {
      return false;
    }

    return super.isAcceptButtonVisible();
  }

  isPreviousButtonVisible(): boolean {
    if (
      this.lease &&
      this.lease.term &&
      this.lease.term.termStatus !== models.TermStatus.Pending &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease,
      )
    ) {
      return true;
    }

    return super.isPreviousButtonVisible();
  }

  isOkButtonVisible(): boolean {
    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
        this.project,
        this.lease
      )
    ) {
      return true;
    }

    const isTenantCounterOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterOffer
    );

    const isLandlordInitiateDealTenantCounterUnsolicitedOfferStep = (
      this.project &&
      this.project.projectState &&
      this.project.projectState.renewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer
    );

    if (
      this.leaseTerm.termStatus === models.TermStatus.Pending &&
      (
        isTenantCounterOfferStep ||
        isLandlordInitiateDealTenantCounterUnsolicitedOfferStep
      )
    ) {
      const leaseTermHistoryRecord = this.leaseHistoryRecord
        .leaseTermHistoryRecords
        .find((record) => (
          record.leaseTermType === this.leaseTerm.leaseTermType
        ));

      if (
        !leaseTermHistoryRecord ||
        !leaseTermHistoryRecord.termHistoryModels ||
        !leaseTermHistoryRecord.termHistoryModels.length
      ) {
        return false;
      }

      const currentTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 1
      ];

      const previousTermHistoryModel = leaseTermHistoryRecord.termHistoryModels[
        leaseTermHistoryRecord.termHistoryModels.length - 2
      ];

      if (!previousTermHistoryModel) {
        return false;
      }

      if (this.project.projectType.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord) {
        return (
          currentTermHistoryModel.isMultipleOptions &&
          previousTermHistoryModel.isMultipleOptions
        );
      }

      return previousTermHistoryModel.isMultipleOptions;
    }

    return super.isOkButtonVisible();
  }

  handlePreviousButtonClick() {
    if (
      this.leaseTerm &&
      this.leaseTerm.hasMultiplyOptions &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease
      )
    ) {
      const alertReference = this.alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this.alertMessagesManager.getConfirmSetMultipleOptionsTermPreviousValueAlertText(),
      });

      alertReference
        .confirmed
        .pipe(
          tap(() => super.handlePreviousButtonClick()),
          take(1),
          takeUntil(this.destroy),
        )
        .subscribe();

      return;
    }

    super.handlePreviousButtonClick();
  }

  handleOkButtonClick(): void {
    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm.termStatus !== models.TermStatus.Ready &&
      (
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
          this.project,
          this.lease
        ) ||
        this.projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterOffer,
          this.project,
          this.lease
        )
      )
    ) {
      super.handleOkButtonClick();
      return;
    }

    if (
      this.leaseTerm &&
      this.leaseTerm.termStatus !== models.TermStatus.Rejected &&
      this.leaseTerm.termStatus !== models.TermStatus.Ready &&
      this.projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
        this.project,
        this.lease
      )
    ) {
      super.handleOkButtonClick();
      return;
    }

    this.handleAcceptButtonClick();
  }

  submit(): void {
    this.clearErrors();

    if (!this.form || this.form.invalid) {
      return;
    }

    if (!this.lease || !this.leaseTerm) {
      return;
    }

    if (!this.leaseTerm.tenantSquareFootageTermType) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (
      this.leaseTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.PhaseIn &&
      this.tenantSquareFootagePhaseInTableComponent
    ) {
      const isPhaseInValid = this.tenantSquareFootagePhaseInTableComponent.trySavePhaseInValues();
      if (!isPhaseInValid) {
        const errors = this.tenantSquareFootagePhaseInTableComponent.getPhaseInValueErrorMessages();
        if (errors) {
          errors.forEach(error => this.addError(error));
        }

        return;
      }
    }

    if (
      this.leaseTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.MultipleOptions &&
      (
        !this.leaseTerm.leaseTermOptions ||
        !this.leaseTerm.leaseTermOptions.options ||
        !this.leaseTerm.leaseTermOptions.options.every(x => this._isFilled(x))
      )
    ) {
      this.addError('You should choose appropriate options.');
      return;
    }

    if (this._areSameMultipleOptionsAndAreSameTermMultipleOptions()) {
      this.addError('Both Option A and B for Term and Size cannot be the same. Please adjust one or the other accordingly.');
      return;
    }

    this.saveLeaseTerm(this.leaseTerm);
  }

  isMultipleOptionsVisible(): boolean {
    return (
      this.lease &&
      this.lease.term &&
      this.lease.term.termType === models.TermTypeEnum.MultiplyOptions &&
      this.lease.term.leaseTermOptions &&
      !this.lease.term.leaseTermOptions.selectedLeaseTerm
    );
  }

  canInsertNewOption(): boolean {
    return this.termManager.canInsertNewOption(this.lease.term, this.project);
  }

  private _initialize(): void {
    this._prepareOptions();
    this._fillMultipleOptionsIfNeeded();
  }

  private _prepareOptions(): void {
    if (!this.options) {
      return;
    }

    const projectType = this.project && this.project.projectType && this.project.projectType.projectTypeEnum;

    if (projectType === models.ProjectTypeEnum.NewDeal) {
      this.options = this.options.filter(option => option.value !== models.TenantSquareFootageTermType.ExistingSquareFootage);
    }

    this.options = this.options.map(option => {
      const isVisible = projectType && option.visibleInProjectTypes.includes(projectType);

      if (option.value === models.TenantSquareFootageTermType.PhaseIn) {
        return {
          ...option,
          visible: isVisible,
          disabled: this.isMultipleOptionsVisible(),
        };
      }

      if (option.value === models.TenantSquareFootageTermType.ExistingUnitSize) {
        const isUnitSizeVisible = this.lease?.buildingUnit?.size && this.lease?.buildingUnit?.size > 0;
        if (!isUnitSizeVisible) {
          return {
            ...option,
            visible: false,
          };
        }
        const size = `Existing Unit Size (${this._safeNumberPipe.transform(this.lease.buildingUnit.size)} SF)`;
        return {
          ...option,
          visible: isVisible,
          name: size
        };
      }

      return {
        ...option,
        visible: isVisible,
      };
    });

    if (
      projectType === models.ProjectTypeEnum.Restructure &&
      this.leaseTerm &&
      this.leaseTerm.tenantSquareFootageTermType !== models.TenantSquareFootageTermType.MultipleOptions &&
      this.leaseTerm.termStatus === models.TermStatus.Draft
    ) {
      this.leaseTerm.tenantSquareFootageTermType = models.TenantSquareFootageTermType.ExistingSquareFootage;
    }
  }

  private _areSameMultipleOptionsAndAreSameTermMultipleOptions(): boolean {
    return (
      this.lease &&

      this.lease.tenantSquareFootageTerm &&
      this.lease.tenantSquareFootageTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.MultipleOptions &&
      this.lease.tenantSquareFootageTerm.leaseTermOptions &&
      this.lease.tenantSquareFootageTerm.leaseTermOptions.options &&

      this.lease.tenantSquareFootageTerm.leaseTermOptions.options.every(x => x.tenantSquareFootageTermType) &&

      this.areSameMultipleOptions(
        this.lease.tenantSquareFootageTerm.leaseTermOptions.options,
        x => this.termManager.getCurrentTenantSquareFootage(x)
      ) &&

      this.lease.term &&
      this.lease.term.termType === models.TermTypeEnum.MultiplyOptions &&
      this.lease.term.leaseTermOptions &&
      this.lease.term.leaseTermOptions.options &&

      this.lease.term.leaseTermOptions.options.every(x => x.termType) &&

      this.areSameMultipleOptions(
        this.lease.term.leaseTermOptions.options,
        x => this.termManager.getCurrentTerm(x)
      )
    );
  }

  private _fillMultipleOptionsIfNeeded(): void {
    if (
      this.leaseTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.MultipleOptions &&
      (
        !this.leaseTerm.leaseTermOptions ||
        !this.leaseTerm.leaseTermOptions.options ||
        !this.leaseTerm.leaseTermOptions.options.length
      )
    ) {
      this.fillLeaseTermOptions(this.leaseTerm.leaseTermOptions);
    }
  }

  private _isFilled(tenantSquareFootageTerm: models.ITenantSquareFootageTermViewModel): boolean {
    if (!tenantSquareFootageTerm || !tenantSquareFootageTerm.tenantSquareFootageTermType) {
      return false;
    }

    switch (tenantSquareFootageTerm.tenantSquareFootageTermType) {
      case models.TenantSquareFootageTermType.ExistingSquareFootage:
        return true;

      case models.TenantSquareFootageTermType.Custom:
        return !!tenantSquareFootageTerm.tenantSquareFootageCustomValue;

      case models.TenantSquareFootageTermType.PhaseIn:
        return (
          tenantSquareFootageTerm.tenantSquareFootagePhaseInValues &&
          0 < tenantSquareFootageTerm.tenantSquareFootagePhaseInValues.length
        );

      case models.TenantSquareFootageTermType.ExistingUnitSize:
        return true;

      case TenantSquareFootageTermType.Expand:
        return (
          !!tenantSquareFootageTerm.tenantSquareFootageRestructuredValue ||
          (
            !!tenantSquareFootageTerm.tenantSquareFootageExpandByUnits &&
            !!tenantSquareFootageTerm.tenantSquareFootageExpandByUnits.length
          )
        );

      case TenantSquareFootageTermType.Relocate:
        return (
          !!tenantSquareFootageTerm.tenantSquareFootageRestructuredValue ||
          (
            !!tenantSquareFootageTerm.tenantSquareFootageRelocateByUnits &&
            !!tenantSquareFootageTerm.tenantSquareFootageRelocateByUnits.length
          )
        );

      case TenantSquareFootageTermType.Downsize:
        return !!tenantSquareFootageTerm.tenantSquareFootageRestructuredValue;

      default:
        return false;
    }
  }

  getMinTenantSquareFootage(buildingUnits: Array<models.IBuildingUnitViewModel>): number {
    if (!buildingUnits || !buildingUnits.length) {
      return null;
    }

    const sortedBuildingUnits = this.vacantUnits
      .sort((x, y) => x.size - y.size);

    if (!sortedBuildingUnits[0]) {
      return null;
    }

    return sortedBuildingUnits[0].size;
  }

  getMaxTenantSquareFootage(buildingUnits: Array<models.IBuildingUnitViewModel>): number {
    if (!buildingUnits || !buildingUnits.length) {
      return null;
    }

    const sortedBuildingUnits = this.vacantUnits
      .reduce((acc, x) => acc + x.size, 0);

    if (!sortedBuildingUnits[0]) {
      return null;
    }

    return sortedBuildingUnits[0].size;
  }

  selectedVacantUnitsValueFormatter(selectedItems: Array<models.ITenantSquareFootageVacantUnitViewModel>): string {
    if (!selectedItems || !selectedItems.length) {
      return null;
    }

    const unitNames = selectedItems
      .map(x => x.name)
      .join(', ');

    const totalSF = selectedItems
      .reduce((acc, x) => acc + x.size, 0)
      .toString();

    return `${unitNames} – ${this._safeNumberPipe.transform(totalSF || 0)} SF`;
  }

  handleExpandByUnitsChange(selectedItems: {selectedRowsData: Array<models.ITenantSquareFootageVacantUnitViewModel>}): void {
    if (!selectedItems || !selectedItems.selectedRowsData || !this.leaseTerm) {
      return;
    }

    this.leaseTerm.tenantSquareFootageExpandByUnits = selectedItems.selectedRowsData
      .filter(Boolean)
      .map(unit => {
        return <models.ITenantSquareFootageVacantUnitViewModel>{
          id: unit.id,
          buildingId: unit.buildingId,
          size: unit.size ?? 0,
          name: unit.name,
        };
      });

    this.leaseTerm.tenantSquareFootageRestructuredValue = this.leaseTerm.tenantSquareFootageExpandByUnits
      .reduce((acc, x) => acc + x.size, 0);
  }

  handleRelocateByUnitsChange(selectedItems: {selectedRowsData: Array<models.ITenantSquareFootageVacantUnitViewModel>}): void {
    if (!selectedItems || !selectedItems.selectedRowsData || !this.leaseTerm) {
      return;
    }

    this.leaseTerm.tenantSquareFootageRelocateByUnits = selectedItems.selectedRowsData
      .filter(Boolean)
      .map(unit => {
        return <models.ITenantSquareFootageVacantUnitViewModel>{
          id: unit.id,
          buildingId: unit.buildingId,
          size: unit.size ?? 0,
          name: unit.name,
        };
      });

    this.leaseTerm.tenantSquareFootageRestructuredValue = this.leaseTerm.tenantSquareFootageRelocateByUnits
      .reduce((acc, x) => acc + x.size, 0);
  }

  canFillPhaseInTable(): boolean {
    return (
      !!this.getTerm() &&
      !!this.getCommencement()
    );
  }

  getTerm(): number {
    if (!this.lease || !this.lease.term || !this.lease.term.termValue) {
      return 0;
    }

    return this.lease.term.termValue;
  }

  getCommencement(): Date | string {
    if (!this.lease || !this.lease.commencementTerm || !this.lease.commencementTerm.commencement) {
      return null;
    }

    return this.lease.commencementTerm.commencement;
  }
}
