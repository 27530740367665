import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AlertNotification, AlertType } from '@statera/sdk/alert';
import { AuthManager, Role } from '@statera/sdk/auth';
import { Notification, NotificationInterceptor } from '@statera/sdk/notification';

@Injectable()
export class TourAlertInterceptor implements NotificationInterceptor<AlertNotification> {
  private readonly _authManager: AuthManager;
  private readonly _router: Router;

  constructor(
    authManager: AuthManager,
    router: Router,
  ) {
    this._authManager = authManager;
    this._router = router;
  }

  intercept(notification: Notification<AlertNotification>): Notification<AlertNotification> {
    const alert = notification.payload;
    if (!alert) {
      return notification;
    }

    /**
     * Skip all non-tour alerts.
     */

    if (
      alert.alertType !== AlertType.TourCreatedOwnTeamAlert &&
      alert.alertType !== AlertType.TourUpdatedOwnTeamAlert &&
      alert.alertType !== AlertType.TourConfirmedOwnTeamAlert &&
      alert.alertType !== AlertType.TourCreatedOtherTeamAlert &&
      alert.alertType !== AlertType.TourUpdatedOtherTeamAlert &&
      alert.alertType !== AlertType.TourConfirmedOtherTeamAlert &&
      alert.alertType !== AlertType.TourCancelledAlert
    ) {
      return notification;
    }

    /**
     * Remove "Refresh" button for all non-action alerts.
     */

    if (
      alert.alertType === AlertType.TourCreatedOwnTeamAlert ||
      alert.alertType === AlertType.TourUpdatedOwnTeamAlert ||
      alert.alertType === AlertType.TourConfirmedOwnTeamAlert ||
      alert.alertType === AlertType.TourCancelledAlert
    ) {
      alert.shouldShowNavigationButton = false;

      return notification;
    }

    /**
     * Add button action handler for "OtherTeam" action alerts.
     */

    if (
      alert.alertType === AlertType.TourCreatedOtherTeamAlert ||
      alert.alertType === AlertType.TourUpdatedOtherTeamAlert
    ) {
      alert
        .reference
        .confirmed
        .pipe(
          take(1),
          switchMap(() => this._authManager.getStartupInfo()),
          tap((startupInfo) => {
            if (startupInfo.role !== Role.Landlord) {
              const colaboUrlTree = this._router
                .createUrlTree(
                  [
                    'colabo',
                    'main',
                    alert.leaseId,
                  ],
                  {
                    queryParams: {
                      openTourRequestDialog: true,
                    },
                  },
                );

              window.location.href = this._router.serializeUrl(colaboUrlTree);

              return;
            }

            const inquiriesAndRequestsUrlTree = this._router
              .createUrlTree(
                [
                  'v2',
                  'inquiries-and-requests',
                ],
                {
                  queryParams: {
                    leaseId: alert.leaseId,
                  },
                },
              );

            window.location.href = this._router.serializeUrl(inquiriesAndRequestsUrlTree);
          }),
          takeUntil(notification.dequeued),
        )
        .subscribe();

      return notification;
    }

    if (alert.alertType === AlertType.TourConfirmedOtherTeamAlert) {
      alert.shouldShowNavigationButton = true;
      alert.navigationButtonText = 'View Details';
      alert.navigation = {
        path: `/colabo/main/${alert.leaseId}`,
        queryParams: {
          openTourRequestDialog: true,
        },
      };

      return notification;
    }

    return notification;
  }
}
