import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {Configuration, IUpdateProjectNotesViewModel} from '@statera/sdk/common';
import { Observable } from 'rxjs';

import * as models from './project.model';

@Injectable()
export class ProjectRepository {
  private readonly _configuration: Configuration;
  private readonly _httpClient: HttpClient;

  constructor(configuration: Configuration, httpClient: HttpClient) {
    this._configuration = configuration;
    this._httpClient = httpClient;
  }

  getProjectsByLeaseId(leaseId: number): Observable<Array<models.ProjectViewModel>> {
    const options = {
      params: new HttpParams().set('leaseId', leaseId.toString())
    };

    return this._httpClient
      .get<Array<models.ProjectViewModel>>(`${this._configuration.apiEndpoint}/project/GetByLeaseId`, options);
  }

  renewalProject(
    lease: models.LeaseViewModel,
    renewalProjectTriggerType: models.RenewalProjectTriggerType,
    project: models.ProjectViewModel,
    approvingAppointment: models.ApprovingAppointment = null,
    isAcceptTiNegotiationByTenantOrLandlord: boolean = null,
    isAcceptTiOptionAfterFloorPlan: boolean = null,
    rejectionLeaseViewModel: models.RejectionLease = null
  ): Observable<models.LeaseViewModel> {
    let params = new HttpParams();

    if (!approvingAppointment) {
      approvingAppointment = <models.ApprovingAppointment>{};
    }

    const clientUtcOffset = ((new Date()).getTimezoneOffset() / 60) * -1;

    const renewalProjectParamsViewModel = <models.RenewalProjectParams>{
      leaseViewModel: lease,
      approvingAppointment: approvingAppointment,
      isAcceptTiNegotiationByTenantOrLandlord: isAcceptTiNegotiationByTenantOrLandlord,
      isAcceptTiOptionAfterFloorPlan: isAcceptTiOptionAfterFloorPlan,
      clientUtcOffset: clientUtcOffset,
      projectId: project.id,
      rejectionLeaseViewModel: rejectionLeaseViewModel
    };

    if (renewalProjectTriggerType) {
      params = params.set('renewalProjectTriggerType', renewalProjectTriggerType.toString());
    }

    return this._httpClient
      .post<models.LeaseViewModel>(`${this._configuration.apiEndpoint}/project/RenewalProject`, renewalProjectParamsViewModel, {params});
  }

  getProjectRequests(): Observable<Array<models.ProjectRequestBriefViewModel>> {
    return this._httpClient
      .get<Array<models.ProjectRequestBriefViewModel>>(`${this._configuration.apiEndpoint}/project/GetProjectRequests`);
  }

  getProjectRequestByLeaseId(leaseId: number): Observable<models.ProjectRequestViewModel> {
    return this._httpClient
      .get<models.ProjectRequestViewModel>(`${this._configuration.apiEndpoint}/project/GetProjectRequestByLeaseId/${leaseId}`);
  }

  getProjectTours(projectId: number): Observable<Array<models.TourViewModel>> {
    return this._httpClient
      .get<Array<models.TourViewModel>>(`${this._configuration.apiEndpoint}/project/${projectId}/tours`);
  }

  createProjectTour(projectId: number, tour: models.CreateTourViewModel): Observable<models.TourViewModel> {
    return this._httpClient
      .post<models.TourViewModel>(`${this._configuration.apiEndpoint}/project/${projectId}/tours`, tour);
  }

  updateProjectTour(tour: models.TourViewModel): Observable<models.TourViewModel> {
    return this._httpClient
      .put<models.TourViewModel>(`${this._configuration.apiEndpoint}/project/${tour.projectId}/tours/${tour.id}`, tour);
  }

  cancelProjectTour(tour: models.TourViewModel): Observable<void> {
    return this._httpClient
      .delete<void>(`${this._configuration.apiEndpoint}/project/${tour.projectId}/tours/${tour.id}`);
  }

  convertInquiryToNewDeal(projectId: number): Observable<models.ProjectViewModel> {
    return this._httpClient
      .post<models.ProjectViewModel>(
        `${this._configuration.apiEndpoint}/project/${projectId}/ConvertToNewDeal`,
        null);
  }

  getActiveInquiryByBuildingUnitId(unitId: number): Observable<models.ProjectViewModel> {
    return this._httpClient
      .get<models.ProjectViewModel | null>(`${this._configuration.apiEndpoint}/project/GetActiveInquiryByBuildingUnitId/${unitId}`);
  }

  getProjectStatisticsInfo(projectId: number): Observable<models.ProjectStatisticsInfo> {
    const options = {
      params: new HttpParams().set('projectId', projectId.toString()),
    };

    return this._httpClient
      .get<models.ProjectStatisticsInfo>(`${this._configuration.apiEndpoint}/project/GetProjectStatisticInfo`, options);
  }

  getProjectStatistics(projectId: number): Observable<models.ProjectStatistics> {
    const options = {
      params: new HttpParams().set('projectId', projectId.toString()),
    };

    return this._httpClient
      .get<models.ProjectStatistics>(`${this._configuration.apiEndpoint}/project/GetProjectStatistic`, options);
  }

  updateProjectNotes(projectId: number, notes: string): Observable<void> {
    return this._httpClient
      .post<void>(`${this._configuration.apiEndpoint}/project/${projectId}/notes`, <IUpdateProjectNotesViewModel> {
        notes,
        projectId
      });
  }
}
