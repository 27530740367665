import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { InfoFlag } from '@statera/sdk/auth';
import { AlertMessagesManager } from '@statera/sdk/alert';

import { AuthService } from '../../auth/services/auth.service';
import { AlertService } from '../../alert/services/alert.service';

import * as models from '../models/generated';

@Injectable({
  providedIn: 'root',
})
export class FillingProfileGuard implements CanActivate {
  static readonly ProfileEditPage = '/profile/profile-edit';

  private readonly _authService: AuthService;
  private readonly _router: Router;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;

  constructor(
    authService: AuthService,
    router: Router,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
  ) {
    this._authService = authService;
    this._router = router;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url: string = state.url;

    return this._authService
      .infoLoadComplete
      .pipe(
        map((startupInfo) => {
          // WORKAROUND: Prevents endless redirect loop
          // Deactivated user status is processed in another guard (see: no-active-user-status.guard.ts)
          // TODO: Create a single guard for all these cases
          if (startupInfo.userStatus === models.UserStatus.Deactivated) {
            return true;
          }

          const hasIncompleteProfile = InfoFlag.hasFlag(startupInfo?.infoFlags, InfoFlag.HasIncompleteProfile);
          if (hasIncompleteProfile) {
            if (FillingProfileGuard.ProfileEditPage !== url) {
              this._alertService.pushWarningAlert({
                message: this._alertMessagesManager.getProfileRequiredFieldsEmptyAlertText(),
              });

              return this._router.createUrlTree(['profile', 'profile-edit']);
            }
          }

          const hasUnconfirmedEmail = InfoFlag.hasFlag(startupInfo?.infoFlags, InfoFlag.HasUnconfirmedEmail);
          if (hasUnconfirmedEmail) {
            if (
              !['/', '/profile', '/profile/profile-edit', '/forgot-password', '/profile/avatar-edit'].find(x => x === url)
            ) {
              return this._router.createUrlTree(['profile']);
            }
          }

          return true;
        }),
        catchError(() => {
          return of(true);
        }),
      );
  }
}
