import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { InfoFlag } from '@statera/sdk/auth';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../../auth/services/auth.service';

import * as models from '../models/generated';

@Injectable({
  providedIn: 'root',
})
export class NoActiveUserStatusGuard implements CanActivate, CanActivateChild {
  static readonly NoActiveUserStatusPageUrl = '/profile/no-active-user-status';

  private readonly _authService: AuthService;
  private readonly _router: Router;

  constructor(
    authService: AuthService,
    router: Router,
  ) {
    this._authService = authService;
    this._router = router;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url: string = state.url;

    return this._authService
      .infoLoadComplete
      .pipe(
        map((startupInfo) => {
          const hasPortfolioInvitation = InfoFlag.hasFlag(startupInfo?.infoFlags, InfoFlag.HasPortfolioInvitation);
          if (!startupInfo || (!hasPortfolioInvitation && startupInfo.userStatus === models.UserStatus.Deactivated)) {
            if (NoActiveUserStatusGuard.NoActiveUserStatusPageUrl !== url) {
              return this._router.createUrlTree(['profile', 'no-active-user-status']);
            }
          }

          return true;
        }),
        catchError(() => {
          return of(true);
        }),
      );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}
