<dx-data-grid class="proposals-grid" [dataSource]="briefDealSummaries | async">
  <!-- Start: Stage -->
  <dxi-column dataField="projectTemplateItemName"
              caption="Stage"
              cellTemplate="projectTemplateItemNameCellTpl"
              width="220px">
  </dxi-column>

  <div *dxTemplate="let item of 'projectTemplateItemNameCellTpl'">
    <div class="d-flex flex-column">

      <span class="stage-title">{{item?.data?.projectTemplateItemName}}</span>

      <div class="stage-line-wrapper">
        <div *ngFor="let stage of [].constructor(maxStageCount); let i = index"
             [class.active-stage]="item?.data?.stageNumber > i"
             class="stage-line">

        </div>
      </div>

    </div>

    <div class="stage-user"
       *ngIf="item?.data?.userWhoProposed">
      proposed By {{item?.data?.userWhoProposed?.role}}
    </div>
  </div>
  <!-- End: Stage -->

  <dxi-column dataField="startDateOfDeal"
              caption="Start Date"
              dataType="date"
              width="160px">
  </dxi-column>

  <dxi-column dataField="term.displayValue" caption="Term"
              width="190px"
              [calculateCellValue]="getCurrentTerm"
              [calculateSortValue]="getCurrentTermNumber">
  </dxi-column>

  <dxi-column dataField="tenantSquareFootageTerm" caption="Proposed SF"
              width="120px"
              [calculateCellValue]="getCurrentTenantSquareFootage"
              [calculateSortValue]="getCurrentTenantSquareFootageNumber">
  </dxi-column>

  <dxi-column dataField="baseRentalRateTerm" caption="Rental Rate"
              width="150px"
              [calculateCellValue]="getCurrentRentalRate"
              [calculateSortValue]="getCurrentRentalRateNumber">
  </dxi-column>

  <dxi-column dataField="freeRentTerm.displayValue" caption="Free Rent"
              width="140px"
              [calculateCellValue]="getCurrentFreeRent"
              [calculateSortValue]="getCurrentFreeRentNumber">
  </dxi-column>

  <dxi-column dataField="tenantImprovementsTerm.displayValue" caption="Tenant Improvements"

              [calculateCellValue]="getCurrentTenantImprovements"
              [calculateSortValue]="getCurrentTenantImprovementsNumber">
  </dxi-column>
</dx-data-grid>
