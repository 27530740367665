import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { AlertManager, AlertNotification, AlertNotificationKind, AlertNotificationReference } from '@statera/sdk/alert';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  readonly refreshColabo: Subject<{leaseId: number, termName: string}>;
  readonly alertRenderingStopped: BehaviorSubject<boolean>;

  private readonly _alertManager: AlertManager;

  constructor(alertManager: AlertManager) {
    this._alertManager = alertManager;

    this.refreshColabo = new Subject<{leaseId: number, termName: string}>();
    this.alertRenderingStopped = new BehaviorSubject<boolean>(false);
  }

  pushSuccessAlert(alertNotification?: AlertNotification): AlertNotificationReference {
    return this.pushAlert({
      ...alertNotification,
      kind: AlertNotificationKind.Success,
      title: alertNotification.title || 'Success',
      autoclose: true,
    });
  }

  pushInfoAlert(alertNotification?: AlertNotification): AlertNotificationReference {
    return this.pushAlert({
      ...alertNotification,
      kind: AlertNotificationKind.Info,
      title: alertNotification.title || 'Info',
      autoclose: true,
    });
  }

  pushWarningAlert(alertNotification?: AlertNotification): AlertNotificationReference {
    return this.pushAlert({
      ...alertNotification,
      kind: AlertNotificationKind.Warning,
      title: alertNotification.title || 'Warning',
      autoclose: true,
    });
  }

  pushErrorAlert(alertNotification?: AlertNotification): AlertNotificationReference {
    return this.pushAlert({
      ...alertNotification,
      kind: AlertNotificationKind.Error,
      title: alertNotification.title || 'Ooops😬!',
      autoclose: true,
    });
  }

  pushConfirmAlert(alertNotification?: AlertNotification): AlertNotificationReference {
    return this.pushAlert({
      shouldShowConfirmButton: true,
      shouldShowDeclineButton: true,
      closeOnButtonClick: true,

      ...alertNotification,
      kind: AlertNotificationKind.Confirm,
      autoclose: false,
    });
  }

  pushAlert(alertNotification?: AlertNotification): AlertNotificationReference {
    const alertNotificationReference = new AlertNotificationReference();

    return this._alertManager
      .queueAlertNotification({
        ...alertNotification,
        id: alertNotification.id || btoa(unescape(encodeURIComponent(alertNotification.message))),
        reference: alertNotificationReference,
      });
  }

  stopRenderingAlerts(): void {
    this.alertRenderingStopped.next(true);
  }

  startRenderingAlerts(): void {
    this.alertRenderingStopped.next(false);
  }
}
