import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { CommonTools, IStateraUserClaimsSimplifiedViewModel, StateraClaimTypeAsEnum, StateraClaimValueAsEnum } from '@statera/sdk/common';

import { StateraUserClaimManager } from '@statera/sdk/statera-user-claim';

type ClaimType = StateraClaimTypeAsEnum | Array<StateraClaimTypeAsEnum>;
type ClaimValue = StateraClaimValueAsEnum;

interface HasClaimConfig {
  type: ClaimType;
  value?: ClaimValue;
  operator?: 'or' | 'and';
  portfolioId?: number;
  companyId?: number;
  buildingId?: number;
  leaseId?: number;
}

/**
 * NOTE: This is a static directive!
 *       This means that any changes within the input will not be processed.
 *
 * @example
 * ```html
 * <ng-container *appHasClaim="{type: ClaimType.Collabo}">Has at least read access</ng-container>
 *
 * <ng-container *appHasClaim="{type: ClaimType.Collabo, leaseId: 42}">Has at least read access to the specific lease</ng-container>
 * <ng-container *appHasClaim="{type: ClaimType.Collabo, companyId: 42}">Has at least read access to the specific company</ng-container>
 * <ng-container *appHasClaim="{type: ClaimType.Collabo, portfolioId: 42}">Has at least read access to the specific portfolio</ng-container>
 * <ng-container *appHasClaim="{type: ClaimType.Collabo, buildingId: 42}">Has at least read access to the specific building</ng-container>
 *
 * <ng-container *appHasClaim="{type: ClaimType.Collabo, value: ClaimValue.Write}">Has read or write access</ng-container>
 *
 * <ng-container *appHasClaim="{type: [ClaimType.Requests_Inquiry, ClaimType.Requests_Negatiation]}">
 *   Has at least read access to one of the types
 * </ng-container>
 *
 * <ng-container *appHasClaim="{type: [ClaimType.Requests_Initiate_Inquiry, ClaimType.Requests_Initiate_Negatiation], operator: 'or'}">
 *   Has at least read access to one of the types
 * </ng-container>
 *
 * <ng-container *appHasClaim="{type: [ClaimType.Requests_Initiate_Inquiry, ClaimType.Requests_Initiate_Negatiation], operator: 'and'}">
 *   Has at least read access to both of the types
 * </ng-container>
 * ```
 */

@Directive({
  selector: '[appHasClaim]',
})
export class HasClaimDirective implements OnInit, OnDestroy {
  private static readonly _minAccessibleClaimValue = StateraClaimValueAsEnum.Read;

  @Input() appHasClaim: HasClaimConfig;
  @Input() appHasClaimThen: TemplateRef<any>;
  @Input() appHasClaimElse: TemplateRef<any>;

  private readonly _templateRef: TemplateRef<any>;
  private readonly _viewContainerRef: ViewContainerRef;
  private readonly _stateraUserClaimManager: StateraUserClaimManager;
  private readonly _destroy: Subject<void>;

  constructor(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    stateraUserClaimManager: StateraUserClaimManager,
  ) {
    this._templateRef = templateRef;
    this._viewContainerRef = viewContainerRef;
    this._stateraUserClaimManager = stateraUserClaimManager;
    this._destroy = new Subject<void>();
  }

  ngOnInit(): void {
    this.appHasClaim = this.appHasClaim || <HasClaimConfig>{};
    if (!this.appHasClaim.operator) {
      this.appHasClaim.operator = 'or';
    }

    this._stateraUserClaimManager
      .getStoredStateraUserClaims()
      .pipe(
        catchError(() => of(null)),
        tap(claims => {
          this._viewContainerRef.clear();

          if (claims) {
            let templateRef = this._templateRef;
            if (this.appHasClaimThen) {
              templateRef = this.appHasClaimThen;
            }

            let { type, value } = this.appHasClaim;

            if (!CommonTools.isArray(type)) {
              type = [<StateraClaimTypeAsEnum>type];
            }

            if (!value) {
              value = HasClaimDirective._minAccessibleClaimValue;
            }

            const flags = (<Array<StateraClaimTypeAsEnum>>type).map(t => this._checkClaim(claims, t, value));

            const { operator } = this.appHasClaim;

            if (operator === 'or' && flags.some(Boolean)) {
              this._viewContainerRef.createEmbeddedView(templateRef);
              return;
            }

            if (operator === 'and' && flags.every(Boolean)) {
              this._viewContainerRef.createEmbeddedView(templateRef);
              return;
            }
          }

          if (this.appHasClaimElse) {
            this._viewContainerRef.createEmbeddedView(this.appHasClaimElse);
            return;
          }
        }),
        takeUntil(this._destroy),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private _checkClaim(
    claims: IStateraUserClaimsSimplifiedViewModel,
    type: StateraClaimTypeAsEnum,
    value?: StateraClaimValueAsEnum
  ): boolean {
    if (!claims || !claims.groups) {
      return false;
    }

    const {
      portfolioId,
      companyId,
      buildingId,
      leaseId,
    } = this.appHasClaim;

    if (portfolioId || companyId || buildingId || leaseId) {
      return this._stateraUserClaimManager.checkAccess(
        claims,
        type,
        value,
        companyId,
        portfolioId,
        buildingId,
        leaseId,
      );
    }

    return claims.groups.some(group => {
      if (!group || !group.claims) {
        return false;
      }

      const claimTypeName = this._stateraUserClaimManager.getClaimTypeName(type);
      const hasClaims = group.claims.hasOwnProperty(claimTypeName);

      return hasClaims && group.claims[claimTypeName].some(c => c >= value);
    });
  }
}
