import { Subject } from 'rxjs';

export enum NotificationTopic {
  Alert = 'alert',
  AlertRequiresAction = 'alert-requires-action',
}

export class Notification<T> {
  key: string | number;
  payload: T;

  readonly queued: Subject<void>;
  readonly dequeued: Subject<void>;

  constructor(key: string | number, payload: T) {
    this.key = key;
    this.payload = payload;
    this.queued = new Subject<void>();
    this.dequeued = new Subject<void>();
  }
}
