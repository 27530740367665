import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter, OnDestroy, ElementRef, AfterViewInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertManager } from '@statera/sdk/alert';
import { LeaseManager } from '@statera/sdk/lease';
import { DxFileUploaderComponent, DxListComponent, DxPopupComponent } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { Observable, of, race, Subject, Subscription } from 'rxjs';
import { filter, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { Role } from '@statera/sdk/auth';
import { CommonTools } from '@statera/sdk/common';
import { LeaseDocumentManager } from '@statera/sdk/lease-document';
import { MessageManagerFactory, VersionedFileCommentManager } from '@statera/sdk/message';
import { ProjectManager } from '@statera/sdk/project';

import { AuthService } from '../../../auth/services/auth.service';
import { AlertService } from '../../../alert/services/alert.service';
import { DialogService } from '../../../dialog/services/dialog.service';
import { AvatarListItem } from '../../../shared/components/avatar-list/avatar-list.component';
import { ProjectAccessService } from '../../../shared/services/project-access.service';
import { ProjectService } from '../../../shared/services/project.service';
import { TermsPageService } from '../../services/terms-page.service';
import { LeaseService } from '../../../shared/services/lease.service';
import { LoaderService } from '../../../infrastructure/services/loader.service';

import * as models from '../../../infrastructure/models/generated';

import { environment } from '../../../../environments/environment';

import { LeaseDocumentsDemoComponent } from '../demos/lease-documents-demo/lease-documents-demo.component';
import {
  InviteExternalSignerToSignaturesDialogComponent
} from '../lease-document-external-signer-dialog/invite-external-signer-to-signatures-dialog.component';

enum ChannelTabType {
  Internal = 'Internal',
  External = 'External'
}

@Component({
  selector: 'app-lease-documents',
  templateUrl: './lease-documents.component.html',
  styleUrls: ['./lease-documents.component.scss'],
})
export class LeaseDocumentsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fileUploader') fileUploader: DxFileUploaderComponent;
  @ViewChild('versionsList') versionsList: DxListComponent;
  @ViewChild('leaseDocumentsDemoComponent') leaseDocumentsDemoComponent: LeaseDocumentsDemoComponent;
  @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;
  @ViewChild('dxPopupComponent') dxPopupComponent: DxPopupComponent;
  @ViewChild('pdfViewerContainer') pdfViewerContainer: ElementRef;

  @Input() lease: models.ILeaseViewModel;

  // TODO: [FIXME] We must stop use setters/getters for Input/Output attributes, for change handling use OnChanges live-cycle hook
  @Input()
  set project(project: models.IProjectViewModel) {
    if (project) {
      this._project = project;
      this._currentProjectState = project.projectState;
    }
  }

  get project(): models.IProjectViewModel {
    return this._project;
  }

  @Output() stageChanged = new EventEmitter();
  @Output() leaseDocumentClosed = new EventEmitter();

  private _project: models.IProjectViewModel;
  private _currentProjectState: models.IProjectTemplateItemViewModel;

  AlertType = models.AlertType;
  Tools = CommonTools;
  versionedFiles: Array<models.IVersionedFileViewModel> = [];
  selectedVersionedFiles: Array<models.IVersionedFileViewModel> = [];
  leaseVersionedFile: models.ILeaseAmendmentFileViewModel;
  uploading = false;
  popupVisible = false;
  height;
  versionedFileCommentManager: VersionedFileCommentManager;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  versionedFileCommentType: models.VersionedFileCommentType;
  tabs = [
    {text: ChannelTabType.Internal, placeholder: 'Reply to internal team here...'},
    {text: ChannelTabType.External, placeholder: 'Reply to both teams here...'}
  ];
  selectedTabIndex = 0;
  commentProfiles: Array<models.IProfileViewModel>;
  avatarItems: Array<AvatarListItem>;

  // TODO: [FIXME] We must stop use setters/getters, use methods instead
  get versionedFile(): models.IVersionedFileViewModel {
    if (this.selectedVersionedFiles && this.selectedVersionedFiles.length) {
      return this.selectedVersionedFiles[0];
    }

    return null;
  }

  set versionedFile(newVersionedFile: models.IVersionedFileViewModel) {
    if (newVersionedFile) {
      this.selectedVersionedFiles[0] = newVersionedFile;
    } else {
      this.selectedVersionedFiles.length = 0;
    }
  }

  get isVisibleDeleteButton(): boolean {
    return (
      this._checkAccessToWriteToTermsPage() &&
      this.versionedFile &&
      !this.versionedFile.isSent &&
      (!this.leaseVersionedFile || this.leaseVersionedFile.versionedFileId !== this.versionedFile.id) &&
      (
        this.isLandlord ||
        this.isTenant ||
        (
          this.isBroker &&
          this.versionedFile?.currentEditorId === this._authService.userId
        )
      )
    );
  }

  get isVisibleUploadButton(): boolean {
    return (
      this._checkAccessToWriteToTermsPage() &&
      this.versionedFiles &&
      (this.versionedFiles.length === 0 || this.versionedFiles[0].isSent)
    );
  }

  get isVisibleSubmitButton(): boolean {
    return (
      !this.isBroker &&
      this._checkAccessToWriteToTermsPage() &&
      this.versionedFile &&
      !this.versionedFile.isSent
    );
  }

  get isVisibleSendForApprovalButton(): boolean {
    return (
      this.isBroker &&
      !this.project.pendingForInternalApproval &&
      this._checkAccessToWriteToTermsPage() &&
      this.versionedFile &&
      !this.versionedFile.isSent
    );
  }

  get isVisiblePendingInternalApprovalButton(): boolean {
    return (
      this.isBroker &&
      this.project.pendingForInternalApproval &&
      this._checkAccessToWriteToTermsPage() &&
      this.versionedFile &&
      !this.versionedFile.isSent
    );
  }

  // TODO: [FIXME] We must stop describe access rules inside a component, use service instead so it will be reusable
  get isVisibleAcceptButton(): boolean {
    const accessToWriteToTermsPage = (
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.LeaseDocumentsStepByTenant,
        this.project,
        this.lease
      ) ||
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.LeaseDocumentsStepByLandlord,
        this.project,
        this.lease
      )
    );

    return (
      !this.isBroker &&
      accessToWriteToTermsPage &&
      this.isCounterpartyFile
    );
  }

  get isVisibleGoToSignatureButton(): boolean {
    return this._projectAccessService
      .checkAccessToRenewalProject(models.RenewalProjectTemplateItemType.CleanCopyReceiptByTenant, this.project, this.lease);
  }

  get isLandlord() {
    return this._authService.role === Role.Landlord;
  }

  get isTenant() {
    return this._authService.role === Role.Tenant;
  }

  get isBroker(): boolean {
    return (
      this._authService.role === Role.Broker ||
      this._authService.role === Role.CoBroker
    );
  }

  get isLandlordTeamBroker(): boolean {
    const { role, userId } = this._authService;
    return this._projectManager.getTeamAlignmentRole(role, userId, this._project, this.lease) === Role.Landlord;
  }

  get uploadUrl() {
    return `${environment.webApiUrl}/project/UploadLeaseDocumentDraft${(this.lease ? `?leaseId=${this.lease.id}` : '')}`;
  }

  get isCounterpartyFile(): boolean {
    if (!this.versionedFiles || !this.versionedFiles.length || !this.lease) {
      return false;
    }

    const userLeaseTeam = this._leaseManager.getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);
    const currentVersionedFile = this.versionedFiles[0];
    if (!currentVersionedFile) {
      return false;
    }

    return userLeaseTeam !== currentVersionedFile.leaseTeam;
  }

  get isDemo() {
    return (
      this._authService.startupInfo.introsShown &&
      !this._authService.startupInfo.introsShown.find(x => x.introType === models.IntroType.LeaseDocumentsPage)
    );
  }

  get isCleanCopySubmittal(): boolean {
    return (
      this._currentProjectState &&
      this._currentProjectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.CleanCopySubmittalByLandlord
    );
  }

  get isLeaseDocumentsFirstStepByLandlord(): boolean {
    return (
      this._currentProjectState &&
      this._currentProjectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.LeaseDocumentsFirstStepByLandlord
    );
  }

  get isShowChatMessages(): boolean {
    return (
      (this.isCleanCopySubmittal || this.isLeaseDocumentsFirstStepByLandlord) &&
      (this.isLandlord || this.isLandlordTeamBroker) ||
      this._currentProjectState &&
      this._currentProjectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.CleanCopyReceiptByTenant
    );
  }

  pdfViewerHeight: number;

  private readonly _wheelHandler: (event: WheelEvent) => void;
  private readonly _resizeHandler: () => void;

  private _alertGroupSubscription: Subscription;

  private readonly _authService: AuthService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _leaseDocumentManager: LeaseDocumentManager;
  private readonly _projectService: ProjectService;
  private readonly _messageManagerFactory: MessageManagerFactory;
  private readonly _destroy$: Subject<void>;
  private readonly _projectAccessService: ProjectAccessService;
  private readonly _leaseManager: LeaseManager;
  private readonly _projectManager: ProjectManager;
  private readonly _dialogService: DialogService;
  private readonly _loaderService: LoaderService;

  constructor(
    authService: AuthService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    leaseDocumentManager: LeaseDocumentManager,
    projectService: ProjectService,
    messageManagerFactory: MessageManagerFactory,
    projectAccessService: ProjectAccessService,
    leaseManager: LeaseManager,
    projectManager: ProjectManager,
    dialogService: DialogService,
    loaderService: LoaderService
  ) {
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._leaseDocumentManager = leaseDocumentManager;
    this._authService = authService;
    this._projectService = projectService;
    this._messageManagerFactory = messageManagerFactory;
    this._projectAccessService = projectAccessService;
    this._leaseManager = leaseManager;
    this._projectManager = projectManager;
    this._dialogService = dialogService;
    this._loaderService = loaderService;
    this._destroy$ = new Subject<void>();

    this._wheelHandler = event => event.stopImmediatePropagation();
    this._resizeHandler = () => this.onPopupResize();
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this._resizeHandler, true);

    if (this.pdfViewerContainer && this.pdfViewerContainer.nativeElement) {
      const {nativeElement} = this.pdfViewerContainer;
      nativeElement.removeEventListener('wheel', this._wheelHandler, true);
    }

    if (this._alertGroupSubscription) {
      this._alertGroupSubscription.unsubscribe();
      this._alertGroupSubscription = null;
    }

    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit(): void {
    window.addEventListener('resize', this._resizeHandler, true);

    if (this.pdfViewerContainer && this.pdfViewerContainer.nativeElement) {
      const {nativeElement} = this.pdfViewerContainer;
      nativeElement.addEventListener('wheel', this._wheelHandler, true);
    }
  }

  onPopupShown(): void {
    this.onPopupResize();

    this._loaderService.show();
  }

  onPopupResize(): void {
    const pdfViewerContainer = this.pdfViewerContainer?.nativeElement;
    if (!pdfViewerContainer) {
      return;
    }

    const pdfViewerComputedStyle = window.getComputedStyle(pdfViewerContainer);
    const pdfViewerHeight = pdfViewerComputedStyle.getPropertyValue('height');

    this.pdfViewerHeight = parseInt(pdfViewerHeight, 10);
  }

  onUploadStarted() {
    this.uploading = true;
  }

  onUploaded(e) {
    this.initialize();
    this.uploading = false;
    this.fileUploader.instance.reset();
    this.popupVisible = true;
  }

  onUploadError(e) {
    this._alertService.pushErrorAlert({
      message: e.request.responseText,
    });

    this.uploading = false;
  }

  onUploadAborted() {
    this.uploading = false;
  }

  onVersionSelected(e: {itemData: models.IVersionedFileViewModel}) {
    if (e && e.itemData && e.itemData.id) {
      this._selectVersionedFileCommentManager(this.versionedFileCommentType, e.itemData.id, this.lease);

      if (this.versionedFile && this.versionedFile.pdfFileVersion && this.versionedFile.pdfFileVersion.url) {
        if (this.pdfViewer.src !== this.versionedFile.pdfFileVersion.url) {
          this._loaderService.show();
        }
      }
    }
  }

  handleVersionedFileChange(versionedFile: models.IVersionedFileViewModel): void {
    if (versionedFile.id === this.versionedFile.id) {
      return;
    }

    this.versionedFile = versionedFile;
    this.onVersionSelected({itemData: versionedFile});
  }

  sendForApproval(): void {
    this._projectService
      .renewalProject(this.lease, models.RenewalProjectTriggerType.SendOfferForApprovalByBroker, this.project)
      .pipe(
        tap(() => this.stageChanged.emit()),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  download(e) {
    if (this.versionedFile && this.versionedFile.fileVersion && this.versionedFile.fileVersion.url) {
      window.open(this.versionedFile.fileVersion.url);
    }
  }

  delete() {
    confirm('Are you sure you want to delete this version?', 'Confirm').done(confirmed => {
      if (confirmed) {
        this._loaderService.show();

        this._projectService
          .deleteLeaseDocumentDraft(this.versionedFile.id)
          .pipe(
            takeUntil(this._destroy$)
          )
          .subscribe(() => {
            this.stageChanged.emit();
            this.versionedFiles.splice(0, 1);
            if (this.versionedFiles.length) {
              this.versionedFile = this.versionedFiles[0];
            } else {
              this.versionedFile = null;
            }
          })
          .add(() => this._loaderService.hide());
      }
    });
  }

  upload(e) {
    e.stopPropagation();
    $((<any>this.fileUploader.instance)._selectButton.element()).trigger('click');
  }

  submit() {
    const renewalProject = this._projectService
      .renewalProject(this.lease, models.RenewalProjectTriggerType.SubmitUploadedLeaseDocumentDraft, this.project)
      .pipe(
        tap(() => {
          this.stageChanged.emit();
        }),
      );

    this._checkSignatureParty()
      .pipe(
        switchMap(() => renewalProject),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  accept() {
    const acceptOffer = () => {
      const alertReference = this._alertService.pushConfirmAlert({
        title: 'Please confirm',
        message: this._alertMessagesManager.getConfirmAcceptLeaseDocumentAlertText(
          this.versionedFile,
          this.versionedFiles,
          this.project.projectType?.projectTypeEnum
        ),
      });

      return alertReference
        .confirmed
        .pipe(
          switchMap(() => {
            this._loaderService.show();

            return this._projectService
              .renewalProject(this.lease, models.RenewalProjectTriggerType.AcceptLeaseDocument, this.project)
              .pipe(
                tap(() => {
                  this.stageChanged.emit();
                }),
                finalize(() => {
                  this._loaderService.hide();
                }),
              );
          }),
          take(1),
          takeUntil(this._destroy$),
        );
    };

    const checkSignatureParty = this._checkSignatureParty();

    checkSignatureParty
      .pipe(
        switchMap(() => acceptOffer()),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  handlePDFLoadComplete(event): void {
    this._loaderService.hide();
    this.leaseDocumentsDemoComponent.show();
  }

  initialize() {
    this.leaseVersionedFile = null;
    this.selectedVersionedFiles.length = 0;
    this.versionedFiles.length = 0;
    this.versionedFileCommentManager = null;
    this.selectedTabIndex = 0;
    this._selectVersionedFileCommentType(this.tabs[this.selectedTabIndex].text);

    if (!this.lease) {
      this._alertService.pushErrorAlert({
        message: this._alertMessagesManager.getLeaseNotSetAlertText(),
      });
    } else {

      // TODO: [FIXME] We must stop subscribe to Observables inside top-level subscribe, use pipe operator instead
      this._leaseDocumentManager.getLeaseAmendments(this.lease.id).subscribe(x => {
        this.leaseVersionedFile = x;

        if (this.leaseVersionedFile || this.isLandlord || this.isLandlordTeamBroker) {
          this._leaseDocumentManager.getVersionedFiles(this.lease.id).subscribe(versionedFiles => {
            this.versionedFiles = versionedFiles;
            if (versionedFiles.length) {
              this.versionedFile = versionedFiles[0];

              this._selectVersionedFileCommentManager(this.versionedFileCommentType, this.versionedFile.id, this.lease);

              this.popupVisible = true;
            } else {
              if (this.isLandlord) {
                const alertReference = this._alertService.pushConfirmAlert({
                  warning: 'Upload Lease Document',
                  message: this._alertMessagesManager.getConfirmUploadLeaseAmendmentForTenantReviewAlertText(this.project.projectTypeId),
                  confirmButtonText: 'Upload',
                  declineButtonText: 'Cancel',
                });

                alertReference
                  .confirmed
                  .pipe(
                    tap(() => $((<any>this.fileUploader.instance)._selectButton.element()).trigger('click')),
                    take(1),
                    takeUntil(this._destroy$),
                  )
                  .subscribe();

                alertReference
                  .declined
                  .pipe(
                    tap(() => {
                      this.leaseDocumentClosed.emit();
                    }),
                    take(1),
                    takeUntil(this._destroy$),
                  )
                  .subscribe();
              } else if (this.isLandlordTeamBroker) {
                this._alertService.pushInfoAlert({
                  message: this._alertMessagesManager.getWaitingForDraftLeaseAmendmentAlertText(
                    this.project.projectType.projectTypeEnum,
                  ),
                });
              }
            }
          });
        } else {
          this._alertService.pushInfoAlert({
            title: 'Notification',
            message: this._alertMessagesManager.getWaitingForDraftLeaseAmendmentAlertText(
              this.project.projectType.projectTypeEnum,
            ),
          });
        }
      });

    }
  }

  hasActionRequired(): boolean {
    return this.project && this._projectService.hasActionRequired(this.project, this.lease);
  }

  onPopupHidden($event) {
    this.leaseDocumentClosed.emit();
  }

  goToSignature() {
    this._loaderService.show();

    this._projectService
      .renewalProject(this.lease, models.RenewalProjectTriggerType.GoToSignature, this.project)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(() => this.stageChanged.emit())
      .add(() => this._loaderService.hide());
  }

  selectionChanged($event) {
    const selectedTab = this.tabs[this.selectedTabIndex];
    this._selectVersionedFileCommentType(selectedTab.text);
    this._selectVersionedFileCommentManager(this.versionedFileCommentType, this.versionedFile.id, this.lease);
  }

  getSubmitButtonText(): string {
    let text = 'Submit';

    if (this.project.pendingForInternalApproval) {
      text = 'Approve & Submit';
    }

    if (this.isCleanCopySubmittal) {
      text += ' Clean Copy';
    }

    return text;
  }

  getAcceptButtonText(): string {
    let text = 'Accept';

    if (this.project.pendingForInternalApproval) {
      text = 'Approve & Accept';
    }

    return text;
  }

  getAvatarList(profiles: Array<models.IProfileViewModel>) {
    return profiles.map(profile =>
      new AvatarListItem(
        profile.displayName,
        profile.firstName,
        profile.lastName,
        profile.avatar?.url,
        profile.company.name
      )
    );
  }

  getAcceptFiles(): string {
    if (this.isCleanCopySubmittal) {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf';
    }
    return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword';
  }

  private _selectVersionedFileCommentType(channelTabType: ChannelTabType) {
    if (channelTabType === ChannelTabType.External) {
      this.versionedFileCommentType = models.VersionedFileCommentType.BothTeams;
    }

    if (channelTabType === ChannelTabType.Internal) {
      const roleInLease = this._projectAccessService.getTurnRole(this.lease);

      if (roleInLease === Role.Tenant) {
        this.versionedFileCommentType = models.VersionedFileCommentType.TenantTeam;
      }

      if (roleInLease === Role.Landlord) {
        this.versionedFileCommentType = models.VersionedFileCommentType.LandlordTeam;
      }
    }
  }

  private _selectVersionedFileCommentManager(
    versionedFileCommentType: models.VersionedFileCommentType,
    versionedFileId: number,
    lease: models.ILeaseViewModel
  ) {
    this.versionedFileCommentManager = this._messageManagerFactory
      .createVersionedFileCommentManager(
        this._authService.startupInfo,
        versionedFileCommentType,
        versionedFileId,
        lease,
      );

    this._leaseDocumentManager
      .getCommentProfiles(lease.id, versionedFileCommentType)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(x => {
        this.commentProfiles = x;
        this.avatarItems = this.getAvatarList(this.commentProfiles);
      });
  }

  // TODO: [FIXME] We must stop describe access rules inside a component, use service instead so it will be reusable
  private _checkAccessToWriteToTermsPage(): boolean {
    return (
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.LeaseDocumentsFirstStepByLandlord,
        this.project,
        this.lease
      ) ||
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.LeaseDocumentsStepByTenant,
        this.project,
        this.lease
      ) ||
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.LeaseDocumentsStepByLandlord,
        this.project,
        this.lease
      ) ||
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.CleanCopySubmittalByLandlord,
        this.project,
        this.lease
      )
    );
  }

  private _checkSignatureParty(): Observable<models.IWhoSignLeaseAmendmentViewModel> {
    const { role, userId, startupInfo } = this._authService;

    if (this.leaseVersionedFile) {
      if (
        (this.leaseVersionedFile.landlordId || this.leaseVersionedFile.isExternalLandlordEmail) &&
        this._projectManager.getTeamAlignmentRole(role, userId, this._project, this.lease) === Role.Landlord
      ) {
        return of(<models.IWhoSignLeaseAmendmentViewModel>{});
      }

      if (
        (this.leaseVersionedFile.tenantId || this.leaseVersionedFile.isExternalTenantEmail) &&
        this._projectManager.getTeamAlignmentRole(role, userId, this._project, this.lease) === Role.Tenant
      ) {
        return of(<models.IWhoSignLeaseAmendmentViewModel>{});
      }
    }

    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmUserIsDocumentSignerOfTheLeaseDocumentAlertText(startupInfo.displayName),
    });

    const leaseAmendmentSignerObserver = new Subject<models.IWhoSignLeaseAmendmentViewModel>();

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          const signer = <models.IWhoSignLeaseAmendmentViewModel>{
            leaseId: this.lease.id,
            whoSignLeaseAmendment: models.WhoSignLeaseAmendment.CurrentUser
          };

          return this._leaseDocumentManager.setLeaseAmendmentSigner(signer);
        }),
        tap(whoSignLeaseAmendment => leaseAmendmentSignerObserver.next(whoSignLeaseAmendment)),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    alertReference
      .declined
      .pipe(
        tap(() => {
          const signerObserver = new Subject<models.IWhoSignLeaseSignatureViewModel>();

          signerObserver
            .pipe(
              switchMap(whoSignLeaseAmendment => {
                if (!whoSignLeaseAmendment) {
                  return this._checkSignatureParty();
                }

                const signer = <models.IWhoSignLeaseAmendmentViewModel> {
                  ...whoSignLeaseAmendment,
                  leaseId: this.lease.id,
                  whoSignLeaseAmendment: models.WhoSignLeaseAmendment.ExternalUser
                };

                return this._leaseDocumentManager.setLeaseAmendmentSigner(signer);
              }),
              tap(whoSignLeaseAmendment => leaseAmendmentSignerObserver.next(whoSignLeaseAmendment)),
              take(1),
              takeUntil(this._destroy$),
            )
            .subscribe();

          this._dialogService.show(InviteExternalSignerToSignaturesDialogComponent, {
            showCloseButton: false,
            closeOnOutsideClick: false,
            width: 700,
            height: 'auto',
            maxWidth: 700,
            injectableData: {
              lease: this.lease,
              addSigner$: signerObserver,
              project: this.project
            },
          });
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    return leaseAmendmentSignerObserver;
  }

  getPopupTitle() {
    let result = 'Lease Amendment';
    if (this.project && this.project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
      result = 'Lease Document';
    }
    return result;
  }
}
