<button type="button"
        class="b-btn-icon-info position-absolute"
        (click)="$event.preventDefault()"
        #burndownTooltip>
  <span class="icon-info"
        inlineSVG="assets/img/signs-icons.svg#icon-tooltip">
  </span>
</button>
<dx-tooltip class="v2-default-tooltip"
            position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend"
            width="240" [target]="burndownTooltip">
  <div>
    By default, all units inherit budget values from the building budget. Here you can adjust those values individually
    for each unit.
  </div>
</dx-tooltip>
<div class="building-unit-budget">
  <form name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <dx-scroll-view direction="both">
      <table>
        <thead>
        <tr>
          <th class="fix" rowspan="2">
            <div class="d-flex flex-column">
              <span>Budget Version</span>
              <dx-select-box name="version" id="version"
                             class="real-estate-input py-0"
                             itemTemplate="field"
                             [displayExpr]="renderDate"
                             [items]="buildingBudgetVersions"
                             [ngModel]="currentBudgetVersion"
                             (ngModelChange)="handleBudgetVersionChange($event)"
                             #version="ngModel">
                <div *dxTemplate="let data of 'field'">
                  <span>
                    {{ data?.versionAsDate | safeDate : 'dd/MM/yy' }}
                  </span>
                </div>
              </dx-select-box>
            </div>
          </th>
          <th colspan="2" class="border-right">
            Investment/return
          </th>
          <th colspan="10" class="border-right">
            unit level mla
          </th>
          <th colspan="2" class="border-right">
            leasing commissions (renewal)
          </th>

          <th colspan="2">
            leasing commissions (new deal)
          </th>
        </tr>
        <tr>
          <th align="right">
            <span>Budgeted CapEx</span>
          </th>
          <th class="border-right">
            <span>Yield On Cost (Unit)</span>
          </th>
          <th>
            <span>Market Rent</span>
          </th>
          <th class="align-left">
            <span>Market Rent Growth</span>
          </th>
          <th>
            <span>Escalations</span>
          </th>
          <th>
            <span>Lease Term</span>
          </th>
          <th>
            <span>Free Rent (Renewal)</span>
          </th>
          <th>
            <span>Free Rent (New Deal)</span>
          </th>
          <th>
            <span>T.I. <br>(Renewal)</span>
          </th>
          <th>
            <span>T.I. <br>(New Deal)</span>
          </th>
          <th>
            <span>Downtime</span>
          </th>
          <th class="border-right">
            <span>Renewal Probability</span>
          </th>
          <th>
            <span>Landlord Rep</span>
          </th>
          <th class="border-right">
            <span>Tenant Rep</span>
          </th>
          <th>
            <span>Landlord Rep</span>
          </th>
          <th>
            <span>Tenant Rep</span>
          </th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let buildingUnitBudget of buildingUnitBudgetsToSave; let index = index">
          <th class="fix">
            {{ getBuildingUnitName(buildingUnitBudget.buildingUnitId) }}
          </th>

          <td>
            <dx-number-box [name]="'budgeted-cap-ex-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="$ (e.g. 50) PSF" format="$ #,###.## PSF" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.budgetedCapex">
              <dx-validator>
                <dxi-validation-rule type="required" message="Budgeted CapEx is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td class="border-right">
            <dx-number-box [name]="'yield-on-cash-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="%" format="#,###.## '%'" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.yieldOnCash">
              <dx-validator>
                <dxi-validation-rule type="required" message="Yield on Cash is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'market-rate-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="$ (e.g. 50) PSF" format="$ #,###.## PSF" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.marketRate">
              <dx-validator>
                <dxi-validation-rule type="required" message="Market Rent is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td class="align-left">
            <div class="d-flex align-items-center">
              <span>
                {{ getAverageMarketRateGrowth(buildingUnitBudget) }}% Avg.
              </span>
              <button type="button"
                      class="market-rate-growth-tooltip-trigger"
                      (click)="$event.preventDefault()"
                      #marketRateGrowthTooltip>
                <i inlineSVG="assets/img/sign-icons.svg#eye-icon"></i>
              </button>
              <dx-tooltip class="v2-default-tooltip"
                          position="bottom" showEvent="dxhoverstart"  hideEvent="dxhoverend"
                          [target]="marketRateGrowthTooltip">
                <div *ngFor="let marketRateGrowth of buildingUnitBudget.marketRateGrowths">
                  Year {{ marketRateGrowth.year }}: {{ marketRateGrowth.value }}%
                </div>
              </dx-tooltip>
            </div>
          </td>

          <td>
            <dx-number-box [name]="'escalations-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="%" format="#,###.## '%'" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.escalations">
              <dx-validator>
                <dxi-validation-rule type="required" message="Escalations is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'lease-term-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="months" format="#,###.## months" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.leaseTerm">
              <dx-validator>
                <dxi-validation-rule type="required" message="Lease Term is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'free-rent-renewal-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="months" format="#,###.## months" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.freeRentRenewal">
              <dx-validator>
                <dxi-validation-rule type="required" message="Free Rent is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'free-rent-new-deal-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="months" format="#,###.## months" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.freeRentNewDeal">
              <dx-validator>
                <dxi-validation-rule type="required" message="Free Rent is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'tenant-improvements-renewal-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="$ (e.g. 50) $PSF" format="$ #,###.## PSF" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.tenantImprovementsRenewal">
              <dx-validator>
                <dxi-validation-rule type="required" message="Tenant Improvements is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'tenant-improvements-new-deal-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="$ (e.g. 50) $PSF" format="$ #,###.## PSF" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.tenantImprovementsNewDeal">
              <dx-validator>
                <dxi-validation-rule type="required" message="Tenant Improvements is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'downtime-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="months" format="#,###.## months" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.downtime">
              <dx-validator>
                <dxi-validation-rule type="required" message="Downtime is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td class="border-right">
            <dx-number-box [name]="'renewal-probability-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="%" format="#,###.## '%'" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.renewalProbability">
              <dx-validator>
                <dxi-validation-rule type="required" message="Renewal Probability is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'landlord-rep-renewal-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="%" min="0" step="0" format="#,###.## '%'"
                           [(ngModel)]="buildingUnitBudget.landlordRepRenewal">
              <dx-validator>
                <dxi-validation-rule type="required" message="Landlord Rep is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td class="border-right">
            <dx-number-box [name]="'tenant-rep-renewal-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="%" format="#,###.## '%'" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.tenantRepRenewal">
              <dx-validator>
                <dxi-validation-rule type="required" message="Tenant Rep is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'landlord-rep-new-deal-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="%" format="#,###.## '%'" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.landlordRepNewDeal">
              <dx-validator>
                <dxi-validation-rule type="required" message="Landlord Rep is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>

          <td>
            <dx-number-box [name]="'tenant-rep-new-deal-' + index"
                           class="no-invalid-message hovered-input mb-0"
                           placeholder="%" format="#,###.## '%'" min="0" step="0"
                           [(ngModel)]="buildingUnitBudget.tenantRepNewDeal">
              <dx-validator>
                <dxi-validation-rule type="required" message="Tenant Rep is required">
                </dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </td>
        </tr>
        </tbody>
      </table>
    </dx-scroll-view>
    <!-- Start: Action Buttons -->
    <div class="building-unit-budget-action-btns">
      <dx-button type="normal"
                 width="100%"
                 text="Cancel"
                 (click)="close()">
      </dx-button>
      <dx-button type="success"
                 width="100%"
                 text="Save"
                 [useSubmitBehavior]="true">
      </dx-button>
    </div>
    <!-- End: Action Buttons -->
  </form>
</div>

