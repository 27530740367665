import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthenticateResult } from '@statera/sdk/auth';

import { InvitationRepository } from './invitation-repository.service';

import * as models from './invitation.model';

@Injectable()
export class InvitationManager {
  private readonly _invitationRepository: InvitationRepository;

  constructor(invitationRepository: InvitationRepository) {
    this._invitationRepository = invitationRepository;
  }

  sendUserInvitationRequest(request: models.InvitationRequest): Observable<void> {
    return this._invitationRepository.sendUserInvitationRequest(request);
  }

  sendUserInvitationRequests(
    requests: Array<models.InvitationRequest>,
    lastLeaseId: number,
    role: string,
    companyName: string,
    companyId: number,
  ): Observable<void> {
    const invitationRequests: Array<models.InvitationRequest> = requests
      .map(request => {
        return {
          ...request,
          role: role,
          leaseIds: [lastLeaseId],
          companyName: companyName,
          companyId: companyId,
        };
      });

    return this._invitationRepository.sendUserInvitationRequests(invitationRequests);
  }

  acceptInvitation(model: models.AcceptInvitation): Observable<AuthenticateResult> {
    return this._invitationRepository.acceptInvitation(model);
  }

  getUserInvitationInfo(protectedToken: string): Observable<models.InvitationInfo> {
    return this._invitationRepository.getUserInvitationInfo(protectedToken);
  }

  resendUserInvitationRequest(invitationId: number): Observable<void> {
    return this._invitationRepository.resendUserInvitationRequest(invitationId);
  }
}
