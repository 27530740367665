import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of, ReplaySubject, Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import heic2any from 'heic2any';
import * as moment from 'moment';

import { SelectItem } from '@statera/sdk/common';
import { BuildingUnitManager } from '@statera/sdk/building-unit';
import { BuildingUnitListingManager } from '@statera/sdk/building-unit-listing';

import { AlertService as UIAlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';

import * as models from '../../../infrastructure/models/generated';

interface UploadFile {
  file: File;
  fileName: string;
  previewUrl: Observable<SafeUrl>;
}

interface UploadLink {
  url: string;
  isExternal?: boolean;
}

interface AttachmentStyleOptions {
  className: string;
  height: string;
}

@Component({
  templateUrl: 'list-space-dialog.component.html',
  styleUrls: ['list-space-dialog.component.scss'],
})
export class ListSpaceDialogComponent implements OnInit, OnDestroy {
  @Input() suite: models.ISuiteViewModel;
  @Input() suites: Array<models.ISuiteViewModel>;

  isLoading: boolean;
  isListed: boolean;

  buildingUnit: models.IBuildingUnitViewModel;
  buildingUnitListing: models.IBuildingUnitListingViewModel;

  buildingUnitFloorPlanImagesToUpload: Array<UploadFile>;
  buildingUnitPicturesToUpload: Array<UploadFile>;
  buildingUnitVideosToUpload: Array<UploadFile>;
  buildingUnitLinksToUpload: Array<UploadLink>;

  virtualTourLinkUrl: string;
  externalLinkUrl: string;

  buildingUnitListingPriceTypes: typeof models.BuildingUnitListingPriceType;

  buildingUnitListingPriceStructureSelectItems: Array<SelectItem>;
  buildingUnitListingPriceUnitMetricsSelectItems: Array<SelectItem>;
  buildingUnitListingPriceTypeSelectItems: Array<SelectItem & { insertData?: boolean }>;

  buildingUnitListingShouldIncludeRegionalMapInMarketingBrochureSelectItems: Array<{ value: boolean, text: string }>;

  private readonly _buildingUnitManager: BuildingUnitManager;
  private readonly _buildingUnitListingManager: BuildingUnitListingManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _domSanitizer: DomSanitizer;
  private readonly _uiAlertService: UIAlertService;
  private readonly _destroy: Subject<void>;

  constructor(
    buildingUnitManager: BuildingUnitManager,
    buildingUnitListingManager: BuildingUnitListingManager,
    dialogRefService: DialogRefService,
    domSanitizer: DomSanitizer,
    uiAlertService: UIAlertService
  ) {
    this._buildingUnitManager = buildingUnitManager;
    this._buildingUnitListingManager = buildingUnitListingManager;
    this._dialogRefService = dialogRefService;
    this._domSanitizer = domSanitizer;
    this._uiAlertService = uiAlertService;

    this._destroy = new Subject<void>();

    this.isSizeMaxFieldValid = this.isSizeMaxFieldValid.bind(this);
    this.isPriceMinFieldValid = this.isPriceMinFieldValid.bind(this);
    this.isLinkFieldValid = this.isLinkFieldValid.bind(this);
  }

  /**
   * Lifecycle
   */

  ngOnInit(): void {
    this.buildingUnitListingPriceTypes = models.BuildingUnitListingPriceType;

    const otherListedSuitesWithFixedPriceType = this.suites
      .filter(suite => (
        suite.buildingUnit.id !== this.suite.buildingUnit.id &&
        !!suite.buildingUnit.listing &&
        suite.buildingUnit.listing.priceType !== models.BuildingUnitListingPriceType.Negotiable
      ));

    this.buildingUnitListingPriceStructureSelectItems = [
      {
        id: models.BuildingUnitListingPriceStructure.Net,
        text: 'Net',
        disabled: otherListedSuitesWithFixedPriceType.some(suite =>
          suite.buildingUnit.listing.priceStructure !== models.BuildingUnitListingPriceStructure.Net
        ),
      },
      {
        id: models.BuildingUnitListingPriceStructure.Gross,
        text: 'Gross',
        disabled: otherListedSuitesWithFixedPriceType.some(suite =>
          suite.buildingUnit.listing.priceStructure !== models.BuildingUnitListingPriceStructure.Gross
        ),
      },
      {
        id: models.BuildingUnitListingPriceStructure.BaseYear,
        text: 'Modified Gross',
        disabled: otherListedSuitesWithFixedPriceType.some(suite =>
          suite.buildingUnit.listing.priceStructure !== models.BuildingUnitListingPriceStructure.BaseYear
        ),
      },
    ];

    this.buildingUnitListingPriceUnitMetricsSelectItems = [
      {
        id: models.BuildingUnitListingPriceUnitMetrics.PsfPerYear,
        text: 'PSF/Yr',
        disabled: otherListedSuitesWithFixedPriceType.some(suite =>
          suite.buildingUnit.listing.priceUnitMetrics !== models.BuildingUnitListingPriceUnitMetrics.PsfPerYear
        ),
      },
      {
        id: models.BuildingUnitListingPriceUnitMetrics.PsfPerMonth,
        text: 'PSF/Mo',
        disabled: otherListedSuitesWithFixedPriceType.some(suite =>
          suite.buildingUnit.listing.priceUnitMetrics !== models.BuildingUnitListingPriceUnitMetrics.PsfPerMonth
        ),
      },
    ];

    this.buildingUnitListingPriceTypeSelectItems = [
      {
        id: models.BuildingUnitListingPriceType.Negotiable,
        text: 'Negotiable',
      },
      {
        id: models.BuildingUnitListingPriceType.PsfValue,
        text: 'PsfValue',
        insertData: true,
      },
    ];

    this.buildingUnitListingShouldIncludeRegionalMapInMarketingBrochureSelectItems = [
      {
        value: true,
        text: 'Yes',
      },
      {
        value: false,
        text: 'No',
      },
    ];

    this.buildingUnitFloorPlanImagesToUpload = new Array<UploadFile>();
    this.buildingUnitPicturesToUpload = new Array<UploadFile>();
    this.buildingUnitVideosToUpload = new Array<UploadFile>();
    this.buildingUnitLinksToUpload = new Array<UploadLink>();

    this.virtualTourLinkUrl = '';
    this.externalLinkUrl = '';

    this.buildingUnit = {
      ...this.suite.buildingUnit,
      floorPlans: this.suite.buildingUnit.floorPlans ? [ ...this.suite.buildingUnit.floorPlans ] : [],
      attachments: this.suite.buildingUnit.attachments ? [ ...this.suite.buildingUnit.attachments ] : [],
    };

    if (this.buildingUnit.listing) {
      this.isListed = true;

      this.buildingUnitListing = {
        ...this.suite.buildingUnit.listing,
        priceStructure: (
          this.suite.buildingUnit.listing.priceStructure ?
            this.suite.buildingUnit.listing.priceStructure :
            models.BuildingUnitListingPriceStructure.Net
        ),
        priceUnitMetrics: (
          this.suite.buildingUnit.listing.priceUnitMetrics ?
            this.suite.buildingUnit.listing.priceUnitMetrics :
            models.BuildingUnitListingPriceUnitMetrics.PsfPerYear
        ),
        priceType: (
          this.suite.buildingUnit.listing.priceType ?
            this.suite.buildingUnit.listing.priceType :
            models.BuildingUnitListingPriceType.Negotiable
        ),
      };
    } else {
      this.isListed = false;

      let availableOn = new Date();
      if (!this.isLeaseExpired) {
        availableOn = moment(this.suite.leaseEndDate)
          .add({ days: 1 })
          .startOf('day')
          .toDate();
      }

      this.buildingUnitListing = <models.IBuildingUnitListingViewModel>{
        availableOn: availableOn,
        sizeMax: this.suite.buildingUnit.size,
        priceStructure: models.BuildingUnitListingPriceStructure.Net,
        priceUnitMetrics: models.BuildingUnitListingPriceUnitMetrics.PsfPerYear,
        priceType: models.BuildingUnitListingPriceType.Negotiable,
        shouldIncludeRegionalMapInMarketingBrochure: true,
      };
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  /**
   * Data extraction
   */

  getPriceUnitMetricsLabel(): string {
    return this.buildingUnitListingPriceUnitMetricsSelectItems
      .find(x => x.id === this.buildingUnitListing.priceUnitMetrics)
      ?.text;
  }

  getAttachments(): Array<models.IBuildingAttachmentViewModel> {
    return (this.buildingUnit.attachments || [])
      .sort((a, b) => {
        if (!a.sortOrder) {
          return 0;
        }

        if (a.sortOrder === b.sortOrder) {
          return 0;
        }

        return a.sortOrder < b.sortOrder ? -1 : 1;
      });
  }

  getPictures(): Array<models.IBuildingAttachmentViewModel> {
    return this.getAttachments()
      .filter(attachment =>
        attachment.buildingAttachmentType === models.BuildingAttachmentType.Picture
      );
  }

  getVideos(): Array<models.IBuildingAttachmentViewModel> {
    return this.getAttachments()
      .filter(attachment =>
        attachment.buildingAttachmentType === models.BuildingAttachmentType.Video
      );
  }

  getBuildingUnitLinks(): Array<models.IBuildingAttachmentViewModel> {
    return this.getAttachments()
      .filter(attachment =>
        attachment.buildingAttachmentType !== models.BuildingAttachmentType.Video &&
        attachment.buildingAttachmentType !== models.BuildingAttachmentType.Picture
      );
  }

  getMainBuildingPicture(): models.IBuildingAttachmentViewModel {
    const pictures = (this.buildingUnit?.building?.attachments || [])
      .sort((a, b) => {
        if (!a.sortOrder) {
          return 0;
        }

        if (a.sortOrder === b.sortOrder) {
          return 0;
        }

        return a.sortOrder < b.sortOrder ? -1 : 1;
      })
      .filter(attachment =>
        attachment.buildingAttachmentType === models.BuildingAttachmentType.Picture
      );

    if (!pictures?.length) {
      return null;
    }

    return pictures[0];
  }

  getFloorPlanAttachmentStyleOptions(): AttachmentStyleOptions {
    const floorPlanPictures = this.buildingUnit.floorPlans;
    const floorPlanPicturesToUpload = this.buildingUnitFloorPlanImagesToUpload;

    return this.getAttachmentStyleOptions(floorPlanPictures, floorPlanPicturesToUpload);
  }

  getPictureAttachmentStyleOptions(): AttachmentStyleOptions {
    const pictures = this.getPictures();
    const picturesToUpload = this.buildingUnitPicturesToUpload;

    return this.getAttachmentStyleOptions(pictures, picturesToUpload);
  }

  getVideoAttachmentStyleOptions(): AttachmentStyleOptions {
    const videos = this.getVideos();
    const videosToUpload = this.buildingUnitVideosToUpload;

    return this.getAttachmentStyleOptions(videos, videosToUpload);
  }

  getAttachmentStyleOptions(
    attachments: Array<models.IBuildingAttachmentViewModel | models.IPlanViewModel>,
    attachmentsToUpload: Array<UploadFile>,
  ): AttachmentStyleOptions {
    let className = '';

    let amount = +attachments.length + +attachmentsToUpload.length;

    switch (true) {
      case amount === 1:
        className = 'unique';
        break;
      case amount === 2:
        className = 'pair';
        break;
      case amount % 2 === 0:
        className = 'even';
        break;
      case amount % 2 !== 0:
        className = 'odd';
        break;
      default:
        className = '';
        break;
    }

    amount = !(amount % 2) ? amount : +amount + 1;

    return {
      className: className,
      height: `calc(100% / (${amount} / 2))`
    };
  }

  isLeaseExpired(): boolean {
    const today = moment().startOf('day');

    const leaseEndDate = moment(this.suite.leaseEndDate).startOf('day');

    return !this.suite.lease || !this.suite.leaseEndDate || today.isAfter(leaseEndDate);
  }

  isSizeMaxFieldValid(): boolean {
    return !!this.buildingUnitListing.sizeMax;
  }

  isPriceMinFieldValid(): boolean {
    return !!this.buildingUnitListing.priceMin;
  }

  isLinkFieldValid(event: { value: string }): boolean {
    if (event.value === null || !event.value.length) {
      return true;
    }

    const regex = /^(http[s?]:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

    return regex.test(event.value);
  }

  isDateDisabled(model: {date: Date}): boolean {
    if (!model || !model.date) {
      return true;
    }

    const today = moment().startOf('day');
    const date = moment(model.date).startOf('day');

    if (today.isSame(date)) {
      return false;
    }

    return today.isAfter(date);
  }

  /**
   * Actions
   */

  handleBuildingUnitListingSizeMinChange(minValue: number): void {
    this.buildingUnitListing.sizeMin = (!isNaN(minValue) && minValue > 0) ? minValue : null;
  }

  handleBuildingUnitListingSizeMaxChange(maxValue: number): void {
    this.buildingUnitListing.sizeMax = (!isNaN(maxValue) && maxValue > 0) ? maxValue : 1;
  }

  handleUploadImagesChange(images: Array<UploadFile>, event: { value: Array<File>, previousValue: Array<File> }): void {
    if (!event || !event.value || !event.value.length) {
      return;
    }

    const file = event.value[0];
    if (!file || !this._isImageFile(file)) {
      return;
    }

    images.push({
      file: file,
      fileName: this._getFileNameWithoutExtension(file.name),
      previewUrl: this._getImagePreviewUrl(file),
    });
  }

  handleUploadVideosChange(videos: Array<UploadFile>, event: { value: Array<File>, previousValue: Array<File> }): void {
    if (!event || !event.value || !event.value.length) {
      return;
    }

    const files = event.value;
    if (!files?.length) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      videos.push({
        file: file,
        fileName: this._getFileNameWithoutExtension(file.name),
        previewUrl: this._getImagePreviewUrl(file)
      });
    }
  }

  removeBuildingUnitFloorPlan(index: number): void {
    this.buildingUnit.floorPlans.splice(index, 1);
  }

  removeBuildingAttachment(id: number): void {
    this.buildingUnit.attachments = this.buildingUnit
      .attachments
      .filter(attachment => attachment.id !== id);
  }

  removeFileToUpload(files: Array<UploadFile>, index: number): void {
    if (!files || (index < 0 || files.length <= index)) {
      return;
    }

    files.splice(index, 1);
  }

  removeLinkToUpload(links: Array<UploadLink>, index: number): void {
    if (!links || (index < 0 || links.length <= index)) {
      return;
    }

    links.splice(index, 1);
  }

  addVirtualTourLink(): void {
    const isValid = this._checkUrlValidity(this.virtualTourLinkUrl);
    if (!isValid) {
      return;
    }

    this.buildingUnitLinksToUpload.push(<UploadLink>{
      url: this.virtualTourLinkUrl,
    });

    this.virtualTourLinkUrl = '';
  }

  addExternalLink(): void {
    const isValid = this._checkUrlValidity(this.externalLinkUrl);
    if (!isValid) {
      return;
    }

    this.buildingUnitLinksToUpload.push(<UploadLink>{
      url: this.externalLinkUrl,
      isExternal: true
    });

    this.externalLinkUrl = '';
  }

  deleteListing(): void {
    this.isLoading = true;

    this._buildingUnitListingManager
      .deleteBuildingUnitListing(this.buildingUnitListing)
      .pipe(
        takeUntil(this._destroy),
      )
      .subscribe(
        () => {
          this.isLoading = false;

          this.close();
        },
        () => {
          this.isLoading = false;
        },
      );
  }

  submit(form: NgForm): void {
    if (!form.valid) {
      return;
    }

    this.isLoading = true;

    const observables = [];

    const buildingUnitViewModel = <models.IBuildingUnitViewModel>{
      ...this.buildingUnit
    };

    const buildingUnitListingViewModel = <models.IBuildingUnitListingViewModel>{
      ...this.buildingUnitListing
    };

    delete buildingUnitViewModel.listing;

    if (this.virtualTourLinkUrl) {
      this.addVirtualTourLink();
    }

    if (this.externalLinkUrl) {
      this.addExternalLink();
    }

    // Select floor plan(s) to delete
    for (let i = this.suite.buildingUnit.floorPlans.length - 1; i >= 0; i--) {
      const floorPlan = this.suite.buildingUnit.floorPlans[i];

      if (!this.buildingUnit.floorPlans.some(({ id }) => floorPlan.id === id)) {
        observables.push(
          this._buildingUnitManager.deleteBuildingUnitFloorPlan(this.buildingUnit.id, floorPlan.id)
        );
      }
    }

    // Select attachment(s) to delete
    for (let i = this.suite.buildingUnit.attachments.length - 1; i >= 0; i--) {
      const attachment = this.suite.buildingUnit.attachments[i];

      if (!this.buildingUnit.attachments.some(({ id }) => attachment.id === id)) {
        observables.push(
          this._buildingUnitManager.deleteBuildingUnitAttachment(this.buildingUnit.id, attachment.id)
        );
      }
    }

    // Select Floor Plan(s) to create
    for (let i = 0; i < this.buildingUnitFloorPlanImagesToUpload.length; i++) {
      const buildingUnitFloorPlanImage = this.buildingUnitFloorPlanImagesToUpload[i];
      observables.push(
        this._buildingUnitManager.createBuildingUnitFloorPlan(this.buildingUnit.id, buildingUnitFloorPlanImage.file)
      );
    }

    // Select Building Unit Picture(s) to create
    for (let i = 0; i < this.buildingUnitPicturesToUpload.length; i++) {
      const buildingUnitPicture = this.buildingUnitPicturesToUpload[i];
      observables.push(
        this._buildingUnitManager.createBuildingUnitPicture(this.buildingUnit.id, buildingUnitPicture.file)
      );
    }

    // Select Building Unit Video(s) to create
    for (let i = 0; i < this.buildingUnitVideosToUpload.length; i++) {
      const buildingUnitVideo = this.buildingUnitVideosToUpload[i];
      observables.push(
        this._buildingUnitManager.createBuildingUnitVideo(this.buildingUnit.id, buildingUnitVideo.file)
      );
    }

    // Select Building Unit Link(s) to create
    for (let i = 0; i < this.buildingUnitLinksToUpload.length; i++) {
      const buildingUnitLinksToUploadElement = this.buildingUnitLinksToUpload[i];
      observables.push(
        this._buildingUnitManager.createBuildingUnitLink(this.buildingUnit.id, buildingUnitLinksToUploadElement.url)
      );
    }

    // Clean price attributes if price type is Negotiable
    if (buildingUnitListingViewModel.priceType === models.BuildingUnitListingPriceType.Negotiable) {
      buildingUnitListingViewModel.priceMin = null;
      buildingUnitListingViewModel.priceMax = null;
    }

    // Create the Building Unit Listing itself, if not exists
    if (!buildingUnitListingViewModel.buildingUnitId) {
      buildingUnitListingViewModel.buildingUnitId = this.buildingUnit.id;

      observables.push(
        this._buildingUnitListingManager.createBuildingUnitListing(buildingUnitListingViewModel)
      );
    } else {
      // Or update Building Unit Listing attributes, if necessary
      if (
        buildingUnitListingViewModel.availableOn !== this.suite.buildingUnit.listing?.availableOn ||
        buildingUnitListingViewModel.sizeMin !== this.suite.buildingUnit.listing?.sizeMin ||
        buildingUnitListingViewModel.sizeMax !== this.suite.buildingUnit.listing?.sizeMax ||
        buildingUnitListingViewModel.priceStructure !== this.suite.buildingUnit.listing?.priceStructure ||
        buildingUnitListingViewModel.priceUnitMetrics !== this.suite.buildingUnit.listing?.priceUnitMetrics ||
        buildingUnitListingViewModel.priceType !== this.suite.buildingUnit.listing?.priceType ||
        buildingUnitListingViewModel.shouldIncludeRegionalMapInMarketingBrochure !==
          this.suite.buildingUnit.listing?.shouldIncludeRegionalMapInMarketingBrochure ||
        buildingUnitListingViewModel.priceMin !== this.suite.buildingUnit.listing?.priceMin ||
        buildingUnitListingViewModel.priceMax !== this.suite.buildingUnit.listing?.priceMax ||
        buildingUnitListingViewModel.estimatedReTaxes !== this.suite.buildingUnit.listing?.estimatedReTaxes ||
        buildingUnitListingViewModel.estimatedOpEx !== this.suite.buildingUnit.listing?.estimatedOpEx ||
        buildingUnitListingViewModel.insurance !== this.suite.buildingUnit.listing?.insurance ||
        buildingUnitListingViewModel.notes !== this.suite.buildingUnit.listing?.notes
      ) {
        observables.push(
          this._buildingUnitListingManager.updateBuildingUnitListing(buildingUnitListingViewModel)
        );
      }
    }

    // Execute selected actions
    if (observables.length) {
      zip(...observables)
        .pipe(
          takeUntil(this._destroy),
        )
        .subscribe(
          () => {
            this.isLoading = false;

            this.close();
          },
          () => {
            this.isLoading = false;
          },
        );
    } else {
      this.isLoading = false;

      this.close();
    }
  }

  close(): void {
    this._dialogRefService.hide();
  }

  getYoutubeOrVimeoLinksToUpload(): Array<string> {
    return this.buildingUnitLinksToUpload.filter(x => !x.isExternal).map(x => x.url);
  }

  getUploadedYoutubeOrVimeoLinksLinks() {
    return this.buildingUnit.attachments
      .filter(x => x.buildingAttachmentType === models.BuildingAttachmentType.Youtube ||
        x.buildingAttachmentType === models.BuildingAttachmentType.Vimeo);
  }

  getExternalUrlsToUpload(): Array<string> {
    return this.buildingUnitLinksToUpload.filter(x => x.isExternal).map(x => x.url);
  }

  getUploadedExternalLinks() {
    return this.buildingUnit.attachments
      .filter(x => x.buildingAttachmentType === models.BuildingAttachmentType.External);
  }

  deleteUploadedLink(id: number): void {
    this.buildingUnit.attachments = this.buildingUnit
      .attachments
      .filter(attachment => attachment.id !== id);
  }

  deleteLinkToUpload(url: string): void {
    this.buildingUnitLinksToUpload = this.buildingUnitLinksToUpload.filter(link => link.url !== url);
  }

  /**
   * Helpers
   */

  private _getImagePreviewUrl(image: File): Observable<SafeUrl> {
    if (!image) {
      return of(null);
    }

    const subject = new ReplaySubject<SafeUrl>(1);

    const fileExtension = this._getFileExtension(image.name);
    if (!fileExtension) {
      return of(null);
    }

    if (fileExtension.toLowerCase() !== 'heic') {
      const fileReader = new FileReader();

      fileReader.onload = (event: ProgressEvent) => {
        if (event.loaded !== event.total) {
          return;
        }

        const previewDataUrl = <string>fileReader.result;

        subject.next(this._domSanitizer.bypassSecurityTrustUrl(previewDataUrl));
        subject.complete();
      };

      fileReader.onerror = (err) => {
        subject.error(err);
      };

      fileReader.readAsDataURL(image);
    } else {
      heic2any({blob: image, quality: 0.5, toType: 'image/jpeg'})
        .then((conversionResult) => {
          const previewDataUrl = URL.createObjectURL(conversionResult);

          subject.next(this._domSanitizer.bypassSecurityTrustUrl(previewDataUrl));
          subject.complete();
        })
        .catch((err) => {
          subject.error(err);
        });
    }

    return subject;
  }

  private _checkUrlValidity(url: string): boolean {
    if (!url) {
      this._uiAlertService.pushWarningAlert({
        message: 'Please enter a valid URL.',
      });

      return false;
    }

    if (!this._isValidUrl(url)) {
      this._uiAlertService.pushWarningAlert({
        message: 'Please enter a valid URL.',
      });

      return false;
    }

    return true;
  }

  private _isValidUrl(url: string): boolean {
    try {
      const _url = new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  }

  private _isImageFile(file: File): boolean {
    if (!file || !file.type) {
      return false;
    }

    return file.type.startsWith('image/');
  }

  private _getFileNameWithoutExtension(filename: string): string {
    if (!filename) {
      return '';
    }

    const filenameSegments = filename.split('.');

    filenameSegments.pop();

    return filenameSegments.join('.');
  }

  private _getFileExtension(filename: string): string {
    if (!filename) {
      return '';
    }

    const filenameSegments = filename.split('.');

    return filenameSegments.pop();
  }
}
