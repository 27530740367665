import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';

import { AlertMessagesManager } from '@statera/sdk/alert';
import { Role } from '@statera/sdk/auth';
import { TeamManager } from '@statera/sdk/team';

import { AlertService } from '../../../alert/services/alert.service';
import { DialogRefService } from '../../../dialog/services/dialog-ref.service';
import { AuthService } from '../../../auth/services/auth.service';

import * as models from '../../../infrastructure/models/generated';

@Component({
  templateUrl: 'invite-team-member-dialog.component.html',
  styleUrls: ['invite-team-member-dialog.component.scss'],
})
export class InviteTeamMemberDialogComponent implements OnInit, OnDestroy {
  userInvitationRequest: models.IUserInvitationRequestViewModel & {company: models.ICompanyViewModel};

  companiesDataSource: DataSource;

  roles: Array<{ name: string, value: string }> = [];

  readonly leaseId?: number;
  readonly portfolioId?: number;
  readonly buildingIds?: Array<number>;
  readonly role: string;
  readonly refreshTeam$: Subject<void>;

  private readonly _dialogRefService: DialogRefService;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _authService: AuthService;
  private readonly _teamManager: TeamManager;
  private readonly _ngZone: NgZone;
  private readonly _destroy$: Subject<void>;

  constructor(
    dialogRefService: DialogRefService,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    authService: AuthService,
    teamManager: TeamManager,
    ngZone: NgZone
  ) {
    this._dialogRefService = dialogRefService;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._authService = authService;
    this._teamManager = teamManager;
    this._ngZone = ngZone;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.userInvitationRequest = <models.IUserInvitationRequestViewModel & {company: models.ICompanyViewModel}>{};
    switch (true) {
      case this.leaseId != null:
        this.userInvitationRequest.requestKind = models.UserInvitationRequestKind.Lease;
        break;
      case this.portfolioId != null:
        this.userInvitationRequest.requestKind = models.UserInvitationRequestKind.Portfolio;
        break;
      case this.buildingIds != null:
        this.userInvitationRequest.requestKind = models.UserInvitationRequestKind.Building;
        break;
      default:
        throw new Error('Lease, portfolio or building are not present');
    }

    this.roles = this._getAvailableRoles();

    this._authService
      .infoLoadComplete
      .pipe(
        tap(info => {
          this.userInvitationRequest = {
            ...this.userInvitationRequest,
            requestedBy: info.id,
          };

          this._prepareCompaniesList(models.CompanyType.BrokerCompany);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._dialogRefService.onContentReady
      .pipe(
        tap((event) => {
          if (!event || !event.component) {
            return;
          }

          const contentElement = event.component.content();
          if (contentElement) {
            const parent = contentElement.parentElement;
            if (parent) {
              parent.style.setProperty('border-radius', '10px', 'important');
            }
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  submit(form: NgForm): void {
    if (!this.userInvitationRequest) {
      return;
    }

    this._ngZone.runOutsideAngular(() => {
      setTimeout(() => this._dialogRefService.repaint());
    });

    if (form.invalid) {
      return;
    }

    const userInvitationRequest = {...this.userInvitationRequest};

    if (userInvitationRequest.companyId <= 0) {
      delete userInvitationRequest.companyId;
      delete userInvitationRequest.company;
    }

    let observable;
    switch (userInvitationRequest.requestKind) {
      case models.UserInvitationRequestKind.Lease:
        observable = this._teamManager
          .sendInvitationRequest({
            ...userInvitationRequest,
            leaseIds: [this.leaseId],
          });

        break;

      case models.UserInvitationRequestKind.Portfolio:
        observable = this._teamManager
          .sendInvitationRequest({
            ...userInvitationRequest,
            portfolioIds: [this.portfolioId],
          });

        break;

      case models.UserInvitationRequestKind.Building:
        observable = this._teamManager
          .sendInvitationRequest({
            ...userInvitationRequest,
            buildingIds: this.buildingIds,
          });

        break;

      default:
        throw new Error('Lease, portfolio or building are not present');
    }

    observable
      .pipe(
        tap(() => {
          if (this.refreshTeam$) {
            this.refreshTeam$.next();
            this.refreshTeam$.complete();
          }

          this._dialogRefService.hide();

          this._alertService.pushSuccessAlert({
            message: this._alertMessagesManager.getInvitationRequestSentAlertText(),
          });
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  close(): void {
    this._dialogRefService.hide();
  }

  handleCompanyChange(company: models.ICompanyViewModel): void {
    if (!this.userInvitationRequest) {
      return;
    }

    delete this.userInvitationRequest.company;

    if (!company) {
      delete this.userInvitationRequest.companyId;
      delete this.userInvitationRequest.companyName;

      return;
    }

    this.userInvitationRequest.companyId = company.id;
    this.userInvitationRequest.companyName = company.name;
  }

  handleCustomCompanyCreating(event): void {
    if (!this.companiesDataSource || !this.userInvitationRequest) {
      return;
    }

    if (!event || !event.text) {
      delete this.userInvitationRequest.companyId;
      delete this.userInvitationRequest.companyName;
      delete this.userInvitationRequest.company;

      return;
    }

    const customCompany = <models.ICompanyViewModel>{
      id: Math.floor(Date.now() / 10_000) * -1,
      name: event.text,
    };

    event.customItem = this.companiesDataSource
      .store()
      .insert(customCompany)
      .then(() => this.companiesDataSource.load())
      .then(() => {
        this.userInvitationRequest.companyId = customCompany.id;
        this.userInvitationRequest.companyName = customCompany.name;
        this.userInvitationRequest.company = customCompany;
      })
      .then(() => customCompany)
      .catch(err => {
        throw err;
      });
  }

  private _prepareCompaniesList(companyType: models.CompanyType): void {
    this._teamManager
      .getCompanies(companyType)
      .pipe(
        tap(companies => {
          this.companiesDataSource = new DataSource({
            store: {
              data:  companies,
              type: 'array',
              key: 'name',
            },
          });
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _getAvailableRoles(): Array<{name: string, value: Role}> {
    switch (this.role) {
      case Role.Tenant:
        return [
          {
            name: 'Broker',
            value: Role.Broker,
          },
        ];

      case Role.Landlord:
        if (this.userInvitationRequest.requestKind === models.UserInvitationRequestKind.Lease) {
          return [
            {
              name: 'Broker',
              value: Role.Broker,
            },
          ];
        } else {
          return [
            {
              name: 'Rep Broker',
              value: Role.RepBroker,
            },
          ];
        }

      case Role.Broker:
        return [
          {
            name: 'Co-Broker',
            value: Role.CoBroker,
          },
        ];
    }

    return [];
  }
}
