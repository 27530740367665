import { NgZone } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState } from '@aspnet/signalr';
import { Observable, Subject } from 'rxjs';

import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

import { WebsocketResponse } from './websocket.model';

export interface WebsocketConnectionOptions {
  reconnectionAttemptsInterval: number;
  reconnectionAttemptsLimit: number;
}

export class WebsocketConnection<T> {
  private readonly _endpoint: string;

  private readonly _ngZone: NgZone;
  private readonly _loggerService: LoggerService;

  private readonly _hubConnection: HubConnection;
  private readonly _hubListeners: Record<string, Subject<WebsocketResponse<T>>>;

  private readonly _disconnectionObserver: Subject<Error>;

  constructor(endpoint: string, ngZone: NgZone, loggerService: LoggerService) {
    this._endpoint = endpoint;

    this._ngZone = ngZone;
    this._loggerService = loggerService;

    this._hubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withUrl(endpoint)
      .build();

    this._hubListeners = {};

    this._disconnectionObserver = new Subject<Error>();
  }

  async connect(options: WebsocketConnectionOptions): Promise<void> {
    try {
      await this._hubConnection.start();

      this._loggerService.debug(
        LoggerTopic.WebSocket,
        `Connection to '${this._endpoint}' endpoint was successfully established`
      );

      this._hubConnection.onclose(error => {
        if (!error) {
          this._disconnectionObserver.next();
          this._disconnectionObserver.complete();

          return;
        }

        this._loggerService.error(
          LoggerTopic.WebSocket,
          `Connection to '${this._endpoint}' endpoint was lost`,
          error
        );

        let attempt = 0;

        let establishConnectionTimeoutId;
        const tryEstablishConnection = (delay: number) => this._ngZone
          .runOutsideAngular(() => {
            attempt += 1;

            this._loggerService.debug(
              LoggerTopic.WebSocket,
              `Attempt to establish connection to '${this._endpoint}' endpoint`,
              {
                delay,
                attempt,
                limit: options.reconnectionAttemptsLimit,
              }
            );

            if (options.reconnectionAttemptsLimit <= attempt) {
              this._loggerService.error(
                LoggerTopic.WebSocket,
                `Connection to '${this._endpoint}' endpoint was terminated, no attempts left`,
                error
              );

              this._disconnectionObserver.next(error);
              this._disconnectionObserver.complete();

              return;
            }

            if (establishConnectionTimeoutId) {
              clearTimeout(establishConnectionTimeoutId);
            }

            establishConnectionTimeoutId = setTimeout(
              () => this._hubConnection
                .start()
                .then(() => {
                  if (this._hubConnection.state !== HubConnectionState.Connected) {
                    tryEstablishConnection(options.reconnectionAttemptsInterval * attempt);
                    return;
                  }

                  this._loggerService.debug(
                    LoggerTopic.WebSocket,
                    `Connection to '${this._endpoint}' endpoint was successfully restored`
                  );

                  this._pullListeners();
                })
                .catch(() => {
                  tryEstablishConnection(options.reconnectionAttemptsInterval * attempt);
                }),
              delay,
            );
          });

        let waitUntilTabFocusedTimeoutId;
        const waitUntilTabFocused = () => this._ngZone
          .runOutsideAngular(() => {
            if (waitUntilTabFocusedTimeoutId) {
              clearTimeout();
            }

            waitUntilTabFocusedTimeoutId = setTimeout(
              () => {
                if (document.hidden) {
                  waitUntilTabFocused();
                  return;
                }

                this._loggerService.debug(
                  LoggerTopic.WebSocket,
                  `Connection to '${this._endpoint}' endpoint was unfrozen`,
                );

                tryEstablishConnection(0);
              },
              250
            );
          });

        if (document.hidden) {
          this._loggerService.debug(
            LoggerTopic.WebSocket,
            `Connection to '${this._endpoint}' endpoint was frozen, wait until tab will be focused`,
          );

          waitUntilTabFocused();

          return;
        }

        tryEstablishConnection(0);
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async disconnect(): Promise<void> {
    return await this._hubConnection.stop();
  }

  async disconnected(): Promise<Error> {
    try {
      return await this._disconnectionObserver.toPromise();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  listen(method: string): Observable<WebsocketResponse<T>> {
    if (!this._hubListeners.hasOwnProperty(method)) {
      this._hubListeners[method] = new Subject<WebsocketResponse<T>>();

      this._loggerService.debug(
        LoggerTopic.WebSocket,
        `${this._endpoint}: Endpoint '${method}' listener was registered`,
        { method }
      );

      this._hubConnection.on(method, model => {
        this._loggerService.debug(
          LoggerTopic.WebSocket,
          `${this._endpoint}: Endpoint '${method}' listener receives payload`,
          { method, model }
        );

        this._hubListeners[method].next({ model, isPulling: false });
      });
    }

    return this._hubListeners[method];
  }

  private _pullListeners(): void {
    const methods = Object.keys(this._hubListeners);

    for (const method of methods) {
      if (!this._hubListeners.hasOwnProperty(method)) {
        continue;
      }

      const listener = this._hubListeners[method];
      if (!listener) {
        continue;
      }

      listener.next({ model: null, isPulling: true });
    }
  }
}

export class WebsocketPool {
  private static readonly _connections: Record<string, WebsocketConnection<unknown>> = {};

  readonly terminationObserver: Subject<Error>;

  private readonly _ngZone: NgZone;
  private readonly _loggerService: LoggerService;

  private _isLocked: boolean;
  private _unsafeUnlock: () => void;

  constructor(ngZone: NgZone, loggerService: LoggerService) {
    this._ngZone = ngZone;
    this._loggerService = loggerService;

    this._isLocked = false;
    this._unsafeUnlock = (() => void(0));

    this.terminationObserver = new Subject<Error>();
  }

  connect<T>(endpoint: string, options: WebsocketConnectionOptions): Observable<WebsocketConnection<T>> {
    // Lock due to websocket connection
    this._lock();

    return new Observable<WebsocketConnection<T>>(observer => {
      if (WebsocketPool._connections.hasOwnProperty(endpoint)) {
        observer.next(<WebsocketConnection<T>>WebsocketPool._connections[endpoint]);
        observer.complete();
        return;
      }

      const websocketConnection = new WebsocketConnection<T>(endpoint, this._ngZone, this._loggerService);

      websocketConnection
        .connect(options)
        .then(() => {
          observer.next(websocketConnection);
          observer.complete();
        })
        .catch(err => {
          observer.error(err);
          observer.complete();
        });

      websocketConnection
        .disconnected()
        .then((error: Error): void => {
          if (!error) {
            this._loggerService.debug(
              LoggerTopic.WebSocket,
              `Connection to '${endpoint}' endpoint was successfully closed`
            );
          }

          if (WebsocketPool._connections.hasOwnProperty(endpoint)) {
            delete WebsocketPool._connections[endpoint];
          }

          // Unlock if no connections
          const endpoints = Object.keys(WebsocketPool._connections);
          if (!endpoints || !endpoints.length) {
            this._unlock();
          }

          if (error) {
            this.terminationObserver.next(error);
          }
        })
        .catch(() => void(0));

      WebsocketPool._connections[endpoint] = websocketConnection;
    });
  }

  private _lock(): void {
    if (this._isLocked) {
      return;
    }

    const unsafeBrowserAPI = <Navigator & { locks?: any }>navigator;
    if (unsafeBrowserAPI && unsafeBrowserAPI.locks && typeof unsafeBrowserAPI.locks.request === 'function') {
      this._isLocked = true;

      unsafeBrowserAPI.locks.request('statera_websocket_lock', { mode: 'shared' }, () => {
        return new Promise(resolve => this._unsafeUnlock = <any>resolve);
      });

      this._loggerService.debug(LoggerTopic.WebSocket, `Tab locked due to websocket connection`);
    }
  }

  private _unlock(): void {
    if (!this._isLocked) {
      return;
    }

    if (typeof this._unsafeUnlock === 'function') {
      this._unsafeUnlock();
    }

    this._loggerService.debug(LoggerTopic.WebSocket, `Tab unlocked`);

    this._isLocked = false;
  }
}
