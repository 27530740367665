import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { InfoFlag, Role } from '@statera/sdk/auth';

import { AuthService } from '../../auth/services/auth.service';
import { LeaseService } from '../../shared/services/lease.service';

@Injectable({
  providedIn: 'root',
})
export class ExistingLeasesGuard implements CanActivate {
  private static readonly _TenantDashboardUrl = '/tenant/dashboard/lease';
  private static readonly _TenantLeasesExistKey = 'tenantLeasesExist';

  private readonly _authService: AuthService;
  private readonly _router: Router;
  private readonly _leaseService: LeaseService;

  constructor(authService: AuthService, router: Router, leaseService: LeaseService) {
    this._authService = authService;
    this._router = router;
    this._leaseService = leaseService;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    if (this._authService.role === Role.Tenant) {
      const tenantLeasesExist = sessionStorage.getItem(this._buildTenantKey()) === 'true';
      if (tenantLeasesExist) {
        return of(true);
      }

      return this._authService
        .infoLoadComplete
        .pipe(
          switchMap(info => {
            const hasLease = InfoFlag.hasFlag(info?.infoFlags, InfoFlag.HasLease);
            if (hasLease) {
              sessionStorage.setItem(this._buildTenantKey(), 'true');
              return of(true);
            }

            if (location.pathname.toLowerCase() !== ExistingLeasesGuard._TenantDashboardUrl) {
              return of(
                this._router.parseUrl(ExistingLeasesGuard._TenantDashboardUrl)
              );
            }

            return of(false);
          }),
        );
    }

    return of(true);
  }

  private _buildTenantKey(): string {
    return `${this._authService.userId}-${ExistingLeasesGuard._TenantLeasesExistKey}`;
  }
}
