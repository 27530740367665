import {Component, OnDestroy, OnInit} from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {catchError, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {AlertMessagesManager} from '@statera/sdk/alert';
import {Role} from '@statera/sdk/auth';
import {InvitationManager} from '@statera/sdk/invitation';
import {TeamManager} from '@statera/sdk/team';
import {StateraUserClaimManager} from '@statera/sdk/statera-user-claim';

import {AlertService} from '../../../alert/services/alert.service';
import {DialogService} from '../../../dialog/services/dialog.service';
import {DialogRefService} from '../../../dialog/services/dialog-ref.service';

import {InviteTeamMemberDialogComponent} from '../invite-team-member-dialog/invite-team-member-dialog.component';

import * as models from '../../../infrastructure/models/generated';

export enum ManageTeamDialogMode {
  Lease = 'Lease',
  Portfolio = 'Portfolio',
  Buildings = 'Buildings',
}

@Component({
  templateUrl: 'manage-team-dialog.component.html',
  styleUrls: ['manage-team-dialog.component.scss'],
})
export class ManageTeamDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean;

  mode: ManageTeamDialogMode;

  teamMembers: Array<models.ITeamMemberViewModel>;

  readonly ManageTeamDialogModes: typeof ManageTeamDialogMode = ManageTeamDialogMode;
  readonly RequestStatuses: typeof models.UserInvitationRequestStatus = models.UserInvitationRequestStatus;
  readonly StateraClaimType: typeof models.StateraClaimTypeAsEnum = models.StateraClaimTypeAsEnum;
  readonly StateraClaimValue: typeof models.StateraClaimValueAsEnum = models.StateraClaimValueAsEnum;

  readonly lease?: models.ILeaseViewModel;
  readonly portfolio?: models.IPortfolioViewModel;
  readonly buildings?: Array<models.IPortfolioBuildingViewModel>;
  readonly role: string;

  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _dialogService: DialogService;
  private readonly _invitationManager: InvitationManager;
  private readonly _teamManager: TeamManager;
  private readonly _stateraUserClaimService: StateraUserClaimManager;
  private readonly _destroy$: Subject<void>;

  constructor(
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    dialogRefService: DialogRefService,
    dialogService: DialogService,
    invitationService: InvitationManager,
    teamManager: TeamManager,
    stateraUserClaimService: StateraUserClaimManager
  ) {
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._dialogRefService = dialogRefService;
    this._dialogService = dialogService;
    this._invitationManager = invitationService;
    this._teamManager = teamManager;
    this._destroy$ = new Subject<void>();
    this._stateraUserClaimService = stateraUserClaimService;
  }

  ngOnInit(): void {
    this.isLoading = true;

    switch (true) {
      case this.lease != null:
        this.mode = ManageTeamDialogMode.Lease;

        this._loadTeamMembers();

        break;
      case this.portfolio != null:
        this.mode = ManageTeamDialogMode.Portfolio;

        this._loadTeamMembers();

        break;
      case this.buildings != null:
        this.mode = ManageTeamDialogMode.Buildings;

        const teams = this.buildings.map(x => x.teamMembers);

        if (!this._teamManager.isTeamsMembersMatch(teams)) {
          forkJoin(
            ...this.buildings
              .map(building => {
                return building
                  .teamMembers
                  .map(teamMember =>
                    this._teamManager
                      .deleteBuildingTeamMember(
                        building.id,
                        teamMember.userInvitationRequest && teamMember.userInvitationRequest.id
                      )
                  );
              })
          )
            .pipe(
              tap(() => {
                this._loadTeamMembers();
              }),
              take(1),
            )
            .subscribe();
        } else {
          this._loadTeamMembers();
        }

        break;
      default:
        console.error('Lease, portfolio or building are not present');
        return;
    }

    this._dialogRefService.onContentReady
      .pipe(
        tap((event) => {
          if (!event || !event.component) {
            return;
          }

          const contentElement = event.component.content();
          if (contentElement) {
            const parent = contentElement.parentElement;
            if (parent) {
              parent.style.setProperty('border-radius', '22px', 'important');
            }
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  close(): void {
    this._dialogRefService.hide();
  }

  showInviteTeamMemberDialog(): void {
    const refreshTeam$ = new Subject<void>();

    refreshTeam$
      .pipe(
        tap(() => this._loadTeamMembers()),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._dialogService.show(InviteTeamMemberDialogComponent, {
      title: null,
      width: 317,
      containerClassNames: ['invite-member-dialog'],
      injectableData: {
        leaseId: this.lease?.id,
        portfolioId: this.portfolio?.id,
        buildingIds: this.buildings?.map(x => x.id),
        refreshTeam$: refreshTeam$,
        role: this.role
      },
    });
  }

  resendInvitationRequest(teamMember: models.ITeamMemberViewModel): void {
    if (!teamMember || !teamMember.userInvitationRequest || !teamMember.userInvitationRequest.id) {
      return;
    }

    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmResendInvitationAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => this._invitationManager
          .resendUserInvitationRequest(teamMember.userInvitationRequest.id)
        ),
        tap(() => {
          this._alertService.pushSuccessAlert({
            message: this._alertMessagesManager.getInvitationRequestSentAlertText(),
          });
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  removeTeamMember(teamMember: models.ITeamMemberViewModel): void {
    if (!teamMember || !teamMember.userInvitationRequest || !teamMember.userInvitationRequest.id) {
      return;
    }

    const alertReference = this._alertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmRemoveTeamMemberAlertText(teamMember.fullName),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          const userInvitationRequestId = teamMember.userInvitationRequest && teamMember.userInvitationRequest.id;

          let observable: Observable<void | Array<void>>;
          switch (this.mode) {
            case ManageTeamDialogMode.Lease:
              observable = this._teamManager.deleteLeaseTeamMember(this.lease.id, userInvitationRequestId);
              break;
            case ManageTeamDialogMode.Portfolio:
              observable = this._teamManager.deletePortfolioTeamMember(this.portfolio.id, userInvitationRequestId);
              break;
            case ManageTeamDialogMode.Buildings:
              observable = forkJoin(
                this.buildings.map(building => {
                  const buildingTeamMember = building
                    .teamMembers
                    .find(x =>
                      x.userId === teamMember.userId
                    );

                  return this._teamManager
                    .deleteBuildingTeamMember(
                      building.id,
                      buildingTeamMember.userInvitationRequest && buildingTeamMember.userInvitationRequest.id
                    );
                })
              );
              break;
            default:
              throw new Error('Lease, portfolio or building are not present');
          }

          return observable;
        }),
        tap(() => {
          this._loadTeamMembers();

          this._alertService.pushSuccessAlert({
            message: this._alertMessagesManager.getTeamMemberRemovedAlertText(),
          });
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  hasAccessToWrite(): boolean {
    if (!this.lease && !this.portfolio && !this.buildings) {
      return false;
    }

    switch (this.role) {
      case Role.Tenant:
      case Role.Landlord:
        switch (this.mode) {
          case ManageTeamDialogMode.Lease:
          case ManageTeamDialogMode.Portfolio:
            return this._stateraUserClaimService
              .checkUserAccess(
                models.StateraClaimTypeAsEnum.Team_Member,
                models.StateraClaimValueAsEnum.Write,
                null,
                this.portfolio?.id,
                null,
                this.lease?.id,
              );
          case ManageTeamDialogMode.Buildings:
            return this.buildings
              .every(building =>
                this._stateraUserClaimService
                  .checkUserAccess(
                    models.StateraClaimTypeAsEnum.Team_Member,
                    models.StateraClaimValueAsEnum.Write,
                    null,
                    null,
                    building.id,
                    null,
                  )
              );
          default:
              return false;
        }

      case Role.Broker:
        switch (this.mode) {
          case ManageTeamDialogMode.Lease:
          case ManageTeamDialogMode.Portfolio:
            return this._stateraUserClaimService
              .checkUserAccess(
                models.StateraClaimTypeAsEnum.Team_Member_CoBroker,
                models.StateraClaimValueAsEnum.Write,
                null,
                this.portfolio?.id,
                null,
                this.lease?.id,
              );
          case ManageTeamDialogMode.Buildings:
            return this.buildings
              .every(building =>
                this._stateraUserClaimService
                  .checkUserAccess(
                    models.StateraClaimTypeAsEnum.Team_Member_CoBroker,
                    models.StateraClaimValueAsEnum.Write,
                    null,
                    null,
                    building?.id,
                    null,
                  )
              );
          default:
            return false;
        }
    }

    return false;
  }

  hasAccessToRead() {
    if (!this.lease && !this.portfolio && !this.buildings) {
      return false;
    }

    switch (this.role) {
      case Role.Tenant:
      case Role.Landlord:
        switch (this.mode) {
          case ManageTeamDialogMode.Lease:
          case ManageTeamDialogMode.Portfolio:
            return this._stateraUserClaimService
              .checkUserAccess(
                models.StateraClaimTypeAsEnum.Team_Member,
                models.StateraClaimValueAsEnum.Read,
                null,
                this.portfolio?.id,
                null,
                this.lease?.id,
              );
          case ManageTeamDialogMode.Buildings:
            return this.buildings
              .every(building =>
                this._stateraUserClaimService
                  .checkUserAccess(
                    models.StateraClaimTypeAsEnum.Team_Member,
                    models.StateraClaimValueAsEnum.Read,
                    null,
                    null,
                    building?.id,
                    null,
                  )
              );
          default:
            return false;
        }

      case Role.Broker:
        switch (this.mode) {
          case ManageTeamDialogMode.Lease:
          case ManageTeamDialogMode.Portfolio:
            return this._stateraUserClaimService
              .checkUserAccess(
                models.StateraClaimTypeAsEnum.Team_Member_CoBroker,
                models.StateraClaimValueAsEnum.Read,
                null,
                this.portfolio?.id,
                null,
                this.lease?.id,
              );
          case ManageTeamDialogMode.Buildings:
            return this.buildings
              .every(building =>
                this._stateraUserClaimService
                  .checkUserAccess(
                    models.StateraClaimTypeAsEnum.Team_Member_CoBroker,
                    models.StateraClaimValueAsEnum.Read,
                    null,
                    null,
                    building?.id,
                    null,
                  )
              );
          default:
            return false;
        }
    }

    return false;
  }

  transformUserRole(role: string): string {
    if (role === Role.CoBroker) {
      return 'Co-Broker';
    }

    if (role === Role.RepBroker) {
      return 'Rep Broker';
    }

    return role;
  }

  private _loadTeamMembers(): void {
    this.isLoading = true;

    let observable: Observable<Array<models.ITeamMemberViewModel>>;
    switch (this.mode) {
      case ManageTeamDialogMode.Lease:
        observable = this._teamManager.getLeaseTeamMembers(this.lease.id);
        break;
      case ManageTeamDialogMode.Portfolio:
        observable = this._teamManager.getPortfolioTeamMembers(this.portfolio.id);
        break;
      case ManageTeamDialogMode.Buildings:
        observable = this._teamManager.getBuildingTeamMembers(this.buildings[0].id);
        break;
      default:
        throw new Error('Lease, portfolio or building are not present');
    }

    observable
      .pipe(
        tap((teamMembers) => {
          this.teamMembers = teamMembers;
          this.isLoading = false;
        }),
        tap(() => {
          setTimeout(() => this._dialogRefService.repaint(), 250);
        }),
        catchError(err => {
          this.isLoading = false;

          return of(err);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }
}
