export enum LoggerLevel {
  Debug = 'debug',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export enum LoggerTopic {
  General = 'General',
  Alert = 'Alert',
  Access = 'Access',
  Auth = 'Auth',
  Colabo = 'Colabo',
  Storage = 'Storage',
  WebSocket = 'WebSocket',
  Notification = 'Notification',
  Map = 'Map',
  AvailableUnit = 'AvailableUnit',
  PointOfInterest = 'PointOfInterest',
  Building = 'Building',
  CompanyFeatureToggles = 'CompanyFeatureToggles',
}
