import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CanActivateGuardFactory } from '../access/guards/factory.guard';
import { NegotiateComponent } from '../dashboard/components/negotiate/negotiate.component';
import { ScheduleProjectsComponent } from '../dashboard/components/schedule-projects/schedule-projects.component';
import { ExistingLeasesGuard } from '../infrastructure/guards/existing-leases.guard';

import { NegotiateGuard } from './guards/negotiate.guard';
import { RedirectToAvailabilitiesGuard } from './guards/redirect-to-availabilities.guard';
import { TenantModule } from './tenant.module';

const [projectScheduleGuardToken, projectScheduleGuardProvider] = CanActivateGuardFactory
  .make((injector, route, state) => {

    const {origin, search} = location;
    const { id } = route.params;
    location.assign([origin, `/v2/colabo/${id}/project-schedule`, search].filter(Boolean).join(''));
    return false;

  });

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/lease',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    redirectTo: 'dashboard/lease',
    pathMatch: 'full',
  },
  {
    path: 'dashboard/lease',
    component: NegotiateComponent,
    canActivate: [
      RedirectToAvailabilitiesGuard,
    ],
  },
  {
    path: 'dashboard/lease/:id',
    component: NegotiateComponent,
    canActivate: [
      NegotiateGuard,
    ],
  },
  {
    path: 'dashboard/project',
    component: ScheduleProjectsComponent,
    canActivate: [
      ExistingLeasesGuard
    ],
  },
  {
    path: 'dashboard/project/:id',
    component: ScheduleProjectsComponent,
    canActivate: [
      projectScheduleGuardToken,
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  providers: [
    projectScheduleGuardProvider,
  ],
  exports: [
    RouterModule,
  ],
})
export class TenantRouterModule {

}
