import { Injectable } from '@angular/core';
import { take, takeUntil, tap } from 'rxjs/operators';

import { AlertNotification, AlertNotificationKind } from '@statera/sdk/alert';
import { Notification, NotificationInterceptor } from '@statera/sdk/notification';

@Injectable()
export class InternetDisconnectedInterceptor implements NotificationInterceptor<AlertNotification> {
  intercept(notification: Notification<AlertNotification>): Notification<AlertNotification> {
    const alert = notification.payload;
    if (!alert || !alert.message || !((<any>alert.message) instanceof ProgressEvent) || (<ProgressEvent>alert.message).type !== 'error') {
      return notification;
    }

    notification.payload = {
      ...alert,
      id: 'connection-lost',
      kind: AlertNotificationKind.Confirm,
      title: 'Ooops! Connection Lost',
      message: (
        `Slow or no connection. Please reload page. `
      ),
      closable: false,
      autoclose: false,
      confirmButtonText: 'Reload',
      shouldShowConfirmButton: true,
      shouldShowDeclineButton: false,
    };

    alert
      .reference
      .confirmed
      .pipe(
        tap(() => window.location.reload()),
        take(1),
        takeUntil(notification.dequeued),
      )
      .subscribe();

    return notification;
  }
}
