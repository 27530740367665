<div class="tour-scheduled-info">
  <div class="tour-scheduled-info-header">
    <h3>Tour scheduled</h3>
    <button type="button"
            (click)="close()">
      <i [inlineSVG]="TourIconsAssetPath + '#icon-close'"></i>
    </button>
  </div>
  <div class="tour-scheduled-info-content">
    <div class="building-info">
      <i [inlineSVG]="TourIconsAssetPath + '#icon-unit-16'"></i>
      <span class="building-info-unit-name">
        {{ buildingUnit.name }}, {{building.name}}
      </span>
    </div>
    <div class="company-info">
      <img width="34" height="34" [src]="getOtherPartyLogoUrl()" />
      <div class="company-info-party">
        <div class="company-info-party-name"
             *ngIf="!isLandlord()">
          {{ landlordCompany.name }}
        </div>
        <div class="company-info-party-name"
             *ngIf="isLandlord()">
          {{ tenantCompany.name }}
        </div>
        <div class="company-info-party-existing-tenant-badge"
             *ngIf="isLandlord()">
          Existing tenant
        </div>
      </div>
    </div>
    <div class="tour-info">
      <div class="tour-info-caption">
        TOUR SCHEDULED FOR
      </div>
      <div class="tour-info-date">
        {{ tour.confirmedDateTimeSlot.date | date: 'EEEE, MMMM d' }}
      </div>
      <div class="tour-info-time-slot">
        <i [inlineSVG]="TourIconsAssetPath + '#icon-clock'"></i>
        <span>{{ getSlotDurationExpression(tour.confirmedDateTimeSlot.timeSlot) }}</span>
      </div>
      <button type="button"
              class="tour-info-edit-button"
              (click)="edit()">
        <i [inlineSVG]="TourIconsAssetPath + '#icon-edit'"></i>
        <span>Offer different time slots</span>
      </button>
    </div>
  </div>
</div>
