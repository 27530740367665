<div class="tour-schedule-calendar">
  <!-- Start: Header -->
  <div class="tour-schedule-calendar-header">
    <span class="tour-schedule-calendar-header-current-date">
      {{ getDateString() }}
    </span>
    <div class="tour-schedule-calendar-header-buttons">
      <button type="button"
              class="tour-schedule-calendar-header-button"
              [disabled]="isPreviousMonthButtonDisabled()"
              (click)="onPreviousMonthClick()">
        <i [inlineSVG]="TourIconsAssetPath + '#icon-chevron-left'"></i>
      </button>
      <button type="button"
              class="tour-schedule-calendar-header-button"
              (click)="onNextMonthClick()">
        <i [inlineSVG]="TourIconsAssetPath + '#icon-chevron-right'"></i>
      </button>
    </div>
  </div>
  <!-- End: Header -->
  <!-- Start: Content -->
  <div class="tour-schedule-calendar-content">
    <!-- Start: Calendar legend -->
    <div class="tour-schedule-calendar-content-legend">
      <div *ngFor="let weekday of Weekdays">
        {{weekday.abbr}}
      </div>
    </div>
    <!-- End: Calendar legend -->
    <!-- Start: Calendar dates -->
    <div class="tour-schedule-calendar-content-dates">
      <div class="tour-schedule-calendar-content-dates-row"
           *ngFor="let row of rows">
        <ng-container *ngFor="let cell of row">
          <button type="button"
                  class="tour-schedule-calendar-content-dates-cell"
                  [class.tour-schedule-calendar-content-dates-cell--today]="cell.isToday"
                  [class.tour-schedule-calendar-content-dates-cell--available]="isAvailableDate(cell)"
                  [class.tour-schedule-calendar-content-dates-cell--selected]="isSelectedDate(cell)"
                  [class.tour-schedule-calendar-content-dates-cell--hidden]="cell.isHidden"
                  [disabled]="cell.isDisabled || !isAvailableDate(cell)"
                  (click)="onDateClick(cell)">
            <span>
              {{ cell.date }}
            </span>
          </button>
        </ng-container>
      </div>
    </div>
    <!-- End: Calendar dates -->
  </div>
  <!-- End: Content -->
  <!-- Start: Footer -->
  <div class="tour-schedule-calendar-footer">
    <div class="tour-schedule-calendar-footer-timezone">
      <i [inlineSVG]="TourIconsAssetPath + '#icon-globe'"></i>
      <span>
        Chicago Time
      </span>
    </div>
  </div>
  <!-- End: Footer -->
</div>
