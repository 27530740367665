import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SimplebarAngularModule } from 'simplebar-angular';

import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { InlineSVGModule } from 'ng-inline-svg';

import { InfrastructureModule } from '../infrastructure/infrastructure.module';

import { TourScheduleCalendarComponent } from './components/tour-schedule-calendar/tour-schedule-calendar.component';
import { TourScheduleDialogComponent } from './components/tour-schedule-dialog/tour-schedule-dialog.component';
import { TourScheduledInfoComponent } from './components/tour-scheduled-info/tour-scheduled-info.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    TooltipModule,

    InlineSVGModule,

    InfrastructureModule,
    SimplebarAngularModule,
  ],
  declarations: [
    TourScheduleCalendarComponent,
    TourScheduleDialogComponent,
    TourScheduledInfoComponent,
  ],
})
export class TourModule {
  static forRoot(): ModuleWithProviders<TourModule> {
    return {
      ngModule: TourModule,
      providers: [
      ],
    };
  }
}
