<div class="add-project-notes">
  <div class="add-project-notes-header">

    <i inlineSVG="assets/icons/inlineSVG/building-desktop.svg#comment"></i>

    <span>
      {{ areNewNotes ? 'Add your comments' : 'Edit your comments' }}
    </span>

    <a role="button" href="javascript:void(0);" class="add-project-notes-close-button"
       (click)="close()">
      <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#cross-close"></i>
    </a>
  </div>

  <form class="add-project-notes-form" name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <div class="form-body-wrapper">
      <div class="dx-field">
        <span class="input-title"> Note (optional) </span>
        <div class="dx-field-value">
          <dx-text-area name="notes" id="fullName" required maxLength="150"
                        placeholder="Feel tree to add any Deal comments here..." class="real-estate-input"
                        [isValid]="form.submitted ? notesField.valid : true"
                        [(ngModel)]="updateProjectNotes.notes"
                        #notesField="ngModel">

          </dx-text-area>
          <span class="comment-description">
            This comment will appear in exported Leasing Activity reports from your Dashboard
          </span>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && notesField.hasError('required')">
            Comments are required
          </div>
        </div>
      </div>
    </div>

    <div class="add-project-notes-form-buttons">
      <button type="button" class="add-project-notes-form-button"
              (click)="deleteNotes()">
        Delete
      </button>
      <button type="submit" class="add-project-notes-form-button invite">
        {{ areNewNotes ? 'Save' : 'Edit' }}
      </button>
    </div>
  </form>
</div>
