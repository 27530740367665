import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { Feature, FeatureTogglingManager } from '@statera/sdk/feature-toggling';
import { DocumentAttachmentPlaces, LandlordManager } from '@statera/sdk/landlord';
import { StateraUserClaimManager } from '@statera/sdk/statera-user-claim';

import { AuthService } from '../../../auth/services/auth.service';
import { AppService } from '../../../shared/services/app.service';
import { LeaseService } from '../../../shared/services/lease.service';
import { ProjectService } from '../../../shared/services/project.service';

import { LeaseTermDashboardComponent } from '../../../shared/components/lease-term-dashboard/lease-term-dashboard.component';

import * as models from '../../../infrastructure/models/generated';

enum TabKind {
  DocumentsTab,
  TimelineTab,
  LeaseAbstractTab,
}

interface TabTitle {
  kind: TabKind;
  name: string;
}

@Component({
  selector: 'app-person-desktop',
  templateUrl: './person-desktop.component.html',
  styleUrls: ['./person-desktop.component.scss'],
})
export class PersonDesktopComponent implements OnInit, OnDestroy {
  private static readonly _leaseAbstractTabIndex = 2;
  private static readonly _optionsAndOtherLeaseRightsAccordionIndex = 2;
  private static readonly _timelineTabIndex = 1;

  documentAttachmentPlaces = DocumentAttachmentPlaces;

  leaseTermConfigurations: Array<models.ILeaseTermConfiguration>;
  selectedTabIndex = 0;

  tabTitles: Array<TabTitle> = [];

  steps = [
    {name: 'establish criteria', active: false},
    {name: 'send rfp', active: false},
    {name: 'receive and analyze response', active: false},
    {name: 'negotiate t.i.', active: false},
    {name: 'proposal negotiat....', active: false},
    {name: 'letter of intent', active: false},
    {name: 'finalize buisness terms', active: false},
    {name: 'lease document...', active: false},
    {name: 'lease execution', active: false},
  ];

  leaseId = 0;
  tenant: models.ITenantDetailViewModel = null;
  leaseTerms: Array<models.ILeaseTermViewModel>;
  project: models.IProjectViewModel;
  projects: Array<models.IProjectViewModel>;

  expandAccordionIndex: number;

  lease$: Observable<models.ILeaseViewModel>;

  safeEmbeddedProjectScheduleURL: SafeUrl;

  isProjectScheduleLoaded: boolean;

  private _projectScheduleTimeout;

  get isMobile() {
    return AppService.isMobile;
  }

  private readonly _windowResizeHandler: () => void;

  private readonly _route: ActivatedRoute;
  private readonly _ngZone: NgZone;
  private readonly _domSanitizer: DomSanitizer;
  private readonly _landlordManager: LandlordManager;
  private readonly _authService: AuthService;
  private readonly _leaseService: LeaseService;
  private readonly _projectService: ProjectService;
  private readonly _featureTogglingManager: FeatureTogglingManager;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _destroy$: Subject<void>;
  private readonly _stateraUserClaimService: StateraUserClaimManager;

  constructor(
    route: ActivatedRoute,
    ngZone: NgZone,
    domSanitizer: DomSanitizer,
    landlordManager: LandlordManager,
    authService: AuthService,
    leaseService: LeaseService,
    projectService: ProjectService,
    featureTogglingManager: FeatureTogglingManager,
    changeDetectorRef: ChangeDetectorRef,
    stateraUserClaimService: StateraUserClaimManager,
  ) {
    this._route = route;
    this._ngZone = ngZone;
    this._domSanitizer = domSanitizer;
    this._landlordManager = landlordManager;
    this._authService = authService;
    this._leaseService = leaseService;
    this._projectService = projectService;
    this._featureTogglingManager = featureTogglingManager;
    this._changeDetectorRef = changeDetectorRef;
    this._destroy$ = new Subject<void>();
    this._stateraUserClaimService = stateraUserClaimService;

    this._windowResizeHandler = () => {
      this._resizeProjectScheduleIframe();
    };
  }

  ngOnInit() {
    this.expandAccordionIndex = -1;

    this._authService
      .infoLoadComplete
      .pipe(
        tap((info) => {
          if (info && info.lists) {
            this.leaseTermConfigurations = info.lists.leaseTermConfigurations;
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._route
      .params
      .pipe(
        switchMap(params => {
          this.leaseId = parseInt(params['id'], 10);

          this.lease$ = this._leaseService.getLeaseWithAbstracts(this.leaseId);

          return this._landlordManager
            .getTenantDetails(this.leaseId)
            .pipe(
              tap(tenantDetails => {
                this.tenant = tenantDetails;
              }),
              takeUntil(this._destroy$),
            );
        }),
        switchMap(() => this._projectService
          .getProjectsByLeaseId(this.leaseId)
          .pipe(
            tap(projects => {
              const project = projects.find(x => x);
              this.projects = projects;

              if (project) {
                this.project = project;
                this.safeEmbeddedProjectScheduleURL = this._getSafeEmbeddedProjectScheduleURL();
              }
            }),
            takeUntil(this._destroy$),
          ),
        ),
        switchMap(() => this._stateraUserClaimService
          .getStoredStateraUserClaims()
          .pipe(
            tap((claims) => this._setTabTitle(claims)),
            takeUntil(this._destroy$),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {

        this._route
          .queryParams
          .pipe(
            tap((params: Params) => {
              if (!params || !params.target) {
                return;
              }

              if (params.target === 'noticeDates') {
                this.selectedTabIndex = PersonDesktopComponent._leaseAbstractTabIndex;
                this.expandAccordionIndex = PersonDesktopComponent._optionsAndOtherLeaseRightsAccordionIndex;
              }

              if (params.target === 'timeline') {
                this.selectedTabIndex = PersonDesktopComponent._timelineTabIndex;
              }

              this._detectChanges();
            }),
            takeUntil(this._destroy$),
          )
          .subscribe();
      });

    window.addEventListener('resize', this._windowResizeHandler);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._changeDetectorRef.detach();

    window.removeEventListener('resize', this._windowResizeHandler);
  }

  handleLeaseTermDashboardViewInit(leaseTermDashboardComponent: LeaseTermDashboardComponent): void {
    if (!leaseTermDashboardComponent || this.expandAccordionIndex < 0) {
      return;
    }

    leaseTermDashboardComponent.openAccordionByIndex(
      this.expandAccordionIndex,
      true /** collapseAll */,
    );

    this.expandAccordionIndex = -1;

    this._detectChanges();
  }

  handleProjectScheduleIframeLoad(eventTarget: EventTarget, timeout: number = 150): void {
    const iframe = <HTMLIFrameElement>eventTarget;

    this._ngZone.runOutsideAngular(() => {
      if (this._projectScheduleTimeout) {
        clearTimeout(this._projectScheduleTimeout);
      }

      this._projectScheduleTimeout = setTimeout(
        () => {
          const body = iframe.contentWindow?.document?.body;
          if (!body) {
            this.handleProjectScheduleIframeLoad(eventTarget, timeout);
            return;
          }

          const rootElement = body.querySelector('.embedded-root');
          if (!rootElement) {
            this.handleProjectScheduleIframeLoad(eventTarget, timeout);
            return;
          }

          const resizeObserver = new ResizeObserver(() => {
            this._ngZone.run(() => {
              iframe.height = `${body.scrollHeight}px`;
              iframe.style.height = `${body.scrollHeight}px`;
            });
          });

          resizeObserver.observe(rootElement);

          this.isProjectScheduleLoaded = true;
        },
        timeout
      );
    });
  }

  private _resizeProjectScheduleIframe(): void {
    const iframe = <HTMLIFrameElement>document.querySelector('#embedded-project-schedule-iframe');
    if (!iframe) {
      return;
    }

    const body = iframe.contentWindow?.document?.body;
    if (!body) {
      return;
    }

    iframe.style.height = `${body.scrollHeight}px`;
  }

  private _getSafeEmbeddedProjectScheduleURL(): SafeUrl {
    if (!this.project || !this.project.id) {
      return null;
    }

    const origin = location.origin;
    const projectId = this.project.id;

    return this._domSanitizer.bypassSecurityTrustResourceUrl(`${origin}/v2/embedded/project-schedule/${projectId}`);
  }

  private _setTabTitle(stateraUserClaims: models.IStateraUserClaimsSimplifiedViewModel) {
    if (!stateraUserClaims) {
      return;
    }

    this.tabTitles = [
      {name: 'Documents', kind: TabKind.DocumentsTab},
    ];

    const hasAccessToProjectSchedule = this._stateraUserClaimService
      .checkAccessToLease(
        stateraUserClaims,
        models.StateraClaimTypeAsEnum.Schedule,
        models.StateraClaimValueAsEnum.Read,
        this.leaseId,
      );

    const isProjectScheduleFeatureEnabled = this._featureTogglingManager
      .getCompanyFeatureToggleSync(Feature.ProjectScheduleFeature);

    if (hasAccessToProjectSchedule && isProjectScheduleFeatureEnabled) {
      this.tabTitles.push({name: 'Timeline', kind: TabKind.TimelineTab});
    }

    const hasAccessToColabo = this._stateraUserClaimService
      .checkAccessToLease(
        stateraUserClaims,
        models.StateraClaimTypeAsEnum.Collabo,
        models.StateraClaimValueAsEnum.Read,
        this.leaseId,
      );

    if (hasAccessToColabo) {
      this.tabTitles.push({name: 'Lease Abstract', kind: TabKind.LeaseAbstractTab});
    }
  }

  private _detectChanges(): void {
    if ((<ViewRef>this._changeDetectorRef).destroyed) {
      return;
    }

    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }
}
