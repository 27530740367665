import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AlertManager } from '@statera/sdk/alert';
import { ColaboManager, ColaboTreeItem } from '@statera/sdk/colabo';
import { LeaseManager } from '@statera/sdk/lease';

import { ProjectService } from '../../../shared/services/project.service';
import { ProjectQuery } from '../../../shared/states/project/project.query';
import { DxTreeViewComponent } from 'devextreme-angular';

import * as models from '../../../infrastructure/models/generated';

@Component({
  selector: 'app-chat-tree',
  templateUrl: './chat-tree.component.html',
  styleUrls: ['./chat-tree.component.scss'],
})
export class ChatTreeComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('treeview') treeview: DxTreeViewComponent;

  @Input() routeSegmentsCount = 1;
  @Input() subpath = '';
  @Input() lease;

  @Output() channelChanged = new EventEmitter();

  chatChannels: Array<ColaboTreeItem>;

  selectedNode: any;

  projects: Array<models.IProjectViewModel>;

  private readonly _colaboManager: ColaboManager;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _router: Router;
  private readonly _projectQuery: ProjectQuery;
  private readonly _projectService: ProjectService;
  private readonly _leaseManager: LeaseManager;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _destroy$: Subject<void>;

  constructor(
    colaboManager: ColaboManager,
    activatedRoute: ActivatedRoute,
    router: Router,
    projectQuery: ProjectQuery,
    projectService: ProjectService,
    leaseManager: LeaseManager,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    this._colaboManager = colaboManager;
    this._activatedRoute = activatedRoute;
    this._router = router;
    this._projectQuery = projectQuery;
    this._projectService = projectService;
    this._leaseManager = leaseManager;
    this._changeDetectorRef = changeDetectorRef;
    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.getChannelIcon('p');

    this._projectQuery
      .selectAll()
      .pipe(
        tap((projects: Array<models.IProjectViewModel>) => {
          this.projects = projects;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.chatChannels = [];

    this._activatedRoute
      .params
      .pipe(
        map(params => {
          const leaseId = parseInt(params && params.id || '0', 10);

          // If no leaseId is specified as a route parameter,
          // we must get the last selected element from temporary storage
          if (!leaseId) {
            return this._colaboManager.getLastSelectedColaboTreeItemId();
          }

          return leaseId;
        }),
        switchMap(leaseId => this._colaboManager
          .getColaboTreeItems()
          .pipe(
            filter(chatTreeItems => chatTreeItems && 0 < chatTreeItems.length),
            tap(chatTreeItems => {
              if (!chatTreeItems || !chatTreeItems.length) {
                return;
              }

              this.chatChannels = chatTreeItems.map(item => {
                const prefix = item.itemId.charAt(0);

                return {
                  ...item,
                  expanded: prefix === 'b' || prefix === 'p',
                  selected: item.leaseId === leaseId,
                };
              });

              const firstChatTreeItem = chatTreeItems.find(x => this.isUnit(x));
              if (!leaseId && firstChatTreeItem) {
                leaseId = firstChatTreeItem.leaseId;
              }

              let selectedChatChannel = this.chatChannels.find(x => x.leaseId === leaseId);
              if (!selectedChatChannel && firstChatTreeItem) {
                selectedChatChannel = this.chatChannels.find(x => x.leaseId === firstChatTreeItem.leaseId);
              }

              if (!selectedChatChannel) {
                this._colaboManager.setLastSelectedColaboTreeItemId(null);
                this._colaboManager.setSelectedColaboTreeItem(null);
                return;
              }

              this._colaboManager.setLastSelectedColaboTreeItemId(leaseId);
              this._colaboManager.setSelectedColaboTreeItem(selectedChatChannel);

              this.itemClick({ node: { itemData: selectedChatChannel } });
            }),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  getChannelIcon(name) {
    const prefix = name.charAt(0);

    switch (prefix) {
      case 'p':
        return 'icon-bag';

      case 'b':
        return 'icon-buildings';

      case 'u':
        return 'icon-unit';

      case 'l':
        return 'icon-lease';

      default:
        return null;
    }
  }

  itemClick(e): void {
    if (!e || !e.node || !e.node.itemData || !e.node.itemData.leaseId) {
      if (e && e.event) {
        e.event.preventDefault();
      }

      if (e && e.node && e.node.itemData) {
        if (e.node.expanded) {
          this.treeview.instance.collapseItem(e.node.itemData);
        } else {
          this.treeview.instance.expandItem(e.node.itemData);
        }
      }

      return;
    }

    this.selectedNode = e.node;

    const navigationPath = `../colabo/${this.subpath ? `${this.subpath}/` : ''}${e.node.itemData.leaseId}`;
    if (!this._router.isActive(navigationPath, true)) {
      this._router.navigate([navigationPath], { queryParamsHandling: 'preserve' });
    }
  }

  itemSelectionChanged(e): void {
    if (!e.node || !e.node.itemData || !e.node.itemData.leaseId) {
      this.treeview.instance.unselectItem(e.itemData.itemId);
      this.treeview.instance.selectItem(this.selectedNode.itemData.itemId);
      return;
    }

    if (!e.node.selected && e.node === this.selectedNode) {
      this.treeview.instance.selectItem(e.itemData.itemId);
    }

    if (this.channelChanged) {
      this.channelChanged.emit();
    }
  }

  getProjectIndicatorClass(leaseId: number): string {
    if (!this.projects) {
      return '';
    }

    // Prevents the dot from being displayed if the lease is signed/expired
    const project = this.projects.find(x => x.leaseId === leaseId);
    if (project && project.lease) {
      if (
        this._leaseManager.isLeaseSigned(this.lease) ||
        this._leaseManager.isLeaseExpired(this.lease)
      ) {
        return '';
      }
    }

    return this._projectService
      .getProjectIndicatorClass(leaseId, this.projects, this.lease);
  }

  isOverflown(element: HTMLElement): boolean {
    if (!element || !element.parentElement) {
      return false;
    }

    return element.parentElement.clientWidth < element.clientWidth;
  }

  isLeaseSigned(chatTreeItem: models.IChatTreeItem): boolean {
    if (!chatTreeItem) {
      return false;
    }

    return this._leaseManager.isLeaseSigned(chatTreeItem.lease);
  }

  isLeaseCancelled(chatTreeItem: models.IChatTreeItem): boolean {
    if (!chatTreeItem) {
      return false;
    }

    return this._leaseManager.isLeaseCancelled(chatTreeItem.lease);
  }

  isLeaseExpired(chatTreeItem: models.IChatTreeItem): boolean {
    if (!chatTreeItem) {
      return false;
    }

    return this._leaseManager.isLeaseExpired(chatTreeItem.lease);
  }

  isLeaseRejected(chatTreeItem: models.IChatTreeItem): boolean {
    if (!chatTreeItem) {
      return false;
    }

    return this._leaseManager.isLeaseRejected(chatTreeItem.lease);
  }

  isUnit(chatTreeItem: {itemId: string}): boolean {
    return chatTreeItem && chatTreeItem.itemId && chatTreeItem.itemId.charAt(0) === 'u';
  }
}
