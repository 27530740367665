import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoggerService, LoggerTopic } from '@statera/sdk/logger';

import { Notification, NotificationTopic } from './notification.model';

import { NotificationStore } from './notification.store';

import { getInterceptors } from './notification.interceptor';

@Injectable()
export class NotificationRepository {
  private readonly _injector: Injector;
  private readonly _notificationStore: NotificationStore;
  private readonly _loggerService: LoggerService;

  constructor(injector: Injector, notificationStore: NotificationStore, loggerService: LoggerService) {
    this._injector = injector;
    this._notificationStore = notificationStore;
    this._loggerService = loggerService;
  }

  getNotifications<T>(topic: NotificationTopic): Observable<Array<Notification<T>>> {
    return this._notificationStore
      .getOrCreateNotificationTopic<T>(topic)
      .pipe(
        tap(notifications => this._loggerService
          .debug(LoggerTopic.Notification, `Notifications received`, {topic, notifications})
        ),
      );
  }

  pushNotification<T>(topic: NotificationTopic, notification: Notification<T>): Notification<T> {
    const notificationQueue = this._notificationStore.getOrCreateNotificationTopic<T>(topic);
    if (!notificationQueue) {
      this._loggerService.error(LoggerTopic.Notification, `Notification queue is null`, {topic});
      return null;
    }

    const queue = (notificationQueue.value || []).slice();

    const existingNotification = queue.find(x => x.key === notification.key);
    if (!existingNotification) {
      const interceptors = getInterceptors<T>(this._injector);

      interceptors.forEach(x => notification = x.intercept(notification));

      queue.push(notification);

      this._loggerService.debug(LoggerTopic.Notification, `Notification pushed`, {topic, notification, queue});

      notificationQueue.next(queue);

      notification.queued.next();
      notification.queued.complete();

      return notification;
    }

    return existingNotification;
  }

  popNotification<T>(topic: NotificationTopic): Notification<T> {
    const notificationQueue = this._notificationStore.getOrCreateNotificationTopic<T>(topic);
    if (!notificationQueue) {
      this._loggerService.error(LoggerTopic.Notification, `Notification queue is null`, {topic});
      return null;
    }

    const queue = (notificationQueue.value || []).slice();
    if (queue.length === 0) {
      this._loggerService.info(LoggerTopic.Notification, `Notification queue is empty, do nothing`, {topic});
      return null;
    }

    const notification = queue[queue.length - 1];
    if (!notification) {
      this._loggerService.info(LoggerTopic.Notification, `Notification queue is null, do nothing`, {topic});
      return null;
    }

    return this.removeNotification(topic, notification.key);
  }

  removeNotification<T>(topic: NotificationTopic, key: string | number): Notification<T> {
    const notificationQueue = this._notificationStore.getOrCreateNotificationTopic<T>(topic);
    if (!notificationQueue) {
      this._loggerService.error(LoggerTopic.Notification, `Notification queue is null`, {topic});
      return null;
    }

    const queue = (notificationQueue.value || []).slice();
    if (queue.length === 0) {
      this._loggerService.info(LoggerTopic.Notification, `Notification queue is empty, do nothing`, {topic});
      return null;
    }

    const notificationIndex = queue.findIndex(x => x.key === key);
    if (notificationIndex < 0) {
      this._loggerService.info(
        LoggerTopic.Notification,
        `Notification queue does not contain a notification with the specified key`,
        {topic, key}
      );

      return null;
    }

    const notification = queue[notificationIndex];

    queue.splice(notificationIndex, 1);

    this._loggerService.debug(LoggerTopic.Notification, `Notification removed`, {topic, notification, queue});

    notificationQueue.next(queue);

    notification.dequeued.next();
    notification.dequeued.complete();

    return notification;
  }
}
