<div class="invite-team-member">
  <div class="invite-team-member-header">
    <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#plus"></i>

    <span>
      Invite a member
    </span>

    <a role="button" href="javascript:void(0);" class="invite-member-close-button"
       (click)="close()">
      <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#cross-close"></i>
    </a>
  </div>

  <form class="invite-team-member-form" name="form" novalidate
        (ngSubmit)="submit(form)"
        #form="ngForm">
    <div class="form-body-wrapper">
      <div class="dx-field">
        <div class="dx-field-value">
          <dx-text-box name="firstName" id="firstName" required
                       placeholder="First name" class="real-estate-input"
                       [isValid]="form.submitted ? firstNameField.valid : true"
                       [(ngModel)]="userInvitationRequest.firstName"
                       #firstNameField="ngModel">
          </dx-text-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && firstNameField.hasError('required')">
            First name is required
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-value">
          <dx-text-box name="lastName" id="lastName" required
                       placeholder="Last name" class="real-estate-input"
                       [isValid]="form.submitted ? lastNameField.valid : true"
                       [(ngModel)]="userInvitationRequest.lastName"
                       #lastNameField="ngModel">
          </dx-text-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && lastNameField.hasError('required')">
            Last name is required
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-value">
          <dx-text-box name="emailAddress" id="emailAddress" required email
                       placeholder="Email" class="real-estate-input"
                       [isValid]="form.submitted ? emailAddress.valid : true"
                       [(ngModel)]="userInvitationRequest.emailAddress"
                       #emailAddress="ngModel">
          </dx-text-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && emailAddress.hasError('required')">
            Email address is required
          </div>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && emailAddress.hasError('email')">
            Enter a valid email address
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-value">
          <dx-select-box name="company" id="company" placeholder="Company"
                         class="real-estate-input" required
                         displayExpr="name" itemTemplate="listItem"
                         noDataText="No existing companies found"
                         [dataSource]="companiesDataSource" [acceptCustomValue]="true"
                         [searchEnabled]="true" [showDropDownButton]="false"
                         [isValid]="form.submitted ? companyField.valid : true"
                         [ngModel]="userInvitationRequest.company"
                         (ngModelChange)="handleCompanyChange($event)"
                         (onCustomItemCreating)="handleCustomCompanyCreating($event)"
                         #companyField="ngModel">
            <ng-container *dxTemplate="let company of 'listItem'">
              <div class="lookup-field d-flex flex-row align-items-center flex-nowrap mx-n1">
                <div class="lookup-field-img px-1">
                  <img width="40" height="40" style="object-fit: cover"
                       [src]="company.logo && company.logo.url ? company.logo.url : 'assets/img/nofoto.png'"
                       [alt]="company.name" />
                </div>
                <div class="lookup-field-summary px-1">
                  <div class="lookup-field-summary px-1 min-width-0">
                    <p class="mb-0 w-100 text-truncate">
                      {{company.name}}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </dx-select-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && companyField.hasError('required')">
            Company is required
          </div>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-value">
          <dx-select-box name="role" id="role" required
                     valueExpr="value" displayExpr="name"
                     placeholder="Role" class="real-estate-input"
                     [items]="roles"
                     [isValid]="form.submitted ? role.valid : true"
                     [(ngModel)]="userInvitationRequest.role"
                     #role="ngModel">
          </dx-select-box>
          <div class="my-1 invalid-feedback d-block"
               *ngIf="form.submitted && role.hasError('required')">
            Role is required
          </div>
        </div>
      </div>
    </div>

    <div class="invite-team-member-form-buttons">
      <button type="button" class="invite-team-member-form-button"
              (click)="close()">
        Cancel
      </button>
      <button type="submit" class="invite-team-member-form-button invite">
        Invite
      </button>
    </div>
  </form>
</div>
