import * as ng from '@angular/core';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subject, zip} from 'rxjs';
import {switchMap, take, takeUntil, tap} from 'rxjs/operators';

import {AuthManager} from '@statera/sdk/auth';
import {environment} from '../../../../environments/environment';

import {AuthService} from '../../../auth/services/auth.service';
import {ProfileService} from '../../../user/services/profile.service';

import {FloorplanDemoComponent} from '../../../colabo/components/demos/floorplan-demo-tenant/floorplan-demo.component';

import * as models from '../../../infrastructure/models/generated';
import {PlanAnchorStatus} from '../../../infrastructure/models/generated';
import * as imageViewerModels from '../../../image-viewer/models/image-viewer-image.model';
import * as planViewerModels from '../../../plan-viewer/models/plan-viewer.model';
import {PlanViewerMode} from '../../../plan-viewer/models/plan-viewer.model';

import {FloorPlanManager} from '@statera/sdk/floor-plan';
import {DialogRefService} from '../../../dialog/services/dialog-ref.service';
import {BuildingManager} from '@statera/sdk/building';
import {CommonTools} from '@statera/sdk/common';
import {AnchorCommentManager} from '@statera/sdk/message';

@Component({
  selector: 'app-floor-plan',
  templateUrl: './floor-plan.component.html',
  styleUrls: ['./floor-plan.component.scss']
})
export class FloorPlanComponent implements OnInit, OnDestroy {
  readonly lease: models.ILeaseViewModel;
  readonly project: models.IProjectViewModel;
  readonly floorPlanDemoComponent: FloorplanDemoComponent;
  readonly floorPlanType: models.FloorPlanType;
  readonly building: models.IBuildingViewModel;
  floorPlans: Array<models.ILeaseFloorPlanViewModel> = [];
  selectedFloorPlan: models.ILeaseFloorPlanViewModel;
  sitePlans: Array<models.IPlanViewModel> = [];
  selectedSitePlan: models.IPlanViewModel;
  anchors: Array<models.IPlanAnchorViewModel> = [];
  rejectedAnchors: Array<models.IPlanAnchorViewModel> = [];
  notRejectedAnchors: Array<models.IPlanAnchorViewModel> = [];
  uploadUrl = environment.webApiUrl + '/floorplan/UploadFloorPlanAnchorImage';
  focused = false;
  selectedTabIndex = 0;
  currentProjectState: models.IProjectTemplateItemViewModel;
  leaseId: number;
  isDemo: boolean;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  tabs = [{text: 'Floor Plan'}, {text: 'Site Plan'}];
  AnchorStatus = models.PlanAnchorStatus;

  selectedAnchor: models.IPlanAnchorViewModel = null;
  selectedAnchorId = null;
  hoveredAnchorId = null;
  PlanViewerMode: typeof PlanViewerMode = PlanViewerMode;
  areas: Array<{ id: number, text: string }>;

  get sitePlanImage(): planViewerModels.PlanViewerImage {
    if (!this.selectedSitePlan) {
      return null;
    }

    return {
      id: this.selectedSitePlan.id,
      imageUrl: this.selectedSitePlan.picture.url,
      angle: this.selectedSitePlan.angle,
      markers: this.selectedSitePlan
        .anchors
        .map(anchor => {
          return this._getPlanViewerMarkerRef(anchor, 0);
        }),
    };
  }

  floorPlanImageScale = 100;

  readonly markerColorChange$: ng.EventEmitter<{id: number, color: planViewerModels.PlanViewerMarkerColor}>;
  readonly markerCreationEnd$: ng.EventEmitter<planViewerModels.PlanViewerMarkerRef>;
  readonly markerDeletionEnd$: ng.EventEmitter<planViewerModels.PlanViewerMarkerRef>;
  readonly markerChangingEnd$: ng.EventEmitter<planViewerModels.PlanViewerMarkerRef>;
  private readonly _authService: AuthService;
  private readonly _authManager: AuthManager;
  private readonly _profileService: ProfileService;
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _floorPlanManager: FloorPlanManager;
  private readonly _dialogRefService: DialogRefService;
  private readonly _buildingManager: BuildingManager;
  private readonly _anchorCommentManager: AnchorCommentManager;

  private readonly _destroy$: Subject<void>;

  constructor(
    authService: AuthService,
    profileService: ProfileService,
    changeDetectorRef: ChangeDetectorRef,
    authManager: AuthManager,
    floorPlanManager: FloorPlanManager,
    dialogRefService: DialogRefService,
    buildingManager: BuildingManager,
    anchorCommentManager: AnchorCommentManager
  ) {
    this._authService = authService;
    this._authManager = authManager;
    this._profileService = profileService;
    this._changeDetectorRef = changeDetectorRef;
    this._destroy$ = new Subject<void>();
    this._floorPlanManager = floorPlanManager;
    this._dialogRefService = dialogRefService;
    this._buildingManager = buildingManager;
    this._anchorCommentManager = anchorCommentManager;

    this.markerColorChange$ = new ng.EventEmitter<{id: number, color: planViewerModels.PlanViewerMarkerColor}>();
    this.markerCreationEnd$ = new ng.EventEmitter<planViewerModels.PlanViewerMarkerRef>();
    this.markerDeletionEnd$ = new ng.EventEmitter<planViewerModels.PlanViewerMarkerRef>();
    this.markerChangingEnd$ = new ng.EventEmitter<planViewerModels.PlanViewerMarkerRef>();
    this.areas = CommonTools.EnumToArray(models.BuildingAreaType);
  }

  ngOnInit(): void {
    this._initialize();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getFloorPlanImage(): planViewerModels.PlanViewerImage {
    if (!this.selectedFloorPlan || !this.selectedFloorPlan.plan || !this.selectedFloorPlan.plan.picture) {
      return null;
    }

    const floorPlan = this.selectedFloorPlan;
    const floorPlanImage = floorPlan.plan;
    const result =  {
      id: floorPlanImage.id,
      imageUrl: floorPlanImage.picture.url,
      angle: floorPlanImage.angle,
      markers: floorPlan.anchors.map((anchor, index) =>
        this._getPlanViewerMarkerRef(anchor, index + 1))
    };
    return result;
  }

  getFloorPlanAnchorImages(anchor: models.IPlanAnchorViewModel): Array<planViewerModels.PlanViewerMarkerImageRef> {
    if (!anchor || !anchor.images || !anchor.images.length) {
      return [];
    }

    return anchor.images.map((anchorImage) => {
      return <planViewerModels.PlanViewerMarkerImageRef>{
        imageViewerImage: {
          id: anchorImage.id,
          imageUrl: anchorImage.image.url,
          imageKind: imageViewerModels.ImageViewerImageKind[imageViewerModels.ImageViewerImageKind[anchorImage.imageKind]],
          markers: anchorImage.markers.map((anchorImageMarker) => {
            return <imageViewerModels.ImageViewerImageMarkerRef>{
              id: anchorImageMarker.id,
              x: anchorImageMarker.x,
              y: anchorImageMarker.y,
              text: anchorImageMarker.text,
            };
          }),
        },
      };
    });
  }

  getMarkerColor(anchor: models.IPlanAnchorViewModel): planViewerModels.PlanViewerMarkerColor {
    switch (anchor.status) {
      case models.PlanAnchorStatus.Accepted:
        return planViewerModels.PlanViewerMarkerColor.Green;

      case models.PlanAnchorStatus.Rejected:
        return planViewerModels.PlanViewerMarkerColor.Red;

      case models.PlanAnchorStatus.Pending:
        return planViewerModels.PlanViewerMarkerColor.Green;

      default:
        return planViewerModels.PlanViewerMarkerColor.Gray;
    }
  }

  handlePlanMarkerCreate(markerRef: planViewerModels.PlanViewerMarkerRef): void {
    const currFloorPlan = this.selectedFloorPlan;
    if (!currFloorPlan) {
      return;
    }

    let observable: Observable<Array<models.IFileViewModel>> = new BehaviorSubject([]);
    if (markerRef.images && markerRef.images.length) {
      const images$ = [];
      for (let i = 0, num = markerRef.images.length; i < num; i++) {
        const image = markerRef.images[i];
        if (!image) {
          continue;
        }

        images$.push(this._floorPlanManager.uploadFloorPlanAnchorImage(image.file));
      }

      observable = zip<Array<models.IFileViewModel>>(...images$);
    }

    observable
      .pipe(
        takeUntil(this._destroy$),
        switchMap((images: Array<models.IFileViewModel>) => {
          const anchor = this._mapMarkerRefToAnchor(markerRef, images);

          return this._floorPlanManager
            .addAnchor(anchor)
            .pipe(
              tap((createdMarker: models.IPlanAnchorViewModel) => {
                markerRef.id = createdMarker.id;
                markerRef.images = this.getFloorPlanAnchorImages(createdMarker);
                markerRef.editable = this.allowChangeMarkers();
                markerRef.canChangeStatus = this.canChangeStatus();
                markerRef.index = this.anchors.length + 1;
                markerRef.comments = createdMarker.comments;

                this.markerCreationEnd$.emit(markerRef);

                if (this.selectedFloorPlan && this.selectedFloorPlan.planId) {
                  const floorPlanIndex = this.floorPlans.findIndex(x => x.planId === this.selectedFloorPlan.planId);
                  if (0 <= floorPlanIndex) {
                    if (!this.floorPlans[floorPlanIndex].anchors) {
                      this.floorPlans[floorPlanIndex].anchors = [];
                    }

                    this.floorPlans[floorPlanIndex].anchors.push(createdMarker);

                    this.selectedFloorPlan = this.floorPlans[floorPlanIndex];
                    this._setAnchors(this.selectedFloorPlan.anchors);
                  }
                }
              }),
            );
        }),
      )
      .subscribe();
  }

  handlePlanMarkerChange(markerRef: planViewerModels.PlanViewerMarkerRef): void {
    const currFloorPlan = this.selectedFloorPlan;
    if (!currFloorPlan) {
      return;
    }

    const anchor = this._mapMarkerRefToAnchor(markerRef, null, PlanAnchorStatus.Pending);
    this._floorPlanManager
      .updateAnchor(anchor)
      .pipe(
        tap(() => {
          this._updateAnchor(anchor, markerRef);
          this.markerChangingEnd$.emit(markerRef);
        }),
      )
      .subscribe();
  }

  handlePlanMarkerDelete(markerRef: planViewerModels.PlanViewerMarkerRef): void {
    const currFloorPlan = this.selectedFloorPlan;
    if (!currFloorPlan) {
      return;
    }
    const anchor = this._mapMarkerRefToAnchor(markerRef, null);
    this._floorPlanManager
      .deleteAnchor(anchor)
      .pipe(
        tap(() => {
          if (this.selectedFloorPlan && this.selectedFloorPlan.planId) {
            const floorPlanIndex = this.floorPlans.findIndex(x => x.planId === this.selectedFloorPlan.planId);
            if (0 <= floorPlanIndex) {
              if (!this.floorPlans[floorPlanIndex].anchors) {
                this.floorPlans[floorPlanIndex].anchors = [];
              }

              const anchorIndex = this.floorPlans[floorPlanIndex].anchors.findIndex(x => x.id === anchor.id);
              if (0 <= anchorIndex) {
                this.floorPlans[floorPlanIndex].anchors.splice(anchorIndex, 1);
              }

              this.selectedFloorPlan = this.floorPlans[floorPlanIndex];
              this._setAnchors(this.selectedFloorPlan.anchors);
              this.markerDeletionEnd$.emit(markerRef);
            }
          }
        }),
      )
      .subscribe();
  }

  handleImageMarkerCreate(ref: planViewerModels.PlanViewerImageMarkerRef): void {
    if (!ref || !ref.markerRef || !ref.imageMarkerRef) {
      return;
    }

    const marker = <models.IPlanAnchorImageMarkerViewModel>{
      anchorImageId: ref.imageMarkerRef.imageRef.id,
      x: ref.imageMarkerRef.x,
      y: ref.imageMarkerRef.y,
      text: ref.imageMarkerRef.text,
    };

    this._floorPlanManager
      .createFloorPlanAnchorImageMarker(marker)
      .pipe(
        tap((createdMarker: models.IPlanAnchorImageMarkerViewModel) => {
          ref.imageMarkerRef.id = createdMarker.id;
          ref.imageMarkerRef.text = createdMarker.text;

          this._addFloorPlanImageMarker(ref, createdMarker);
        }),
      )
      .subscribe();
  }

  handleImageMarkerChange(ref: planViewerModels.PlanViewerImageMarkerRef): void {
    if (!ref || !ref.markerRef || !ref.imageMarkerRef) {
      return;
    }

    const marker = <models.IPlanAnchorImageMarkerViewModel>{
      id: ref.imageMarkerRef.id,
      anchorImageId: ref.imageMarkerRef.imageRef.id,
      x: ref.imageMarkerRef.x,
      y: ref.imageMarkerRef.y,
      text: ref.imageMarkerRef.text,
    };

    this._floorPlanManager
      .updateFloorPlanAnchorImageMarker(marker)
      .pipe(
        tap((changedMarker: models.IPlanAnchorImageMarkerViewModel) => {
          ref.imageMarkerRef.text = changedMarker.text;

          this._updateFloorPlanImageMarker(ref, changedMarker);
        }),
      )
      .subscribe();
  }

  handleImageMarkerDelete(ref: planViewerModels.PlanViewerImageMarkerRef): void {
    if (!ref || !ref.markerRef || !ref.imageMarkerRef) {
      return;
    }

    const marker = <models.IPlanAnchorImageMarkerViewModel>{
      id: ref.imageMarkerRef.id,
      anchorImageId: ref.imageMarkerRef.imageRef.id,
      x: ref.imageMarkerRef.x,
      y: ref.imageMarkerRef.y,
      text: ref.imageMarkerRef.text,
    };

    this._floorPlanManager
      .deleteFloorPlanAnchorImageMarker(marker)
      .pipe(
        tap(() => {
          this._deleteFloorPlanImageMarker(ref);
        }),
      )
      .subscribe();
  }

  handlePlanChange(imageRef: planViewerModels.PlanViewerImageRef): void {
    const floorPlan = this.selectedFloorPlan.plan;
    if (!floorPlan) {
      return;
    }

    this._floorPlanManager
      .updatePlan(<models.IPlanViewModel>{...floorPlan, angle: imageRef.angle})
      .pipe(
        tap(() => {
          if (this.selectedFloorPlan && this.selectedFloorPlan.planId) {
            const floorPlanIndex = this.floorPlans.findIndex(x => x.planId === this.selectedFloorPlan.planId);
            if (0 <= floorPlanIndex && this.floorPlans[floorPlanIndex].plan) {
              this.floorPlans[floorPlanIndex].plan.angle = imageRef.angle;

              this.selectedFloorPlan = this.floorPlans[floorPlanIndex];
              this._setAnchors(this.selectedFloorPlan.anchors);
            }
          }
        }),
      )
      .subscribe();
  }

  handleScaleChange(scale: number): void {
    this.floorPlanImageScale = scale;
    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  handleSelectedAnchorChange(anchorId: number): void {
    if (anchorId) {
      this.selectedAnchorId = anchorId;

      if (anchorId === -1) {
        this.selectedAnchor = null;
      } else {
        this.selectedAnchor = this.anchors.find(x => x.id === this.selectedAnchorId);
      }
    } else {
      this.selectedAnchorId = 0;
      this.selectedAnchor = null;
    }
  }

  onLeasePlanSelected(e) {
    if (!this.selectedFloorPlan || this.selectedFloorPlan.id !== e.selectedItem?.id) {
      this.selectedFloorPlan = e.selectedItem;
      if (e.selectedItem.anchors) {
        this._setAnchors(e.selectedItem.anchors);
      }
    }
  }

  handleAnchorCommentAdded($event: { comment: planViewerModels.PlanAnchorComment, marker: planViewerModels.PlanViewerMarkerRef }): void {
    const comment = $event.comment;
    if (!comment) {
      return;
    }

    const anchor = this.anchors.find(x => x.id === comment.anchorId);

    if (!anchor) {
      return;
    }

    const model = <models.IPlanAnchorCommentViewModel>{
      anchorId: comment.anchorId,
      authorId: this._authService.userId,
      content: comment.content,
      createdOn: comment.createdOn
    };

    anchor.comments.push(model);
  }

  handleAnchorCommentChanged($event: { comment: planViewerModels.PlanAnchorComment, marker: planViewerModels.PlanViewerMarkerRef }): void {
    const editedComment = $event.comment;
    if (!editedComment) {
      return;
    }

    const anchor = this.anchors.find(x => x.id === editedComment.anchorId);

    if (!anchor) {
      return;
    }

    const comment = anchor.comments.find(x => x.id === editedComment.id);
    comment.content = editedComment.content;
    this._anchorCommentManager
      .updateMessage(comment, anchor.id)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
          this._updateAnchor(anchor, $event.marker);
          this.markerChangingEnd$.emit($event.marker);
      });
  }

  changeStatus(markerRef: planViewerModels.PlanViewerMarkerRef) {
    const anchorId = markerRef.id;
    const anchor = this.anchors.find(x => x.id === anchorId);
    if (!anchor) {
      return;
    }

    this._floorPlanManager
      .updateStatusAsync(anchorId, markerRef.status)
      .pipe(
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe(() => {
        anchor.status = markerRef.status;

        const markerId = anchor.id;
        const markerColor = this.getMarkerColor(anchor);

        this._updateAnchor(anchor, markerRef);
        this.markerColorChange$.emit({id: markerId, color: markerColor});
        this.markerChangingEnd$.emit(markerRef);
      });
  }

  onAmountChanged(markerRef: planViewerModels.PlanViewerMarkerRef) {
    const anchor = this._mapMarkerRefToAnchor(markerRef, null, PlanAnchorStatus.Pending);
    if (anchor) {
      const model = {...anchor};
      model.amount = markerRef.amount;
      model.status = models.PlanAnchorStatus.Pending;
      this._floorPlanManager
        .updateAnchorAmount(model)
        .pipe(
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe(x => {
          this._updateAnchor(model, markerRef);
          this.markerChangingEnd$.emit(markerRef);
        });
    }
  }

  resetDemo(): void {
    this._profileService
      .resetIntro(models.IntroType.FloorPlan)
      .pipe(
        switchMap(() => this._authManager
          .getIndependentStartupInfo()
          .pipe(
            tap(startupInfo => {
              this._authService.setStartupInfo(startupInfo);

              this.floorPlanDemoComponent.reset();
              this.floorPlanDemoComponent.show(this);
            }),
          ),
        ),
      )
      .subscribe();
  }

  getSuggestions(): Array<{ id?: number, name: string }> {
    const suggestedMarkerNames = new Array<{ id?: number, name: string }>();
    if (!this.lease) {
      return suggestedMarkerNames;
    }

    const { tenantImprovementsTerm } = this.lease;

    if (
      tenantImprovementsTerm &&
      tenantImprovementsTerm.tenantImprovementsOptionDescription &&
      tenantImprovementsTerm.tenantImprovementsOptionDescription.length
    ) {
      const improvements = tenantImprovementsTerm.tenantImprovementsOptionDescription.split(/;\s*/);

      improvements.forEach(x => suggestedMarkerNames.push({ name: x }));
    }

    return suggestedMarkerNames;
  }

  allowChangeMarkers(): boolean {
    return this._floorPlanManager
      .allowCreateOrUpdateMarker(this._authService.userId, this._authService.role, this.selectedFloorPlan, this.lease, this.project);
  }

  canChangeStatus(): boolean {
    return this._floorPlanManager
      .canChangeAnchorStatus(this._authService.userId, this._authService.role, this.selectedFloorPlan, this.lease, this.project);
  }

  canChangeAmount(): boolean {
    return this._floorPlanManager
      .canChangeAmount(this._authService.userId, this._authService.role, this.selectedFloorPlan, this.lease, this.project);
  }

  close(): void {
    this._dialogRefService.hide();
  }

  tabsChanged() {
    if (this.selectedTabIndex === 1) {
      this._buildingManager
        .requestBuildingSitePlans(this.building.id)
        .pipe(
          takeUntil(this._destroy$)
        )
        .subscribe(sitePlans => {
          this.sitePlans = sitePlans;
          if (this.sitePlans.some(x => x)) {
            this.selectedSitePlan = this.sitePlans[0];
          }
        });
    }
  }

  getPlanAnchorImages(anchor: models.IPlanAnchorViewModel): Array<planViewerModels.PlanViewerMarkerImageRef> {
    if (!anchor || !anchor.images || !anchor.images.length) {
      return [];
    }

    return anchor.images.map((anchorImage) => {
      return <planViewerModels.PlanViewerMarkerImageRef>{
        imageViewerImage: {
          id: anchorImage.id,
          imageUrl: anchorImage.image.url,
          imageKind: imageViewerModels.ImageViewerImageKind[imageViewerModels.ImageViewerImageKind[anchorImage.imageKind]],
          markers: anchorImage.markers.map((anchorImageMarker) => {
            return <imageViewerModels.ImageViewerImageMarkerRef>{
              id: anchorImageMarker.id,
              x: anchorImageMarker.x,
              y: anchorImageMarker.y,
              text: anchorImageMarker.text,
            };
          }),
        },
      };
    });
  }

  getLastCommentDate(anchor: models.IPlanAnchorViewModel): Date {
    return anchor
      .comments
      .filter(x => x.createdOn)
      .sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())
      .map(x => new Date(x.createdOn))
      .shift();
  }

  getTotalAmountCosts(): {sum: number, allCostAvailable: boolean} {
    const sum = this._floorPlanManager.getTotalAmountCosts(this.anchors);
    return {sum, allCostAvailable: this.anchors.some(x => !x.amount)};
  }

  getBuildingArea(buildingArea: models.BuildingAreaType) {
    return this.areas.find(x => x.id === buildingArea)?.text;
  }

  private _getRejectedAnchors() {
    return this.anchors.filter(x => x.status === models.PlanAnchorStatus.Rejected);
  }

  private _getNotRejectedAnchors() {
    return this.anchors.filter(x => x.status !== models.PlanAnchorStatus.Rejected);
  }

  private _addFloorPlanImageMarker(
    ref: planViewerModels.PlanViewerImageMarkerRef,
    marker: models.IPlanAnchorImageMarkerViewModel
  ): void {
    if (!this.selectedFloorPlan || !this.selectedFloorPlan.planId) {
      return;
    }

    const floorPlanIndex = this.floorPlans
      .findIndex(x => x.planId === this.selectedFloorPlan.planId);

    if (floorPlanIndex < 0) {
      return;
    }

    const anchorIndex = this.floorPlans[floorPlanIndex].anchors
      .findIndex(x => x.id === ref.markerRef.id);

    if (anchorIndex < 0) {
      return;
    }

    const imageIndex = this.floorPlans[floorPlanIndex].anchors[anchorIndex].images
      .findIndex(x => x.id === ref.imageMarkerRef.imageRef.id);

    if (imageIndex < 0) {
      return;
    }

    if (!this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers) {
      this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers = [];
    }

    this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers.push(marker);

    this.selectedFloorPlan = this.floorPlans[floorPlanIndex];
    this._setAnchors(this.selectedFloorPlan.anchors);
  }

  private _deleteFloorPlanImageMarker(ref: planViewerModels.PlanViewerImageMarkerRef): void {
    if (!this.selectedFloorPlan || !this.selectedFloorPlan.planId) {
      return;
    }

    const floorPlanIndex = this.floorPlans
      .findIndex(x => x.planId === this.selectedFloorPlan.planId);

    if (floorPlanIndex < 0) {
      return;
    }

    const anchorIndex = this.floorPlans[floorPlanIndex].anchors
      .findIndex(x => x.id === ref.markerRef.id);

    if (anchorIndex < 0) {
      return;
    }

    const imageIndex = this.floorPlans[floorPlanIndex].anchors[anchorIndex].images
      .findIndex(x => x.id === ref.imageMarkerRef.imageRef.id);

    if (imageIndex < 0) {
      return;
    }

    if (!this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers) {
      this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers = [];
    }

    const markerIndex = this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers
      .findIndex(x => x.id === ref.imageMarkerRef.id);

    if (0 <= markerIndex) {
      this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers.splice(markerIndex, 1);
    }

    this.selectedFloorPlan = this.floorPlans[floorPlanIndex];
    this._setAnchors(this.selectedFloorPlan.anchors);
  }

  private _updateFloorPlanImageMarker(
    ref: planViewerModels.PlanViewerImageMarkerRef,
    marker: models.IPlanAnchorImageMarkerViewModel
  ): void {
    if (!this.selectedFloorPlan || !this.selectedFloorPlan.planId) {
      return;
    }

    const floorPlanIndex = this.floorPlans
      .findIndex(x => x.planId === this.selectedFloorPlan.planId);

    if (floorPlanIndex < 0) {
      return;
    }

    const anchorIndex = this.floorPlans[floorPlanIndex].anchors
      .findIndex(x => x.id === ref.markerRef.id);

    if (anchorIndex < 0) {
      return;
    }

    const imageIndex = this.floorPlans[floorPlanIndex].anchors[anchorIndex].images
      .findIndex(x => x.id === ref.imageMarkerRef.imageRef.id);

    if (imageIndex < 0) {
      return;
    }

    if (!this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers) {
      return;
    }

    const markerIndex = this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers
      .findIndex(x => x.id === ref.imageMarkerRef.id);

    if (markerIndex < 0) {
      return;
    }

    this.floorPlans[floorPlanIndex].anchors[anchorIndex].images[imageIndex].markers[markerIndex] = marker;

    this.selectedFloorPlan = this.floorPlans[floorPlanIndex];
    this._setAnchors(this.selectedFloorPlan.anchors);
  }

  private _initialize() {
    if (this.floorPlanType === models.FloorPlanType.LeaseFloorPlan) {
      this._floorPlanManager
        .getLeaseFloorPlans(this.lease?.id)
        .pipe(
          takeUntil(this._destroy$)
        )
        .subscribe(floorPlans => {
          if (floorPlans && floorPlans.length) {
            this.onLeasePlanSelected({ selectedItem: floorPlans[0] });
          }
          this.floorPlans = floorPlans;
          this.floorPlanDemoComponent.show(this);
        });
    }
  }

  private _getPlanViewerMarkerRef(anchor: models.IPlanAnchorViewModel, index: number): planViewerModels.PlanViewerMarkerRef {
    let isOwnSpace: boolean;
    let isAvailableSpace: boolean;
    if (this._authService.startupInfo && anchor.buildingUnit && anchor.buildingUnit.lease) {
      isOwnSpace = this._authService.startupInfo.companyId === anchor.buildingUnit.lease.tenantCompany.id;
    }
    if (anchor.buildingUnit && anchor.buildingUnit.listing) {
      isAvailableSpace = true;
    }
    return <planViewerModels.PlanViewerMarkerRef>{
      id: anchor.id,
      title: anchor.name,
      description: anchor.description,
      isOwnSpace: isOwnSpace,
      isAvailableSpace: isAvailableSpace,
      buildingUnitId: anchor.buildingUnitId,
      buildingUnit: anchor.buildingUnit,
      x: anchor.x,
      y: anchor.y,
      width: anchor.width,
      height: anchor.height,
      points: anchor.points,
      type: planViewerModels.PlanViewerMarkerType[planViewerModels.PlanViewerMarkerType[anchor.anchorType]],
      color: this.getMarkerColor(anchor),
      area: anchor.buildingArea,
      images: this.getPlanAnchorImages(anchor),
      amount: anchor.amount,
      comments: anchor.comments,
      editable: this.allowChangeMarkers(),
      canChangeStatus: this.canChangeStatus(),
      index: index,
      canChangeAmount: this.canChangeAmount(),
      status: anchor.status,
    };
  }

  private _setAnchors(anchors: Array<models.IPlanAnchorViewModel>) {
    this.anchors = anchors;
    this.rejectedAnchors = this._getRejectedAnchors();
    this.notRejectedAnchors = this._getNotRejectedAnchors();
  }

  private _mapMarkerRefToAnchor(markerRef: planViewerModels.PlanViewerMarkerRef,
                                images: Array<models.IFileViewModel>, status?: models.PlanAnchorStatus)
    : models.IPlanAnchorViewModel {
    return <models.IPlanAnchorViewModel>{
      id: markerRef.id,
      planId: this.selectedFloorPlan.plan.id,
      name: markerRef.title,
      description: markerRef.description,
      buildingArea: markerRef.area,
      x: markerRef.x,
      y: markerRef.y,
      points: markerRef.points,
      width: markerRef.width,
      height: markerRef.height,
      anchorType: models.PlanAnchorType[models.PlanAnchorType[markerRef.type]],
      status: status ?? markerRef.status,
      amount: markerRef.amount,
      floorPlanId: this.selectedFloorPlan.id,
      images: images?.map(image => {
        return <models.IPlanAnchorImageViewModel>{
          image: image,
        };
      }),
    };
  }

  private _updateAnchor(anchor: models.IPlanAnchorViewModel, markerRef: planViewerModels.PlanViewerMarkerRef): void {
    if (this.selectedFloorPlan && this.selectedFloorPlan.planId) {
      const floorPlanIndex = this.floorPlans.findIndex(x => x.planId === this.selectedFloorPlan.planId);
      if (0 <= floorPlanIndex) {
        if (!this.floorPlans[floorPlanIndex].anchors) {
          this.floorPlans[floorPlanIndex].anchors = [];
        }

        const anchorIndex = this.floorPlans[floorPlanIndex].anchors.findIndex(x => x.id === anchor.id);
        if (0 <= anchorIndex) {
          const existingAnchor = this.floorPlans[floorPlanIndex].anchors[anchorIndex];

          markerRef.images = this.getFloorPlanAnchorImages(existingAnchor);

          this.floorPlans[floorPlanIndex].anchors[anchorIndex] = {
            ...existingAnchor,
            name: anchor.name,
            description: anchor.description,
            buildingArea: anchor.buildingArea,
            status: anchor.status,
            amount: anchor.amount
          };

          const markerColor = this.getMarkerColor(anchor);

          this.markerColorChange$.emit({id: anchor.id, color: markerColor});
        }

        this.selectedFloorPlan = this.floorPlans[floorPlanIndex];
        this._setAnchors(this.selectedFloorPlan.anchors);
      }
    }
  }
}
