import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { HasClaimDirective } from './directives/has-claim.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    HasClaimDirective,
  ],
  exports: [
    HasClaimDirective,
  ],
})
export class AccessModule {
  static forRoot(): ModuleWithProviders<AccessModule> {
    return {
      ngModule: AccessModule,
      providers: [],
    };
  }
}
