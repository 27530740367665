import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { Role } from '@statera/sdk/auth';

import { StageChanged, WebsocketRepository } from '@statera/sdk/websocket';

import { ProjectRepository } from './project.repository';

import * as models from './project.model';
import {
  ILeaseViewModel,
  IProjectTemplateItemViewModel,
  IProjectViewModel,
  ProjectStatus
} from '@statera/sdk/common';

@Injectable()
export class ProjectManager {
  private readonly _projectRepository: ProjectRepository;
  private readonly _websocketRepository: WebsocketRepository;

  constructor(projectRepository: ProjectRepository, websocketRepository: WebsocketRepository) {
    this._projectRepository = projectRepository;
    this._websocketRepository = websocketRepository;
  }

  getRealtimeStageChangedMessage(): Observable<StageChanged> {
    return this._websocketRepository
      .getRealtimeStageChangedMessage()
      .pipe(
        map(response => response.model),
        filter(message => !!message),
      );
  }

  isRenewalInitiatedByLandlord(projects: Array<models.ProjectViewModel>, lease: models.LeaseViewModel): boolean {
    if (lease && lease.leaseStatus !== models.LeaseStatus.InProcess) {
      return false;
    }

    if (!projects || projects.length === 0) {
      return false;
    }

    const proj = projects.find(p => p.leaseId === lease.id);
    if (!proj) {
      return false;
    }

    return (
      proj.projectState &&
      proj.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord
    );
  }

  isRenewAvailable(
    leases: Array<models.LeaseViewModel | models.TenantDashboardViewModel>,
    projects: Array<models.ProjectViewModel>,
    lease: models.LeaseViewModel,
    project: models.ProjectViewModel
  ): boolean {
    if (this.isRenewalInitiatedByLandlord(projects, lease)) {
      return true;
    }

    if (lease && lease.leaseStatus !== models.LeaseStatus.Active) {
      return false;
    }

    const childLeases = leases.filter(x => lease && x.abstractLeaseId === lease.id);

    let visibleRenewButton = true;

    childLeases.forEach(childLease => {
      if (projects) {
        const renewalProject = projects.find(x =>
          x.projectTypeId === models.ProjectTypeEnum.Renewal &&
          x.leaseId === childLease.id &&
          project &&
          project.id !== x.id
        );

        if (renewalProject) {
          visibleRenewButton = false;
        }
      }
    });

    return visibleRenewButton;
  }

  isUnsolicitedOfferLandlordReviewingFinancialDocuments(project: models.ProjectViewModel): boolean {
    if (!project || !project.projectState) {
      return false;
    }

    const {projectTypeId, projectState: {renewalProjectTemplateItemType}} = project;
    if (projectTypeId === models.ProjectTypeEnum.RenewalInitiatedByLandlord) {
      return (
        renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOfferLandlordReviewingFinancialDocuments
      );
    }

    return false;
  }

  isEstablishCriteriaByLandlord(project: models.ProjectViewModel): boolean {
    if (project && project.projectState) {
      if (project.projectTypeId === models.ProjectTypeEnum.Renewal) {
        return (
          project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.EstablishCriteriaByLandlord
        );
      }

      if (project.projectTypeId === models.ProjectTypeEnum.Restructure) {
        return (
          project.projectState.renewalProjectTemplateItemType ===
          models.RenewalProjectTemplateItemType.Restructure_EstablishCriteriaByLandlord
        );
      }

      if (project.projectTypeId === models.ProjectTypeEnum.NewDeal) {
        return (
          project.projectState.renewalProjectTemplateItemType ===
          models.RenewalProjectTemplateItemType.EstablishCriteriaByLandlord
        );
      }

      if (project.projectTypeId === models.ProjectTypeEnum.NewDealInquiry) {
        return (
          project.projectState.renewalProjectTemplateItemType ===
          models.RenewalProjectTemplateItemType.EstablishCriteriaByLandlord
        );
      }
    }
    return false;
  }

  isCurrentRoleTurnOnTerms(
    role: Role | string,
    userId: number,
    project: models.ProjectViewModel,
    lease: models.LeaseViewModel,
    teamAlignmentRoles: Array<models.TeamAlignmentRoleViewModel> = null
  ): boolean {
    let teamAlignmentRole;

    try {
      teamAlignmentRole = this.getTeamAlignmentRole(role, userId, project, lease, teamAlignmentRoles);
    } catch (e) {
      console.error(e);
      return false;
    }

    if (project && project.projectState) {
      const currentProjectState = project.projectState;

      if (currentProjectState) {
        return teamAlignmentRole === currentProjectState.writeAccessToTermsByRole;
      }
    }

    return false;
  }

  getTeamAlignmentRole(
    role: Role | string,
    userId: number,
    project: models.ProjectViewModel,
    lease: models.LeaseViewModel,
    teamAlignmentRoles: Array<models.TeamAlignmentRoleViewModel> = null
  ): Role {
    switch (role) {
      case Role.Tenant:
      case Role.Landlord:
        return role;

      case Role.Broker:
      case Role.CoBroker:
        if (!project) {
          throw new Error('Project, lease or lease users are not present');
        }

        let teamAlignmentRole = null;

        if (teamAlignmentRoles) {
          teamAlignmentRole = teamAlignmentRoles.find(x => x.leaseId === project.leaseId);
          if (teamAlignmentRole) {
            return teamAlignmentRole.role;
          }
        }

        if (!lease || !lease.leaseUsers) {
          throw new Error('Project, lease or lease users are not present');
        }

        const leaseUser = lease
          .leaseUsers
          .find(x => x.userId === userId);

        if (!leaseUser) {
          throw new Error('User has no access to lease');
        }

        if (
          lease.landlordCompany &&
          leaseUser.relatedToCompanyId === lease.landlordCompany.id
        ) {
          teamAlignmentRole = Role.Landlord;
        }

        if (
          lease.tenantCompany &&
          leaseUser.relatedToCompanyId === lease.tenantCompany.id
        ) {
          teamAlignmentRole = Role.Tenant;
        }

        if (!teamAlignmentRole) {
          throw new Error('Unable to determine team alignment for broker');
        }

        return teamAlignmentRole;

      default:
        throw new Error('Unknown role');
    }
  }

  getNegotiationProject(projects: Array<models.ProjectViewModel>): models.ProjectViewModel {
    return projects.find(x =>
      x.projectTypeId === models.ProjectTypeEnum.Renewal ||
      x.projectTypeId === models.ProjectTypeEnum.RenewalInitiatedByLandlord ||
      x.projectTypeId === models.ProjectTypeEnum.Restructure ||
      x.projectTypeId === models.ProjectTypeEnum.NewDeal ||
      x.projectTypeId === models.ProjectTypeEnum.NewDealInquiry
    );
  }

  getProjectsByLeaseId(leaseId: number): Observable<Array<models.ProjectViewModel>> {
    return this._projectRepository.getProjectsByLeaseId(leaseId);
  }

  getProjectTypeVerb(projectType: models.ProjectTypeEnum): string {
    switch (projectType) {
      case models.ProjectTypeEnum.Renewal:
        return 'renew';
      case models.ProjectTypeEnum.Restructure:
        return 'restructure';
      case models.ProjectTypeEnum.Maintenance:
        return 'maintenance';
      case models.ProjectTypeEnum.NewDeal:
        return 'new deal';
      case models.ProjectTypeEnum.NewDealInquiry:
        return 'inquiry';
      default:
        return 'renew';
    }
  }

  renewalProject(
    lease: models.LeaseViewModel,
    renewalProjectTriggerType: models.RenewalProjectTriggerType,
    project: models.ProjectViewModel,
    approvingAppointment: models.ApprovingAppointment = null,
    isAcceptTiNegotiationByTenantOrLandlord: boolean = null,
    isAcceptTiOptionAfterFloorPlan: boolean = null,
    rejectionLeaseViewModel: models.RejectionLease = null
  ): Observable<models.LeaseViewModel> {
    return this._projectRepository
      .renewalProject(lease,
        renewalProjectTriggerType,
        project,
        approvingAppointment,
        isAcceptTiNegotiationByTenantOrLandlord,
        isAcceptTiOptionAfterFloorPlan,
        rejectionLeaseViewModel
      );
  }

  skipDuplicateValidation(project: models.ProjectViewModel): boolean {
    const projectTypesWhereSkip = [
      models.RenewalProjectTemplateItemType.SendRfp,
      models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord
    ];

    if (project && project.projectState) {
      return projectTypesWhereSkip.some(pt => pt === project.projectState.renewalProjectTemplateItemType);
    }

    return false;
  }

  getProjectRequests(): Observable<Array<models.ProjectRequestBriefViewModel>> {
    return this._projectRepository.getProjectRequests();
  }

  getProjectRequestByLeaseId(leaseId: number): Observable<models.ProjectRequestViewModel> {
    return this._projectRepository.getProjectRequestByLeaseId(leaseId);
  }

  checkAccessToRenewalProject(renewalProjectTemplateItemType: models.RenewalProjectTemplateItemType,
                              project: IProjectViewModel, lease: ILeaseViewModel, userId: number, currentRole: string) {
    if (!renewalProjectTemplateItemType || !project || !project.projectState || !lease) {
      return false;
    }

    if (project.projectStatus !== ProjectStatus.Active) {
      return false;
    }

    const role = this.getTurnRole(lease, userId, currentRole);
    if (!role) {
      return false;
    }

    if (currentRole === Role.Admin) {
      return true;
    }

    return this._checkAccessToRenewalProjectByRole(role, renewalProjectTemplateItemType, project.projectState);
  }

  private _checkAccessToRenewalProjectByRole(role: string, renewalProjectTemplateItemType: models.RenewalProjectTemplateItemType,
                                             currentProjectState: IProjectTemplateItemViewModel): boolean {
    if (currentProjectState && renewalProjectTemplateItemType === currentProjectState.renewalProjectTemplateItemType) {
      return role === currentProjectState.writeAccessToTermsByRole;
    }

    if (
      currentProjectState && currentProjectState.parentProjectTemplateItem &&
      currentProjectState.parentProjectTemplateItem.renewalProjectTemplateItemType === renewalProjectTemplateItemType
    ) {
      return role === currentProjectState.writeAccessToTermsByRole;
    }

    return false;
  }

  getTurnRole(lease: ILeaseViewModel, userId: number, currentRole: string): string {
    if (currentRole === Role.Broker && lease && lease.leaseUsers) {
      const leaseUser = lease.leaseUsers.find(x => x.userId === userId);
      if (!leaseUser) {
        return null;
      }
      let role = null;

      if (lease && lease.landlordCompanyId &&
        leaseUser.relatedToCompanyId === lease.landlordCompanyId) {
        role = Role.Landlord;
      }

      if (lease && lease.tenantCompanyId &&
        leaseUser.relatedToCompanyId === lease.tenantCompanyId) {
        role = Role.Tenant;
      }

      return role;
    }

    return currentRole;
  }

  getProjectTours(projectId: number): Observable<Array<models.TourViewModel>> {
    return this._projectRepository
      .getProjectTours(projectId);
  }

  createProjectTour(
    projectId: number,
    dateTimeSlots: Array<models.DateTimeSlotEntry>,
    shouldCreateTourRequest = true
  ): Observable<models.TourViewModel> {
    return this._projectRepository
      .createProjectTour(projectId, {
        dateTimeSlots: dateTimeSlots,
        shouldCreateTourRequest: shouldCreateTourRequest,
      });
  }

  updateProjectTour(tour: models.TourViewModel): Observable<models.TourViewModel> {
    return this._projectRepository
      .updateProjectTour(tour);
  }

  cancelTour(tour: models.TourViewModel): Observable<void> {
    return this._projectRepository
      .cancelProjectTour(tour);
  }

  convertInquiryToNewDeal(projectId: number): Observable<models.ProjectViewModel> {
    return this._projectRepository
      .convertInquiryToNewDeal(projectId);
  }

  getActiveNewDealByBuildingUnitId(unitId: number): Observable<models.ProjectViewModel | null> {
    return this._projectRepository
      .getActiveInquiryByBuildingUnitId(unitId);
  }

  getProjectName(project: IProjectViewModel): string {
    if (project) {
      return this.getProjectNameByProjectTypeId(project.projectTypeId);
    }
  }

  getProjectNameByProjectTypeId(projectTypeId: number) {
    switch (projectTypeId) {
      case models.ProjectTypeEnum.Renewal:
      case models.ProjectTypeEnum.RenewalInitiatedByLandlord:
        return 'Renewal';
      case models.ProjectTypeEnum.Restructure:
        return 'Restructure';
      case models.ProjectTypeEnum.InsuranceCertificate:
        return 'Insurance Of Certificate';
      case models.ProjectTypeEnum.NewDeal:
        return 'New Deal';
      case models.ProjectTypeEnum.NewDealInquiry:
        return 'Inquiry';
    }
}

  getProjectStatisticsInfo(projectId: number): Observable<models.ProjectStatisticsInfo> {
    return this._projectRepository
      .getProjectStatisticsInfo(projectId);
  }

  getProjectStatistics(projectId: number): Observable<models.ProjectStatistics> {
    return this._projectRepository
      .getProjectStatistics(projectId);
  }

  updateProjectNotes(projectId: number, notes: string): Observable<void> {
    return this._projectRepository
      .updateProjectNotes(projectId, notes);
  }
}
