import { Injectable } from '@angular/core';
import { Observable, of, race, Subject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import moment from 'moment';

import { AlertManager, AlertNotificationKind } from '@statera/sdk/alert';
import { CommonTools, CommonTransformer, Configuration } from '@statera/sdk/common';
import { LeaseManager } from '@statera/sdk/lease';
import { ProjectManager } from '@statera/sdk/project';

import { QuizRepository } from './quiz.repository';

import * as models from './quiz.model';

@Injectable()
export class QuizManager {
  isStarting$: Subject<boolean>;
  questionChange$: Subject<models.QuizQuestion>;
  quizId$: Subject<number>;

  private readonly _configuration: Configuration;
  private readonly _quizRepository: QuizRepository;
  private readonly _projectManager: ProjectManager;
  private readonly _leaseManager: LeaseManager;
  private readonly _alertManager: AlertManager;
  private readonly _commonTransformer: CommonTransformer;

  constructor(
    configuration: Configuration,
    quizRepository: QuizRepository,
    projectManager: ProjectManager,
    leaseManager: LeaseManager,
    alertManager: AlertManager,
    commonTransformer: CommonTransformer
  ) {
    this._configuration = configuration;
    this._quizRepository = quizRepository;
    this._projectManager = projectManager;
    this._leaseManager = leaseManager;
    this._alertManager = alertManager;
    this._commonTransformer = commonTransformer;

    this.questionChange$ = new Subject<models.QuizQuestion>();
    this.isStarting$ = new Subject<boolean>();
    this.quizId$ = new Subject<number>();
  }

  /**
   * Requests management
   */

  getWizardQuiz(leaseId: number): Observable<models.Quiz> {
    return this._quizRepository
      .getWizardQuiz(leaseId);
  }

  getQuestion(questionId: number, leaseId: number): Observable<models.QuizQuestion> {
    return this._quizRepository
      .getQuestion(questionId, leaseId);
  }

  getQuizQuestions(quizType: models.QuizType, leaseId: number): Observable<Array<models.QuizQuestion>> {
    return this._quizRepository
      .getQuizQuestionsByType(quizType, leaseId);
  }

  getLastQuestion(quizId: number, leaseId: number): Observable<models.QuizQuestion> {
    return this._quizRepository
      .getLastQuestion(quizId, leaseId);
  }

  getSummaryQuizAnswers(leaseId: number, quizId: number): Observable<Array<models.QuizItem>> {
    return this._quizRepository
      .getSummaryQuizAnswers(leaseId, quizId);
  }

  getLeaseAmendmentsChoices(leaseId: number) {
    return this._quizRepository
      .getLeaseAmendmentsChoices(leaseId);
  }

  answer(model: models.QuizAnswer): Observable<models.QuizQuestion> {
    return this._quizRepository
      .answer(model);
  }

  complete(leaseId: number, model: models.Quiz): Observable<models.QuizQuestion> {
    return this._quizRepository
      .complete(leaseId, model);
  }

  /**
   * v2 wizard management
   */

  getLastStepIndex(quiz: models.Quiz): number {
    for (let i = 0; i < quiz.steps.length; i++) {
      const step = quiz.steps[i];

      if (!step.isFinished) {
        return i;
      }
    }

    return quiz.steps.length - 1;
  }

  shouldHandleQuizQuestion(questions: Array<models.QuizQuestion>, question: models.QuizQuestion): boolean {
    if (!question.parentQuestionId) {
      return true;
    }

    const parentQuestion = questions.find(q => q.id === question.parentQuestionId);
    if (!parentQuestion) {
      return true;
    }

    if (!this.shouldHandleQuizQuestion(questions, parentQuestion)) {
      return false;
    }

    // TODO: Extensive chaining support
    if (parentQuestion.questionType === models.QuestionType.Choice) {
      const selectedOption = parentQuestion.options.find(o => o.id === parentQuestion.answer.selectedOptionId);
      if (!selectedOption) {
        return false;
      }

      const nextQuestion = questions.find(q => q.id === selectedOption.nextQuestionId);
      if (!nextQuestion) {
        return false;
      }

      if (nextQuestion.id === question.id || nextQuestion.nextQuestionId === question.id) {
        return true;
      }
    }

    if (parentQuestion.questionType === models.QuestionType.ChoiceToggle) {
      if (parentQuestion.answer.selectedOptionId === models.RestructureAnswerType.TenantImprovement
        || parentQuestion.answer.selectedOptionId === models.RestructureAnswerType.ExtendLease
        || parentQuestion.answer.selectedOptionId === models.RestructureAnswerType.SizeChange) {
        return true;
      }

      const selectedOption = parentQuestion.options.find(o => o.id === parentQuestion.answer.selectedOptionId);
      if (!selectedOption) {
        return false;
      }

      const nextQuestion = questions.find(q => q.id === selectedOption.nextQuestionId);
      if (!nextQuestion) {
        return false;
      }

      if (nextQuestion.id === question.id || nextQuestion.nextQuestionId === question.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Legacy/Other management
   */

  getQuestionText(question: models.QuizQuestion, lease: models.Lease, isSummaryResults: boolean = true): string {
    if (question.questionType === models.QuestionType.NumberWithExpirationDate) {
      let text = 'How long are you willing to extend the lease beyond the current expiration date';

      if (lease && lease.expiration) {
        const date = moment(lease.expiration).format('ll');
        text = `${text} of ${date}`;
      }
      text += '?';
      return text;
    }

    if (question.questionType === models.QuestionType.SelectCities) {
      let buildingCity = '';
      if (lease && lease.building && lease.building.address && lease.building.address.city) {
        buildingCity = lease.building.address.city;
        if (!buildingCity) {
          buildingCity = lease.building.address.displayString;
        }
      }
      return `Is ${buildingCity} the ideal location or are there other cities you would consider?`;
    }

    if (question.questionType === models.QuestionType.IdealUnitSize) {
      const { text } = question;

      if (lease && lease.buildingUnit && lease.buildingUnit.size) {
        const unitSize = this._commonTransformer.transformNumberSafe(lease.buildingUnit.size.toString(), '2.');

        return text.replace('[unit size SF]', unitSize);
      }

      return text.split('  ')[1];
    }

    if (question.questionType === models.QuestionType.ChoiceToggle) {
      let { text } = question;

      const abstractSquareFootage = lease?.abstractLease?.tenantSquareFootageTerm?.tenantSquareFootageValue;
      const unitSquareFootage = lease?.buildingUnit?.size;
      const occupiedSquareFootage = abstractSquareFootage || unitSquareFootage;

      if (occupiedSquareFootage !== null && occupiedSquareFootage > 0) {
        const formattedOccupiedSquareFootage = this._commonTransformer.transformNumberSafe(occupiedSquareFootage.toString(), '2.');

        text = text.replace('[Occupied unit size SF]', ` (Currently occupy ${formattedOccupiedSquareFootage} SF)`);
      } else {
        text = text.replace('[Occupied unit size SF]', '');
      }

      return text;
    }

    if (question.questionType === models.QuestionType.BuildingSpecs && isSummaryResults) {
      return 'Building Specs';
    }

    if (question.questionType === models.QuestionType.LeaseTerm && isSummaryResults) {
      return 'What kind of lease term would you consider?';
    }

    if (question.questionType === models.QuestionType.LeaseExpiration && isSummaryResults) {
      return 'When does the lease expire?';
    }

    if (question.questionType === models.QuestionType.LeaseAmendments) {
      let landlordCompanyName = 'Landlord Company';
      if (lease && lease.landlordCompany) {
        landlordCompanyName = lease.landlordCompany.name;
      }
      // tslint:disable-next-line:max-line-length
      return `If you would like to upload the lease and any amendments here, our system will abstract it & provide a curated experience as a guide throughout the negotiation process.  The landlord will NEVER see this information, promise 🤝! Alternatively, you can choose an existing lease you currently have with ${landlordCompanyName}, if it's impacted by this decision.(Optional)`;
    }

    return question.text;
  }

  renderAnswer(
    answer: models.QuizAnswer,
    lease: models.Lease,
  ): (
    string |
    Array<string> |
    number |
    Date |
    Array<models.IUserInvitationRequestViewModel> |
    Array<models.QuizQuestionOption> |
    { [index: string]: number }
  ) {
    if (
      answer.quizQuestion.questionType === models.QuestionType.Text ||
      answer.quizQuestion.questionType === models.QuestionType.YesNo
    ) {
      return answer.customAnswerText;
    } else if (
      answer.quizQuestion.questionType === models.QuestionType.Choice ||
      answer.quizQuestion.questionType === models.QuestionType.ChoiceToggle
    ) {
      return answer.selectedOptionText;
    } else if (
      answer.quizQuestion.questionType === models.QuestionType.MultipleChoice ||
      answer.quizQuestion.questionType === models.QuestionType.MultipleChoiceMaxTwoItems
    ) {
      return answer.multipleChoices;
    } else if (answer.quizQuestion.questionType === models.QuestionType.File) {
      return answer.uploadedFileUrl;
    } else if (answer.quizQuestion.questionType === models.QuestionType.Address) {
      if (answer.selectedAddress) {
        return answer.selectedAddress.displayString;
      }
      return '';
    } else if (answer.quizQuestion.questionType === models.QuestionType.MultipleNumber) {
      return answer.multipleNumberAnswers;
    } else if (
      answer.quizQuestion.questionType === models.QuestionType.Number ||
      answer.quizQuestion.questionType === models.QuestionType.SqftNumber ||
      answer.quizQuestion.questionType === models.QuestionType.DollarNumber ||
      answer.quizQuestion.questionType === models.QuestionType.NumberWithExpirationDate
    ) {
      return answer.answerNumber;
    } else if (answer.quizQuestion.questionType === models.QuestionType.Calendar) {
      return answer.answerDate;
    } else if (answer.quizQuestion.questionType === models.QuestionType.Range) {
      const range = <models.QuizRangeAnswer>answer.rangeNumber;
      return `From ${range.from} to ${range.to}`;
    } else if (answer.quizQuestion.questionType === models.QuestionType.BrokerInvitation) {
      if (answer.questionOptionViewModel.answerType === models.AnswerType.InviteBroker) {
        try {
          return answer.userInvitationRequests;
        } catch {
          return null;
        }
      }
    } else if (answer.quizQuestion.questionType === models.QuestionType.TenantInvitation) {
      return answer.userInvitationRequests;
    } else if (answer.quizQuestion.questionType === models.QuestionType.Tour) {
      return answer.questionOptionViewModel.text;
    } else if (answer.quizQuestion.questionType === models.QuestionType.SelectCities) {
      if (answer.selectCitiesAnswer) {
        if (answer.selectCitiesAnswer.selectCitiesAnswerType === models.SelectCitiesAnswerType.CurrentCity) {
          let buildingCity = '';
          if (lease && lease.building && lease.building.address && lease.building.address.city) {
            buildingCity = lease.building.address.city;
            if (!buildingCity) {
              buildingCity = lease.building.address.displayString;
            }
          }
          if (buildingCity) {
            return `${buildingCity} is ideal`;
          }
          return 'Ideal location';
        }

        if (answer.selectCitiesAnswer.selectCitiesAnswerType === models.SelectCitiesAnswerType.OtherCities &&
        answer.selectCitiesAnswer.cities) {
          return answer.selectCitiesAnswer.cities.join(', ');
        }
      }
    } else if (answer.quizQuestion.questionType === models.QuestionType.IdealUnitSize) {
      if (answer.idealUnitSizeAnswer) {
        if (answer.idealUnitSizeAnswer.idealUnitSizeAnswerType === models.IdealUnitSizeAnswerType.SameSize) {
          return 'Yes';
        }

        if (answer.idealUnitSizeAnswer.idealUnitSizeAnswerType === models.IdealUnitSizeAnswerType.SizeRange) {
          const from = this._commonTransformer.transformNumberSafe(answer.idealUnitSizeAnswer.from.toString(), '2.');
          const to = this._commonTransformer.transformNumberSafe(answer.idealUnitSizeAnswer.to.toString(), '2.');
          return `I need between ${from} and ${to} total SF.`;
        }
      }
    } else if (answer.quizQuestion.questionType === models.QuestionType.MonthlyBudget && answer.answerNumber) {
      let budget = this._commonTransformer.transformNumberSafe(answer.answerNumber.toString());

      if (budget.endsWith('.00')) {
        budget = budget.slice(0, -3);
      }

      return `My budget is $${budget}`;
    } else if (answer.quizQuestion.questionType === models.QuestionType.LeaseExpiration) {
      return `My lease expires ${moment(answer.answerDate).format('ll')}`;
    } else if (answer.quizQuestion.questionType === models.QuestionType.LeaseAmendments) {
      if (answer.leaseAmendmentsAnswer) {
        if (answer.leaseAmendmentsAnswer.leaseAmendmentsAnswerType === models.LeaseAmendmentsAnswerType.Skip) {
          return 'Skip';
        }

        if (answer.leaseAmendmentsAnswer.leaseAmendmentsAnswerType === models.LeaseAmendmentsAnswerType.ChooseLease) {
          if (answer.leaseAmendmentsAnswer.displayValue) {
            return answer.leaseAmendmentsAnswer.displayValue;
          }
          return `Lease: ${answer.leaseId}`;
        }
      }
    } else if (answer.quizQuestion.questionType === models.QuestionType.BuildingSpecs) {
      if (answer.buildingSpecsAnswer) {
        const minimumOfficeArea = this._commonTransformer
          .transformNumberSafe(answer.buildingSpecsAnswer.minimumOfficeArea.toString(), '2.');
        const minimumPower = this._commonTransformer
          .transformNumberSafe(answer.buildingSpecsAnswer.minimumPower.toString(), '2.');
        // tslint:disable-next-line:max-line-length
        return [
          `- Clear Height: ${answer.buildingSpecsAnswer.clearHeight}′`,
          `- Office Area: ${minimumOfficeArea} SF`,
          `- Power: ${minimumPower} Amps`,
          `- Loading: ${answer.buildingSpecsAnswer.minimumDocks} Dock(s)`,
          `- Drive-in-Doors: ${answer.buildingSpecsAnswer.minDriveDoorsRamps} Drive-in(s)`,
        ];
      }
    } else if (answer.quizQuestion.questionType === models.QuestionType.LeaseTerm) {
      if (answer.leaseTermAnswer) {
        return (
          `For the perfect space, I would consider anywhere from ${answer.leaseTermAnswer.from} ` +
          `to ${answer.leaseTermAnswer.to} years.`
        );
      }
    } else if (answer.quizQuestion.questionType === models.QuestionType.SpecificUseForBuildingAnswer) {
      if (answer.specificUseForBuildingAnswer && answer.specificUseForBuildingAnswer.result) {
        return answer.specificUseForBuildingAnswer.result.join(', ');
      }
    } else if (answer.quizQuestion.questionType === models.QuestionType.DateOrDateRange) {
      if (answer.dateOrDateRangeAnswer) {
        if (answer.dateOrDateRangeAnswer.specificDate) {
          return moment(answer.dateOrDateRangeAnswer.specificDate).format('ll');
        }

        const fromDateString = moment(answer.dateOrDateRangeAnswer.from).format('MM/DD/YYYY');
        const toDateString = moment(answer.dateOrDateRangeAnswer.to).format('MM/DD/YYYY');

        return `${fromDateString} - ${toDateString}`;
      }
    }
  }

  getQuizId(project: models.Project): number {
    if (!project) {
      return;
    }

    if (project.projectTypeId === models.ProjectType.Renewal ||
      project.projectTypeId === models.ProjectType.RenewalInitiatedByLandlord) {
      return this._configuration.renewalOnboardingQuizId;
    }

    if (project.projectTypeId === models.ProjectType.Restructure) {
      return this._configuration.restructureOnboardingQuizId;
    }
  }

  validate(answer: models.QuizAnswer, quizQuestion: models.QuizQuestion): [boolean, string] {
    switch (quizQuestion.questionType) {
      case models.QuestionType.MultipleChoice: {
        if (!answer.multipleChoices || answer.multipleChoices.length === 0) {
          return [false, 'Please select at least one option'];
        }

        break;
      }

      case models.QuestionType.Choice:
      case models.QuestionType.ChoiceToggle: {
        if (!answer.selectedOptionId) {
          return [false, 'Please make your choice'];
        }

        break;
      }

      case models.QuestionType.BrokerInvitation: {
        if (!answer.selectedOptionId) {
          return [false, 'Please make your choice'];
        }

        break;
      }

      case models.QuestionType.TenantInvitation: {
        if (!answer.userInvitationRequests?.length) {
          return [false, 'Please make your choice'];
        }

        break;
      }

      case models.QuestionType.Text: {
        if (!answer.customAnswerText) {
          return [false, 'The text field cannot be empty'];
        }

        break;
      }

      case models.QuestionType.YesNo: {
        if (!answer.customAnswerText) {
          return [false, 'Please make your choice'];
        }

        break;
      }

      case models.QuestionType.Calendar: {
        if (!answer.answerDate) {
          return [false, 'Date cannot be empty'];
        }

        break;
      }

      case models.QuestionType.MultipleNumber: {
        if (Object.keys(answer.multipleNumberAnswers).length !== quizQuestion.options.length) {
          return [false, 'Please select at least one option'];
        }
        break;
      }

      case models.QuestionType.Address: {
        if (!answer.selectedAddressId) {
          return [false, 'Please chooses buildings address'];
        }

        break;
      }

      case models.QuestionType.FinancialDocuments: {
        if (!answer.selectedOptionId) {
          return [false, 'Please make your choice'];
        }

        const selectedOption = quizQuestion.options.find(o => o.id === answer.selectedOptionId);
        if (selectedOption && selectedOption.answerType === models.AnswerType.UploadFinancialFiles &&
          (!answer.quizAnswerFiles || answer.quizAnswerFiles.length < 1)) {
          return [false, 'You should upload financial documents'];
        }

        break;
      }

      case models.QuestionType.MultipleChoiceMaxTwoItems: {
        if (!answer.multipleChoices || answer.multipleChoices.length === 0) {
          return [false, 'Please select at least one option'];
        }

        break;
      }

      case models.QuestionType.Number: {
        if (typeof answer.answerNumber !== 'number' || answer.answerNumber < 0) {
          return [false, 'Please specify the number'];
        }

        break;
      }

      case models.QuestionType.NumberWithExpirationDate: {
        if (typeof answer.answerNumber !== 'number' || answer.answerNumber < 0) {
          return [false, 'Please specify the number'];
        }

        break;
      }

      case models.QuestionType.SqftNumber: {
        if (typeof answer.answerNumber !== 'number' || answer.answerNumber < 0) {
          return [false, 'Please specify the square footage'];
        }

        break;
      }

      case models.QuestionType.Range: {
        if (!answer.rangeNumber.to || !answer.rangeNumber.from) {
          return [false, 'Please specify the range'];
        }

        if (answer.rangeNumber.from >= answer.rangeNumber.to) {
          return [false, 'Minimum size cannot be greater the maximum'];
        }
        break;
      }

      case models.QuestionType.SelectCities: {
        if (!answer.selectCitiesAnswer || !answer.selectCitiesAnswer.selectCitiesAnswerType) {
          return [false, 'Please make your choice'];
        }

        if (answer.selectCitiesAnswer.selectCitiesAnswerType === models.SelectCitiesAnswerType.OtherCities &&
          (!answer.selectCitiesAnswer.cities || answer.selectCitiesAnswer.cities.length === 0)) {
          return [false, 'Please select cities'];
        }
        break;
      }

      case models.QuestionType.IdealUnitSize: {
        if (!answer.idealUnitSizeAnswer || !answer.idealUnitSizeAnswer.idealUnitSizeAnswerType) {
          return [false, 'Please make your choice'];
        }

        if (answer.idealUnitSizeAnswer.idealUnitSizeAnswerType === models.IdealUnitSizeAnswerType.SizeRange) {
          if (!answer.idealUnitSizeAnswer.to || !answer.idealUnitSizeAnswer.from) {
            return [false, 'Please specify the range'];
          }

          if (answer.idealUnitSizeAnswer.from >= answer.idealUnitSizeAnswer.to) {
            return [false, 'Minimum size cannot be greater the maximum'];
          }
        }
        break;
      }

      case models.QuestionType.LeaseExpiration: {
        if (!answer.answerDate) {
          return [false, 'Please make your choice'];
        }

        break;
      }

      case models.QuestionType.LeaseAmendments: {
        if (!answer.leaseAmendmentsAnswer || !answer.leaseAmendmentsAnswer.leaseAmendmentsAnswerType) {
          return [false, 'Please make your choice'];
        }
        if (answer.leaseAmendmentsAnswer.leaseAmendmentsAnswerType === models.LeaseAmendmentsAnswerType.Upload &&
          (!answer.quizAnswerFiles || answer.quizAnswerFiles.length < 1)) {
          return [false, 'You should upload lease documents'];
        }

        if (answer.leaseAmendmentsAnswer.leaseAmendmentsAnswerType === models.LeaseAmendmentsAnswerType.ChooseLease &&
        !answer.leaseAmendmentsAnswer.leaseId) {
          return [false, 'You should choose lease'];
        }
        break;
      }

      case models.QuestionType.BuildingSpecs: {
        if ((CommonTools.isNullOrUndefined(answer.buildingSpecsAnswer) ||
          CommonTools.isNullOrUndefined(answer.buildingSpecsAnswer.clearHeight) ||
          CommonTools.isNullOrUndefined(answer.buildingSpecsAnswer.minDriveDoorsRamps) ||
          CommonTools.isNullOrUndefined(answer.buildingSpecsAnswer.minimumDocks) ||
          CommonTools.isNullOrUndefined(answer.buildingSpecsAnswer.minimumPower))) {
          return [false, 'Please make your choice'];
        }
        break;
      }
      case models.QuestionType.LeaseTerm: {
        if (!answer.leaseTermAnswer || !answer.leaseTermAnswer.to || !answer.leaseTermAnswer.from) {
          return [false, 'Please specify the range'];
        }

        if (answer.leaseTermAnswer.from >= answer.leaseTermAnswer.to) {
          return [false, 'Minimum size cannot be greater the maximum'];
        }
        break;
      }
      case models.QuestionType.SpecificUseForBuildingAnswer: {
        if (!answer.specificUseForBuildingAnswer ||
          !answer.specificUseForBuildingAnswer.result || answer.specificUseForBuildingAnswer.result.length === 0) {
          return [false, 'Please make your choice'];
        }
        break;
      }
      case models.QuestionType.MonthlyBudget: {
        if (typeof answer.answerNumber !== 'number' || answer.answerNumber < 0) {
          return [false, 'Please specify the monthly budget'];
        }

        break;
      }
      case models.QuestionType.DateOrDateRange: {
        if (!answer.dateOrDateRangeAnswer) {
          return [false, 'Choose a range or a specific date'];
        }

        if (
          !answer.dateOrDateRangeAnswer.specificDate &&
          !answer.dateOrDateRangeAnswer.from &&
          !answer.dateOrDateRangeAnswer.to
        ) {
          return [false, 'Choose a range or a specific date'];
        }

        if (answer.dateOrDateRangeAnswer.specificDate) {
          if (!moment(answer.dateOrDateRangeAnswer.specificDate).isValid()) {
            return [false, 'Choose a range or a specific date'];
          }
        } else {
          if (
            !answer.dateOrDateRangeAnswer.from ||
            !answer.dateOrDateRangeAnswer.to
          ) {
            return [false, 'Choose a range or a specific date'];
          }

          if (
            !moment(answer.dateOrDateRangeAnswer.to).isValid() ||
            !moment(answer.dateOrDateRangeAnswer.from).isValid()
          ) {
            return [false, 'Choose a range or a specific date'];
          }

          if (
            moment(answer.dateOrDateRangeAnswer.from).isAfter(moment(answer.dateOrDateRangeAnswer.to))
          ) {
            return [false, 'Range minimum cannot be greater the maximum'];
          }
        }

        break;
      }
    }
    return [true, null];
  }

  isDone(quizQuestion: models.QuizQuestion): boolean {
    return quizQuestion && quizQuestion.questionType === models.QuestionType.Done;
  }

  showBackButton(quizQuestion: models.QuizQuestion): boolean {
    return this.isDone(quizQuestion) && !!quizQuestion.previousQuestionId;
  }

  showStartScreen(question: models.QuizQuestion): boolean {
    return !question.id;
  }

  initializeOnboarding(
    leaseId: number,
    project: models.Project,
  ): Observable<models.QuizQuestion> {
    this.isStarting$.next(true);
    let quizId = this._configuration.renewalOnboardingQuizId;
    if (project.projectTypeId === models.ProjectType.Restructure) {
      quizId = this._configuration.restructureOnboardingQuizId;
    }
    if (project.projectTypeId === models.ProjectType.NewDeal) {
      quizId = this._configuration.newDealQuizId;
    }
    if (project.projectTypeId === models.ProjectType.NewDealInquiry) {
      quizId = this._configuration.newDealInquiryQuizId;
    }
    this.quizId$.next(quizId);

    return this
      .getLastQuestion(quizId, leaseId)
      .pipe(
        tap(lastQuestion => {
          this.questionChange$.next(lastQuestion);
        }),
        tap(_ => this.isStarting$.next(false))
      );
  }

  restart(quizId: number, leaseId: number) {
    const alertReference = this._alertManager.queueAlertNotification({
      kind: AlertNotificationKind.Confirm,
      title: 'Confirm restart the quiz',
      message: 'Are you sure? All previous answers will be deleted',
    });

    return alertReference
      .confirmed
      .pipe(
        switchMap(() => this._quizRepository.restart(quizId, leaseId)),
        take(1),
      );
  }

  submitQuiz(project: models.Project, lease: models.Lease, quiz: models.Quiz): Observable<boolean> {
    let confirmMessage = 'Are you sure you want to submit your request?';
    if (project.projectTypeId === models.ProjectType.RenewalInitiatedByLandlord) {
      confirmMessage = 'Are you certain you want to submit your responses & financials?';
    }

    const alertReference = this._alertManager.queueAlertNotification({
      kind: AlertNotificationKind.Confirm,
      message: confirmMessage,
      closeOnButtonClick: false,
    });

    const confirmed = alertReference
      .confirmed
      .pipe(
        switchMap(() => alertReference.hide()),
        switchMap(() => this.complete(lease.id, quiz)),
        switchMap(() => this._submitQuiz(lease, quiz.id, project)),
        switchMap(() => of(true)),
      );

    const declined = alertReference
      .declined
      .pipe(
        switchMap(() => alertReference.hide()),
        switchMap(() => of(false)),
      );

    return race([confirmed, declined]);
  }

  cancelRequest(lease: models.Lease): Observable<boolean> {
    const alertReference = this._alertManager.queueAlertNotification({
      kind: AlertNotificationKind.Confirm,
      message: 'Are you sure you want to cancel request?',
    });

    const onConfirmClicked = alertReference
      .confirmed
      .pipe(
        switchMap(() => this._leaseManager.cancelRequest(lease)),
        map(() => true),
      );

    const onDeclineClicked = alertReference
      .declined
      .pipe(
        map(() => false)
      );

    return race([onConfirmClicked, onDeclineClicked]);
  }

  private _submitQuiz(lease: models.Lease, quizId: number, project: models.Project): Observable<void> {
    let establishNextStep = this._projectManager
      .renewalProject(lease, models.RenewalProjectTriggerType.EstablishCriteriaByTenant, project);

    if (quizId === this._configuration.restructureOnboardingQuizId) {
      establishNextStep = this._projectManager
        .renewalProject(lease, models.RenewalProjectTriggerType.Restructure_EstablishCriteriaByTenant, project);
    }

    if (project.projectTypeId === models.ProjectType.RenewalInitiatedByLandlord) {
      establishNextStep = this._projectManager
        .renewalProject(lease, models.RenewalProjectTriggerType.UnsolicitedOfferRenewalWizard, project);
    }

    if (project.projectTypeId === models.ProjectType.NewDeal) {
      establishNextStep = this._projectManager
        .renewalProject(lease, models.RenewalProjectTriggerType.NewDeal_EstablishCriteriaByTenant, project);
    }

    if (project.projectTypeId === models.ProjectType.NewDealInquiry) {
      establishNextStep = this._projectManager
        .renewalProject(lease, models.RenewalProjectTriggerType.NewDeal_SubmitInquiryByTenant, project);
    }

    return establishNextStep
      .pipe(
        switchMap(() => of(null)),
      );
  }

  initializeAnswer(newQuestion: models.QuizQuestion, leaseId: number, quizId: number): models.QuizAnswer {
    let answer = null;
    if (!newQuestion) {
      answer = <models.QuizQuestion>{};
      answer.leaseId = leaseId;
      answer.quizId = quizId;
      return answer;
    }
    if (newQuestion.answers) {
      answer = newQuestion.answers.filter(x => x.quizQuestion.id === newQuestion.id)[0];

      if (!answer) {
        answer = <models.QuizQuestion>{};
        answer.leaseId = leaseId;
        answer.quizQuestionId = newQuestion.id;
        answer.quizQuestion = newQuestion;
        answer.quizId = quizId;
        if (newQuestion.questionType === models.QuestionType.MultipleChoice ||
          newQuestion.questionType === models.QuestionType.MultipleChoiceMaxTwoItems) {
          answer.multipleChoices = [];
        }

        if (newQuestion.questionType === models.QuestionType.BrokerInvitation) {
          answer.userInvitationRequests = <Array<models.IUserInvitationRequestViewModel>>[];
        }

        if (newQuestion.questionType === models.QuestionType.TenantInvitation) {
          answer.userInvitationRequests = <Array<models.IUserInvitationRequestViewModel>>[];
        }

        if (newQuestion.questionType === models.QuestionType.MultipleNumber) {
          answer.multipleNumberAnswers = {};
        }

        if (newQuestion.questionType === models.QuestionType.FinancialDocuments) {
          answer.quizAnswerFiles = [];
        }

        if (newQuestion.questionType === models.QuestionType.Range) {
          answer.rangeNumber = <models.QuizRangeAnswer> {};
        }

        if (newQuestion.questionType === models.QuestionType.SelectCities) {
          answer.selectCitiesAnswer = <models.SelectCitiesAnswer> {};
        }

        if (newQuestion.questionType === models.QuestionType.IdealUnitSize) {
          answer.idealUnitSizeAnswer = <models.IdealUnitSizeAnswer> {};
        }

        if (newQuestion.questionType === models.QuestionType.LeaseAmendments) {
          answer.leaseAmendmentsAnswer = <models.LeaseAmendmentsAnswer> {};
          answer.leaseAmendmentsAnswer.leaseAmendmentsAnswerType = models.LeaseAmendmentsAnswerType.Skip;
          answer.quizAnswerFiles = [];
        }

        if (newQuestion.questionType === models.QuestionType.BuildingSpecs) {
          answer.buildingSpecsAnswer = <models.BuildingSpecsAnswer> {};
        }

        if (newQuestion.questionType === models.QuestionType.LeaseTerm) {
          answer.leaseTermAnswer = <models.LeaseTermAnswer> {};
        }

        if (newQuestion.questionType === models.QuestionType.SpecificUseForBuildingAnswer) {
          answer.specificUseForBuildingAnswer = <models.SpecificUseForBuildingAnswer> {};
        }

        if (newQuestion.questionType === models.QuestionType.DateOrDateRange) {
          answer.dateOrDateRangeAnswer = <models.DateOrDateRangeAnswer> {};
        }
      } else {

      }
    }

    return answer;
  }

  getStartScreenText(projectType: models.ProjectType) {
    switch (projectType) {
      case models.ProjectType.Renewal:
        return `You have chosen to renew your lease, this process will only take a few minutes`;
      case models.ProjectType.Restructure:
        return `You have chosen to restructure your lease, this process will only take a few minutes`;
      case models.ProjectType.Maintenance:
        return `You have chosen to maintenance your lease, this process will take a few minutes.`;
      case models.ProjectType.NewDeal:
        return (
          'Hi I\'m Aleasa. ' +
          'I\'m simply rounding up some intel before we start this negotiation, ' +
          'in order to better streamline your experience. Hope you don\'t mind 😉. ' +
          'This will only take a few minutes.'
        );
      case models.ProjectType.NewDealInquiry:
        return (
          'Hi I\'m Aleasa. ' +
          'I\'m simply rounding up some intel for your inquiry, ' +
          'in order to better streamline your experience. Hope you don\'t mind 😉. ' +
          'This will only take a few minutes.'
        );
      default:
        return `You have chosen to renew your lease, this process will only take a few minutes.`;
    }
  }

  getLeaseAmendmentsOptions(
    lease: models.Lease,
    leaseAmendmentChoices: Array<models.QuizLeaseAmendmentChoiceViewModel>,
  ): Array<models.LeaseAmendmentsOption> {
    let landlordCompany = 'Landlord Company';
    if (lease && lease.landlordCompany) {
      landlordCompany = lease.landlordCompany.name;
    }
    let chooseLeaseDisabled = true;
    if (leaseAmendmentChoices && leaseAmendmentChoices.length) {
      chooseLeaseDisabled = false;
    }
    return [
      { text: 'Skip',
        value: models.LeaseAmendmentsAnswerType.Skip,
        tooltip: '',
        disabled: false
      },
      {
        text: 'Upload',
        value: models.LeaseAmendmentsAnswerType.Upload,
        tooltip: '',
        disabled: false
      },
      {
        text: 'Choose Lease',
        value: models.LeaseAmendmentsAnswerType.ChooseLease,
        tooltip: `No other existing leases with ${landlordCompany}`,
        disabled: chooseLeaseDisabled
      }
    ];
  }
}
