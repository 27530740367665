import {Location} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import * as ng from '@angular/core';
import {Component, Renderer2, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {AlertMessagesManager, AlertNotificationKind, AlertNotificationReference} from '@statera/sdk/alert';
import {Role} from '@statera/sdk/auth';
import {ColaboManager} from '@statera/sdk/colabo';
import {CommonTools} from '@statera/sdk/common';
import {FinancialsRequestManager} from '@statera/sdk/financials-request';
import {LeaseManager} from '@statera/sdk/lease';
import {LoggerService, LoggerTopic} from '@statera/sdk/logger';
import {ProjectManager} from '@statera/sdk/project';
import {TermManager} from '@statera/sdk/term';
import {TermCustomValueTemplateManager} from '@statera/sdk/term-custom-value-template';
import {TermHistoryManager} from '@statera/sdk/term-history';
import {DxAccordionComponent, DxTabPanelComponent} from 'devextreme-angular';
import {combineLatest, forkJoin, Subject, Subscription, throwError} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {catchError, filter, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AlertService as UIAlertService} from '../../../alert/services/alert.service';

import {AuthService} from '../../../auth/services/auth.service';
import * as models from '../../../infrastructure/models/generated';
import {ProjectStatus, RenewalProjectTemplateItemType} from '../../../infrastructure/models/generated';
import {ActivityTrackerService} from '../../../infrastructure/services/activity-tracker.service';
import {DialogService} from '../../../dialog/services/dialog.service';
import {LoaderService} from '../../../infrastructure/services/loader.service';
import {
  RequestFinancialsDialogComponent,
} from '../../../lease-request/components/request-financials-dialog/request-financials-dialog.component';
import {FloorPlanComponent} from '../../../plan/components/floor-plan/floor-plan.component';
import {AlertService} from '../../../shared/services/alert.service';
import {AppService} from '../../../shared/services/app.service';
import {DocumentsService} from '../../../shared/services/documents.service';
import {LeaseService} from '../../../shared/services/lease.service';
import {ProjectAccessService} from '../../../shared/services/project-access.service';
import {ProjectService} from '../../../shared/services/project.service';
import {TermsPageService} from '../../services/terms-page.service';
import {CertificateOfInsuranceComponent} from '../certificate-of-insurance/certificate-of-insurance.component';
import {ColaboDemoTermsComponent} from '../colabo-demo-terms/colabo-demo-terms.component';
import {FloorplanDemoComponent} from '../demos/floorplan-demo-tenant/floorplan-demo.component';
import {ElectTermsForNegotiationComponent} from '../elect-terms-for-negotiation/elect-terms-for-negotiation.component';
import {
  InviteExternalSignerToSignaturesDialogComponent,
} from '../lease-document-external-signer-dialog/invite-external-signer-to-signatures-dialog.component';
import {LeaseDocumentsComponent} from '../lease-documents/lease-documents.component';
import {TermHeaderComponent} from './term-header/term-header.component';
import {LeaseTermEventModel} from './term/lease-term-event-model';
import {TermComponent} from './term/term/term.component';
import {StateraUserClaimManager} from '@statera/sdk/statera-user-claim';
import { TourScheduledInfoComponent } from '../../../tour/components/tour-scheduled-info/tour-scheduled-info.component';
import { TourScheduleDialogComponent } from '../../../tour/components/tour-schedule-dialog/tour-schedule-dialog.component';
import {LeaseSettingsComponent} from '../lease-settings/lease-settings.component';
import {FloorPlanManager} from '@statera/sdk/floor-plan';
import {Feature, FeatureTogglingManager} from '@statera/sdk/feature-toggling';

const TenantImprovements = 'TenantImprovements';

class TermStatusDisplay {
  constructor(termStatus: models.TermStatus, leaseTermConfiguration: models.ILeaseTermConfiguration) {
    this.termStatus = termStatus;
    this.leaseTermConfiguration = leaseTermConfiguration;
  }

  termStatus: models.TermStatus;
  leaseTermConfiguration: models.ILeaseTermConfiguration;
}

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements ng.OnInit, ng.OnDestroy {
  @ViewChild('tabPanel') tabPanel: DxTabPanelComponent;
  @ViewChild('colaboDemoTerms') colaboDemoTerms: ColaboDemoTermsComponent;
  @ViewChild('leaseTermsElement') leaseTermsElement: ng.ElementRef;
  @ViewChild('floorplanDemoTenant') floorplanDemoTenant: FloorplanDemoComponent;
  @ViewChild('accordion') accordion: DxAccordionComponent;
  @ViewChild('leaseDocumentsComponent') leaseDocumentsComponent: LeaseDocumentsComponent;
  @ViewChild(TermComponent) termComponent: TermComponent | undefined;

  showTitle = false;
  isDataLoaded = false;
  termConfigurations: Array<models.ILeaseTermConfiguration> = [];
  TermType = models.TermType;
  TermStatus = models.TermStatus;
  RenewalProjectTemplateItemType = models.RenewalProjectTemplateItemType;
  TenantImprovementsType = models.TenantImprovementsType;
  lease: models.ILeaseViewModel;
  leaseTermStatuses: Array<TermStatusDisplay>;
  tour?: models.ITourViewModel;
  leaseTeam?: models.ILeaseTeamViewModel;
  accordionIndex: number;
  currentLeaseTermConfiguration: models.ILeaseTermConfiguration;

  message: string;
  startupInfo: models.IStartupInfoViewModel;
  TenantImprovements: string = TenantImprovements;
  letterOfIntentPopupVisible = false;
  signingLeaseAmendmentPopupVisible = false;
  leaseDocumentsComponentVisible = false;
  leaseExecutePopupVisible = false;
  certificateOfInsurancePopupVisible = false;
  sendOfferButtonVisible = false;
  sendOfferForApprovalButtonVisible = false;
  reviewTenantImprovementsMultiplyOptionsByLandlordButtonVisible = false;
  reviewTenantImprovementsMultiplyOptionsByTenantButtonVisible = false;
  tourComingSoonTooltipVisible = false;

  rejectButtonClicked: any = {};
  acceptButtonClicked: any = {};
  rejectFormVisibilityMap: { [key: string]: boolean } = {};
  itemHeaders: Array<TermHeaderComponent> = [];
  project: models.IProjectViewModel;
  floorPlans: Array<models.ILeaseFloorPlanViewModel>;
  planButtonVisible = false;
  demoRentalRateTerm = false;
  planPopupVisible = false;
  IntroType = models.IntroType;
  contentTabs = [{title: 'Tab1', template: 'templ1'}, {title: 'Tab2', template: 'templ2'}];
  notShownAlerts: Array<models.IAlertViewModel>;
  leaseHistoryRecord: models.ILeaseHistoryRecordViewModel;
  leaseSignature: models.ILeaseSignViewModel;
  disabledAccordion = false;
  tooltipCOIVisible = false;
  projects: Array<models.IProjectViewModel>;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  financialRequests: Array<models.ILeaseRequestViewModel>;
  Feature: typeof Feature = Feature;

  accordionAnimationDurationMs = 350;

  accordionOpened$: { [termName: string]: Subject<void> };
  errorOccured$: { [termName: string]: Subject<HttpErrorResponse> };

  get isMobile(): boolean {
    return AppService.isMobile;
  }

  get isDemo() {
    let hasAccess = false;
    if (this.lease) {
      hasAccess = this._stateraUserClaimService
        .checkUserAccess(models.StateraClaimTypeAsEnum.Collabo, models.StateraClaimValueAsEnum.Read,
          null, null, null, this.lease.id);
    }
    return (
      hasAccess &&
      !!this._authService.startupInfo.introsShown &&
      !this._authService.startupInfo.introsShown.find(x => x.introType === models.IntroType.ColaboPage2)
    );
  }

  private readonly _termManager: TermManager;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _leaseService: LeaseService;
  private readonly _uiAlertService: UIAlertService;
  private readonly _authService: AuthService;
  private readonly _projectService: ProjectService;
  private readonly _location: Location;
  private readonly _router: Router;
  private readonly _alertService: AlertService;
  private readonly _alertMessagesManager: AlertMessagesManager;
  private readonly _termsPageService: TermsPageService;
  private readonly _changeDetectorRef: ng.ChangeDetectorRef;
  private readonly _documentService: DocumentsService;
  private readonly _loaderService: LoaderService;
  private readonly _dialogService: DialogService;
  private readonly _stateraUserClaimService: StateraUserClaimManager;
  private readonly _ngZone: ng.NgZone;
  private readonly _projectAccessService: ProjectAccessService;
  private readonly _financialRequestManager: FinancialsRequestManager;
  private readonly _renderer: Renderer2;
  private readonly _leaseManager: LeaseManager;
  private readonly _projectManager: ProjectManager;
  private readonly _termHistoryManager: TermHistoryManager;
  private readonly _colaboManager: ColaboManager;
  private readonly _loggerService: LoggerService;
  private readonly _activityTrackService: ActivityTrackerService;
  private readonly _termCustomValueTemplateManager: TermCustomValueTemplateManager;
  private readonly _floorPlanManager: FloorPlanManager;
  private readonly _featureTogglingManager: FeatureTogglingManager;

  private readonly _destroy$: Subject<void>;

  private _currentRenewalProjectTemplateItemType: models.RenewalProjectTemplateItemType;
  private _currentProjectState: models.IProjectTemplateItemViewModel;
  private _termRouteName: string;

  constructor(
    termManager: TermManager,
    activatedRoute: ActivatedRoute,
    leaseService: LeaseService,
    uiAlertService: UIAlertService,
    authService: AuthService,
    projectService: ProjectService,
    location: Location,
    router: Router,
    alertService: AlertService,
    alertMessagesManager: AlertMessagesManager,
    termsPageService: TermsPageService,
    changeDetectorRef: ng.ChangeDetectorRef,
    documentService: DocumentsService,
    loaderService: LoaderService,
    dialogService: DialogService,
    ngZone: ng.NgZone,
    projectAccessService: ProjectAccessService,
    stateraUserClaimService: StateraUserClaimManager,
    financialsRequestManager: FinancialsRequestManager,
    rendered: Renderer2,
    leaseManager: LeaseManager,
    projectManager: ProjectManager,
    termCustomValueTemplateManager: TermCustomValueTemplateManager,
    termHistoryManager: TermHistoryManager,
    colaboManager: ColaboManager,
    loggerService: LoggerService,
    activityTrackService: ActivityTrackerService,
    floorPlanManager: FloorPlanManager,
    featureTogglingManager: FeatureTogglingManager
  ) {
    this._termManager = termManager;
    this._activatedRoute = activatedRoute;
    this._leaseService = leaseService;
    this._uiAlertService = uiAlertService;
    this._authService = authService;
    this._projectService = projectService;
    this._location = location;
    this._router = router;
    this._alertService = alertService;
    this._alertMessagesManager = alertMessagesManager;
    this._termsPageService = termsPageService;
    this._changeDetectorRef = changeDetectorRef;
    this._documentService = documentService;
    this._loaderService = loaderService;
    this._dialogService = dialogService;
    this._ngZone = ngZone;
    this._projectAccessService = projectAccessService;
    this._stateraUserClaimService = stateraUserClaimService;
    this._financialRequestManager = financialsRequestManager;
    this._renderer = rendered;
    this._leaseManager = leaseManager;
    this._projectManager = projectManager;
    this._termCustomValueTemplateManager = termCustomValueTemplateManager;
    this._termHistoryManager = termHistoryManager;
    this._colaboManager = colaboManager;
    this._loggerService = loggerService;
    this._activityTrackService = activityTrackService;
    this._floorPlanManager = floorPlanManager;
    this._featureTogglingManager = featureTogglingManager;

    this._destroy$ = new Subject<void>();
  }

  ngOnInit(): void {
    const queryParamMap = this._activatedRoute.snapshot.queryParamMap;
    const leaseId = parseInt(queryParamMap.get('leaseId'), 10);

    this._activatedRoute.queryParams
      .pipe(
        switchMap((params) => {
          if (this.isDemo) {
            this._termRouteName = 'RentalRate';
            this._replaceLocationState(this._termRouteName);
          } else {
            this._termRouteName = params['termName'];
          }

          this._initializePage(this._termRouteName, params.leaseId || leaseId, false);

          return this._leaseManager.getLeaseTeam(params.leaseId || leaseId);
        }),
        tap((leaseTeam) => {
          this.leaseTeam = leaseTeam;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._uiAlertService
      .refreshColabo
      .pipe(
        tap((alertRefreshPayload: {leaseId: number, termName: string}) => {
          const nextLeaseId = alertRefreshPayload.leaseId || leaseId;
          const nextTermName = alertRefreshPayload.termName || this._termRouteName;

          if (typeof this.accordionIndex === 'number') {
            const indexOfTerm = this.termConfigurations.findIndex(x => x.termName === nextTermName);
            if (0 <= indexOfTerm) {
              this.accordionIndex = indexOfTerm;
            }
          }

          this._termRouteName = nextTermName;

          this._initializePage(this._termRouteName, nextLeaseId, false);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._trackRealtimeEvents(leaseId);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._changeDetectorRef.detach();

    Object.values(this.accordionOpened$).forEach(x => x.complete());
    Object.values(this.errorOccured$).forEach(x => x.complete());
  }

  private _trackRealtimeEvents(leaseId: number): void {
    let realtimeRefreshSubscription: Subscription;
    let realtimeStageChangedSubscription: Subscription;

    const unsubscribe = () => {
      if (realtimeRefreshSubscription) {
        realtimeRefreshSubscription.unsubscribe();
        realtimeRefreshSubscription = null;
      }

      if (realtimeStageChangedSubscription) {
        realtimeStageChangedSubscription.unsubscribe();
        realtimeStageChangedSubscription = null;
      }
    };

    const subscribe = () => {
      unsubscribe();

      realtimeRefreshSubscription = this._colaboManager
        .getRealtimeRefreshColaboMessage()
        .pipe(
          filter(message => message && message.leaseId === leaseId),
          tap(message => {
            this._initializePage(null, leaseId);

            this._loggerService.debug(LoggerTopic.Colabo, 'Page refreshed', message);
          }),
          takeUntil(this._destroy$),
        )
        .subscribe();

      realtimeStageChangedSubscription = this._projectManager
        .getRealtimeStageChangedMessage()
        .pipe(
          filter(message => message && message.leaseId === leaseId),
          tap(message => {
            this._loggerService.debug(LoggerTopic.Colabo, 'Project stage changed', message);
          }),
          takeUntil(this._destroy$),
        )
        .subscribe();
    };

    let reInitPage = false;

    this._activityTrackService
      .track()
      .pipe(
        tap(isUserActive => {
          if (isUserActive) {
            subscribe();

            if (reInitPage) {
              this._initializePage(this._termRouteName, this.lease.id, false);
            }

            reInitPage = true;
            return;
          }

          unsubscribe();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  setDemoRentalRate(isDemoRentalRate: boolean): void {
    this.demoRentalRateTerm = isDemoRentalRate;
    this._detectChanges();
  }

  shouldShowSendOfferButton(): boolean {
    return this._authService.role === Role.Tenant || this._authService.role === Role.Landlord;
  }

  shouldShowSendOfferForApprovalButton(): boolean {
    return this._authService.role === Role.Broker && !this.project.pendingForInternalApproval;
  }

  showViewLOI(): boolean {
    if (!this.project || !this.project.projectState) {
      return false;
    }

    // NOTE: Colabo Read claim is required to access LOI document. If user has an access to Colabo for that lease, it is
    //       guaranteed that he has Colabo Read claim, so there's no need to double check his claims again.
    return this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.LetterOfIntent;
  }

  showSignLOIButton(): boolean {
    if (this._authService.isTenant()) {
      return this._currentRenewalProjectTemplateItemType === RenewalProjectTemplateItemType.LetterOfIntent;
    } else {
      return this._currentRenewalProjectTemplateItemType !== RenewalProjectTemplateItemType.LetterOfIntent;
    }
  }

  expandNextTerm(): void {
    const firstAttentionRequiredLeaseTermConfiguration = this._getFirstAttentionRequiredLeaseTermConfiguration();
    if (firstAttentionRequiredLeaseTermConfiguration) {
      this._replaceLocationState(firstAttentionRequiredLeaseTermConfiguration.termName);
      this.accordion.instance.expandItem(
        this.termConfigurations.findIndex(x => x.id === firstAttentionRequiredLeaseTermConfiguration.id),
      );
    } else if (this.currentLeaseTermConfiguration) {
      const currLeaseTermConfigurationIndex = this.termConfigurations
        .findIndex(x => x.id === this.currentLeaseTermConfiguration.id);
      this.accordion.instance.collapseItem(currLeaseTermConfigurationIndex);
      this.leaseTermsElement.nativeElement.scrollTop = 0;
      this._replaceLocationState(null);
    }
  }

  private _getFirstAttentionRequiredLeaseTermConfiguration(): models.ILeaseTermConfiguration {
    return this.termConfigurations
      .find(leaseTermConfiguration => {
        const status = this.getTermStatus(leaseTermConfiguration.leaseTermType);
        return (
          !leaseTermConfiguration.isHidden &&
          (status === models.TermStatus.Draft || status === models.TermStatus.Pending) &&
          (this.currentLeaseTermConfiguration && this.currentLeaseTermConfiguration.id !== leaseTermConfiguration.id)
        );
      });
  }

  sendOffer(button: HTMLButtonElement): void {
    if (!this._validateTerms()) {
      return;
    }

    if (!this._isAllStatusSetWithDialogue(models.TermStatus.Ready, true)) {
      return;
    }

    const alertReference = this._uiAlertService
      .pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmSendOfferAlertText(),
      });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          button.disabled = true;

          return this._projectService
            .renewalProject(this.lease, models.RenewalProjectTriggerType.SendOffer, this.project)
            .pipe(
              tap(() => {
                button.disabled = false;
                this.sendOfferButtonVisible = false;
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  sendUnsolicitedOffer(button: HTMLButtonElement): void {
    if (!this._validateTerms()) {
      return;
    }

    if (!this._isAllStatusSetWithDialogue(models.TermStatus.Ready, true)) {
      return;
    }

    const alertReference = this._uiAlertService
      .pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmSendUnsolicitedOfferAlertText(),
      });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          button.disabled = true;

          return this._projectService
            .renewalProject(this.lease, models.RenewalProjectTriggerType.UnsolicitedOfferByLandlord, this.project)
            .pipe(
              tap(() => {
                button.disabled = false;
                this.sendOfferButtonVisible = false;
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  acceptUnsolicitedOfferByTenant(button: HTMLButtonElement): void {
    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmProceedUnsolicitedOfferByTenantAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          button.disabled = true;

          return this._projectService
            .renewalProject(this.lease, models.RenewalProjectTriggerType.AcceptUnsolicitedOfferByTenant, this.project)
            .pipe(
              tap(() => {
                button.disabled = false;
                this._initializePage(this._termRouteName, this.lease.id);
                this._detectChanges();
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  rejectUnsolicitedOfferByTenant(button: HTMLButtonElement): void {
    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmRejectUnsolicitedOfferByTenantAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          button.disabled = true;

          return this._projectService
            .renewalProject(this.lease, models.RenewalProjectTriggerType.RejectUnsolicitedOfferByTenant, this.project)
            .pipe(
              tap(() => {
                button.disabled = false;
                this._initializePage(this._termRouteName, this.lease.id);
                this._detectChanges();
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  reviewTenantImprovementsMultiplyOptionsByLandlord(button: HTMLButtonElement): void {
    if (!this._validateTerms()) {
      return;
    }

    if (!this._isAllStatusesSetWithDialogue([models.TermStatus.Ready, models.TermStatus.Rejected, models.TermStatus.Accepted])) {
      return;
    }

    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmSendOfferAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          button.disabled = true;

          return this._projectService
            .renewalProject(
              this.lease,
              models.RenewalProjectTriggerType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
              this.project,
            )
            .pipe(
              tap(() => {
                button.disabled = false;

                this.reviewTenantImprovementsMultiplyOptionsByLandlordButtonVisible = false;
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  reviewTenantImprovementsMultiplyOptionsByTenant(button: HTMLButtonElement): void {
    if (!this._validateTerms()) {
      return;
    }

    if (!this._isAllStatusesSetWithDialogue([models.TermStatus.Ready, models.TermStatus.Rejected, models.TermStatus.Accepted])) {
      return;
    }

    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmSendOfferAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          button.disabled = true;

          return this._projectService
            .renewalProject(
              this.lease,
              models.RenewalProjectTriggerType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
              this.project,
            )
            .pipe(
              tap(() => {
                button.disabled = false;

                this.reviewTenantImprovementsMultiplyOptionsByTenantButtonVisible = false;
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  receiveAndAnalyzeResponseByLandlord(receiveAndAnalyzeResponseByLandlordButton: HTMLButtonElement) {
    if (this.startupInfo.role !== Role.Landlord) {
      return;
    }

    receiveAndAnalyzeResponseByLandlordButton.disabled = true;

    for (const leaseTermStatus of this.leaseTermStatuses) {
      if (leaseTermStatus.termStatus === models.TermStatus.Pending) {
        const alertReference = this._uiAlertService
          .pushWarningAlert({
            message: this._alertMessagesManager.getTermsStatusesNotSetAlertText(),
          });

        alertReference
          .hidden
          .pipe(
            tap(() => {
              receiveAndAnalyzeResponseByLandlordButton.disabled = false;
            }),
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe();

        return;
      }
    }

    if (!this.isAllStatusSet(models.TermStatus.Accepted)) {
      const alertReference = this._uiAlertService
        .pushConfirmAlert({
          message: this._alertMessagesManager.getConfirmSendOfferAlertText(),
        });

      alertReference
        .confirmed
        .pipe(
          switchMap(() => {
            return this._projectService
              .renewalProject(this.lease, models.RenewalProjectTriggerType.ReviewTenantImprovementsByLandlord, this.project)
              .pipe(
                tap(x => {
                  receiveAndAnalyzeResponseByLandlordButton.disabled = false;
                }),
              );
          }),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();

      return;
    }

    this.checkSignatureParty(() => {
      const alertReference = this._uiAlertService
        .pushConfirmAlert({
          message: this._alertMessagesManager.getConfirmSendOfferAlertText(),
        });

      alertReference
        .confirmed
        .pipe(
          switchMap(() => {
            return this._projectService
              .renewalProject(this.lease, models.RenewalProjectTriggerType.ReviewTenantImprovementsByLandlord, this.project)
              .pipe(
                tap(x => {
                  receiveAndAnalyzeResponseByLandlordButton.disabled = false;
                }),
              );
          }),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();
    });
  }

  sendTenantCounterUnsolicitedOffer(buttonElement: HTMLButtonElement) {
    buttonElement.disabled = true;
    for (const leaseTermStatus of this.leaseTermStatuses) {
      if (leaseTermStatus.termStatus === models.TermStatus.Pending) {
        this._uiAlertService.pushWarningAlert({
          message: this._alertMessagesManager.getTermsStatusesNotSetAlertText(),
        });

        buttonElement.disabled = false;
        return;
      }
    }

    if (this.startupInfo.role !== Role.Tenant) {
      buttonElement.disabled = false;
      return;
    }

    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmSendOfferAlertText(),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          return this._projectService
            .renewalProject(this.lease, models.RenewalProjectTriggerType.TenantCounterUnsolicitedOffer, this.project)
            .pipe(
              tap(() => {
                buttonElement.disabled = false;
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  getReceiveAndAnalyzeResponseButtonText(): string {
    if (!this.project) {
      return 'Send Offer';
    }

    const isSendLOI = (
      this.isAllStatusSet(models.TermStatus.Accepted) &&
      (
        this._projectAccessService
          .checkAccessToRenewalProject(
            models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord,
            this.project,
            this.lease,
          )
      )
    );

    if (this.project.pendingForInternalApproval) {
      if (isSendLOI) {
        return 'Approve & Send LOI';
      }

      return 'Approve & Send Offer';
    }

    if (isSendLOI) {
      return 'Send LOI';
    }

    return 'Send Offer';
  }

  getCounterOfferButtonText(): string {
    if (!this.project) {
      return 'Send Offer';
    }

    const hasAcceptedFinancialRequests = this._financialRequestManager
      .hasAcceptedFinancialRequests(this.financialRequests);

    const hasUnapprovedFinancialRequests = this._financialRequestManager
      .hasUnapprovedFinancialRequests(this.financialRequests);

    const isSendLOI = (
      this.isAllStatusSet(models.TermStatus.Accepted) &&
      (
        (
          this._projectAccessService
            .checkAccessToRenewalProject(
              models.RenewalProjectTemplateItemType.TenantCounterOffer,
              this.project,
              this.lease,
            ) &&
          !hasUnapprovedFinancialRequests &&
          (hasAcceptedFinancialRequests || this.leaseSignature?.hasLandlordSigner)
        ) ||
        this._projectAccessService
          .checkAccessToRenewalProject(
            models.RenewalProjectTemplateItemType.LandlordCounterOffer,
            this.project,
            this.lease,
          )
      )
    );

    if (this.project.pendingForInternalApproval) {
      if (isSendLOI) {
        return 'Approve & Send LOI';
      }

      return 'Approve & Send Offer';
    }

    if (isSendLOI) {
      return 'Send LOI';
    }

    return 'Send Offer';
  }

  getFinalizeTermsButtonText(): string {
    if (
      (this._authService.isLandlord() || this._isBroker(models.LeaseTeam.LandlordTeam)) &&
      !this.leaseSignature?.hasLandlordSigner &&
      !this.leaseSignature?.isExternalLandlordEmail
    ) {
      return 'Sign LOI';
    }

    return 'Finalize Terms';
  }

  counterOffer(button?: HTMLButtonElement) {
    if (!this.isAllStatusSet(models.TermStatus.Accepted)) {
      this.sendCounterOffer();
      return;
    }

    this.checkFinancialRequest(() => this.sendCounterOffer());
  }

  checkFinancialRequest(next?: () => any) {
    const leaseTeam = this._leaseManager
      .getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);

    if (leaseTeam === models.LeaseTeam.LandlordTeam) {
      const hasAcceptedFinancialRequests = this._financialRequestManager
        .hasAcceptedFinancialRequests(this.financialRequests);
      const hasUnapprovedFinancialRequests = this._financialRequestManager.hasUnapprovedFinancialRequests(this.financialRequests);
      if (hasUnapprovedFinancialRequests) {
        this._router
          .navigate(
            ['landlord', 'buildings', 'person-desktop', this.lease.id],
            {
              queryParams: {
                target: 'requests',
              },
            },
          );

        return;
      }

      if (!hasAcceptedFinancialRequests) {
        const alertReference = this._uiAlertService
          .pushConfirmAlert({
            message: this._alertMessagesManager.getConfirmProceedWithoutFinancialsAlertText(),
          });

        alertReference
          .confirmed
          .pipe(
            tap(() => this.checkSignatureParty(next)),
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe();

        alertReference
          .declined
          .pipe(
            tap(() => {
              this.requestFinancialDocuments();
            }),
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe();

        return alertReference;
      }
    }

    if (leaseTeam === models.LeaseTeam.TenantTeam) {
      const shouldUploadByTenantTeam = this._financialRequestManager
        .shouldUploadByTenantTeam(this.project, this.financialRequests);

      if (shouldUploadByTenantTeam && !this.leaseSignature?.hasLandlordSigner) {
        const alertReference = this._uiAlertService
          .pushInfoAlert({
            message: this._alertMessagesManager.getCheckLandlordFinancialRequestAlertText(this.lease.landlordCompany.name),
          });

        alertReference
          .hidden
          .pipe(
            tap(() => this.checkSignatureParty(next)),
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe();

        return alertReference;
      }
    }

    return this.checkSignatureParty(next);
  }

  sendCounterOffer() {
    const isSendLOI = (
      this.isAllStatusSet(models.TermStatus.Accepted) &&
      (
        this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.TenantCounterOffer ||
        this.project.projectState.renewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.LandlordCounterOffer
      )
    );

    const alertReference = this._uiAlertService
      .pushConfirmAlert({
        message: (
          isSendLOI ?
            this._alertMessagesManager.getConfirmSendLOIAlertText() :
            this._alertMessagesManager.getConfirmSendOfferAlertText()
        ),
      });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => this._projectService
          .renewalProject(this.lease, models.RenewalProjectTriggerType.CounterOffer, this.project),
        ),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    return alertReference;
  }

  checkSignatureParty(next?: () => any): AlertNotificationReference {
    if (
      (
        (this._authService.isLandlord() && this.leaseSignature?.hasLandlordSigner) ||
        (this._authService.isTenant() && this.leaseSignature?.hasTenantSigner)
      ) &&
      next
    ) {
      return next();
    }

    const alertReference = this._uiAlertService
      .pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmUserIsDocumentSignerAlertText(this.startupInfo.displayName),
      });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => this._leaseService
          .setLeaseDocumentSigner(this.lease.id, <models.IWhoSignLeaseSignatureViewModel>{
            leaseId: this.lease.id,
            whoSignLeaseSignature: models.WhoSignLeaseSignature.CurrentUser,
          }),
        ),
        switchMap(() => this._leaseService
          .getLeaseSignature(this.lease.id)
        ),
        tap(() => {
          if (next) {
            next();
          }
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe(leaseSignature => {
        this.leaseSignature = leaseSignature;
      });

    alertReference
      .declined
      .pipe(
        tap(() => {
          const whoSignLeaseDocument$ = new Subject<models.IWhoSignLeaseSignatureViewModel>();

          whoSignLeaseDocument$
            .pipe(
              switchMap(whoSignLeaseDocument => {
                if (!whoSignLeaseDocument) {
                  this.checkSignatureParty(next);
                  return of(null);
                }

                const model = <models.IWhoSignLeaseSignatureViewModel>{
                  ...whoSignLeaseDocument,
                  leaseId: this.lease.id,
                  whoSignLeaseSignature: models.WhoSignLeaseSignature.ExternalUser,
                };

                return this._leaseService
                  .setLeaseDocumentSigner(this.lease.id, model)
                  .pipe(
                    tap(() => {
                      if (next) {
                        next();
                      }
                    }),
                  );
              }),
              switchMap(() => this._leaseService
                .getLeaseSignature(this.lease.id)
              ),
              take(1),
              takeUntil(this._destroy$),
            )
            .subscribe(leaseSignature => {
              this.leaseSignature = leaseSignature;
            });

          this._dialogService
            .show(InviteExternalSignerToSignaturesDialogComponent, {
              showCloseButton: false,
              closeOnOutsideClick: false,
              width: 700,
              height: 'auto',
              maxWidth: 700,
              injectableData: {
                lease: this.lease,
                addSigner$: whoSignLeaseDocument$,
                project: this.project,
              },
            });
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    return alertReference;
  }

  showFloorPlan() {
    const dialogServiceRef = this._dialogService.show(FloorPlanComponent, {
      width: '95%',
      height: '95%',
      showCloseButton: false,
      closeOnOutsideClick: false,
      dragEnabled: false,
      containerClassNames: ['floor-plan-modal'],
      injectableData: {
        lease: this.lease,
        project: this.project,
        floorPlanDemoComponent: this.floorplanDemoTenant,
        floorPlanType: models.FloorPlanType.LeaseFloorPlan,
        building: this.lease.building
      },
    });

    dialogServiceRef
      .onHidden
      .pipe(
        take(1),
        tap(() => this.reInitializePage()),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  accordionOptionChanged(event): void {
    if (this.accordionIndex === -1) {
      this.currentLeaseTermConfiguration = null;
    } else if (this.accordionIndex != null) {
      this.currentLeaseTermConfiguration = this.termConfigurations[this.accordionIndex];
      this._scrollToCurrentAccordionElement();
      this._replaceLocationState();
    }

    if (event && event.addedItems && event.addedItems.length) {
      for (let i = 0, num = event.addedItems.length; i < num; i++) {
        const openedAccordionItem = event.addedItems[i];

        if (openedAccordionItem.termName && this.accordionOpened$.hasOwnProperty(openedAccordionItem.termName)) {
          this.accordionOpened$[openedAccordionItem.termName].next();
        }
      }
    }
  }

  private _scrollToCurrentAccordionElement() {
    const scrollTo = () => {
      this._ngZone.runOutsideAngular(() => {
        setTimeout(
          () => {
            if (!this.leaseTermsElement || !this.leaseTermsElement.nativeElement) {
              return;
            }

            const leaseTermsContainer = this.leaseTermsElement.nativeElement;

            const openedLeaseTerm = leaseTermsContainer.querySelector('.dx-accordion-item-opened');
            if (!openedLeaseTerm) {
              return;
            }

            const currScrollTop = openedLeaseTerm.offsetTop;
            const prevScrollTop = leaseTermsContainer.scrollTop;

            if (currScrollTop !== prevScrollTop) {
              leaseTermsContainer.scrollTop = currScrollTop;

              if (this.colaboDemoTerms) {
                this.colaboDemoTerms.onResize();
              }
            }
            this._detectChanges();
          },
          this.accordionAnimationDurationMs,
        );
      });
    };

    scrollTo();
  }

  isSendOffer(): boolean {
    return (
      this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp &&
      this.startupInfo.role === Role.Tenant
    );
  }

  isUnsolicitedOfferByLandlord(): boolean {
    return (
      this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord &&
      this.startupInfo.role === Role.Landlord
    );
  }

  isUnsolicitedOfferByLandlordBroker(): boolean {
    return (
      this._isAllStatusesSetWithDialogue([models.TermStatus.Ready, models.TermStatus.Rejected, models.TermStatus.Accepted]) &&
      (this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord ||
        this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOffer) &&
      (this.startupInfo.role === Role.Broker || this.startupInfo.role === Role.CoBroker) &&
      this.lease.leaseUsers.some(u => u.userId === this.startupInfo.id && u.relatedToCompanyId === this.lease.landlordCompany.id)
    );
  }

  isRenewCounterUnsolicitedOfferByTenant(): boolean {
    return this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer &&
           this.startupInfo.role === Role.Tenant;
  }

  isUnsolicitedOfferByTenant(): boolean {
    return (
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.UnsolicitedOfferByTenant,
        this.project,
        this.lease
      )
    );
  }

  isReviewTenantImprovementsByLandlord(): boolean {
    return (
      this._currentRenewalProjectTemplateItemType ===
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord &&
      this.startupInfo.role === Role.Landlord
    );
  }

  isReviewTenantImprovementsMultiplyOptionsByLandlord(): boolean {
    return (
      this._currentRenewalProjectTemplateItemType ===
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord &&
      this.startupInfo.role === Role.Landlord
    );
  }

  isReviewTenantImprovementsMultiplyOptionsByLandlordBroker(): boolean {
    return (
      this._isAllStatusesSetWithDialogue([models.TermStatus.Ready, models.TermStatus.Rejected, models.TermStatus.Accepted]) &&
      this._currentRenewalProjectTemplateItemType ===
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord &&
      (this.startupInfo.role === Role.Broker || this.startupInfo.role === Role.CoBroker) &&
      this.lease.leaseUsers.some(u => u.userId === this.startupInfo.id && u.relatedToCompanyId === this.lease.landlordCompany.id)
    );
  }

  isReviewTenantImprovementsMultiplyOptionsByTenantBroker(): boolean {
    return (
      this._isAllStatusesSetWithDialogue([models.TermStatus.Ready, models.TermStatus.Rejected, models.TermStatus.Accepted]) &&
      this._currentRenewalProjectTemplateItemType ===
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant &&
      (this.startupInfo.role === Role.Broker || this.startupInfo.role === Role.CoBroker) &&
      this.lease.leaseUsers.some(u => u.userId === this.startupInfo.id && u.relatedToCompanyId === this.lease.landlordCompany.id)
    );
  }

  isReviewTenantImprovementsMultiplyOptionsByTenant(): boolean {
    return (
      this._currentRenewalProjectTemplateItemType ===
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant &&
      this.startupInfo.role === Role.Tenant
    );
  }

  isChooseOptionStageByBroker(): boolean {
    return (
      this.lease &&
      this.startupInfo &&
      this._isChooseOptionStage() &&
      (this.startupInfo.role === Role.Broker || this.startupInfo.role === Role.CoBroker) &&
      this.lease.leaseUsers.some(x =>
        x.userId === this.startupInfo.id &&
        x.relatedToCompanyId === this.lease.tenantCompanyId
      )
    );
  }

  isChooseOptionStageByTenant(): boolean {
    return (
      this.lease &&
      this.startupInfo &&
      this._isChooseOptionStage() &&
      this.startupInfo.role === Role.Tenant
    );
  }

  private _isChooseOptionStage(): boolean {
    const { ReviewTenantImprovementsSelectMultiplyOptionsTenant } = models.RenewalProjectTemplateItemType;

    const allowedStatuses = [
      models.TermStatus.Accepted,
      models.TermStatus.Rejected,
      models.TermStatus.Ready
    ];

    return (
      this._currentRenewalProjectTemplateItemType === ReviewTenantImprovementsSelectMultiplyOptionsTenant &&
      this._isAllStatusesSetWithDialogue(allowedStatuses)
    );
  }

  chooseOption(button: HTMLButtonElement): void {
    const alertReference = this._uiAlertService
      .pushConfirmAlert({
        message: this._alertMessagesManager.getConfirmContinueAlertText(),
      });

    alertReference
      .confirmed
      .pipe(
        tap(() => button.disabled = true),
        switchMap(() => this._projectService
          .renewalProject(
            this.lease,
            models.RenewalProjectTriggerType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
            this.project
          ),
        ),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe()
      .add(() => {
        button.disabled = false;
      });
  }

  isVisibleSendOfferForReceiveAndAnalyzeResponseForLandlord(): boolean {
    if (
      this.leaseTermStatuses.filter(x => (
        x.termStatus !== models.TermStatus.Accepted &&
        x.termStatus !== models.TermStatus.Rejected &&
        x.termStatus !== models.TermStatus.Ready
      )).length
    ) {
      return false;
    }

    return this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord,
      this.project,
      this.lease
    );
  }

  isVisibleContinueForReviewTenantImprovementsSelectMultiplyOptionsTenant(): boolean {
    if (
      this.leaseTermStatuses.filter(x => (
        x.termStatus !== models.TermStatus.Accepted &&
        x.termStatus !== models.TermStatus.Rejected &&
        x.termStatus !== models.TermStatus.Ready
      )).length
    ) {
      return false;
    }

    return this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
      this.project,
      this.lease
    );
  }

  isVisibleTenantCounterUnsolicitedOffer(): boolean {
    if (
      this.leaseTermStatuses.filter(x => (
        x.termStatus !== models.TermStatus.Accepted &&
        x.termStatus !== models.TermStatus.Rejected &&
        x.termStatus !== models.TermStatus.Ready
      )).length
    ) {
      return false;
    }
    return this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
      this.project,
      this.lease
    );
  }

  isVisibleProposalNegotiation(): boolean {
    if (
      this.leaseTermStatuses.some(x =>
        x.termStatus !== models.TermStatus.Accepted &&
        x.termStatus !== models.TermStatus.Rejected &&
        x.termStatus !== models.TermStatus.Ready
      )
    ) {
      return false;
    }

    return this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.TenantCounterOffer,
      this.project,
      this.lease
    ) ||  this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.LandlordCounterOffer,
      this.project,
      this.lease
    );
  }

  reInitializePage() {
    this._initializePage(this._termRouteName, this.lease.id);
  }

  isSingleRejected() {
    if (!this.leaseTermStatuses || this.leaseTermStatuses.length !== this.termConfigurations.length) {
      return false;
    }

    const acceptedCount = this.leaseTermStatuses.filter(s => s.termStatus === models.TermStatus.Accepted).length;
    const rejectedCount = this.leaseTermStatuses.filter(s => s.termStatus === models.TermStatus.Rejected).length;

    return (rejectedCount === 1 && acceptedCount === this.leaseTermStatuses.length - 1);
  }

  isAllStatusSet(termStatus: models.TermStatus) {
    return this._isAllStatusSetWithDialogue(termStatus, false);
  }

  private _createEventsAfterSavingLeaseTermStatus() {
    if (this.isSendOffer()) {
      this._setEventsForFinishedSendRfpOffer();
    }

    if (this.canSendOfferForApproval()) {
      this._setEventsForSendOfferForApproval();
    }

    if (this.isUnsolicitedOfferByLandlord()) {
      this._setEventsForFinishedUnsolicitedOffer();
    }

    const isStage3 = this._projectAccessService.checkAccessToRenewalProject(
      models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord,
      this.project,
      this.lease
    );

    if (isStage3) {
      this._setEventsForSendOfferOn3Stage();
    }

    if (this.isVisibleContinueForReviewTenantImprovementsSelectMultiplyOptionsTenant()) {
      this._setEventsForContinueOn3Stage();
    }

    if (this.isVisibleProposalNegotiation()
      && (this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.TenantCounterOffer
        || this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.LandlordCounterOffer)) {
      this._setEventsForSendOfferOn5Stage();
    }

    if (!isStage3 && (this.isReviewTenantImprovementsByLandlord() || this.isReviewTenantImprovementsMultiplyOptionsByLandlord())) {
      this._setEventsForFinishReviewTenantImprovementsMultiplyOptionsByLandlord();
    }

    if (this.isReviewTenantImprovementsMultiplyOptionsByTenant()) {
      this._setEventsForFinishReviewTenantImprovementsMultiplyOptionsByTenant();
    }

    if (this.isRenewCounterUnsolicitedOfferByTenant()) {
      this._setEventsForFinishedCounterUnsolicitedOfferByTenant();
    }
  }

  private _validateTerms(): boolean {
    for (const termSetting of this.termConfigurations) {
      if (!termSetting.isRequired) {
        continue;
      }

      if (
        termSetting.leaseTermType === models.LeaseTermType.TenantSquareFootage &&
        this.lease.tenantSquareFootageTerm.tenantSquareFootageTermType === models.TenantSquareFootageTermType.PhaseIn
      ) {
        return 0 < this.lease.tenantSquareFootageTerm.tenantSquareFootagePhaseInValues.length;
      }

      const term = this._termManager.getLeaseTerm(this.lease, termSetting.leaseTermType);
      if (!term || term.termStatus === models.TermStatus.Ready) {
        continue;
      }

      const termValue = this._termManager.getCurrentTermValue(term);
      if (!termValue) {
        this._uiAlertService.pushWarningAlert({
          message: this._alertMessagesManager.getTermValueNotSetAlertText(termSetting.termName),
        });

        this.setIndexInAccordion(this.termConfigurations, termSetting.termName);
        return false;
      }
    }

    return true;
  }

  private _isAllStatusSetWithDialogue(termStatus: models.TermStatus, showWarningDialog?: boolean): boolean {
    if (!this.leaseTermStatuses) {
      return false;
    }

    for (const leaseTermStatus of this.leaseTermStatuses) {
      if (leaseTermStatus.termStatus !== termStatus) {
        if (showWarningDialog) {
          this._uiAlertService.pushWarningAlert({
            message: this._alertMessagesManager.getNeedToSetAllTermStatusesAlertText(models.TermStatus[termStatus]),
          });
        }
        return false;
      }
    }

    return true;
  }

  private _isAllStatusesSetWithDialogue(termStatuses: Array<models.TermStatus>): boolean {
    if (!this.leaseTermStatuses || this.leaseTermStatuses.length !== this.termConfigurations.length) {
      return false;
    }

    for (const leaseTermStatus of this.leaseTermStatuses) {
      if (!termStatuses.find(x => x === leaseTermStatus.termStatus)) {
        return false;
      }
    }

    return true;
  }

  setIndexInAccordion(leaseTermConfigurations: Array<models.ILeaseTermConfiguration>, termName: string) {
    for (let i = 0; i < leaseTermConfigurations.length; i++) {
      const item = leaseTermConfigurations[i];
      if (item.termName === termName) {
        this.accordionIndex = item.sortOrder - 1;
        this.currentLeaseTermConfiguration = item;
        return;
      }
    }
  }

  showLeaseSettingsButton() {
    const leaseTeam = this._leaseManager.getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);
    return (
      leaseTeam === models.LeaseTeam.LandlordTeam &&
      (
        this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOfferByLandlord ||
        this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord ||
        this._currentRenewalProjectTemplateItemType ===
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord
      )
    );
  }

  openLeaseSettings() {
    const dialogRef = this._dialogService.show(LeaseSettingsComponent, {
      width: 650,
      height: 300,
      injectableData: {
        lease: this.lease
      },
    });
    dialogRef
      .onHiding
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(x => {
        this._initializePage(this._termRouteName, this.lease.id, false);
      });
  }

  private _initializePage(termName: string, leaseId: number, afterChangedStage: boolean = true) {
    this.planButtonVisible = false;
    this.planPopupVisible = false;
    this.leaseExecutePopupVisible = false;

    this.leaseDocumentsComponentVisible = false;
    this.letterOfIntentPopupVisible = false;
    this.signingLeaseAmendmentPopupVisible = false;
    this.isDataLoaded = false;
    this.project = null;
    this.lease = null;
    this._currentProjectState = null;
    this._currentRenewalProjectTemplateItemType = null;
    this._termRouteName = termName;

    this._termCustomValueTemplateManager
      .getList()
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe();

    const getProjects = this._projectService.getProjectsByLeaseId(leaseId);
    const getLeaseHistory = this._termHistoryManager.getLeaseHistory(leaseId);
    const startupInfo = this._authService.startupInfo;

    forkJoin([getProjects, getLeaseHistory])
      .pipe(
        tap(([projects, leaseHistory]) => {
          this.startupInfo = startupInfo;
          this.projects = projects;
          this.project = this._projectService.getNegotiationProject(this.projects);
          this.lease = leaseHistory.lease;
          this.leaseHistoryRecord = leaseHistory;
        }),
        switchMap(() => forkJoin([
          this._financialRequestManager.getFinancialRequests(this.lease.id),
          this._leaseService.getLeaseSignature(this.lease.id)
        ])),
        tap(([financialRequests, leaseSignature]) => {
          this.financialRequests = financialRequests;
          this.leaseSignature = leaseSignature;

          const floorPlanFeatureEnabled = this._featureTogglingManager.getCompanyFeatureToggleSync(Feature.FloorPlanFeature);
          if (floorPlanFeatureEnabled) {
            this._floorPlanManager.getLeaseFloorPlans(leaseId)
              .pipe(
                takeUntil(this._destroy$),
              )
              .subscribe(floorPlans => this.floorPlans = floorPlans);
          }

          this._setTermConfigurations();

          if (this.project && this.project.projectState) {
            this._currentRenewalProjectTemplateItemType = this.project.projectState.renewalProjectTemplateItemType;
            this._currentProjectState = this.project.projectState;
            if (
              this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.UnsolicitedOfferRenewalWizard &&
              this.startupInfo.role === Role.Tenant
            ) {
              const urlTree = this._router
                .createUrlTree(
                  [
                    'v2',
                    'wizard',
                    this.lease.id
                  ],
                  {},
                );

              const url = this._router.serializeUrl(urlTree);

              window.location.href = url;

              return;
            }
          }

          this._setTermStatuses(this.termConfigurations, this.lease);

          if (this._termRouteName) {
            if (this.isDemo) {
              this._termRouteName = 'RentalRate';
            }
            this.setIndexInAccordion(this.termConfigurations, this._termRouteName);
          } else {
            if (this.termConfigurations && this.termConfigurations.length > 0) {
              const termStatusIdx = this.leaseTermStatuses.findIndex(termStatus =>
                termStatus.termStatus === models.TermStatus.Pending || termStatus.termStatus === models.TermStatus.Draft);
              const nextTermName = this.termConfigurations[termStatusIdx > 0 ? termStatusIdx : 0].termName;
              this.setIndexInAccordion(this.termConfigurations, nextTermName);
            }
          }

          this._alertService
            .getLastStateChangedAlertsByLeaseId(leaseId)
            .pipe(
              tap(alerts => {
                if (this.colaboDemoTerms) {
                  if (this.colaboDemoTerms.isShowDemo()) {
                    this.notShownAlerts = alerts;
                  } else {
                    this.showAlerts(alerts, afterChangedStage);
                  }
                }
              }),
              takeUntil(this._destroy$),
            )
            .subscribe();

          this._checkFinancialRequests(this.lease, this.project);
          this._checkTourRequests();
          this._setCurrentStateOfPage();

          this.isDataLoaded = true;
          this.accordionOptionChanged(null);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _setEventsForFinishedSendRfpOffer() {
    if (this.isAllStatusSet(models.TermStatus.Ready)) {
      this.sendOfferButtonVisible = true;
      const message = this._getSendOfferMessage(this.lease.landlordCompany.name);
      this._uiAlertService.pushSuccessAlert({
        message: message,
      });
    } else {
      this.sendOfferButtonVisible = false;
    }
  }

  private _setEventsForFinishedCounterUnsolicitedOfferByTenant() {
    if (this._isAllStatusesSetWithDialogue([models.TermStatus.Ready, models.TermStatus.Rejected, models.TermStatus.Accepted])) {
      this.sendOfferButtonVisible = true;
      const message = this._getSendOfferMessage(this.lease.landlordCompany.name);
      this._uiAlertService.pushSuccessAlert({
        message: message,
      });
    } else {
      this.sendOfferButtonVisible = false;
    }
  }

  private _setEventsForSendOfferForApproval() {
    if (this.isAllStatusSet(models.TermStatus.Ready) ||
      this.isUnsolicitedOfferByLandlordBroker() ||
      this.isVisibleTenantCounterUnsolicitedOffer() ||
      this.isReviewTenantImprovementsMultiplyOptionsByLandlordBroker() ||
      this.isReviewTenantImprovementsMultiplyOptionsByTenantBroker() ||
      this.isVisibleSendOfferForReceiveAndAnalyzeResponseForLandlord() ||
      this.isVisibleProposalNegotiation()) {
      this.sendOfferForApprovalButtonVisible = true;
    } else {
      this.sendOfferForApprovalButtonVisible = false;
    }
  }

  private _setEventsForFinishedUnsolicitedOffer() {
    if (this.isAllStatusSet(models.TermStatus.Ready)) {
      this.sendOfferButtonVisible = true;
      const message = this._getSendOfferMessage(this.lease.tenantCompany.name);
      this._uiAlertService.pushSuccessAlert({
        message: message,
      });
    } else {
      this.sendOfferButtonVisible = false;
    }
  }

  private _setEventsForFinishReviewTenantImprovementsMultiplyOptionsByLandlord() {
    if (this._isAllStatusesSetWithDialogue([models.TermStatus.Ready, models.TermStatus.Rejected, models.TermStatus.Accepted])) {
      this.reviewTenantImprovementsMultiplyOptionsByLandlordButtonVisible = true;
      const message = this._getSendOfferMessage(this.lease.tenantCompany.name);
      this._uiAlertService.pushSuccessAlert({
        message: message,
      });
    } else {
      this.reviewTenantImprovementsMultiplyOptionsByLandlordButtonVisible = false;
    }
  }

  private _setEventsForFinishReviewTenantImprovementsMultiplyOptionsByTenant() {
    if (this._isAllStatusesSetWithDialogue([models.TermStatus.Ready, models.TermStatus.Rejected, models.TermStatus.Accepted])) {
      const {ReviewTenantImprovementsSelectMultiplyOptionsTenant} = models.RenewalProjectTemplateItemType;

      if (
        this.project &&
        this.project.projectState &&
        this.project.projectState.renewalProjectTemplateItemType === ReviewTenantImprovementsSelectMultiplyOptionsTenant
      ) {
        return;
      }

      this.reviewTenantImprovementsMultiplyOptionsByTenantButtonVisible = true;
      const message = this._getSendOfferMessage(this.lease.landlordCompany.name);
      this._uiAlertService.pushSuccessAlert({
        message: message,
      });
    } else {
      this.reviewTenantImprovementsMultiplyOptionsByTenantButtonVisible = false;
    }
  }

  private _setEventsForSendOfferOn3Stage() {
    if (this.isAllStatusSet(models.TermStatus.Accepted)) {
      const otherCompanyName = this.startupInfo.role === Role.Tenant
        ? this.lease.landlordCompany.name
        : this.lease.tenantCompany.name;

      this._pushSendOfferAlertToGo5Stage(otherCompanyName);
    }
  }

  private _setEventsForContinueOn3Stage(): void {
    if (
      this.leaseTermStatuses.some(x => (
        x.termStatus !== models.TermStatus.Accepted &&
        x.termStatus !== models.TermStatus.Rejected &&
        x.termStatus !== models.TermStatus.Ready
      ))
    ) {
      return;
    }

    this._uiAlertService.pushSuccessAlert({
      message: this._alertMessagesManager.getReadyToContinueAlertText(),
    });
  }

  private _setEventsForSendOfferOn5Stage() {
    const otherCompanyName = this.startupInfo.role === Role.Tenant
      ? this.lease.landlordCompany.name
      : this.lease.tenantCompany.name;

    if (this.isSingleRejected()) {
      this._uiAlertService.pushSuccessAlert({
        message: this._getSendOfferMessageWithSingleRejectedTerm(otherCompanyName),
      });
    }
  }

  private _replaceLocationState(termName: string = null): void {
    let leaseId: number;
    if (this.lease) {
      leaseId = this.lease.id;
    } else {
      leaseId = CommonTools.searchToObject(location.search)['leaseId'];
    }

    if (!termName && this.currentLeaseTermConfiguration) {
      termName = this.currentLeaseTermConfiguration.termName;
    }

    this._location.replaceState(
      this._router
        .createUrlTree(
          [this._location.path().split('?')[0]], // Get uri
          {
            queryParams: {
              leaseId: leaseId,
              termName: termName,
            },
          }, // Pass all parameters inside queryParamsObj
        )
        .toString(),
    );
  }

  onLOIPreview() {
    this.letterOfIntentPopupVisible = true;
  }

  private _setCurrentStateOfPage() {
    if (
      this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.LetterOfIntent &&
      this.startupInfo.role === Role.Tenant
    ) {
      this.letterOfIntentPopupVisible = true;
    }

    if (
      this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.FinalizeBusinessTermsByTenant ||
      this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.FinalizeBusinessTermsByLandlord
    ) {
      if (this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.FinalizeBusinessTermsByTenant) {
        this.signingLeaseAmendmentPopupVisible = true;
      }

      if (
        this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.FinalizeBusinessTermsByLandlord &&
        this.leaseSignature?.hasLandlordSigner
      ) {
        this.signingLeaseAmendmentPopupVisible = true;
      }
    }

    if (this.IsLeaseDocumentsAvailable()) {
      this.showLeaseDocuments();
    }

    if (this.IsLeaseExecutionAvailable()) {
      this.showLeaseExecution();
    }

    if (this.isSendOffer()) {
      this._setEventsForFinishedSendRfpOffer();
    }

    if (this.isUnsolicitedOfferByLandlord()) {
      this._setEventsForFinishedUnsolicitedOffer();
    }

    if (this.isVisibleSendOfferForReceiveAndAnalyzeResponseForLandlord()) {
      this._setEventsForSendOfferOn3Stage();
    }

    if (this.isVisibleContinueForReviewTenantImprovementsSelectMultiplyOptionsTenant()) {
      this._setEventsForContinueOn3Stage();
    }
  }

  private _setTermStatuses(leaseTermConfigurations: Array<models.ILeaseTermConfiguration>, lease: models.ILeaseViewModel) {
    this.leaseTermStatuses = [];
    const termStatuses: Array<TermStatusDisplay> = [];
    leaseTermConfigurations.forEach(leaseTermConfiguration => {
      const status = this._termManager.getStatusByLeaseTermType(leaseTermConfiguration.leaseTermType, lease);
      const leaseTermStatus: TermStatusDisplay = new TermStatusDisplay(status, leaseTermConfiguration);

      if (leaseTermStatus) {
        termStatuses.push(leaseTermStatus);
      } else {
        termStatuses.push(new TermStatusDisplay(models.TermStatus.Draft, leaseTermConfiguration));
      }
    });

    this.leaseTermStatuses = termStatuses;

    this._createEventsAfterSavingLeaseTermStatus();
  }

  openComments() {
    if (AppService.isMobile) {
      this.tabPanel.selectedIndex = 1;
    }
  }

  closeComments() {
    if (AppService.isMobile) {
      this.tabPanel.selectedIndex = 0;
    }
  }

  onStageChanged(termName: string = null) {
    if (termName) {
      this._initializePage(termName, this.lease.id);
    } else {
      this._initializePage(this._termRouteName, this.lease.id);
    }
  }

  onLeaseTermSaved(leaseTermEventModel: LeaseTermEventModel) {
    if (!leaseTermEventModel || !leaseTermEventModel.leaseTermViewModel) {
      return throwError('You should fill the term');
    }
    this.disabledAccordion = true;
    const subscription = this._termManager
      .saveLeaseTerm(leaseTermEventModel.leaseTermViewModel, this.lease.id)
      .pipe(
        switchMap(() => this._termHistoryManager
          .getLeaseHistory(this.lease.id),
        ),
        catchError((e) => {
          this.disabledAccordion = false;
          return throwError(e);
        }),
      )
      .subscribe(leaseHistory => {
        this.disabledAccordion = false;
        this.lease = leaseHistory.lease;
        this.leaseHistoryRecord = leaseHistory;
        this._setTermStatuses(this.termConfigurations, this.lease);
        this._detectChanges();

        if (leaseTermEventModel && leaseTermEventModel.leaseTermViewModel && leaseTermEventModel.exitEditModeAfterSaving) {
          Object
            .keys(this.rejectButtonClicked)
            .forEach(x => {
              this.rejectButtonClicked[x] = false;
            });
        }

        if (leaseTermEventModel.isExpandNextTerm) {
          this.expandNextTerm();
        }
        if (leaseTermEventModel.onEventFinished) {
          leaseTermEventModel.onEventFinished();
        }

        const termConf = this.termConfigurations
          .find(x => x.leaseTermType === leaseTermEventModel.leaseTermViewModel.leaseTermType);

        if (leaseTermEventModel && leaseTermEventModel.exitEditModeAfterSaving) {
          this.onTermChangeCancelled(termConf);
        }

        subscription.unsubscribe();
      },
      error => {
        if (!leaseTermEventModel || !leaseTermEventModel.leaseTermViewModel) {
          return;
        }

        const termConf = this.termConfigurations
          .find(x => x.leaseTermType === leaseTermEventModel.leaseTermViewModel.leaseTermType);

        if (!termConf || !this.errorOccured$.hasOwnProperty(termConf.termName)) {
          return;
        }

        this.errorOccured$[termConf.termName].next(error);

        if (leaseTermEventModel.onEventError) {
          leaseTermEventModel.onEventError();
        }
        subscription.unsubscribe();
      },
      () => {
        this.disabledAccordion = false;
        subscription.unsubscribe();
      });
  }

  onSetPreviousLeaseTermValue(value: models.IPreviousLeaseTermViewModel) {
    this._termManager
      .setPreviousLeaseTermValue(value)
      .pipe(
        switchMap(() => this._termHistoryManager.getLeaseHistory(this.lease.id)),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe(leaseHistory => {
        if (
          this.lease.term.hasMultiplyOptions &&
          value.leaseTermConfiguration.leaseTermType === models.LeaseTermType.Term &&
          (
            this._projectAccessService.checkAccessToRenewalProject(
              models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
              this.project,
              this.lease,
            ) ||
            this._projectAccessService.checkAccessToRenewalProject(
              models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
              this.project,
              this.lease,
            )
          )
        ) {
          this._initializePage(this._termRouteName, this.lease.id, false);
          return;
        }

        if (
          (
            this._projectAccessService.checkAccessToRenewalProject(
              models.RenewalProjectTemplateItemType.SendRfp,
              this.project,
              this.lease,
            ) ||
            this._projectAccessService.checkAccessToRenewalProject(
              models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsByLandlord,
              this.project,
              this.lease,
            )
          ) &&
          value.leaseTermConfiguration.leaseTermType === models.LeaseTermType.TenantSquareFootage
        ) {
          this.lease = leaseHistory.lease;
        } else {
          this.lease = this._termManager.fillInitialMultipleOptions(leaseHistory.lease);
        }

        this.leaseHistoryRecord = leaseHistory;

        this._setTermStatuses(this.termConfigurations, this.lease);
        this._detectChanges();

        this.rejectButtonClicked[this.currentLeaseTermConfiguration.termName] = false;
      });
  }

  getTermStatus(leaseTermType: models.LeaseTermType): models.TermStatus {
    return this._termManager.getStatusByLeaseTermType(leaseTermType, this.lease);
  }

  onItemHeaderInitialized(itemHeader: TermHeaderComponent) {
    if (this.itemHeaders.indexOf(itemHeader) < 0) {
      this.itemHeaders.push(itemHeader);
    }

    if (this.itemHeaders.length === this.termConfigurations.length) {
      if (this.colaboDemoTerms) {
        this.colaboDemoTerms.show(this);
      }
    }
  }

  IsVisibleTourButton(): boolean {
    return (
      this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDeal &&
      (this.startupInfo.role === Role.Tenant || this.startupInfo.role === Role.Landlord)
    );
  }

  IsDisabledTourButton(): boolean {
    if (this.tour?.status === models.TourStatus.Scheduled) {
      return false;
    }

    if (!this.tour) {
      // Only tenant could initiate a tour at the moment
      return this.startupInfo.role !== Role.Tenant;
    }

    return false;
  }

  IsVisibleShowLeaseDocumentsButton(): boolean {
    return this.IsLeaseDocumentsAvailable();
  }

  isVisibleFinalizeButton(): boolean {
    return (
      this.leaseSignature &&
      (
        this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.FinalizeBusinessTermsByLandlord ||
        this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.FinalizeBusinessTermsByTenant
      )
    );
  }

  showFinalizeBusinessTerms() {
    if (
      (this._authService.isLandlord() || this._isBroker(models.LeaseTeam.LandlordTeam)) &&
      !this.leaseSignature?.hasLandlordSigner &&
      !this.leaseSignature?.isExternalLandlordEmail
    ) {
      this.checkSignatureParty();
      return;
    }

    this.signingLeaseAmendmentPopupVisible = true;
  }

  onLeaseDocumentClosed($event) {
    this.leaseDocumentsComponentVisible = false;
  }

  IsLeaseDocumentsAvailable(): boolean {
    return this._termsPageService.isShowLeaseDocuments(this.project, this.lease);
  }

  tourButtonClick() {
    const leaseTeam = this._leaseManager
      .getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);

    const tourSubject = new Subject<models.ITourViewModel>();
    tourSubject
      .pipe(
        tap((tour) => this.tour = tour),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    if (this.tour?.status === models.TourStatus.Scheduled) {
      this._dialogService.show(TourScheduledInfoComponent, {
        width: 330,
        injectableData: {
          tenantCompany: this.lease.tenantCompany,
          landlordCompany: this.lease.landlordCompany,
          buildingUnit: this.lease.buildingUnit,
          building: this.lease.building,
          tour: this.tour,
          tour$: tourSubject,
          leaseTeam: leaseTeam,
          projectId: this.project.id,
        },
      });

      return;
    }
    const counterPartUser = leaseTeam === models.LeaseTeam.TenantTeam
      ? this.leaseTeam.landlordTeam[0]?.user
      : this.leaseTeam.tenantTeam[0]?.user;
    const counterPartCompany = counterPartUser.company;

    const openTourScheduleDialog = () => {
      this._dialogService.show(TourScheduleDialogComponent, {
        width: 1240,
        height: 661,
        injectableData: {
          tenantCompany: this.lease.tenantCompany,
          landlordCompany: this.lease.landlordCompany,
          buildingUnit: this.lease.buildingUnit,
          building: this.lease.building,
          tour: this.tour,
          tour$: tourSubject,
          leaseTeam: leaseTeam,
          projectId: this.project.id,
          counterPartUser: counterPartUser,
          counterPartCompany: counterPartCompany,
        },
      });
    };

    if (this.tour?.status === models.TourStatus.WaitingForApproval) {
      const canEditTourRequest =
        leaseTeam === models.LeaseTeam.LandlordTeam && this.tour.isApprovedByLandlord ||
        leaseTeam === models.LeaseTeam.TenantTeam && this.tour.isApprovedByTenant;

      if (canEditTourRequest) {
        const alertReference = this._uiAlertService.pushConfirmAlert({
          message: this._alertMessagesManager.getConfirmTourAlternateDateText(),
        });

        alertReference
          .confirmed
          .pipe(
            tap(() => {
              openTourScheduleDialog();
            }),
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe();

        return;
      }
    }

    openTourScheduleDialog();
  }

  showLeaseDocuments() {
    if (!this.leaseDocumentsComponentVisible) {
      this.leaseDocumentsComponentVisible = true;
    } else {
      // cannot trigger onLeaseExecutionClosed from dx-file-uploader when User closes File Dialog
      // therefore call initialize method
      this.leaseDocumentsComponent.initialize();
    }
  }

  IsLeaseExecutionAvailable(): boolean {
    return (
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.LeaseAmendmentSignatureByTenant,
        this.project,
        this.lease
      ) ||
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.LeaseAmendmentSignatureByLandlord,
        this.project,
        this.lease
      )
    );
  }

  showLeaseExecution() {
    this.leaseExecutePopupVisible = true;
  }

  onLeaseExecutionClosed($event) {
    this.leaseExecutePopupVisible = false;
  }

  onShowCertificateOfInsuranceClick() {
    const project = this._projectService.getCertificateOfInsuranceProject(this.projects);
    if (
      this._projectAccessService.checkAccessToCertificateOfInsurance(
        models.InsuranceCertificateProjectTemplateItemType.LandlordFirstStep,
        project,
        this.lease
      )
    ) {
      this.executeInsuranceOfCertificateConfirmationMessage();
    } else {
      this._createCoiDialog();
    }
  }

  showCertificateOfInsurance() {
    this._createCoiDialog();
  }

  private _createCoiDialog() {
    let isNewDealProject = false;
    if (this.projects && this.projects.length) {
      isNewDealProject = (
        this.projects.some(x =>
          x &&
          x.projectType &&
          x.projectType.projectTypeEnum === models.ProjectTypeEnum.NewDeal
        )
      );
    }

    const dialogRef = this._dialogService.show(CertificateOfInsuranceComponent, {
      showCloseButton: false,
      closeOnOutsideClick: false,
      width: 700,
      height: 820,
      maxHeight: '95%',
      injectableData: {
        lease: this.lease,
        project: this.getCertificateOfInsuranceProject(),
        isNewDealProject: isNewDealProject,
      },
      containerClassNames: [
        'certificate-of-insurance-dialog',
      ],
    });

    dialogRef
      .onShowing
      .pipe(
        tap(() => {
          this.certificateOfInsurancePopupVisible = true;
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    dialogRef
      .onHiding
      .pipe(
        tap(() => {
          if (dialogRef.outputData && dialogRef.outputData.isSaved) {
            this._initializePage(this._termRouteName, this.lease.id, true);
          } else {
            this.tooltipCOIVisible = true;
          }

          this.certificateOfInsurancePopupVisible = false;
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  onRejectClicked(termConfiguration: models.ILeaseTermConfiguration): void {
    this.rejectButtonClicked[termConfiguration.termName] = true;
    this.acceptButtonClicked[termConfiguration.termName] = false;

    /**
     * Scroll the lease terms container so
     * current term form will always be visible
     * and to focus user on it.
     */

    if (!this.leaseTermsElement || !this.leaseTermsElement.nativeElement) {
      return;
    }

    const leaseTermsContainer = this.leaseTermsElement.nativeElement;

    const openedLeaseTermForm = leaseTermsContainer
      .querySelector('.dx-accordion-item-opened .terms-form');
    if (!openedLeaseTermForm) {
      return;
    }

    const nextScrollTop = openedLeaseTermForm.offsetTop - 12;
    const currentScrollTop = leaseTermsContainer.scrollTop;

    if (nextScrollTop !== currentScrollTop) {
      this._ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          leaseTermsContainer.scrollTop = nextScrollTop;
        }, 1);
      });
    }
  }

  onAcceptClicked(termConfiguration: models.ILeaseTermConfiguration) {
    this.acceptButtonClicked[termConfiguration.termName] = true;
    this.rejectButtonClicked[termConfiguration.termName] = false;
  }

  demoTutorialSkipped() {
    this.showAlerts(this.notShownAlerts, false);
  }

  showAlerts(alerts: Array<models.IAlertViewModel>, afterChangedStage: boolean) {
    let isShowCertificateOfInsuranceConfirmation = false;
    if (this.projects && this.projects.length) {
      const certificateOfInsuranceProject = this.getCertificateOfInsuranceProject();
      if (certificateOfInsuranceProject) {
        isShowCertificateOfInsuranceConfirmation = (
          this.isShowCertificateOfInsuranceButton() &&
          this._projectAccessService.checkAccessToCertificateOfInsurance(
            models.InsuranceCertificateProjectTemplateItemType.LandlordFirstStep,
            certificateOfInsuranceProject,
            this.lease
          )
        );
      }
    }

    if (alerts && alerts.length) {
      const lastAlertIsSeries = alerts[alerts.length - 1];

      for (let i = 0, num = alerts.length; i < num; i++) {
        const alert = alerts[i];

        const alertReference = this._uiAlertService.pushInfoAlert({
          id: alert.id,
          title: 'Notification',
          message: alert.text,
        });

        alertReference
          .shown
          .pipe(
            switchMap(() => this._alertService
              .markReadAlert(alert),
            ),
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe();

        alertReference
          .hidden
          .pipe(
            tap(() => {
              if (lastAlertIsSeries.id === alert.id) {
                if (isShowCertificateOfInsuranceConfirmation) {
                  this.executeInsuranceOfCertificateConfirmationMessage();
                }

                return;
              }

              if (alert.alertSettingTemplate.alertType === models.AlertType.StateChangeCOIRequestFinished) {
                if (this.startupInfo.role === Role.Tenant) {
                  this._router.navigate(
                    ['/profile/documents'],
                    {
                      queryParams: {
                        leaseId: this.lease.id,
                      },
                    },
                  );
                }

                this._router.navigate(
                  [`/landlord/buildings/person-desktop/${this.lease.id}`],
                );
              }
            }),
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe();
      }
    } else {
      if (isShowCertificateOfInsuranceConfirmation) {
        this.executeInsuranceOfCertificateConfirmationMessage();
      }
    }
  }

  isLandlord(): boolean {
    return this._authService.isLandlord();
  }

  canShowElectTermsForNegotiationDialog(): boolean {
    if (!this.project) {
      return false;
    }

    if (
      this._projectAccessService.checkAccessToRenewalProject(
        models.RenewalProjectTemplateItemType.ReviewTenantImprovementsSelectMultiplyOptionsTenant,
        this.project,
        this.lease,
      ) ||
      (
        this._projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.UnsolicitedOffer,
          this.project,
          this.lease,
        ) &&
        !(this._authService.isLandlord() || this._isBroker(models.LeaseTeam.LandlordTeam))
      )
    ) {
      return false;
    }

    if (
      (
        this._projectAccessService.checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
          this.project,
          this.lease,
        )
      ) &&
      this.lease.term.hasMultiplyOptions &&
      this.lease.term.termStatus !== models.TermStatus.Ready &&
      this.lease.term.termStatus !== models.TermStatus.Rejected
    ) {
      return false;
    }

    return this._projectAccessService
      .hasAccessToCurrentProjectStageOrSubstage(
        this.project,
        [
          models.RenewalProjectTemplateItemType.UnsolicitedOffer,
          models.RenewalProjectTemplateItemType.EstablishCriteria,
          models.RenewalProjectTemplateItemType.SendRfp,
          models.RenewalProjectTemplateItemType.TenantCounterUnsolicitedOffer,
          models.RenewalProjectTemplateItemType.ReceiveAndAnalyzeResponse,
        ],
        this.lease
      );
  }

  showElectTermsForNegotiationDialog(): void {
    if (!this.lease) {
      return;
    }

    const refreshColabo$ = new Subject<void>();

    refreshColabo$
      .pipe(
        tap(() => {
          this._initializePage(this._termRouteName, this.lease.id, false);
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this._dialogService.show(ElectTermsForNegotiationComponent, {
      title: 'Add Terms',
      showCloseButton: true,
      width: 760,
      height: '95%',
      containerClassNames: ['overflow-hidden'],
      injectableData: {
        lease: this.lease,
        project: this.project,
        refreshColabo$: refreshColabo$,
      },
    });
  }

  generateTermsOfSummary() {
    this._loaderService.show();
    this._documentService
      .getSummaryOfTerms(this.lease.id)
      .subscribe(
        data => {
          const downloadURL = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = downloadURL;
          link.download = 'SummaryOfTerms.pdf';
          link.click();
        },
        error => {
          this._uiAlertService.pushErrorAlert({
            message: this._alertMessagesManager.getGenerateSummaryOfTermsErrorAlertText(),
          });
        },
        () => {
          this._loaderService.hide();
        },
      );
  }

  isShowCertificateOfInsuranceButton(): boolean {
    const project = this._projectService.getCertificateOfInsuranceProject(this.projects);
    if (!project) {
      return false;
    }

    if (project.projectStatus === ProjectStatus.Rejected) {
      return false;
    }

    return (
      this._authService.isLandlord() &&
      project && !this._projectService.isClosed(project)
    );
  }

  shouldShowReviewCOIForm(): boolean {
    const project = this._projectService.getCertificateOfInsuranceProject(this.projects);
    return (
      this._authService.isTenant() &&
      project && !this._projectService.isClosed(project) &&
      project.projectState &&
      project.projectState.insuranceCertificateProjectTemplateItemType === models.InsuranceCertificateProjectTemplateItemType.TenantStep
    );
  }

  getCertificateOfInsuranceProject(): models.IProjectViewModel {
    return this._projectService.getCertificateOfInsuranceProject(this.projects);
  }

  showLOI(): void {
    this.letterOfIntentPopupVisible = true;
  }

  executeInsuranceOfCertificateConfirmationMessage() {
    // Don't show COI alert while certificate of insurance popup is displayed
    if (this.certificateOfInsurancePopupVisible) {
      return;
    }

    let isFirstUpload = false;
    if (this.projects && this.projects.length) {
      const certificateOfInsuranceProject = this.getCertificateOfInsuranceProject();
      if (certificateOfInsuranceProject && this.lease) {
        isFirstUpload = this._projectAccessService.checkAccessToCertificateOfInsurance(
          models.InsuranceCertificateProjectTemplateItemType.LandlordFirstStep,
          certificateOfInsuranceProject,
          this.lease
        );
      }
    }

    let message = this._alertMessagesManager.getConfirmTenantShouldUploadCertificateOfInsuranceAlertText();
    if (isFirstUpload) {
      message = this._alertMessagesManager.getFirstCertificateOfInsuranceUploadAlertText();
    }

    const alertReference = this._uiAlertService.pushAlert({
      kind: AlertNotificationKind.Confirm,
      message: message,
      confirmButtonText: 'Yes',
      declineButtonText: 'No',
      closable: true,
      autoclose: false,
      shouldShowConfirmButton: true,
      shouldShowDeclineButton: true,
      closeOnButtonClick: true,
    });

    alertReference
      .confirmed
      .pipe(
        tap(() => {
          this.showCertificateOfInsurance();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    alertReference
      .declined
      .pipe(
        tap(() => {
          this._executeInsuranceOfCertificateAdditionalConfirmationMessage();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  getProjectName(): string {
    return this._projectService.getProjectName(this.project);
  }

  getLeaseName(lease: models.ILeaseViewModel): string {
    if (!lease) {
      return null;
    }

    const fragments = [];

    if (lease.building?.address?.addressLine1) {
      fragments.push(lease.building.address.addressLine1);
    }

    if (lease.building?.address?.addressLine2) {
      fragments.push(lease.building.address.addressLine2);
    }

    if (lease.buildingUnit?.name) {
      fragments.push(lease.buildingUnit.name);
    }

    return fragments.filter(Boolean).join(', ');
  }

  onTermChangeCancelled(termConfiguration: models.ILeaseTermConfiguration): void {
    this.acceptButtonClicked[termConfiguration.termName] = false;
    this.rejectButtonClicked[termConfiguration.termName] = false;
    this.rejectFormVisibilityMap[termConfiguration.termName] = false;
  }

  isRejectFormVisibleChangeHandler(isFormVisible: boolean, termConfiguration: models.ILeaseTermConfiguration): void {
    this.rejectFormVisibilityMap[termConfiguration.termName] = isFormVisible;
  }

  private _executeInsuranceOfCertificateAdditionalConfirmationMessage() {
    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmTenantComplianceAlertText(),
      confirmButtonText: 'Yes',
      declineButtonText: 'No',
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          const commandParam = <models.IInsuranceCertificateParamsViewModel>{
            insuranceCertificateViewModel: null,
            leaseViewModel: this.lease,
          };

          return this._projectService
            .insuranceCertificateProject(commandParam, models.InsuranceCertificateTriggerType.RejectUpdatedInsuranceCertificate)
            .pipe(
              tap(() => {
                this._initializePage(this._termRouteName, this.lease.id, true);
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();

    alertReference
      .declined
      .pipe(
        tap(() => {
          this.executeInsuranceOfCertificateConfirmationMessage();
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  private _detectChanges(): void {
    if ((<ng.ViewRef>this._changeDetectorRef).destroyed) {
      return;
    }

    this._changeDetectorRef.markForCheck();
    this._changeDetectorRef.detectChanges();
  }

  private _getSendOfferMessage(companyName: string) {
    const text = this._authService.role === Role.Broker || this._authService.role === Role.CoBroker
      ? 'Send Offer For Approval'
      : !this.project.pendingForInternalApproval ? 'Send Offer' : 'Approve & Send Offer';

    return (
      `Great. You are now ready to send your Offer, please review the terms one last time and ` +
      `press <b>“${text}”</b> to submit to ${companyName} for review`
    );
  }

  private _pushSendOfferAlertToGo5Stage(companyName: string) {
    const hasAcceptedFinancialRequests = this._financialRequestManager
      .hasAcceptedFinancialRequests(this.financialRequests);

    const hasUnapprovedFinancialRequests = this._financialRequestManager
      .hasUnapprovedFinancialRequests(this.financialRequests);

    const isSendLOI = (
      (
        (
          this._projectAccessService
            .checkAccessToRenewalProject(
              models.RenewalProjectTemplateItemType.TenantCounterOffer,
              this.project,
              this.lease,
            )
        ) &&
        !hasUnapprovedFinancialRequests &&
        (hasAcceptedFinancialRequests || this.leaseSignature?.hasLandlordSigner)
      ) ||
      (
        this._projectAccessService
          .checkAccessToRenewalProject(
            models.RenewalProjectTemplateItemType.ReviewTenantImprovementsByLandlord,
            this.project,
            this.lease,
          ) ||
        this._projectAccessService
          .checkAccessToRenewalProject(
            models.RenewalProjectTemplateItemType.LandlordCounterOffer,
            this.project,
            this.lease,
          )
      )
    );

    let buttonText;
    if (this._authService.role === Role.Broker || this._authService.role === Role.CoBroker) {
      buttonText = 'Send Offer For Approval';
    } else {
      if (isSendLOI) {
        buttonText = !this.project.pendingForInternalApproval ? 'Send LOI' : 'Approve & Send LOI';
      } else {
        buttonText = !this.project.pendingForInternalApproval ? 'Send Offer' : 'Approve & Send Offer';
      }
    }

    if (
      isSendLOI &&
      this._projectAccessService
        .checkAccessToRenewalProject(
          models.RenewalProjectTemplateItemType.TenantCounterOffer,
          this.project,
          this.lease,
        ) &&
      !(this._authService.role === Role.Broker || this._authService.role === Role.CoBroker)
    ) {
      const alertReference = this._uiAlertService
        .pushConfirmAlert({
          message: 'Great. Everything is accepted. You are now ready to proceed to LOI signature.',
          confirmButtonText: buttonText,
          declineButtonText: 'Cancel',
          autoclose: true,
        });

      if (alertReference) {
        alertReference
          .confirmed
          .pipe(
            take(1),
            takeUntil(this._destroy$),
          )
          .subscribe(() => {
            this.counterOffer();
          });

        return;
      }
    }

    this._uiAlertService
      .pushSuccessAlert({
        message: (
          `Great. Everything is accepted. You are now ready to send, ` +
          `please review the terms one last time and press <b>“${buttonText}”</b> to submit to ${companyName} for review`
        ),
      });
  }

  private _getSendOfferMessageWithSingleRejectedTerm(companyName: string) {
    const text = this._authService.role === Role.Broker || this._authService.role === Role.CoBroker
      ? 'Send Offer For Approval'
      : !this.project.pendingForInternalApproval ? 'Send Offer' : 'Approve & Send Offer';

    return (
      `You are now ready to send your Offer, please review the terms one last time and ` +
      `press <b>“${text}”</b> to submit to ${companyName} for review`
    );
  }

  private _setTermConfigurations() {
    this.termConfigurations = this._termManager
      .getLeaseTermConfigurations(
        this.startupInfo.lists.leaseTermConfigurations,
        this.project,
        this.lease,
        true, /** electedTermsOnly */
      );

    this.accordionOpened$ = this.termConfigurations
      .reduce(
        (acc, conf) => ({...acc, [conf.termName]: new Subject()}),
        {}, /** acc */
      );

    this.errorOccured$ = this.termConfigurations
      .reduce(
        (acc, conf) => ({...acc, [conf.termName]: new Subject<HttpErrorResponse>()}),
        {}, /** acc */
      );
  }

  isSendOfferForApproval() {
    return (
      this._currentRenewalProjectTemplateItemType === models.RenewalProjectTemplateItemType.SendRfp &&
      (this.startupInfo.role === Role.Broker || this.startupInfo.role === Role.CoBroker) &&
      this._projectManager.isCurrentRoleTurnOnTerms(this.startupInfo.role, this.startupInfo.id, this.project, this.lease)
    );
  }

  canSendOfferForApproval() {
    return (
      (
        this.isSendOfferForApproval() ||
        this.isUnsolicitedOfferByLandlordBroker() ||
        this.isVisibleTenantCounterUnsolicitedOffer() ||
        this.isReviewTenantImprovementsMultiplyOptionsByLandlordBroker() ||
        this.isReviewTenantImprovementsMultiplyOptionsByTenantBroker() ||
        this.isVisibleSendOfferForReceiveAndAnalyzeResponseForLandlord() ||
        this.isVisibleProposalNegotiation()
      ) &&
      (this.startupInfo.role === Role.Broker || this.startupInfo.role === Role.CoBroker)
    );
  }

  sendOfferForApproval(button: HTMLButtonElement, checkReadyTermStatus = false): void {
    if (!this._validateTerms()) {
      return;
    }

    if (checkReadyTermStatus && !this._isAllStatusSetWithDialogue(models.TermStatus.Ready, true)) {
      return;
    }

    const leaseUser = this.lease.leaseUsers.find(u => u.userId === this.startupInfo.id);

    if (!leaseUser) {
      return;
    }

    const toClientType = leaseUser.relatedToCompanyId === this.lease.tenantCompany.id ? 'Tenant' : 'Landlord';

    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmSendOfferForApprovalAlertText(toClientType),
    });

    alertReference
      .confirmed
      .pipe(
        switchMap(() => {
          button.disabled = true;

          return this._projectService
            .renewalProject(this.lease, models.RenewalProjectTriggerType.SendOfferForApprovalByBroker, this.project)
            .pipe(
              tap(() => {
                button.disabled = false;
                this._initializePage(this._termRouteName, this.lease.id);
                this.sendOfferForApprovalButtonVisible = false;
              }),
            );
        }),
        take(1),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  isWaitingForApproval() {
    return this.project.pendingForInternalApproval && this._authService.role === Role.Broker;
  }

  requestFinancialDocuments() {
    const dialogRef = this._dialogService.show(RequestFinancialsDialogComponent, {
      showCloseButton: false,
      closeOnOutsideClick: false,
      width: 600,
      height: 'auto',
      maxHeight: 700,
      injectableData: {
        leaseId: this.lease.id,
        lease: this.lease
      },
    });

    dialogRef.onHiding
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this._checkFinancialRequests(this.lease, this.project);
        }),
      )
      .subscribe();
  }

  uploadFinancialDocumentsButtonClick() {
    this._uploadFinancialDocuments()
      .pipe(
        takeUntil(
          this._destroy$,
        ),
      )
      .subscribe();
  }

  _uploadFinancialDocuments() {
    const inputElement = this._renderer.createElement('input');
    this._renderer.setAttribute(inputElement, 'type', 'file');
    this._renderer.setAttribute(inputElement, 'multiple', '');
    this._renderer.setAttribute(inputElement, 'accept', '.pdf,.xls,.xlsx,.doc,.docx');

    const alertReference = this._uiAlertService.pushConfirmAlert({
      message: this._alertMessagesManager.getConfirmUploadFinancialsAlertText(),
      confirmButtonText: 'Upload',
      declineButtonText: 'Cancel',
      closeOnButtonClick: false,
    });

    inputElement.onchange = (event) => {
      if (!event || !inputElement.files || !inputElement.files.length) {
        return;
      }

      this._financialRequestManager
        .uploadFinancialsFilesByLeaseId(this.lease.id, inputElement.files)
        .pipe(
          tap(() => {
            this._uiAlertService.pushSuccessAlert({
              message: this._alertMessagesManager.getFinancialsUploadedAlertText(),
            });

            alertReference.hide();
          }),
          catchError(err => {
            this._uiAlertService.pushErrorAlert({
              message: this._alertMessagesManager.getFinancialsUploadErrorAlertText(),
            });

            return of(err);
          }),
          tap(() =>
            this._checkFinancialRequests(this.lease, this.project),
          ),
          take(1),
          takeUntil(this._destroy$),
        )
        .subscribe();
    };

    const confirmed = alertReference
      .confirmed
      .pipe(
        tap(() => {
          if (!inputElement) {
            return;
          }

          inputElement.click();
        }),
        take(1),
        takeUntil(this._destroy$),
      );

    const declined = alertReference
      .declined
      .pipe(
        tap(() => {
          alertReference.hide();
        }),
        take(1),
        takeUntil(this._destroy$),
      );

    return combineLatest([confirmed, declined]);
  }

  showUploadFinancialDocumentsButton(): boolean {
    if (!this.project) {
      return false;
    }

    return this._financialRequestManager.canUploadFinancialDocumentsInColabo(this.project,
      this.financialRequests, this._authService.role);
  }

  showRequestFinancialDocumentsButton(): boolean {
    if (!this.project) {
      return false;
    }

    return this._financialRequestManager.canRequestFinancialDocumentsInColabo(this.project,
      this.financialRequests, this._authService.role);
  }

  isShowTimeline(): boolean {
    return this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.InsuranceCertificate
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.NewDeal
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.Renewal
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.RenewalInitiatedByLandlord
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.Restructure
      || this.project?.projectType?.projectTypeEnum === models.ProjectTypeEnum.Maintenance;
  }

  getTourButtonCaption(): string {
    if (!this.tour) {
      return this.startupInfo.role === Role.Tenant
        ? 'Request a Tour'
        : 'Invite to Tour';
    }

    if (this.tour.status === models.TourStatus.WaitingForApproval) {
      const leaseTeam = this._leaseManager
        .getUserLeaseTeam(this.lease, this._authService.userId, this._authService.role);
      const canEditTourRequest =
        leaseTeam === models.LeaseTeam.LandlordTeam && this.tour.isApprovedByLandlord ||
        leaseTeam === models.LeaseTeam.TenantTeam && this.tour.isApprovedByTenant;

      return canEditTourRequest
        ? 'Edit Tour Info'
        : 'Approve Tour';
    }

    if (this.tour.status === models.TourStatus.Scheduled) {
      return 'Tour Scheduled';
    }

    return 'Request a Tour';
  }

  onTourButtonMouseEnter() {
    this.tourComingSoonTooltipVisible = true;
  }

  onTourButtonMouseLeave() {
    this.tourComingSoonTooltipVisible = false;
  }

  private _checkFinancialRequests(lease: models.ILeaseViewModel, project: models.IProjectViewModel) {
    if (lease && project) {
      const getFinancialRequestService = this._financialRequestManager.getFinancialRequests(lease.id);
      forkJoin([getFinancialRequestService])
        .pipe(
          takeUntil(this._destroy$),
          tap(([financialRequests]) => {
            this.financialRequests = financialRequests;
          }),
        )
        .subscribe();
    }
  }

  getTenantCompanyName(): string {
    if (!this.lease || !this.lease.tenantCompany) {
      return 'tenant company';
    }

    return this.lease.tenantCompany.name;
  }

  getLandlordCompanyName(): string {
    if (!this.lease || !this.lease.landlordCompany) {
      return 'landlord company';
    }

    return this.lease.landlordCompany.name;
  }

  refreshColabo(termName: string) {
    this._initializePage(termName, this.lease.id, false);
  }

  private _isBroker(leaseTeam: models.LeaseTeam): boolean {
    if (!(this.startupInfo.role === Role.Broker || this.startupInfo.role === Role.CoBroker)) {
      return false;
    }

    if (leaseTeam === models.LeaseTeam.LandlordTeam) {
      return this.lease.leaseUsers.some(u => u.userId === this.startupInfo.id && u.relatedToCompanyId === this.lease.landlordCompany.id);
    }
    return this.lease.leaseUsers.some(u => u.userId === this.startupInfo.id && u.relatedToCompanyId === this.lease.tenantCompany.id);
  }

  private _checkTourRequests() {
    if (!this.project) {
      return;
    }

    this._projectManager
      .getProjectTours(this.project.id)
      .pipe(
        tap((tours) => {
          this.tour = tours.find((t) => t.status !== models.TourStatus.Archived);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }
}
