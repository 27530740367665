<ng-progress [spinner]="false" [color]="'#59b680'">
</ng-progress>

<router-outlet>
</router-outlet>

<app-loader>
</app-loader>

<app-onboadring-popup>
</app-onboadring-popup>

<!-- Start: Alert Container -->
<app-alert-container>
</app-alert-container>
<!-- End: Alert Container -->
<!-- Start: Alert Requires Action Container -->
<app-alert-requires-action-container>
</app-alert-requires-action-container>
<!-- End: Alert Requires Action Container -->
