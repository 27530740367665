import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';

import * as dx from 'devextreme-angular';

import { FeatureTogglingModule } from '../feature-toggling/feature-toggling.module';

import { LandlordRouter } from './landlord.router';

import { AddBuildingComponent } from './components/add-building/add-building.component';
import { AddPortfolioComponent } from './components/add-portfolio/add-portfolio.component';
import { AnalyticsModule } from '../analytics/analytics.module';
import { AsideDropdownComponent } from './components/shared/aside-dropdown/aside-dropdown.component';
import { AttachmentsModule } from '../attachments/attachments.module';
import { DocumentViewerModule } from '../document-viewer/document-viewer.module';
import { BriefDealSummaryComponent } from './components/brief-deal-summary/brief-deal-summary.component';
import { BuildingComponent } from './components/building/building.component';
import { BuildingDesktopComponent } from './components/building-desktop/building-desktop.component';
import { BuildingFormComponent } from './components/building-form/building-form.component';
import { BuildingsComponent } from './components/buildings/buildings.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EditBuildingComponent } from './components/edit-building/edit-building.component';
import { EditBuildingMobileComponent } from './components/edit-building-mobile/edit-building-mobile.component';
import { InfrastructureModule } from '../infrastructure/infrastructure.module';
import {
  LeaseLandlordCancellationComponent,
} from './components/lease-cancellation/lease-landlord-cancellation.component';
import { LeaseRequestModule } from '../lease-request/lease-request.module';
import { ListSpaceDialogComponent } from './components/list-space-dialog/list-space-dialog.component';
import { PersonDesktopComponent } from './components/person-desktop/person-desktop.component';
import { RejectionReasonDialogComponent } from './components/rejection-reason-dialog/rejection-reason-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { TeamMembersAvatarsComponent } from './components/team-members-avatars/team-members-avatars.component';
import { TenantRequestsComponent } from './components/quiz-response/tenant-requests.component';
import { ProjectRequestModule } from '../project-request/project-request.module';

import { BuildingBudgetComponent } from './components/building-budget/building-budget.component';
import { BuildingBudgetDialogComponent } from './components/building-budget/building-budget-dialog/building-budget-dialog.component';
import {
  BuildingUnitBudgetDialogComponent,
} from './components/building-budget/building-unit-budget-dialog/building-unit-budget-dialog.component';
import { BuildingModule } from '../building/building.module';
import {AddProjectNotesComponent} from './components/add-project-notes/add-project-notes.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    InlineSVGModule,

    DxiValidationRuleModule,
    dx.DxAccordionModule,
    dx.DxBoxModule,
    dx.DxButtonModule,
    dx.DxCheckBoxModule,
    dx.DxDataGridModule,
    dx.DxDropDownButtonModule,
    dx.DxFileUploaderModule,
    dx.DxFormModule,
    dx.DxListModule,
    dx.DxNumberBoxModule,
    dx.DxPopoverModule,
    dx.DxPopupModule,
    dx.DxRadioGroupModule,
    dx.DxScrollViewModule,
    dx.DxSelectBoxModule,
    dx.DxSwitchModule,
    dx.DxTabPanelModule,
    dx.DxTabsModule,
    dx.DxTagBoxModule,
    dx.DxTemplateModule,
    dx.DxTextAreaModule,
    dx.DxTextBoxModule,
    dx.DxTreeListModule,
    dx.DxValidationGroupModule,
    dx.DxValidationSummaryModule,
    dx.DxValidatorModule,
    dx.DxTooltipModule,
    dx.DxDateBoxModule,

    InfrastructureModule,
    SharedModule,

    FeatureTogglingModule,

    LandlordRouter,

    AnalyticsModule,
    AttachmentsModule,
    DocumentViewerModule,
    LeaseRequestModule,
    ProjectRequestModule,
    BuildingModule,
    CommonModule,
    CommonModule,
  ],
  declarations: [
    AddBuildingComponent,
    AddPortfolioComponent,
    AsideDropdownComponent,
    BriefDealSummaryComponent,
    BuildingComponent,
    BuildingDesktopComponent,
    BuildingFormComponent,
    BuildingsComponent,
    DashboardComponent,
    EditBuildingComponent,
    EditBuildingMobileComponent,
    LeaseLandlordCancellationComponent,
    ListSpaceDialogComponent,
    PersonDesktopComponent,
    RejectionReasonDialogComponent,
    TeamMembersAvatarsComponent,
    TenantRequestsComponent,
    BuildingBudgetComponent,
    BuildingBudgetDialogComponent,
    BuildingUnitBudgetDialogComponent,
    AddProjectNotesComponent,
  ],
  entryComponents: [
    LeaseLandlordCancellationComponent,
    ListSpaceDialogComponent,
    RejectionReasonDialogComponent,
  ],
  exports: [
    LeaseLandlordCancellationComponent,
  ],
})
export class LandlordModule {
}
