<div class="tour-schedule-dialog-container">
  <ng-container>
    <div class="tour-schedule-dialog-pane tour-schedule-dialog-left-pane">
      <div class="tour-schedule-dialog-pane-header">
        <p>
          <ng-container *ngIf="state === TourProcessState.Edit ||
                               state === TourProcessState.ReplyReject;
                               else setUpTourTemplate">
            Edit Tour
          </ng-container>
          <ng-template #setUpTourTemplate>
            Set up a Tour
          </ng-template>
        </p>
      </div>

      <div class="tour-schedule-dialog-pane-content">
        <div class="building-info">
          <img width="42" height="42" [src]="getBuildingPictureUrl()" />
          <div class="building-info-address">
            <p>{{ building.address.addressLine1 }}</p>
            <div class="building-info-unit-name">{{ buildingUnit.name }}</div>
          </div>
        </div>

        <div class="counterpart-cell"
             *ngIf="counterPartUser && counterPartCompany">
          <div class="counterpart-cell-images-container">
            <div class="counterpart-cell-user-avatar"
                 [class.counterpart-cell-user-avatar_empty]="!counterPartUser?.avatar?.url">
              <ng-container *ngIf="counterPartUser?.avatar?.url; else noUserAvatarTemplate">
                <img [src]="counterPartUser.avatar.url" width="27" height="27" />
              </ng-container>
              <ng-template #noUserAvatarTemplate>
                <i [inlineSVG]="TourIconsAssetPath + '#icon-user-17'"></i>
              </ng-template>
            </div>

            <div class="counterpart-cell-company-logo"
                 [class.counterpart-cell-company-logo_empty]="!counterPartCompany?.logo?.url">
              <ng-container *ngIf="counterPartCompany?.logo?.url; else noCompanyLogoTemplate">
                <img [src]="counterPartCompany.logo.url" width="26" height="26" alt="Counterpart company logo" />
              </ng-container>
              <ng-template #noCompanyLogoTemplate>
                <i [inlineSVG]="TourIconsAssetPath + '#icon-user-company-16'"></i>
              </ng-template>
            </div>
          </div>
          <div class="counterpart-cell-info">
            <div class="counterpart-cell-info-user">
              {{ counterPartUser?.displayName }}
            </div>
            <div class="counterpart-cell-info-name">
              {{ counterPartCompany?.name }}
            </div>
          </div>
        </div>

        <div class="tour-length-info">
          <i [inlineSVG]="TourIconsAssetPath + '#icon-clock'"></i>
          <span>
            Tour length: 30 min.
          </span>
        </div>

        <div class="tour-schedule-dialog-pane-footer">
          <ng-container *ngIf="state === TourProcessState.Initial">
            Pick the time slots and dates out of the available and then the other party
            will pick the final time slot for the tour out of them.
          </ng-container>
          <ng-container *ngIf="state === TourProcessState.ReplyAccept">
            Pick from the available time slots, so the other party can confirm a final
            date and time for the tour.
          </ng-container>
          <ng-container *ngIf="state === TourProcessState.Edit ||
                               state === TourProcessState.ReplyReject">
            You have chosen to edit the tour date and time.
            Please pick the new time slots that work for you and we will send them to the other party.
          </ng-container>
        </div>
      </div>
    </div>

    <div class="tour-schedule-dialog-pane tour-schedule-dialog-middle-pane">
      <div class="tour-schedule-dialog-pane-header tour-schedule-dialog-pane-header--noborder">
        <ng-container *ngIf="state == TourProcessState.ReplyAccept; else defaultHeaderTemplate">
          Pick the final time slot out of the suggested options:
        </ng-container>
        <ng-template #defaultHeaderTemplate>
          Pick all the time slots and dates that work for you:
        </ng-template>
      </div>

      <div class="tour-schedule-dialog-calendar">
        <div class="tour-schedule-dialog-calendar-wrapper">
          <app-tour-schedule-calendar [state]="state"
                                      [timezone]="timezone"
                                      [referencedDates]="getReferencedDates()"
                                      (dateSelected)="onDateSelected($event)">
          </app-tour-schedule-calendar>
        </div>

        <div class="tour-schedule-dialog-calendar-time-slots"
             appStopDevexpressScrollTampering>
          <div class="tour-schedule-dialog-calendar-selected-date">
            {{ selectedDateTitle }}
          </div>
          <div style="flex: 1; overflow: hidden;">
            <ngx-simplebar style="height: 100%">
              <div class="tour-schedule-dialog-calendar-time-slots-list">
                <ng-container *ngIf="state === TourProcessState.Initial ||
                                     state === TourProcessState.ReplyReject ||
                                     state === TourProcessState.Edit; else availableSlots">
                  <button type="button"
                          class="tour-schedule-dialog-calendar-time-slot-button"
                          [class.tour-schedule-dialog-calendar-time-slot-button--active]="isSlotActive(slot)"
                          *ngFor="let slot of timeSlots"
                          (click)="onTimeSlotClick(slot)">
                    {{ slot }}
                  </button>
                </ng-container>
                <ng-template #availableSlots>
                  <ng-container *ngFor="let slot of availableTimeSlots">
                    <button type="button"
                            class="tour-schedule-dialog-calendar-time-slot-button"
                            [class.tour-schedule-dialog-calendar-time-slot-button--active]="isSlotActive(slot)"
                            (click)="setApprovedTimeSlot(slot)">
                      {{ slot }}
                    </button>
                  </ng-container>
                </ng-template>
              </div>
            </ngx-simplebar>
          </div>
        </div>
      </div>
    </div>

    <div class="tour-schedule-dialog-pane tour-schedule-dialog-right-pane">
      <div class="tour-schedule-dialog-pane-header">
        <ng-container *ngIf="state !== TourProcessState.ReplyAccept || isOwnTimeSlotPicked(); else offeredTimeSlotsTemplate">
          Time slots you picked:
        </ng-container>
        <ng-template #offeredTimeSlotsTemplate>
          Offered time slots:
        </ng-template>

        <div>
          <button type="button" class="close-button"
                  (click)="close()">
            <i [inlineSVG]="TourIconsAssetPath + '#icon-close'"></i>
          </button>
        </div>
      </div>

      <div class="tour-schedule-dialog-pane-content h-100">
        <div class="d-flex flex-column tour-schedule-dialog-pane-content-wrapper"
             *ngIf="selectedTimeSlots?.length > 0; else noTimeSlotsPicked">
          <ng-container *ngIf="state !== TourProcessState.ReplyAccept || isOwnTimeSlotPicked(); else approvedTimeSlots">
            <div *ngFor="let entry of (approvedTimeSlotOption ? [{ date: approvedTimeSlotOption.date, timeSlots: [approvedTimeSlotOption.timeSlot]}] : selectedTimeSlots)">
              <div class="picked-time-slots-date-heading">
                {{ getDateTitle(entry.date) }}
              </div>
              <div class="picked-time-slots-badges">
                <div class="picked-time-slots-badge"
                     *ngFor="let slot of entry.timeSlots">
                  <span>{{ getSlotDurationExpression(slot) }}</span>
                  <i [inlineSVG]="TourIconsAssetPath + '#icon-delete'"
                     (click)="onRemoveSlotClick(entry, slot)">
                  </i>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-template #approvedTimeSlots>
            <div *ngFor="let entry of getApprovedTimeSlots()">
              <div class="picked-time-slots-date-heading">
                {{ getDateTitle(entry.date) }}
              </div>
              <div class="picked-time-slots-badges">
                <div class="picked-time-slots-badge picked-time-slots-badge--gray"
                     *ngFor="let slot of entry.timeSlots">
                  <span>{{ getSlotDurationExpression(slot) }}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </div>

        <ng-template #noTimeSlotsPicked>
          <div class="picked-time-slots-no-data">
            <i [inlineSVG]="TourIconsAssetPath + '#icon-no-data'"></i>
            <p>No time slots picked :(</p>
            <p class="subtitle">Pick one or several time slots to send a tour request</p>
          </div>
        </ng-template>

        <div class="tour-schedule-dialog-pane-footer">
          <ng-container *ngIf="isTooManyTimeSlotsSelected(); else confirmButtonTemplate">
            <div class="picked-time-slots-too-many">
              Please select no more than five time slots.
            </div>
          </ng-container>

          <ng-template #confirmButtonTemplate>
            <button type="button"
                    class="picked-time-slots-confirm-button"
                    [disabled]="!(selectedTimeSlots.length > 0)"
                    *ngIf="state === TourProcessState.Initial ||
                           state === TourProcessState.ReplyReject ||
                           state === TourProcessState.Edit"
                    (click)="onConfirmTimeSlotsButtonClick()">
              <ng-container *ngIf="state === TourProcessState.Initial; else confirmTimeSlotsTemplate">
                Send a tour request
              </ng-container>
              <ng-template #confirmTimeSlotsTemplate>
                Confirm time slots
              </ng-template>
            </button>
            <button type="button"
                    class="picked-time-slots-cancel-button"
                    *ngIf="state === TourProcessState.ReplyReject ||
                           state === TourProcessState.Edit"
                    (click)="onCancelTimeSlotsButtonClick()">
              Cancel
            </button>
          </ng-template>

          <button type="button"
                  class="picked-time-slots-confirm-button"
                  *ngIf="state === TourProcessState.ReplyAccept && !!approvedTimeSlotOption"
                  (click)="onApproveTourButtonClick()">
            Confirm time slot
          </button>

          <div *ngIf="state === TourProcessState.ReplyReject || state === TourProcessState.Edit" class="tour-cancel">
            <i [inlineSVG]="TourIconsAssetPath + '#icon-x-small'"></i>
            <a (click)="cancelTour()">Cancel Tour?</a>
          </div>

          <div *ngIf="state === TourProcessState.ReplyAccept" class="tour-cancel">
            <i [inlineSVG]="TourIconsAssetPath + '#icon-x-small'"></i>
            <a (click)="onReplyRejectButtonClick()">Offer different time slots</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
