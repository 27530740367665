<dx-tree-view class="chat-tree-view" width="100%" dataStructure="plain" keyExpr="itemId" parentIdExpr="itemParentId"
              selectedExpr="selected" displayExpr="name" searchEnabled="true" searchMode="contains" selectByClick="true"
              itemTemplate="itemTemplate"
              [items]="chatChannels" [selectionMode]="'single'" [focusStateEnabled]="false"
              [selectNodesRecursive]="false" [expandAllEnabled]="true"
              (onItemClick)="itemClick($event)"
              (onItemSelectionChanged)="itemSelectionChanged($event)"
              #treeview>
  <div *dxTemplate="let item of 'itemTemplate'"
       [ngClass]="{'indicated-item': (item?.leaseId && !isLeaseExpired(item) && !isLeaseSigned(item))}">
    <span class="indicator"
          *ngIf="item?.leaseId && isUnit(item) && !isLeaseExpired(item) && !isLeaseSigned(item)"
          [ngClass]="getProjectIndicatorClass(item?.leaseId)">
    </span>
    <a role="button" class="tree-item-name"
       [class.overflown]="isOverflown(nameElementRef)">
      <span class="name"
            #nameElementRef>
        <span>
          {{ item.name }}
        </span>
      </span>

      <span class="status"
            *ngIf="item?.rejectedDate">
        ({{item.rejectedDate | safeDate: 'HH:mm aaa MM/dd/yyyy' }})
      </span>
    </a>
  </div>
</dx-tree-view>
