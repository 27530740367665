<div class="d-none d-md-block"
     *ngIf="(userRole | async) as userRole">
  <section class="my-dash-blocks">
    <div *ngIf="pageCount > 0" class="b-sort d-flex flex-row">
      <div class="d-flex align-items-center">
        <ng-container *ngIf="!isSelectBuildingsModeEnabled; else selectBuildingsModeEnabledHeaderTemplate">
          <ng-container *ngIf="!isAssetsFilterUsed(); else filterResultsHeaderTemplate">
            <span class="current-portfolio">
              {{ portfolio.name }}
            </span>
          </ng-container>
          <ng-template #filterResultsHeaderTemplate>
            <span class="current-portfolio">
              filter results
            </span>
          </ng-template>
        </ng-container>
        <ng-template #selectBuildingsModeEnabledHeaderTemplate>
          <div class="selected-buildings-title">
            <i inlineSVG="assets/icons/inlineSVG/lanlordBuildings.svg#check"></i>
            {{selectedBuildings.length}} {{ selectedBuildings.length | i18nPlural: { '=1': 'building', 'other': 'buildings' } }} selected
          </div>
        </ng-template>

        <ng-container *ngIf="!isSelectBuildingsModeEnabled; else selectBuildingsModeEnabledLeasingTeamTemplate">
          <ng-container *ngIf="!isAssetsFilterUsed()">
            <ng-container *ngIf="!portfolio.teamMembers?.length; else portfolioLeasingTeamTemplate">
              <!-- TODO: The button is temporarily disabled for all role types except Landlord -->
              <button type="button"
                      class="assign-leasing-team-btn"
                      *ngIf="userRole === Role.Landlord"
                      (click)="handlePortfolioAssignLeasingTeamButtonClick(portfolio)">
                <i inlineSVG='assets/icons/inlineSVG/lanlordBuildings.svg#plus'></i>
                assign a leasing team
              </button>
            </ng-container>
            <ng-template #portfolioLeasingTeamTemplate>
              <div class="leasing-team">
                <div class="leasing-team__title">
                  Leasing team:
                </div>
                <ng-container *ngFor="let user of portfolio.teamMembers">
                  <div class="leasing-team-member">
                    <img [src]="user.avatar?.url || 'assets/img/no-image.svg'" [alt]="user.fullName"/>
                  </div>
                </ng-container>
                <!-- TODO: The button is temporarily disabled for all role types except Landlord -->
                <ng-container *ngIf="userRole === Role.Landlord">

                  <button type="button"
                          class="leasing-team-edit-btn"
                          (click)="handlePortfolioManageLeasingTeamButtonClick(portfolio)"
                          #portfolioLeasingTeamEditButton>
                    <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-edit"></i>
                  </button>

                  <dx-tooltip class="v2-default-tooltip"
                              position="bottom"
                              showEvent="dxhoverstart" hideEvent="dxhoverend"
                              [target]="portfolioLeasingTeamEditButton">
                    <div class="d-flex flex-column tooltip-menu">
                      Edit
                    </div>
                  </dx-tooltip>
                </ng-container>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-template #selectBuildingsModeEnabledLeasingTeamTemplate>
          <ng-container *ngIf="selectedBuildings.length">
            <ng-container *ngIf="!isSelectedBuildingsTeamMembersMatch() || !isSelectedBuildingsHaveAnyTeamMembers(); else multipleBuildingsLeasingTeamTemplate">
              <span class="leasing-teams-dont-match"
                    *ngIf="!isSelectedBuildingsTeamMembersMatch()">
                leasing teams don't match
              </span>
              <!-- TODO: The button is temporarily disabled for all role types except Landlord -->
              <button type="button"
                      class="assign-leasing-team-btn"
                      *ngIf="userRole === Role.Landlord"
                      (click)="handleSelectedBuildingAssignLeasingTeamButtonClick()">
                <i inlineSVG='assets/icons/inlineSVG/lanlordBuildings.svg#plus'></i>
                assign a leasing team
              </button>
            </ng-container>
            <ng-template #multipleBuildingsLeasingTeamTemplate>
              <div class="leasing-team">
                <div class="leasing-team__title">
                  Leasing team:
                </div>
                <ng-container *ngFor="let user of selectedBuildings[0].teamMembers">
                  <div class="leasing-team-member">
                    <img [src]="user.avatar?.url || 'assets/img/no-image.svg'" [alt]="user.fullName"/>
                  </div>
                </ng-container>
                <!-- TODO: The button is temporarily disabled for all role types except Landlord -->
                <ng-container *ngIf="userRole === Role.Landlord">
                  <button type="button"
                          class="leasing-team-edit-btn"
                          (click)="handleSelectedBuildingManageLeasingTeamButtonClick()"
                          #portfolioLeasingTeamEditButton>
                    <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-edit"></i>
                  </button>
                  <dx-tooltip class="v2-default-tooltip"
                              position="bottom"
                              showEvent="dxhoverstart" hideEvent="dxhoverend"
                              [target]="portfolioLeasingTeamEditButton">
                    <div class="d-flex flex-column tooltip-menu">
                      Edit
                    </div>
                  </dx-tooltip>
                </ng-container>
              </div>
            </ng-template>
          </ng-container>
        </ng-template>
      </div>
      <div class="d-flex flex-row">
        <!-- TODO: The buttons is temporarily disabled for all role types except Landlord -->
        <ng-container *ngIf="userRole === Role.Landlord">
          <ng-container *ngIf="!isSelectBuildingsModeEnabled; else selectModeEnabledButtonsTemplate">
            <button class="select-btn"
                    (click)="handleSelectBuildingsButtonClick()">
              select
            </button>
          </ng-container>
          <ng-template #selectModeEnabledButtonsTemplate>
            <button class="select-btn"
                    (click)="handleSelectAllBuildingsButtonClick()">
              select all
            </button>
            <button class="select-btn"
                    (click)="handleDeselectAllBuildingsButtonClick()">
              deselect all
            </button>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="!isSelectBuildingsModeEnabled">

          <dx-select-box [className]="'buildings-sort_select'"
                         placeholder="Sort buildings"
                         [(value)]="currentSorting"
                         [dataSource]="[
                           SortBuildings.NewestToOldest,
                           SortBuildings.Alphabet,
                           SortBuildings.Size
                         ]"
                         (valueChange)="onSortingChanged($event)">
          </dx-select-box>

          <div class="b-sort-button"
               *ngIf="pageCount > 1">
            <!--          For last page-->
            <ng-container *ngIf="page === pageCount && page > 3">
              <button (click)="onPageChanged($event, page - 2)">{{ page - 2 }}</button>
            </ng-container>

            <!--          example - (3, 4, 5) . 4-current page-->
            <button *ngIf="(page - 1) > 0 && (page - 1) < pageCount"
                    (click)="onPageChanged($event, page - 1)">{{ page - 1 }}
            </button>
            <button class="active" *ngIf="page > 0 && page <= pageCount"
                    (click)="onPageChanged($event, page)">{{ page }}
            </button>
            <button *ngIf="(page + 1) > 0 && (page + 1) <= pageCount"
                    (click)="onPageChanged($event, page + 1)">{{ page + 1 }}
            </button>

            <!--          For 1 page-->

            <ng-container *ngIf="page === 1 && pageCount >= 3">
              <button (click)="onPageChanged($event, 3)">{{ 3 }}</button>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <ng-container *ngFor="let building of buildings">

        <div class="col-4">
          <div class="b-building">
            <div class="building-select-control"
                 *ngIf="isSelectBuildingsModeEnabled && building?.id">
              <dx-check-box [value]="isBuildingSelected(building)"
                            (click)="handleBuildingSelectClick(building); $event.stopImmediatePropagation()">
              </dx-check-box>
            </div>
            <div class="building-image-wrapper">
              <div class="building-image-wrapper-gallery">
                <ng-container *ngIf="buildingPictures[building.id] as buildingPictures; else noBuildingImageTemplate">
                  <ng-container *ngIf="buildingPictures && buildingPictures.length; else noBuildingImageTemplate">
                    <app-building-attachments [attachments]="buildingPictures">
                    </app-building-attachments>
                  </ng-container>
                </ng-container>
                <ng-template #noBuildingImageTemplate>
                  <div class="b-building-background placeholder"
                       style="background-image: url(assets/img/landlord/dashboard/image-placeholder.svg)">
                  </div>
                </ng-template>
              </div>
            </div>


            <div class="b-building-info">
              <div class="b-list list-unstyled">
                <div *ngIf="building?.address">

                  <h1 class="b-building-name">{{ building?.name }}</h1>

                  <div class="b-list-header">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6 10L3.87867 7.65684C3.45911 7.19341 3.1734 6.60296 3.05764 5.96017C2.94189 5.31737 3.0013 4.6511 3.22837 4.0456C3.45543 3.4401 3.83995 2.92257 4.33329 2.55846C4.82664 2.19434 5.40666 2 6 2C6.59334 2 7.17336 2.19434 7.66671 2.55846C8.16005 2.92257 8.54457 3.4401 8.77163 4.0456C8.9987 4.6511 9.05811 5.31737 8.94236 5.96017C8.8266 6.60296 8.54089 7.19341 8.12133 7.65684L6 10ZM7.65 7.13622C7.9763 6.77576 8.1985 6.31653 8.28852 5.81658C8.37853 5.31664 8.33231 4.79843 8.1557 4.3275C7.97908 3.85657 7.68001 3.45407 7.29631 3.17088C6.9126 2.88769 6.46148 2.73654 6 2.73654C5.53852 2.73654 5.0874 2.88769 4.70369 3.17088C4.31999 3.45407 4.02092 3.85657 3.8443 4.3275C3.66769 4.79843 3.62147 5.31664 3.71148 5.81658C3.8015 6.31653 4.0237 6.77576 4.35 7.13622L6 8.95876L7.65 7.13622ZM6 6.05006C5.82319 6.05006 5.65362 5.97248 5.5286 5.83438C5.40357 5.69628 5.33333 5.50898 5.33333 5.31368C5.33333 5.11838 5.40357 4.93108 5.5286 4.79298C5.65362 4.65488 5.82319 4.5773 6 4.5773C6.17681 4.5773 6.34638 4.65488 6.4714 4.79298C6.59643 4.93108 6.66667 5.11838 6.66667 5.31368C6.66667 5.50898 6.59643 5.69628 6.4714 5.83438C6.34638 5.97248 6.17681 6.05006 6 6.05006Z"
                        fill="#8E939C"/>
                    </svg>

                    <div class="b-list-content">
                      <!--                      <span class="d-block">-->
                      <!--                        {{ building.address?.addressLine1 }} {{ building.address?.addressLine2 }}-->
                      <!--                      </span>-->

                      <span class="d-block">
                         {{ building.address?.city }}, {{ building.address?.stateCode }} {{ building.address?.zipCode }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-row flex-wrap justify-content-between">
                  <div>
                    <div *ngIf="building?.unitsNumber" class="units-part">
                      <div class="building-info-badge">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.11111 6.1444V2.5L10 4.9V10.5H2V4.9L5.11111 6.1444ZM6 3.8476V7.3756L2.88889 6.1312V9.7H9.11111V5.3748L6 3.848V3.8476Z"
                            fill="#424B5A"/>
                        </svg>
                        {{ building?.unitsNumber | safeNumber }} Total Unit(s)
                      </div>

                      <div class="building-info-badge">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.11111 6.1444V2.5L10 4.9V10.5H2V4.9L5.11111 6.1444ZM6 3.8476V7.3756L2.88889 6.1312V9.7H9.11111V5.3748L6 3.848V3.8476Z"
                            fill="#424B5A"/>
                        </svg>
                        {{ building?.availableUnitsNumber | safeNumber }} Unit(s) Available
                      </div>
                    </div>

                    <div class="sf-part">
                      <div *ngIf="building.totalBuildingSize">
                        <div class="building-info-badge">
                          <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M3.91293 7.39476L3.01894 8.2896L4.21149 9.48215L8.98212 4.7111L7.78957 3.51897L6.89431 4.41296L7.49101 5.00924L6.89431 5.60636L6.29803 5.00966L5.70176 5.60636L6.29803 6.20263L5.70176 6.79891L5.10548 6.20263L4.50878 6.79891L5.10548 7.39519L4.50878 7.99188L3.91293 7.39434V7.39476ZM8.0877 2.62413L9.87653 4.41296C9.95559 4.49204 10 4.59928 10 4.7111C10 4.82292 9.95559 4.93016 9.87653 5.00924L4.5092 10.3766C4.43012 10.4556 4.32288 10.5 4.21107 10.5C4.09925 10.5 3.99201 10.4556 3.91293 10.3766L2.1241 8.58774C2.04504 8.50866 2.00063 8.40142 2.00063 8.2896C2.00063 8.17778 2.04504 8.07054 2.1241 7.99146L7.49143 2.62413C7.57051 2.54508 7.67775 2.50067 7.78957 2.50067C7.90138 2.50067 8.00863 2.54508 8.0877 2.62413ZM6.89431 9.18359L7.49101 8.58732L8.43645 9.53318H9.03272V8.9369L8.08728 7.99104L8.68356 7.39476L9.79557 8.50635V10.2956H8.00674L6.89473 9.18359H6.89431ZM3.31665 5.60551L2.12368 4.41296C2.08447 4.3738 2.05337 4.32729 2.03214 4.2761C2.01092 4.2249 2 4.17003 2 4.11461C2 4.05919 2.01092 4.00432 2.03214 3.95313C2.05337 3.90194 2.08447 3.85543 2.12368 3.81626L3.31665 2.62371C3.39573 2.54466 3.50297 2.50024 3.61479 2.50024C3.72661 2.50024 3.83385 2.54466 3.91293 2.62371L5.10632 3.81626L4.5092 4.41296L3.61479 3.51812L3.01851 4.11482L3.91293 5.00882L3.31665 5.60551Z"
                              fill="#424B5A"/>
                          </svg>
                          {{ building?.totalBuildingSize | safeNumber }} SF
                        </div>
                      </div>
                      <div *ngIf="building?.totalBuildingSize" class="building-info-badge">
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M3.91293 7.39476L3.01894 8.2896L4.21149 9.48215L8.98212 4.7111L7.78957 3.51897L6.89431 4.41296L7.49101 5.00924L6.89431 5.60636L6.29803 5.00966L5.70176 5.60636L6.29803 6.20263L5.70176 6.79891L5.10548 6.20263L4.50878 6.79891L5.10548 7.39519L4.50878 7.99188L3.91293 7.39434V7.39476ZM8.0877 2.62413L9.87653 4.41296C9.95559 4.49204 10 4.59928 10 4.7111C10 4.82292 9.95559 4.93016 9.87653 5.00924L4.5092 10.3766C4.43012 10.4556 4.32288 10.5 4.21107 10.5C4.09925 10.5 3.99201 10.4556 3.91293 10.3766L2.1241 8.58774C2.04504 8.50866 2.00063 8.40142 2.00063 8.2896C2.00063 8.17778 2.04504 8.07054 2.1241 7.99146L7.49143 2.62413C7.57051 2.54508 7.67775 2.50067 7.78957 2.50067C7.90138 2.50067 8.00863 2.54508 8.0877 2.62413ZM6.89431 9.18359L7.49101 8.58732L8.43645 9.53318H9.03272V8.9369L8.08728 7.99104L8.68356 7.39476L9.79557 8.50635V10.2956H8.00674L6.89473 9.18359H6.89431ZM3.31665 5.60551L2.12368 4.41296C2.08447 4.3738 2.05337 4.32729 2.03214 4.2761C2.01092 4.2249 2 4.17003 2 4.11461C2 4.05919 2.01092 4.00432 2.03214 3.95313C2.05337 3.90194 2.08447 3.85543 2.12368 3.81626L3.31665 2.62371C3.39573 2.54466 3.50297 2.50024 3.61479 2.50024C3.72661 2.50024 3.83385 2.54466 3.91293 2.62371L5.10632 3.81626L4.5092 4.41296L3.61479 3.51812L3.01851 4.11482L3.91293 5.00882L3.31665 5.60551Z"
                            fill="#424B5A"/>
                        </svg>
                        {{ building?.availableSquareFeet | squareFootage | safeNumber: '1.0-0' }} SF Available
                      </div>
                    </div>
                  </div>
                  <a *ngIf="building?.id"
                     class="building-details__btn"
                     [routerLink]="['../building-desktop/' + building?.id]">

                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.32 15C0.32 6.89246 6.89246 0.32 15 0.32C23.1075 0.32 29.68 6.89246 29.68 15C29.68 23.1075 23.1075 29.68 15 29.68C6.89246 29.68 0.32 23.1075 0.32 15Z"
                        stroke="#B3B7BD" stroke-width="0.64"/>
                      <path
                        d="M18.6505 14.1251L13.957 9.43159L15.1943 8.19434L22 15.0001L15.1943 21.8058L13.957 20.5686L18.6505 15.8751H8V14.1251H18.6505Z"
                        fill="currentColor"/>
                    </svg>
                  </a>
                </div>

                <!-- TODO: This is temporarily disabled for all role types except Landlord -->
                <div *ngIf="building.id && userRole === Role.Landlord">
                  <ng-container *ngIf="!building.teamMembers?.length; else cardLeasingTeamTemplate">
                    <button type="button"
                            class="assign-leasing-team-btn"
                            (click)="handleBuildingAssignLeasingTeamButtonClick(building)">
                      <i inlineSVG='assets/icons/inlineSVG/lanlordBuildings.svg#plus'></i>
                      assign a leasing team
                    </button>
                  </ng-container>
                  <ng-template #cardLeasingTeamTemplate>
                    <div>
                      <div class="leasing-team__card-title">
                        Leasing team:
                      </div>

                      <div class="leasing-team">
                        <ng-container *ngFor="let user of building.teamMembers">
                          <div class="leasing-team-member">
                            <img [src]="user.avatar?.url || 'assets/img/no-image.svg'" [alt]="user.fullName"/>
                          </div>
                        </ng-container>
                        <button type="button"
                                class="leasing-team-edit-btn"
                                (click)="handleBuildingManageLeasingTeamButtonClick(building)"
                                #buildingLeasingTeamEditButton>
                          <i inlineSVG="assets/v2/icons/colabo-icons.svg#icon-edit"></i>
                        </button>
                        <dx-tooltip class="v2-default-tooltip"
                                    position="bottom"
                                    showEvent="dxhoverstart" hideEvent="dxhoverend"
                                    [target]="buildingLeasingTeamEditButton">
                          <div class="d-flex flex-column tooltip-menu">
                            Edit
                          </div>
                        </dx-tooltip>
                      </div>

                    </div>

                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <!--b-building-->
        </div>
        <!--col-->
      </ng-container>
    </div>
    <!--row-->
  </section>
</div>
