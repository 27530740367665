import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import * as models from '@statera/sdk/common';

@Injectable({
  providedIn: 'root',
})
export class StateraUserClaimStore {
  readonly stateraUserClaims: BehaviorSubject<models.IStateraUserClaimsSimplifiedViewModel>;

  constructor() {
    this.stateraUserClaims = new BehaviorSubject<models.IStateraUserClaimsSimplifiedViewModel>(null);
  }
}
