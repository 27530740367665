import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as models from '../../infrastructure/models/generated';
import { AuthService } from './auth.service';
import {StateraUserClaimManager} from '@statera/sdk/statera-user-claim';

@Injectable({
  providedIn: 'root'
})
export class StateraUserClaimService {
  private readonly _stateraUserClaimManager: StateraUserClaimManager;
  private readonly _authService: AuthService;
  private _currentUserId: number;

  constructor(authService: AuthService, stateraUserClaimManager: StateraUserClaimManager) {
    this._authService = authService;
    this._stateraUserClaimManager = stateraUserClaimManager;
    const infoLoadCompleteSub = this._authService
      .infoLoadComplete
      .subscribe(startupInfo => {
        if (startupInfo && startupInfo.id) {
          this._currentUserId = startupInfo.id;
          infoLoadCompleteSub.unsubscribe();
        }
      });
  }

  checkIfStateraUserClaimsWasUpdated(httpResponse: HttpResponse<any>) {
    if (this._currentUserId) {
      this._stateraUserClaimManager.checkIfStateraUserClaimsWasUpdated(httpResponse);
    }
  }

  requestStateraUserClaims(): Observable<models.IStateraUserClaimsSimplifiedViewModel> {
    return this._stateraUserClaimManager.requestStateraUserClaims();
  }
}
