import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TourClientVersionCompatibility } from '../../models/tour-client-version-compatibility.model';
import { TourProcessState } from '../../models/tour-process-state.model';

const WEEKDAYS = [
  {
    id: 0,
    name: 'Sunday',
    abbr: 'Sun',
  },
  {
    id: 1,
    name: 'Monday',
    abbr: 'Mon',
  },
  {
    id: 2,
    name: 'Tuesday',
    abbr: 'Tue',
  },
  {
    id: 3,
    name: 'Wednesday',
    abbr: 'Wed',
  },
  {
    id: 4,
    name: 'Thursday',
    abbr: 'Thu',
  },
  {
    id: 5,
    name: 'Friday',
    abbr: 'Fri',
  },
  {
    id: 6,
    name: 'Monday',
    abbr: 'Sat',
  },
];

const MONTHS = [
  {
    id: 0,
    name: 'January',
    abbr: 'Jan',
  },
  {
    id: 1,
    name: 'February',
    abbr: 'Feb',
  },
  {
    id: 2,
    name: 'March',
    abbr: 'Mar',
  },
  {
    id: 3,
    name: 'April',
    abbr: 'Apr',
  },
  {
    id: 4,
    name: 'May',
    abbr: 'May',
  },
  {
    id: 5,
    name: 'June',
    abbr: 'Jun',
  },
  {
    id: 6,
    name: 'July',
    abbr: 'Jul',
  },
  {
    id: 7,
    name: 'August',
    abbr: 'Aug',
  },
  {
    id: 8,
    name: 'September',
    abbr: 'Sep',
  },
  {
    id: 9,
    name: 'October',
    abbr: 'Oct',
  },
  {
    id: 10,
    name: 'November',
    abbr: 'Nov',
  },
  {
    id: 11,
    name: 'December',
    abbr: 'Dec',
  },
];

enum MonthRelativity {
  Current,
  Previous,
  Next,
}

interface Cell {
  isHidden: boolean;
  isToday: boolean;
  isDisabled: boolean;
  year: number;
  month: number;
  date: number;
  relativity: MonthRelativity;
}

const moment = TourClientVersionCompatibility.moment;

@Component({
  selector: 'app-tour-schedule-calendar',
  templateUrl: './tour-schedule-calendar.component.html',
})
export class TourScheduleCalendarComponent implements OnInit {
  readonly TourIconsAssetPath = TourClientVersionCompatibility.TourIconsAssetPath;

  readonly Weekdays = WEEKDAYS;
  readonly Months = MONTHS;

  @Input() state: TourProcessState;
  @Input() timezone: string;
  @Input() referencedDates: Array<moment.Moment> = [];

  @Output() dateSelected = new EventEmitter<moment.Moment>();

  selectedDate: moment.Moment;
  currentYear: number;
  currentMonth: number;

  rows: Array<Array<Cell>> = [];

  ngOnInit(): void {
    const now = moment().tz(this.timezone);

    if (this.referencedDates && this.referencedDates.length) {
      const isNowInReferencedDates = this.referencedDates
        ?.some((d) => (
          d.isSame(now)
        ));

      if (!isNowInReferencedDates) {
        this.selectedDate = this.referencedDates[0];
        this.currentYear = this.referencedDates[0].get('year');
        this.currentMonth = this.referencedDates[0].get('month');
      } else {
        this.selectedDate = now;
        this.currentYear = now.get('year');
        this.currentMonth = now.get('month');
      }
    } else {
      this.selectedDate = now;
      this.currentYear = now.get('year');
      this.currentMonth = now.get('month');
    }

    this.dateSelected.emit(this.selectedDate);

    this._updateCalendar(this.currentMonth, this.currentYear);
  }

  getDateString(): string {
    return [MONTHS[this.currentMonth]?.name, this.currentYear]
      .filter(Boolean)
      .join(' ');
  }

  isPreviousMonthButtonDisabled(): boolean {
    let now = new Date();
    now = new Date(now.getUTCFullYear(), now.getMonth(), now.getDate());
    return (
      this.currentYear === now.getFullYear() &&
      this.currentMonth === now.getMonth()
    );
  }

  isSelectedDate(cell: Cell): boolean {
    return (
      cell.relativity === MonthRelativity.Current &&
      this.selectedDate.get('year') === this.currentYear &&
      this.selectedDate.get('month') === this.currentMonth &&
      this.selectedDate.get('date') === cell.date
    );
  }

  isAvailableDate(cell: Cell): boolean {
    if (
      this.state === TourProcessState.Initial ||
      this.state === TourProcessState.Edit ||
      this.state === TourProcessState.ReplyReject
    ) {
      return (
        !cell.isHidden &&
        !cell.isDisabled
      );
    }

    return (
      !this.isSelectedDate(cell) &&
      !!this.referencedDates
        ?.find((d) => (
          d.get('year') === cell.year &&
          d.get('month') === cell.month &&
          d.get('date') === cell.date
        ))
    );
  }

  onNextMonthClick() {
    this.currentYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
    this.currentMonth = (this.currentMonth + 1) % 12;
    this._updateCalendar(this.currentMonth, this.currentYear);
  }

  onPreviousMonthClick() {
    this.currentYear = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
    this.currentMonth = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
    this._updateCalendar(this.currentMonth, this.currentYear);
  }

  onDateClick(cell: Cell) {
    let localDate: moment.Moment;

    if (cell.relativity === MonthRelativity.Previous) {
      localDate = moment([this.currentYear, this.currentMonth - 1, cell.date]);
    } else if (cell.relativity === MonthRelativity.Next) {
      localDate = moment([this.currentYear, this.currentMonth + 1, cell.date]);
    } else {
      localDate = moment([this.currentYear, this.currentMonth, cell.date]);
    }

    this.selectedDate = localDate;
    this.dateSelected.emit(localDate);
  }

  private _daysInMonth(month: number, year: number): number {
    return 32 - new Date(year, month, 32).getDate();
  }

  private _daysInPreviousMonth(): number {
    const year = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
    const month = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
    return this._daysInMonth(month, year);
  }

  private _updateCalendar(month: number, year: number) {
    const firstDay = (new Date(year, month)).getDay();
    const today = new Date();
    const daysInCurrentMonth = this._daysInMonth(month, year);
    const daysInPreviousMonth = this._daysInPreviousMonth();

    const rows = [];
    let date = 1;
    for (let rowNum = 0; rowNum < 6 && date <= daysInCurrentMonth; rowNum++) {
      const row: Array<Cell> = [];

      for (let colNum = 0; colNum < 7; colNum++) {
        if (rowNum === 0 && colNum < firstDay) {
          row.push({
            year: year,
            month: month,
            date: daysInPreviousMonth - (firstDay - colNum - 1),
            isHidden: true,
            isDisabled: true,
            isToday: false,
            relativity: MonthRelativity.Previous,
          });
        } else if (date > daysInCurrentMonth) {
          row.push({
            year: year,
            month: month,
            date: date % daysInCurrentMonth,
            isHidden: true,
            isDisabled: true,
            isToday: false,
            relativity: MonthRelativity.Next
          });

          date++;
        } else {
          row.push({
            year: year,
            month: month,
            date: date,
            isHidden: false,
            isDisabled:
              (this.currentYear === today.getFullYear() && this.currentMonth === today.getMonth() && date < today.getDate()) ||
              (this.currentYear === today.getFullYear() && this.currentMonth < today.getMonth()) ||
              (this.currentYear < today.getFullYear()),
            isToday: date === today.getDate() && this.currentYear === today.getFullYear() && this.currentMonth === today.getMonth(),
            relativity: MonthRelativity.Current,
          });

          date++;
        }
      }

      rows.push(row);
    }

    this.rows = rows;
  }
}
