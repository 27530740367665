import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AlertManager } from '@statera/sdk/alert';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { Role } from '@statera/sdk/auth';
import { MessageManagerFactory, TermCommentManager } from '@statera/sdk/message';
import { TermManager } from '@statera/sdk/term';

import { ProjectAccessService } from '../../../../shared/services/project-access.service';
import { AuthService } from '../../../../auth/services/auth.service';

import { AvatarListItem } from '../../../../shared/components/avatar-list/avatar-list.component';

import * as models from '../../../../infrastructure/models/generated';
import { MessengerContext } from '../../../../shared/components/messages/messages.component';

enum ChannelTabType {
  Internal = 'Internal Comments',
  External = 'External Comments'
}
@Component({
  selector: 'app-term-comments',
  templateUrl: './term-comments.component.html',
  styleUrls: ['./term-comments.component.scss'],
})
export class TermCommentsComponent implements OnChanges, OnDestroy {
  @Input() lease: models.ILeaseViewModel;
  @Input() leaseTermConfiguration: models.ILeaseTermConfigurationViewModel;

  @Output() closeComments: EventEmitter<void> = new EventEmitter();

  MessengerContext: typeof MessengerContext = MessengerContext;

  message: string;
  startupInfo: models.IStartupInfoViewModel;
  termCommentManager: TermCommentManager = null;
  StateraClaimType = models.StateraClaimTypeAsEnum;
  StateraClaimValue = models.StateraClaimValueAsEnum;
  allowAdd = true;
  termTypeComment: models.TermCommentType;
  tabs = [
    {text: ChannelTabType.Internal, placeholder: 'Reply to internal team here...'},
    {text: ChannelTabType.External, placeholder: 'Reply to both teams here...'}
  ];
  selectedTabIndex = 0;
  commentProfiles: Array<models.IProfileViewModel>;
  avatarItems: Array<AvatarListItem>;

  private _alertGroupSubscription: Subscription;

  private readonly _authService: AuthService;
  private readonly _termManager: TermManager;
  private readonly _messageManagerFactory: MessageManagerFactory;
  private readonly _projectAccessService: ProjectAccessService;
  private readonly _activatedRoute: ActivatedRoute;
  private readonly _destroy$: Subject<void>;

  constructor(
    authService: AuthService,
    termManager: TermManager,
    messageManagerFactory: MessageManagerFactory,
    projectAccessService: ProjectAccessService,
    activatedRoute: ActivatedRoute
  ) {
    this._authService = authService;
    this._termManager = termManager;
    this._messageManagerFactory = messageManagerFactory;
    this._projectAccessService = projectAccessService;
    this._activatedRoute = activatedRoute;
    this._destroy$ = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    let lease = null;
    if (
      changes.lease &&
      (
        changes.lease.isFirstChange() ||
        (
          changes.lease.previousValue !== changes.lease.currentValue
        )
      )
    ) {
      lease = changes.lease.currentValue;
    }

    let leaseTermConfiguration = null;
    if (
      changes.leaseTermConfiguration &&
      (
        changes.leaseTermConfiguration.isFirstChange() ||
        (
          changes.leaseTermConfiguration.previousValue !== changes.leaseTermConfiguration.currentValue
        )
      )
    ) {
      leaseTermConfiguration = changes.leaseTermConfiguration.currentValue;
    }

    if (leaseTermConfiguration || lease) {
      leaseTermConfiguration = leaseTermConfiguration ? leaseTermConfiguration : this.leaseTermConfiguration;
      lease = lease ? lease : this.lease;

      this._selectChannelType(this.tabs[this.selectedTabIndex].text);
      this._getTermComments(lease, leaseTermConfiguration, this.termTypeComment);
    }

    this._activatedRoute
      .queryParams
      .pipe(
        tap(params => {
          const chatTabName = params['chatTab'] === 'External Comments'
            ? ChannelTabType.External
            : ChannelTabType.Internal;
          this._setChatTabIndex(chatTabName);
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this._alertGroupSubscription) {
      this._alertGroupSubscription.unsubscribe();
      this._alertGroupSubscription = null;
    }

    this._destroy$.next();
    this._destroy$.complete();
  }

  selectionChanged($event) {
    const selectedTab = this.tabs[this.selectedTabIndex];
    this._selectChannelType(selectedTab.text);
    this._getTermComments(this.lease, this.leaseTermConfiguration, this.termTypeComment);
  }

  isExternalChannel(): boolean {
    return this.termTypeComment === models.TermCommentType.BothTeams;
  }

  private _selectChannelType(channelTabType: ChannelTabType) {
    if (channelTabType === ChannelTabType.External) {
      this.termTypeComment = models.TermCommentType.BothTeams;
    }

    if (channelTabType === ChannelTabType.Internal) {
      const roleInLease = this._projectAccessService.getTurnRole(this.lease);

      if (roleInLease === Role.Tenant) {
        this.termTypeComment = models.TermCommentType.TenantTeam;
      }

      if (roleInLease === Role.Landlord) {
        this.termTypeComment = models.TermCommentType.LandlordTeam;
      }
    }
  }

  private _getTermComments(
    lease: models.ILeaseViewModel,
    leaseTermConfiguration: models.ILeaseTermConfigurationViewModel,
    termCommentType: models.TermCommentType
  ): void {
    if (!leaseTermConfiguration) {
      return;
    }

    this.termCommentManager = this._messageManagerFactory
      .createTermCommentManager(
        this._authService.startupInfo,
        leaseTermConfiguration.leaseTermType,
        termCommentType,
        lease,
      );

    this._termManager
      .getCommentProfiles(lease.id, termCommentType)
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(x => {
        this.commentProfiles = x;
        this.avatarItems = this._getAvatarList(this.commentProfiles);
      });
  }

  private _getAvatarList(profiles: Array<models.IProfileViewModel>) {
    return profiles.map(profile => new AvatarListItem(profile.displayName, profile.firstName,
      profile.lastName, profile.avatar ? profile.avatar.url : null, profile.company.name));
  }

  private _setChatTabIndex(tabName: string) {
    this.selectedTabIndex = this.tabs.findIndex(t => t.text === tabName);
  }
}
