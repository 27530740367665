export enum GeoJSONObjectType
{
	Point = 0,
	MultiPoint = 1,
	LineString = 2,
	MultiLineString = 3,
	Polygon = 4,
	MultiPolygon = 5,
	GeometryCollection = 6,
	Feature = 7,
	FeatureCollection = 8,
}
export interface IGeoJSONObject
{
	boundingBoxes: number[];
	cRS: IICRSObject;
	type: GeoJSONObjectType;
}
export interface IICRSObject
{
	type: CRSType;
}
export enum CRSType
{
	Unspecified = 0,
	Name = 1,
	Link = 2,
}
export interface IPolygon extends IGeoJSONObject
{
	type: GeoJSONObjectType;
	coordinates: ILineString[];
}
export interface ILineString extends IGeoJSONObject
{
	type: GeoJSONObjectType;
	coordinates: IIPosition[];
}
export interface IIPosition
{
	altitude: number;
	latitude: number;
	longitude: number;
}
export interface IIdentityRole<TKey>
{
	id: TKey;
	name: string;
	normalizedName: string;
	concurrencyStamp: string;
}
export interface IIdentityUserRole<TKey>
{
	userId: TKey;
	roleId: TKey;
}
export interface IIdentityUser<TKey>
{
	id: TKey;
	userName: string;
	normalizedUserName: string;
	email: string;
	normalizedEmail: string;
	emailConfirmed: boolean;
	passwordHash: string;
	securityStamp: string;
	concurrencyStamp: string;
	phoneNumber: string;
	phoneNumberConfirmed: boolean;
	twoFactorEnabled: boolean;
	lockoutEnd: IDateTimeOffset;
	lockoutEnabled: boolean;
	accessFailedCount: number;
}
export interface IHierarchyId
{
}
export interface IPeriod
{
	zero: IPeriod;
	maxValue: IPeriod;
	minValue: IPeriod;
	additiveIdentity: IPeriod;
	normalizingEqualityComparer: IIEqualityComparer<IPeriod>;
	nanoseconds: number;
	ticks: number;
	milliseconds: number;
	seconds: number;
	minutes: number;
	hours: number;
	days: number;
	weeks: number;
	months: number;
	years: number;
	hasTimeComponent: boolean;
	hasDateComponent: boolean;
}
export interface IShortLeaseViewModel
{
	id: number;
	leaseId: number;
	buildingId: number;
	buildingUnitId: number;
	tenantCompanyName: string;
	landlordCompanyName: string;
	buildingName: string;
	buildingUnitName: string;
}
export interface ILeaseAbstractAnalyzeResult
{
	formRecognitionResult: IFormRecognitionResult;
	termExtractionResult: ITermExtractionResultSimplifiedView;
}
export interface IUserViewModel
{
	id: number;
	firstName: string;
	lastName: string;
	primaryEmail: string;
	mobilePhone: string;
	additionalEmailAddress: string;
	userStatus: UserStatus;
	documentsMode: DocumentsMode;
	emailDuplicatingToAdminEnabled: boolean;
	investorType: InvestorType;
	markets: string;
	portfolioSizeOffice: number;
	portfolioSizeIndustrial: number;
	portfolioSizeLand: number;
	portfolioSizeRetail: number;
	role: string;
	emailConfirmed: boolean;
	spaceUseId: number;
	industryId: number;
}
export interface IDemographicsReport
{
	name: string;
	units: DemographicsReportUnits;
	areas: IDemographicsReportArea[];
}
export interface IDemographicsReportArea
{
	extent: number;
	label: string;
	geometry: IPolygon;
	variables: IDemographicsReportVariable[];
}
export interface IDemographicsReportVariable
{
	type: DemographicsReportVariableType;
	description: string;
	value: string;
}
export enum DemographicsReportUnits
{
	Miles = 1,
	Minutes = 2,
}
export interface IFormRecognitionResult
{
	pages: IPage[];
}
export interface IPage
{
	number: number;
	angle: number;
	width: number;
	height: number;
	unit: string;
	pageContents: IPageContent[];
}
export interface IPageContent
{
	kind: PageContentKind;
	pageNumber: number;
	boundingBox: IBoundingBox;
	textLines: ITextLine[];
	words: IWord[];
}
export interface IWord
{
	text: string;
	pageNumber: number;
	boundingBox: IBoundingBox;
}
export interface ITextLine
{
	text: string;
	pageNumber: number;
	boundingBox: IBoundingBox;
}
export interface IBoundingBox
{
	points: IPoint[];
	hashCode: number;
	topLeft: IPoint;
	topRight: IPoint;
	bottomRight: IPoint;
	bottomLeft: IPoint;
}
export interface IPoint
{
	x: number;
	y: number;
}
export enum PageContentKind
{
	PageHeader = 1,
	PageFooter = 2,
	PageHeading = 3,
	PageSectionHeading = 4,
	PageParagraph = 5,
	PageFootnote = 6,
	PageNumber = 7,
	PageTable = 8,
	PageCompound = 9,
	PageSegregatedCompound = 10,
}
export interface ITermExtractionResultSimplifiedView
{
	commencement: IExtractionResultSimplifiedView[];
	expiration: IExtractionResultSimplifiedView[];
	length: IExtractionResultSimplifiedView[];
	squareFootage: IExtractionResultSimplifiedView[];
	operatingExpenses: IExtractionResultSimplifiedView[];
	realEstateTaxes: IExtractionResultSimplifiedView[];
	securityDeposit: IExtractionResultSimplifiedView[];
	baseRentalRate: IExtractionResultSimplifiedView[];
	freeRent: IExtractionResultSimplifiedView[];
	rentalRateEscalation: IExtractionResultSimplifiedView[];
	buildingPercentage: IExtractionResultSimplifiedView[];
}
export interface IExtractionResultSimplifiedView
{
	phrase: IPhraseNodeSimplifiedView;
	table: IPhraseNodeSimplifiedView[];
	result: any;
	found: { [index: string]: IPhraseNodeSimplifiedView };
	spans: IExtractionResultSpan[];
}
export interface IPhraseNodeSimplifiedView
{
	rawString: string;
	tokens: ITokenSimplifiedView[];
}
export interface ITokenSimplifiedView
{
	rawValue: string;
	value: string;
	numberOfTrailingSpaces: number;
}
export interface IExtractionResultSpan
{
	pageNumber: number;
	boundingBox: IBoundingBox;
}
export enum LandlordDealsByStageReportType
{
	Basic = 0,
	LeasingActivity = 1,
	Inquiries = 2,
}
export enum LandlordDealsByStageReportFileType
{
	Pdf = 0,
	Excel = 1,
}
export enum InquiriesReportExportField
{
	Building = 0,
	Unit = 1,
	Date = 2,
	Tenant = 3,
	SizeRequested = 4,
	InquiryType = 5,
	BrokerNameAndCompany = 6,
	TargetOccupancy = 7,
	DaysSinceInquiry = 8,
	Comments = 9,
}
export enum TenantRequestType
{
	Renewal = 0,
	RenewalInitiatedByLandlord = 1,
	Restructure = 2,
	NewDeal = 3,
	Inquiry = 4,
	Tour = 5,
}
export enum LeasingActivityReportExportField
{
	Date = 0,
	Tenant = 1,
	DealType = 2,
	DealStage = 3,
	BrokerNameAndCompany = 4,
	Size = 5,
	Unit = 6,
	CancelReason = 7,
	ProposedBy = 8,
	Term = 9,
	Commencement = 10,
	Expiration = 11,
	BaseRent = 12,
	Escalations = 13,
	FreeRent = 14,
	Commissions = 15,
	TenantImprovements = 16,
	DealNotes = 17,
}
export enum ProjectTypeEnum
{
	Renewal = 1,
	Restructure = 2,
	Maintenance = 3,
	InsuranceCertificate = 4,
	RenewalInitiatedByLandlord = 5,
	NewDeal = 6,
	NewDealInquiry = 7,
}
export enum LeaseTeamUserRole
{
	Tenant = 0,
	Landlord = 1,
	Broker = 2,
	CoBroker = 3,
}
export enum PointOfInterestStatus
{
	Draft = 1,
	Published = 2,
}
export enum PointOfInterestKind
{
	Port = 1,
	IntermodalTerminal = 2,
	CentralBusinessDistrict = 3,
	Airport = 4,
}
export enum PortfolioInvitationUserAcceptanceStatus
{
	Pending = 1,
	Accepted = 2,
	Rejected = 3,
}
export enum LeaseTeam
{
	TenantTeam = 1,
	LandlordTeam = 2,
}
export enum LeaseUserRole
{
	Broker = 1,
	CoBroker = 2,
	Tenant = 3,
	RepBroker = 4,
}
export enum CompanyType
{
	TenantCompany = 1,
	LandlordCompany = 2,
	BrokerCompany = 3,
}
export enum ChatMessageKind
{
	General = 1,
	FloorPlanBudgetChange = 2,
}
export enum PlanAnchorAmountAcceptanceStatus
{
	Pending = 1,
	Accepted = 2,
	Rejected = 3,
}
export enum InsuranceCertificateTriggerType
{
	DoLandlordFirstStep = 1,
	DoTenantStep = 2,
	DoLandlordStep = 3,
	RejectUpdatedInsuranceCertificate = 4,
	Finish = 5,
}
export enum LeaseRequestStatus
{
	Requested = 1,
	ReviewRequired = 2,
	Accepted = 3,
	Rejected = 4,
	Skipped = 5,
	Cancelled = 6,
}
export enum LeaseRequestType
{
	FinancialRequest = 1,
	TourRequest = 2,
}
export enum LandlordMaintenanceTermType
{
	PerCurrentLease = 1,
	SoleMaintenanceByLandlord = 2,
	Custom = 3,
}
export enum ImportTaskStatus
{
	NotStarted = 0,
	Running = 1,
	Success = 2,
	WithErrors = 3,
	Failed = 4,
}
export enum ImportTaskType
{
	ImportBuildingsTask = 0,
	ImportPointsOfInterestTask = 1,
	ImportLeaseAbstractsTask = 2,
	ImportDocumentMarkersTask = 3,
}
export enum TenantImprovementsAmortizationOption
{
	AmortizationOption = 0,
	NoAmortizationAvailable = 1,
}
export enum RequestedDocumentType
{
	LeaseDocuments = 1,
}
export enum TerminationOptionFeeType
{
	AmortizationRate = 1,
	DollarValue = 2,
}
export enum BuildingAreaType
{
	InteriorOffice = 0,
	InteriorWarehouse = 1,
	Exterior = 2,
}
export enum InvestorType
{
	Owner = 0,
	Manager = 1,
	Developer = 2,
}
export enum UserStatus
{
	Activated = 0,
	Deactivated = 1,
}
export enum VersionedFileType
{
	LeaseAmendment = 0,
}
export enum InsuranceTermType
{
	PerCurrentLease = 1,
	Custom = 2,
}
export enum EscalationTermType
{
	None = 1,
	FixedPercentagePerYear = 2,
	FixedAmountPsfPerYear = 3,
	Custom = 4,
	MultiplyOptions = 5,
	RequestMultiplyOptions = 6,
}
export enum RentalRateEscalationTaxType
{
	NetRentalRate = 1,
	GrossRentalRate = 2,
	BaseRentalRate = 3,
}
export enum HvacTermType
{
	PerCurrentLease = 1,
	ResponsibilityByTenant = 2,
	MoneyValue = 3,
	PercentValue = 4,
	CustomValue = 5,
}
export enum RealEstateTaxesCamExpensesTermType
{
	PerCurrentLease = 1,
	PercentagePerYear = 2,
	RetainingByTenant = 3,
	Custom = 4,
	EstimatedReTaxesOpEx = 5,
	ReOpExIncludedInGross = 6,
	TenantPayItsProRataShareOfExpenses = 7,
	TenantPayItsProRataShareOfBuildingsActualOperatingExpenses = 8,
}
export enum SecurityDepositTermType
{
	PerCurrentLease = 1,
	MonthsCount = 2,
	MoneyValue = 3,
	ReducingValue = 4,
	Guaranty = 5,
	LetterOfCredit = 6,
	DeterminedUponReviewOfTenantFinancials = 7,
}
export enum LeaseTermType
{
	Default = 1,
	Expiration = 2,
	ExpansionOption = 3,
	Commencement = 4,
	Term = 5,
	TenantSquareFootage = 6,
	FreeRent = 7,
	BaseRentalRate = 8,
	SecurityDeposit = 9,
	RealEstateTaxesCamExpenses = 10,
	RentalRateAnnualEscalation = 11,
	TenantImprovements = 12,
	LandlordMaintenance = 13,
	TenantMaintenance = 14,
	SelfHelp = 15,
	Assignment = 16,
	RenewalOption = 17,
	EstoppelCertificate = 18,
	TerminationOption = 19,
	CamExpenses = 20,
	Hvac = 21,
	RealEstateTaxes = 22,
	BuildingPercentage = 23,
	Insurance = 24,
	HoldoverProvision = 25,
	ForceMajeure = 26,
	Parking = 27,
	SpaceUse = 28,
	ConditionOfPremises = 29,
	CodeCompliance = 30,
	Utilities = 31,
	Signage = 32,
	NonDisturbance = 33,
	HazardousMaterials = 34,
	Rail = 35,
}
export enum BaseRentalRateUnitMetrics
{
	PsfPerYear = 1,
	PsfPerMonth = 2,
}
export enum BaseRentalRateType
{
	None = 1,
	Net = 2,
	Gross = 3,
	BaseYear = 4,
	MultiplyOptions = 5,
	RequestMultiplyOptions = 6,
}
export enum TenantSquareFootageTermType
{
	ExistingSquareFootage = 1,
	Custom = 2,
	PhaseIn = 3,
	MultipleOptions = 4,
	Expand = 5,
	Relocate = 6,
	Downsize = 7,
	ExistingUnitSize = 8,
}
export enum AppointmentType
{
	TenantImprovementsVisit = 0,
}
export enum TenantImprovementsType
{
	AsIs = 0,
	TurnKey = 1,
	DollarAllowance = 2,
	Custom = 3,
	CustomAmortized = 4,
	FloorPlanRequested = 5,
}
export enum AlertType
{
	Message = 1,
	TermComment = 2,
	StateChange = 3,
	FloorPlanAnchorComment = 4,
	VersionedFileComment = 5,
	FloorPlanAlert = 6,
	RequestLeaseDocumentsFromLandlord = 7,
	SharedDocument = 8,
	DocumentSigningError = 9,
	ContractorVisitReminder = 10,
	LeaseAlert = 11,
	FinancialsRequest = 12,
	FinancialsReviewRequest = 13,
	StateChangeCOIRequestFinished = 14,
	NewRenewalOrRestructureRequest = 15,
	CoBrokerInvitationByBroker = 16,
	BuildingUnitListingUpdatePricePrompt = 17,
	LeaseFloorPlanComment = 18,
	InquiriesAndRequestsUpdatePrompt = 19,
	TourCreatedOwnTeamAlert = 20,
	TourCreatedOtherTeamAlert = 21,
	TourUpdatedOwnTeamAlert = 22,
	TourUpdatedOtherTeamAlert = 23,
	TourCancelledAlert = 24,
	TourConfirmedOwnTeamAlert = 25,
	TourConfirmedOtherTeamAlert = 26,
}
export enum PlanAnchorType
{
	Circle = 0,
	Rect = 1,
	Cross = 2,
	Polygon = 3,
}
export enum RenewalProjectTemplateItemType
{
	PendingInternalApproval = 53,
	EstablishCriteria = 10000,
	EstablishCriteriaByLandlord = 10202,
	Restructure_EstablishCriteriaByLandlord = 10204,
	EstablishCriteriaByTenant = 10301,
	Restructure_EstablishCriteriaByTenant = 10303,
	UnsolicitedOffer = 11000,
	UnsolicitedOfferByLandlord = 11205,
	UnsolicitedOfferLandlordReviewingFinancialDocuments = 11208,
	NewDealInquiryByTenant = 11300,
	UnsolicitedOfferByTenant = 11306,
	UnsolicitedOfferRenewalWizard = 11307,
	NewDeal_3rdPartyLeaseByAdmin = 12109,
	SendRfp = 20000,
	TenantCounterUnsolicitedOffer = 21000,
	ReceiveAndAnalyzeResponse = 30000,
	ReviewTenantImprovementsByLandlord = 30201,
	ReviewTenantImprovementsSelectMultiplyOptionsByLandlord = 30203,
	LandlordCounterOffer = 30206,
	ReviewTenantImprovementsSelectMultiplyOptionsTenant = 30304,
	TenantCounterOffer = 30305,
	NewDealInquiryConvertToDeal = 32200,
	LetterOfIntent = 40000,
	FinalizeBusinessTermsByLandlord = 40219,
	FinalizeBusinessTermsByTenant = 40318,
	LeaseDocuments = 50000,
	LeaseAmendmentSigned = 50013,
	CleanCopyPreparationByAdmin = 50110,
	LeaseDocumentsFirstStepByLandlord = 50205,
	LeaseDocumentsStepByLandlord = 50207,
	CleanCopySubmittalByLandlord = 50208,
	LeaseAmendmentSignatureByLandlord = 50212,
	LeaseDocumentsStepByTenant = 50306,
	CleanCopyReceiptByTenant = 50309,
	LeaseAmendmentSignatureByTenant = 50311,
}
export enum SprinklerSystem
{
	ESFR = 0,
	Yes = 1,
	WetSystem = 2,
	DrySystem = 3,
	ClassIV = 4,
}
export enum LeedCertification
{
	Certified = 0,
	Gold = 1,
	Silver = 2,
	Platinum = 3,
	None = 4,
}
export enum TermStatus
{
	Draft = 0,
	Pending = 1,
	Accepted = 2,
	Rejected = 3,
	Ready = 4,
}
export enum ChatChannelType
{
	Lease = 0,
	Building = 1,
	Community = 2,
	LeaseLandlordTeam = 3,
	LeaseTenantTeam = 4,
}
export enum TenantStatus
{
	In_Progress = 0,
	Completed = 1,
}
export enum PortfolioSize
{
	ForOffice = 0,
	Industrial = 1,
	Land = 2,
	Retail = 3,
}
export enum BuildingType
{
	Industrial = 0,
	Office = 1,
	Land = 2,
	Retail = 3,
}
export enum DocumentTemplateFormat
{
	Razor = 0,
}
export enum DocumentTemplateType
{
	None = 0,
	PasswordResetRequestTemplate = 3,
	VerifyEmailRequestTemplate = 4,
	TenantManagerNotification = 5,
	IntentToRenewNotification = 6,
	FloorPlanRequestEventNotification = 7,
	LetterOfIntent = 8,
	AlertEmail = 9,
	CashFlowReportAvailabilityTemplate = 10,
	Universal = 11,
	LeaseDocuments = 12,
	PdfHeaderTemplate = 13,
	PdfFooterTemplate = 14,
	DocumentAttachedTemplate = 15,
	UpcomingContractorVisitReminder = 16,
	CompletedContractorVisitReminder = 17,
	TermsSummary = 18,
	TermsSummaryPdfHeader = 19,
	TenantRenewalRequestTemplate = 20,
	RequestToSignDocument = 21,
	UserInvitation = 22,
	ChatMessage = 23,
	TermComment = 24,
	PasswordResetTemplate = 25,
}
export enum FolderType
{
	None = 0,
	Documents = 1,
}
export enum TreeItemType
{
	Folder = 0,
	Document = 1,
	File = 2,
	LeaseDocuments = 3,
}
export enum TreeOperationType
{
	Copy = 0,
	Move = 1,
	Restore = 2,
}
export enum QuizStepType
{
	Initial = 1,
	Default = 2,
	Financials = 3,
	Broker = 4,
	Tenant = 5,
	Tour = 6,
	Final = 7,
}
export enum QuestionType
{
	Choice = 0,
	MultipleChoice = 1,
	Text = 2,
	File = 3,
	YesNo = 4,
	Form = 5,
	Done = 6,
	Calendar = 7,
	Range = 8,
	Number = 9,
	MultipleNumber = 10,
	SqftNumber = 11,
	Address = 12,
	FinancialDocuments = 13,
	MultipleChoiceMaxTwoItems = 15,
	NumberWithExpirationDate = 16,
	BrokerInvitation = 17,
	DollarNumber = 18,
	SelectCities = 19,
	IdealUnitSize = 20,
	MonthlyBudget = 21,
	LeaseExpiration = 22,
	BuildingSpecs = 23,
	LeaseTerm = 24,
	LeaseAmendments = 25,
	SpecificUseForBuildingAnswer = 26,
	ChoiceToggle = 27,
	Textarea = 28,
	DateOrDateRange = 29,
	TenantInvitation = 30,
	Tour = 31,
}
export enum LeaseStatus
{
	Active = 0,
	Expired = 1,
	Rejected = 2,
	Cancelled = 3,
	InProcess = 4,
	Draft = 5,
}
export enum DocumentsMode
{
	Basic = 0,
	Extended = 1,
}
export enum BuildingClass
{
	A = 0,
	B = 1,
	C = 2,
}
export enum TourStatus
{
	WaitingForApproval = 0,
	Scheduled = 1,
	Archived = 2,
}
export enum AvailableUnitsForbiddenResultReason
{
	AccessDenied = 0,
	PendingAcceptance = 1,
}
export enum TenantRequestStatus
{
	Pending = 1,
	Active = 2,
	Archived = 3,
}
export enum DemographicsReportVariableType
{
	TotalPopulation = 0,
	TotalWorkforce = 1,
	TotalWhiteCollarWorkers = 2,
	TotalBlueCollarWorkers = 3,
	TotalCollegeDegrees = 4,
	TotalHighSchoolDegrees = 5,
	MedianHouseholdIncome = 6,
	UnemploymentRate = 7,
	MeanTravelTimeToWork = 8,
}
export enum BuildingFileFolderType
{
	BuildingInsurance = 1,
	MaintenanceContracts = 2,
	Surveys = 3,
	BuildingInspections = 4,
	Permits = 5,
	Warranties = 6,
	BuildingPhotos = 7,
	Brochures = 8,
	MakeReady = 9,
	TenantImprovements = 10,
	Shared = 11,
	Miscellaneous = 12,
	Admin = 13,
	SitePlans = 14,
}
export enum LeaseFileFolderType
{
	TenantInsurance = 1,
	MaintenanceContracts = 2,
	Plans = 3,
	Financials = 4,
	LetterOfIntent = 5,
	Construction = 7,
	Shared = 8,
	Miscellaneous = 9,
	LeaseDocuments = 10,
	SummaryOfTerms = 11,
}
export enum TermType
{
	Text = 0,
	Number = 1,
	Date = 2,
}
export enum GreenCertification
{
	None = 0,
	LeedCertified = 1,
	LeedSilver = 2,
	LeedGold = 3,
	LeedPlatinum = 4,
	EnergyStar = 5,
	Breeam = 6,
	GreenGlobe = 7,
	LivingBuildingChallenge = 8,
	NationalGreenBuildingStandard = 9,
	GreenGuard = 10,
	WellBuildingStandard = 11,
	Fitwell = 12,
	Other = 13,
	GreenGlobeGold = 14,
	GreenGlobePlatinum = 15,
}
export enum AlertIntervalType
{
	Off = 1,
	Day = 4,
	Week = 5,
	Month = 6,
	Year = 7,
}
export enum VersionedFileCommentType
{
	BothTeams = 1,
	LandlordTeam = 2,
	TenantTeam = 3,
}
export enum BuildingUnitListingPriceType
{
	Negotiable = 1,
	PsfValue = 2,
}
export enum BuildingUnitListingPriceUnitMetrics
{
	PsfPerYear = 1,
	PsfPerMonth = 2,
}
export enum BuildingUnitListingPriceStructure
{
	Net = 1,
	Gross = 2,
	BaseYear = 3,
}
export enum PortfolioInvitationKind
{
	Email = 1,
	Link = 2,
}
export enum UserInvitationRequestKind
{
	Unknown = 0,
	Lease = 1,
	Portfolio = 2,
	Building = 3,
}
export enum UserInvitationRequestStatus
{
	Pending = 1,
	UserAcceptance = 2,
	Accepted = 3,
	UserAcceptanceReadTracked = 4,
	UserAcceptanceClickTracked = 5,
}
export enum TermTypeEnum
{
	None = 0,
	Custom = 1,
	MultiplyOptions = 2,
	MultipleOptionsWithCustomValue = 3,
}
export enum RenewalProjectTriggerType
{
	SendOfferForApprovalByBroker = 0,
	SendOffer = 1,
	ReviewTenantImprovementsByLandlord = 2,
	ReviewTenantImprovementsByTenant = 3,
	ReviewTenantImprovementsByTenantWhenFloorPlanBypassed = 4,
	CounterOffer = 5,
	SendToReviewTiList = 6,
	ApproveTiListCommand = 7,
	ApproveTiListCostsCommand = 8,
	FloorPlanContractorVisitNegotiationByLandlord = 9,
	SendToReviewTiListCosts = 10,
	GenerateSignDocument = 11,
	SignDocument = 12,
	TiFloorPlanEdit = 13,
	TiNegotiationByTenantIfTenantCancelPlanIntoFloorPlan = 14,
	CancelTi = 15,
	SubmitUploadedLeaseDocumentDraft = 16,
	GoToSignature = 17,
	AcceptLeaseDocument = 18,
	EstablishCriteriaByTenant = 19,
	EstablishCriteriaByLandlord = 20,
	TiNegotiationByTenantOrLandlordIfLandlordCancelPlan = 21,
	ChooseTiOptionAfterFloorPlan = 22,
	ApproveAppointment = 23,
	BypassContractorVisit = 24,
	ReviewTenantImprovementsSelectMultiplyOptionsByLandlord = 25,
	ReviewTenantImprovementsSelectMultiplyOptionsTenant = 26,
	UnsolicitedOfferByLandlord = 27,
	AcceptUnsolicitedOfferByTenant = 28,
	RejectUnsolicitedOfferByTenant = 29,
	UnsolicitedOfferRenewalWizard = 30,
	TenantCounterUnsolicitedOffer = 31,
	UnsolicitedOfferLandlordReviewedFinancialDocuments = 32,
	RescheduleContractorVisit = 33,
	ProceedToBudget = 34,
	RejectEstablishCriteriaByLandlord = 35,
	FinalizeLeaseNegotiation = 36,
	CleanCopyPreparationByAdmin = 37,
	Restructure_EstablishCriteriaByTenant = 101,
	Restructure_EstablishCriteriaByLandlord = 102,
	Restructure_RejectEstablishCriteriaByLandlord = 103,
	NewDeal_EstablishCriteriaByTenant = 201,
	NewDeal_3rdPartyLeaseByAdmin = 202,
	NewDeal_SubmitInquiryByTenant = 203,
	UnsolicitedOffer_RejectLandlordReviewingFinancialDocumentsCommand = 303,
}
export enum ProjectRenewalRejectionStatus
{
	TooEarly = 0,
	RenewalIsNotOffered = 1,
	OtherTenantInterestedIn = 2,
	Economics = 3,
	Other = 4,
}
export enum TenantFileFolderType
{
	Financials = 1,
	Insurance = 2,
	LetterOfIntent = 3,
	CashFlowModels = 4,
	Shared = 5,
	Construction = 6,
	Admin = 7,
	LeaseDocuments = 8,
	Plans = 10,
	SummaryOfTerms = 11,
}
export enum RentalRateAnnualEscalationTermCustomValueType
{
	FixedPercentagePerYear = 1,
	FixedAmountPsfPerYear = 2,
}
export enum RentalRateAnnualEscalationTermCustomRepeatType
{
	No = 1,
	Month = 2,
	EveryYear = 3,
}
export enum RenewalOptionTermType
{
	PerCurrentLease = 1,
	RenewalOptionValues = 2,
	None = 3,
	Custom = 4,
	RenewalOptionValuesExtended = 5,
	RenewalOptionValuesExtendedNoGreaterThatPercentageOfRent = 6,
}
export enum TerminationOptionType
{
	NoTerminationOption = 1,
	RightToTerminateLease = 2,
	Custom = 3,
	MultiplyOptions = 4,
	RequestMultiplyOptions = 5,
}
export enum PropertyManagementFeePercentageType
{
	None = 0,
	GrossReceipts = 1,
	BaseRent = 2,
}
export enum PropertyManagementFeeType
{
	None = 0,
	PsfValue = 1,
	Percentage = 2,
}
export enum RealEstateTaxesCamExpensesCumulativeType
{
	NonCumulative = 1,
	Cumulative = 2,
}
export enum OperatingExpensesType
{
	Estimate = 1,
	Stop = 2,
}
export enum RealEstateTaxesType
{
	Estimate = 1,
	Stop = 2,
}
export enum SecurityGuarantyOptionType
{
	Corporate = 1,
	Personal = 2,
}
export enum SecurityDepositMonths
{
	BeginningOfTerm = 0,
	EndOfTerm = 1,
}
export enum FreeRentTaxesType
{
	Net = 1,
	Gross = 2,
}
export enum FreeRentTermType
{
	None = 1,
	MonthsCount = 2,
	SpecificSchedule = 3,
	MultiplyOptions = 4,
	RequestMultiplyOptions = 5,
}
export enum TenantSquareFootagePhaseInRepeatType
{
	No = 1,
	EndOfYear = 2,
	EndOfTerm = 3,
}
export enum WarrantyDurationTypeForLandlordsContractors
{
	None = 1,
	_3Months = 2,
	_6Months = 3,
	_12Months = 4,
	Custom = 5,
}
export enum ContractorTypeForTenantImprovementsWork
{
	UseLandlordsContractors = 1,
	TenantCanChooseTheirOwnContractors = 2,
}
export enum TermCommentType
{
	BothTeams = 1,
	LandlordTeam = 2,
	TenantTeam = 3,
}
export enum AlertCreatorType
{
	User = 1,
	System = 2,
}
export enum AlertLeaseUserRole
{
	Tenant = 1,
	Landlord = 2,
	TenantBroker = 3,
	LandlordBroker = 4,
}
export enum MessageType
{
	Chat = 0,
	AddChat = 1,
	UpdateMessage = 2,
	DeleteMessage = 3,
}
export enum PlanAnchorImageKind
{
	Standard = 0,
	Panorama = 1,
}
export enum PlanAnchorStatus
{
	Pending = 0,
	Accepted = 1,
	Rejected = 2,
}
export enum PlanKind
{
	Floor = 1,
	Site = 2,
	PlanPro = 3,
}
export enum InsuranceCertificateProjectTemplateItemType
{
	LandlordFirstStep = 1,
	TenantStep = 2,
	LandlordStep = 3,
	Finished = 4,
}
export enum ProjectStatus
{
	Active = 0,
	Completed = 1,
	Rejected = 2,
	Cancelled = 3,
}
export interface ITreeItem
{
	id: string;
	parentId: string;
	name: string;
	itemType: TreeItemType;
	createdOn: Date;
	updatedOn: Date;
	deletedOn: Date;
	url: string;
	version: string;
	isAttachement: boolean;
	isReadOnly: boolean;
	tenantFileFolderType: TenantFileFolderType;
	leaseFileFolderType: LeaseFileFolderType;
}
export enum IntroType
{
	ColaboPage1 = 0,
	ColaboPage2 = 1,
	FloorPlan = 2,
	LeaseDocumentsPage = 4,
	ProjectSchedule = 5,
	Onboadring = 6,
	BrokerGreeting = 7,
}
export interface ILists
{
	projectTypes: IProjectType[];
	templateItems: IProjectTemplateItem[];
	industries: IIndustry[];
	spaceUses: ISpaceUse[];
	leaseTermConfigurations: ILeaseTermConfigurationViewModel[];
}
export enum QuizQuestionBadgeType
{
	NeedMoreSpace = 1,
	NeedLessSpace = 2,
	RentReliefOrDeferredRent = 3,
	ExtendLease = 4,
	HowLongWillingToExtend = 5,
	HowMuchSpaceNeeded = 6,
}
export enum AnswerType
{
	LeaseDocuments = 1,
	RequestFromLandlord = 2,
	DocumentsOnFile = 3,
	InviteBroker = 4,
	ProceedWithoutBroker = 5,
	SkipFinancialFiles = 6,
	UploadFinancialFiles = 7,
	SameSpace = 8,
	MoreSpace = 9,
	LessSpace = 10,
	InviteTenant = 11,
	ProceedWithoutTenant = 12,
	RequestTour = 13,
	ProceedWithoutTour = 14,
}
export enum QuizType
{
	RenewalWizard = 1,
	RestructureWizard = 2,
	TenantCancellationWizard = 3,
	LandlordCancellationWizard = 4,
	NewDealWizard = 5,
	InquiryWizard = 6,
	InquiryDeleteWizard = 7,
	NewDealByBrokerWizard = 600,
	InquiryByBrokerWizard = 700,
}
export enum MessengerEmailNotifications
{
	Enabled = 0,
	Disabled = 1,
}
export enum MessengerSmsNotifications
{
	Enabled = 0,
	Disabled = 1,
}
export enum EmailNotifications
{
	Enabled = 0,
	Disabled = 1,
}
export enum NotificationsDisplay
{
	Enabled = 0,
	Disabled = 1,
}
export enum RailTermType
{
	PerCurrentLease = 1,
	LandlordResponsibility = 2,
	TenantResponsibility = 3,
	RailProvidedBy = 4,
	IndustryTrackAgreementPossible = 5,
	Inactive = 6,
	Custom = 7,
}
export enum HazardousMaterialsTermType
{
	PerCurrentLease = 1,
	LandlordResponsibility = 2,
	LandlordResponsibilityExtended = 3,
	LandlordResponsibilityWithEnvironmentalPhaseOne = 4,
	ToBeAddressedInLease = 5,
	Custom = 6,
}
export enum NonDisturbanceTermType
{
	PerCurrentLease = 1,
	LandlordProvidesSnda = 2,
	ToBeAddressedInLease = 3,
	Custom = 4,
}
export enum SignageTermType
{
	PerCurrentLease = 1,
	LandlordApproved = 2,
	ToBeAddressedInLease = 3,
	Custom = 4,
}
export enum UtilitiesTermGreenLeaseOption
{
	NotRequired = 0,
	Required = 1,
}
export enum UtilitiesTermType
{
	PerCurrentLease = 1,
	ToBeAddressedInLease = 2,
	LandlordPaysBaseRentalRateIncluded = 3,
	LandlordPays = 4,
	LandlordPaysExcept = 5,
	Custom = 6,
}
export enum CodeComplianceTermType
{
	PerCurrentLease = 1,
	ToBeAddressedInLease = 2,
	LandlordExpense = 3,
	Custom = 4,
}
export enum ConditionOfPremisesTermType
{
	AsIs = 1,
	LandlordDelivered = 2,
	LandlordDeliveredWithWarranty = 3,
	Custom = 4,
}
export enum ParkingTermType
{
	PerCurrentLease = 1,
	PleaseIdentifyByTenant = 2,
	PleaseIdentifyByLandlord = 3,
	TenantRequested = 4,
	ToBeAddressedInLease = 5,
	Custom = 6,
}
export enum SpaceUseTermType
{
	PerCurrentLease = 1,
	Custom = 2,
	None = 3,
}
export enum HoldoverProvisionType
{
	PerCurrentLease = 1,
	TenantRemainsFollowingEndTerm = 2,
	Custom = 3,
	MonthlyRentPriorToHoldover = 4,
}
export enum ExpansionOptionTermType
{
	PerCurrentLease = 1,
	None = 2,
	RightOfFirstRefusal = 3,
	RightOfFirstOffer = 4,
	Custom = 5,
}
export enum EstoppelCertificateTermType
{
	PerCurrentLease = 1,
	TenantAndLandlordExecuteEstoppelCertificate = 2,
	Custom = 3,
	TenantExecuteAndProvideEstoppelCertificateAfterLandlordRequest = 4,
}
export enum AssignmentTermType
{
	PerCurrentLease = 1,
	TenantShallHaveTheRight = 2,
	Custom = 3,
}
export enum SelfHelpTermType
{
	PerCurrentLease = 1,
	None = 2,
	TenantRequiresLandlordProvision = 3,
	Custom = 4,
}
export enum TenantMaintenanceTermType
{
	PerCurrentLease = 1,
	SoleMaintenanceByTenant = 2,
	Custom = 3,
}
export enum TenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriodType
{
	BaseRent = 1,
	OperatingExpenses = 2,
	BaseRentAndOperatingExpenses = 3,
}
export enum BeneficialOccupancyType
{
	NoneGranted = 1,
	TenantShallBeProvidedBeneficialOccupancy = 2,
	Custom = 3,
}
export enum LeaseActionType
{
	None = 0,
	LeaseShouldBeAbstracted = 1,
}
export enum LeaseType
{
	None = 0,
	Internal = 1,
	ThirdPartyLease = 2,
}
export interface IGeopoint
{
	name: string;
	latitude: number;
	longitude: number;
}
export enum ProjectItemTemplateComputeType
{
	LeaseExecution = 0,
}
export interface IAvatarOptions
{
	points: number[];
	zoom: number;
	orientation: number;
}
export enum DocumentMarkingReferType
{
	LandlordCompany = 1,
}
export interface IFeatureToggles
{
	isColaboFeatureEnabled: boolean;
	isProjectScheduleFeatureEnabled: boolean;
	isFloorPlanFeatureEnabled: boolean;
	isPlanProEnabled: boolean;
}
export interface ILeaseAnalysisYearLandlordPerspective extends ILeaseAnalysisYearPerspective
{
	totalOperatingExpenses: number;
	netOperatingIncome: number;
	leaseCommissions: number;
	tenantImprovements: number;
	tenantImprovementsLoanFunding: number;
	totalOtherCosts: number;
}
export interface ILeaseAnalysisYearPerspective
{
	leaseAnalysisYearType: LeaseAnalysisYearType;
	startDate: Date | string;
	endDate: Date | string;
	numberOfDays: number;
	baseRent: number;
	escalations: number;
	freeRent: number;
	totalBaseRent: number;
	realEstateTaxes: number;
	commonAreaMaintenance: number;
	commonAreaMaintenanceBreakdown: ICommonAreaMaintenanceBreakdown;
	totalRecoveries: number;
	totalRent: number;
	tenantImprovementsAmortization: number;
	totalCost: number;
	cashFlow: number;
	psfCashFlow: number;
	presentValue: number;
	cumulativeCashFlow: number;
	insurance: number;
}
export interface ICommonAreaMaintenanceBreakdown
{
	realEstateTaxes: number;
	insurance: number;
	repairsAndMaintenance: number;
	fireAndLifeSafety: number;
	parkingLotMaintenance: number;
	roofRepairs: number;
	landscaping: number;
	snowRemoval: number;
	managementFee: number;
	total: number;
}
export interface IComparisonSummaryReportValuesDirection
{
	totalCashFlowValueDirection: IComparisonSummaryReportValueDirection;
	psfTotalCashFlowValueDirection: IComparisonSummaryReportValueDirection;
	presentValueDirection: IComparisonSummaryReportValueDirection;
	presentValuePsfDirection: IComparisonSummaryReportValueDirection;
	averageCashFlowDirection: IComparisonSummaryReportValueDirection;
	psfAverageCashFlowDirection: IComparisonSummaryReportValueDirection;
	averageMonthlyRateDirection: IComparisonSummaryReportValueDirection;
	yearOneCashFlowDirection: IComparisonSummaryReportValueDirection;
	psfYearOneCashFlowDirection: IComparisonSummaryReportValueDirection;
	proposalEffectiveRateDirection: IComparisonSummaryReportValueDirection;
	psfProposalEffectiveRateDirection: IComparisonSummaryReportValueDirection;
	valueOfConcessionsDirection: IComparisonSummaryReportValueDirection;
	termDirection: IComparisonSummaryReportValueDirection;
	baseRentDirection: IComparisonSummaryReportValueDirection;
	freeRentDirection: IComparisonSummaryReportValueDirection;
	escalationDirection: IComparisonSummaryReportValueDirection;
	tenantImprovementsDirection: IComparisonSummaryReportValueDirection;
	totalDealCostDirection: IComparisonSummaryReportValueDirection;
}
export interface IComparisonSummaryReportValueDirection
{
	valueDirection: ValueDirection;
	differenceInPercent: number;
	differenceInValue: number;
}
export enum ValueDirection
{
	Unch = 0,
	Up = 1,
	Down = 2,
}
export enum DealsByStageReportDealType
{
	RenewalInitiatedByTenant = 1,
	RestructureInitiatedByTenant = 2,
	RenewalInitiatedByLandlord = 5,
	NewDealInitiatedByTenant = 6,
}
export enum OccupancyAndVacancyReportGroupingOption
{
	Day = 0,
	Month = 1,
	Year = 2,
}
export enum ExpirationsReportGroupingOption
{
	Day = 0,
	Month = 1,
	Year = 2,
}
export interface ICountry
{
	countryCode: string;
	name: string;
}
export interface IState
{
	name: string;
	countryCode: string;
	stateCode: string;
	country: ICountry;
}
export enum BuildingAttachmentType
{
	None = 0,
	Picture = 1,
	Video = 2,
	Youtube = 3,
	Vimeo = 4,
	External = 1000,
}
export interface IBuildingWithAvailableUnitsViewRecord
{
	buildingId: number;
	buildingName: string;
	buildingSize: number;
	buildingBuiltYear: number;
	buildingClass: BuildingClass;
	buildingType: BuildingType;
	buildingClearHeight: number;
	addressId: number;
	portfolioId: number;
	companyId: number;
	companyName: string;
	companyLogoUrl: string;
	unitsCount: number;
	unitsSize: number;
	availableUnitsCount: number;
	minAvailableUnitSize: number;
	maxAvailableUnitSize: number;
	availableUnitPriceStructure: BuildingUnitListingPriceStructure;
	availableUnitPriceUnitMetrics: BuildingUnitListingPriceUnitMetrics;
	availableUnitPriceType: BuildingUnitListingPriceType;
	minAvailableUnitPrice: number;
	maxAvailableUnitPrice: number;
	addressLine1: string;
	addressLine2: string;
	city: string;
	stateCode: string;
	marketId: number;
	marketName: string;
	subMarketId: number;
	subMarketName: string;
	zipCode: string;
	assetTypeId: number;
	assetType: string;
	parentAssetTypeId: number;
	parentAssetType: string;
	clearHeight: number;
	geopointLatitude: number;
	geopointLongitude: number;
	buildingAttachments: IBuildingAttachment[];
	buildingCertificates: IBuildingCertificate[];
	geopoint: IPoint;
	companyEmployees: IProfileViewModel[];
	availableSpaceExpression: string;
	askingPriceExpression: string;
}
export interface IBuildingCertificate
{
	buildingId: number;
	building: IBuilding;
	certificationType: GreenCertification;
	customValue: string;
}
export interface IBuildingAttachment
{
	id: number;
	buildingId: number;
	building: IBuilding;
	buildingUnitId: number;
	buildingUnit: IBuildingUnit;
	fileId: number;
	file: IFile;
	externalUrl: string;
	sortOrder: number;
	buildingAttachmentType: BuildingAttachmentType;
}
export interface IBuildingUnit
{
	id: number;
	buildingId: number;
	building: IBuilding;
	name: string;
	description: string;
	size: number;
	totalOfficeArea: number;
	totalDocksInterior: number;
	totalDocksExterior: number;
	amps: number;
	volts: string;
	phase: number;
	columnSpacing: string;
	clearHeight: number;
	driveInDoors: number;
	totalDriveInDoorsSize: string;
	sprinklerSystem: SprinklerSystem;
	deletedOn: Date;
	createdOn: Date | string;
	listing: IBuildingUnitListing;
	plans: IPlan[];
	attachments: IBuildingAttachment[];
	leases: ILease[];
	company: ICompany;
	companyEmployees: IProfile[];
	buildingUnitBudgets: IBuildingBudget[];
	planAnchors: IPlanAnchor[];
}
export interface IBuildingBudget
{
	id: number;
	buildingId: number;
	building: IBuilding;
	buildingUnitId: number;
	buildingUnit: IBuildingUnit;
	version: number;
	createdOn: Date;
	createdById: number;
	createdBy: IUser;
	updatedOn: Date;
	updatedById: number;
	leaseTerm: number;
	renewalProbability: number;
	investmentBasis: number;
	investmentDate: Date | string;
	budgetedCapex: number;
	goingInCapRate: number;
	exitCapRate: number;
	yieldOnCash: number;
	hasCapitalItems: boolean;
	capitalItems: string[];
	marketRate: number;
	marketRateGrowths: IMarketRateGrowth[];
	escalations: number;
	freeRentRenewal: number;
	freeRentNewDeal: number;
	tenantImprovementsNewDeal: number;
	tenantImprovementsRenewal: number;
	downtime: number;
	opEx: number;
	landlordRepRenewal: number;
	tenantRepRenewal: number;
	landlordRepNewDeal: number;
	tenantRepNewDeal: number;
}
export interface IMarketRateGrowth
{
	year: number;
	value: number;
}
export interface IUser extends IIdentityUser<number>
{
	profile: IProfile;
	landlordProfile: ILandlordProfile;
	companyId: number;
	company: ICompany;
	projects: IProject[];
	passwordHash: string;
	securityStamp: string;
	userStatus: UserStatus;
	lastUpdatedStateraClaimsTicks: ITimeSpan;
	lastActivityDateTime: Date;
	skipConfirmationStatusCheck: boolean;
	userChannels: IChatChannelUser[];
	versionedFiles: IVersionedFile[];
	userRoles: IUserRole[];
	alertsPreference: IAlertsPreference;
	emailDuplicatingToAdminEnabled: boolean;
	createdCompanies: ICompany[];
	leaseActivities: ILeaseActivity[];
	accessibleLeases: ILeaseUserViewRecord[];
}
export interface ILeaseUserViewRecord
{
	leaseUserId: number;
	userId: number;
	user: IUser;
	leaseId: number;
	lease: ILease;
	representsCompanyId: number;
	representsCompany: ICompany;
	representativeRole: LeaseUserRole;
	representativeCompanyType: CompanyType;
}
export interface ILeaseActivity
{
	id: number;
	leaseId: number;
	lease: ILease;
	createdById: number;
	createdOn: Date;
	createdBy: IUser;
	lastReadOn: Date;
	projectId: number;
	project: IProject;
	projectTemplateItemId: number;
	projectTemplateItem: IProjectTemplateItem;
	leaseTeam: LeaseTeam;
}
export interface IAlertsPreference
{
	id: number;
	userId: number;
	user: IUser;
	leaseCommencementIntervalType: AlertIntervalType;
	leaseCommencementIntervalValue: number;
	leaseExpirationIntervalType: AlertIntervalType;
	leaseExpirationIntervalValue: number;
	leaseRentEscalationIntervalType: AlertIntervalType;
	leaseRentEscalationIntervalValue: number;
	leaseRenewalOptionIntervalType: AlertIntervalType;
	leaseRenewalOptionIntervalValue: number;
	leaseTerminationOptionIntervalType: AlertIntervalType;
	leaseTerminationOptionIntervalValue: number;
	inquiriesAndRequestsIntervalType: AlertIntervalType;
	inquiriesAndRequestsIntervalValue: number;
}
export interface IUserRole extends IIdentityUserRole<number>
{
	user: IUser;
	role: IRole;
}
export interface IRole extends IIdentityRole<number>
{
	userRoles: IUserRole[];
}
export interface IVersionedFile
{
	id: number;
	name: string;
	versionedFileType: VersionedFileType;
	currentEditorId: number;
	currentEditor: IUser;
	fileVersionId: number;
	fileVersion: IFile;
	comments: IVersionedFileComment[];
	pdfFileVersionId: number;
	pdfFileVersion: IFile;
	leaseId: number;
	lease: ILease;
	isSent: boolean;
	leaseTeam: LeaseTeam;
	version: number;
	deletedOn: Date;
}
export interface IVersionedFileComment
{
	id: number;
	content: string;
	versionedFileId: number;
	versionedFile: IVersionedFile;
	authorId: number;
	author: IUser;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	versionedFileCommentType: VersionedFileCommentType;
}
export interface IChatChannelUser
{
	chatChannelId: number;
	chatChannel: IChatChannel;
	userId: number;
	user: IUser;
}
export interface IChatChannel
{
	id: number;
	entityId: number;
	channelType: ChatChannelType;
	channelUsers: IChatChannelUser[];
	createdOn: Date;
	createdById: number;
	lease: ILease;
	building: IBuilding;
}
export interface IBuildingUnitListing
{
	id: number;
	buildingUnitId: number;
	buildingUnit: IBuildingUnit;
	availableOn: Date;
	sizeMin: number;
	sizeMax: number;
	priceStructure: BuildingUnitListingPriceStructure;
	priceUnitMetrics: BuildingUnitListingPriceUnitMetrics;
	priceType: BuildingUnitListingPriceType;
	shouldIncludeRegionalMapInMarketingBrochure: boolean;
	priceMin: number;
	priceMax: number;
	priceUpdatedOn: Date;
	estimatedReTaxes: number;
	estimatedOpEx: number;
	insurance: number;
	notes: string;
	deletedOn: Date;
	createdOn: Date;
}
export interface IStateraClaim
{
	id: number;
	claimType: StateraClaimTypeAsEnum;
	claimValue: StateraClaimValueAsEnum;
	stateraUserClaims: IStateraUserClaim[];
	stateraClaimGroups: IStateraClaimGroup[];
}
export interface IStateraClaimGroup
{
	id: number;
	name: string;
	stateraClaims: IStateraClaim[];
	stateraUserClaims: IStateraUserClaim[];
}
export interface IStateraUserClaim
{
	id: number;
	stateraClaimId: number;
	stateraClaim: IStateraClaim;
	stateraClaimGroupId: number;
	stateraClaimGroup: IStateraClaimGroup;
	userId: number;
	user: IUser;
	companyId: number;
	company: ICompany;
	portfolioId: number;
	portfolio: IPortfolio;
	buildingId: number;
	building: IBuilding;
	leaseId: number;
	lease: ILease;
	expiresAt: Date | string;
	createdAt: Date;
	createdById: number;
	updatedAt: Date;
	updatedById: number;
}
export interface IDealCountsInfo
{
	active: IDealCountsInfoItem;
	closed: IDealCountsInfoItem;
	dead: IDealCountsInfoItem;
	all: IDealCountsInfoItem;
}
export interface IDealCountsInfoItem
{
	tenant: number;
	landlord: number;
}
export interface IReadMessagesViewModel
{
	chatChannelId: number;
}
export interface ILeaseAnalysisYearTenantPerspective extends ILeaseAnalysisYearPerspective
{
}
export enum LeaseAnalysisYearType
{
	LeaseYear = 0,
	CalendarYear = 1,
}
export interface ILeaseTermStatus
{
	id: number;
	termName: string;
	lease: ILease;
	leaseId: number;
	termStatus: TermStatus;
	updatedOn: Date;
	updatedById: number;
}
export enum LeaseFloorPlanRequestStatus
{
	None = 0,
	RequestedByLandlord = 1,
	NoPlanAvailable = 2,
	LeaseFloorPlanWasCreatedByTenant = 3,
}
export interface IUserInvitationRequest
{
	id: number;
	requestKind: UserInvitationRequestKind;
	requestStatus: UserInvitationRequestStatus;
	leases: ILease[];
	buildings: IBuilding[];
	portfolios: IPortfolio[];
	leaseUsers: ILeaseUser[];
	userId: number;
	user: IUser;
	firstName: string;
	lastName: string;
	companyName: string;
	companyId: number;
	emailAddress: string;
	role: string;
	requestedById: number;
	requestedByRole: string;
	requestedBy: IUser;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	quizAnswer: IQuizAnswer;
	claimGroupIds: number[];
	claimIds: number[];
}
export interface ILeaseUser
{
	id: number;
	portfolioId: number;
	buildingId: number;
	leaseId: number;
	portfolio: IPortfolio;
	building: IBuilding;
	lease: ILease;
	userId: number;
	user: IUser;
	leaseUserRole: LeaseUserRole;
	relatedToCompanyId: number;
	relatedToCompany: ICompany;
	relatedToCompanyType: CompanyType;
	isInvitationAccepted: boolean;
}
export interface ILeaseAnalysisProject
{
	name: string;
	optionName: string;
	lease: ILease;
	project: IProject;
}
export interface IComparisonSummaryReportAbstractLease extends ILeaseAnalysisTenantPerspectiveResult
{
	remainingCashFlow: number;
	endingBaseRent: number;
	isReTaxesSet: boolean;
	isOpExpensesSet: boolean;
}
export interface IComparisonSummaryReportItems
{
	items: ILeaseAnalysisTenantPerspectiveResult[];
	projectTemplateItem: IProjectTemplateItem;
	proposalDate: Date;
}
export interface IComparisonSummaryReport
{
	items: IComparisonSummaryReportItems[];
	currentLeaseResults: IComparisonSummaryReportAbstractLease;
	thirdPartyLeaseResults: IComparisonSummaryReportAbstractLease;
	leaseTeam: LeaseTeam;
}
export interface IImportTaskLog
{
	id: number;
	importTaskId: number;
	importTask: IImportTask;
	message: string;
	createdOn: Date;
	createdById: number;
}
export interface IImportPointsOfInterestTaskPayload
{
	kind: PointOfInterestKind;
	status: PointOfInterestStatus;
	fieldMappings: { [index: string]: number };
}
export interface IImportBuildingsTaskPayload
{
	landlordId: number;
	portfolioId: number;
	fieldMappings: { [index: string]: number };
}
export interface IImportTask
{
	id: number;
	taskType: ImportTaskType;
	taskStatus: ImportTaskStatus;
	fileId: number;
	file: IFile;
	payload: string;
	createdOn: Date;
	createdById: number;
	taskLogs: IImportTaskLog[];
}
export interface IMarketRentalRate
{
	marketId: number;
	market: IMarket;
	squareFootageRangeId: number;
	squareFootageRange: ISquareFootageRange;
	lowRate: number;
	highRate: number;
	avgRate: number;
}
export interface ISquareFootageRange
{
	id: number;
	minValue: number;
	maxValue: number;
}
export interface IAlertSettingTemplate
{
	id: number;
	name: string;
	alertType: AlertType;
	isRequiresAction: boolean;
}
export interface IPagedResult<T> extends IPagedResultBase
{
	results: T[];
}
export interface IPagedResultBase
{
	currentPage: number;
	pageCount: number;
	pageSize: number;
	rowCount: number;
	firstRowOnPage: number;
	lastRowOnPage: number;
}
export interface IAlert
{
	id: number;
	text: string;
	emailText: string;
	user: IUser;
	userId: number;
	createdOn: Date;
	createdById: number;
	createdBy: IUser;
	isRead: boolean;
	isRequiresAction: boolean;
	readOn: Date;
	leaseId: number;
	lease: ILease;
	projectId: number;
	project: IProject;
	leaseRole: AlertLeaseUserRole;
	buildingId: number;
	leaseTermType: LeaseTermType;
	alertSettingTemplateId: number;
	alertSettingTemplate: IAlertSettingTemplate;
	chatMessageId: number;
	chatMessage: IChatMessage;
	leaseTermCommentId: number;
	leaseTermComment: ILeaseTermComment;
	planAnchorCommentId: number;
	planAnchorComment: IPlanAnchorComment;
	versionedFileCommentId: number;
	versionedFileComment: IVersionedFileComment;
	appointmentId: number;
	appointment: IAppointment;
	alertCreatorType: AlertCreatorType;
	leaseRequestId: number;
	files: IFile[];
}
export interface IAppointment
{
	id: number;
	appointmentType: AppointmentType;
	text: string;
	startDate: Date;
	endDate: Date;
	allDay: boolean;
	approved: boolean;
	entityId: number;
	updatedOn: Date;
	updatedById: number;
	lastRemindedOn: Date;
}
export interface ILeaseTermComment extends IMessageEntity
{
	lease: ILease;
	leaseId: number;
	termName: string;
	leaseTermType: LeaseTermType;
	termCommentType: TermCommentType;
	createdBy: IUser;
}
export interface IMessageEntity
{
	id: number;
	message: string;
	icon: string;
	firstName: string;
	lastName: string;
	companyName: string;
	data: string;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	deletedOn: Date;
	updatedById: number;
	deleted: boolean;
}
export interface ILeaseSignature
{
	id: number;
	documentHash: string;
	embeddedSigningUrlForTenant: string;
	documentId: number;
	document: IFile;
	lease: ILease;
	leaseId: number;
	tenantSignDate: Date;
	landlordSignDate: Date;
	embeddedSigningUrlForLandlord: string;
	landlordEmail: string;
	landlordName: string;
	tenantEmail: string;
	tenantName: string;
	isExternalTenantEmail: boolean;
	isExternalLandlordEmail: boolean;
	tenantId: number;
	tenant: IUser;
	landlordId: number;
	landlord: IUser;
	expiration: Date | string;
}
export interface IChatMessage extends IMessageEntity
{
	messageType: MessageType;
	chatChannelId: number;
	channel: IChatChannel;
	chatQueuedMessage: IChatQueuedMessage;
	alert: IAlert;
	versionedFile: IVersionedFile;
	versionedFileId: number;
	anchorId: number;
	anchor: IPlanAnchor;
	messageKind: ChatMessageKind;
}
export interface IChatQueuedMessage
{
	chatMessageId: number;
	chatMessage: IChatMessage;
	userId: number;
	user: IUser;
}
export interface ILeaseTermConfiguration
{
	id: number;
	description: string;
	termName: string;
	termGroup: string;
	tooltip: string;
	type: TermType;
	format: string;
	max: number;
	min: number;
	sortOrder: number;
	groupSortOrder: number;
	isHidden: boolean;
	isRequired: boolean;
	isUsedInDashboardPage: boolean;
	isUsedInTermPage: boolean;
	leaseTermType: LeaseTermType;
}
export interface IIndustry extends ISimpleDictionary
{
	sortOrder: number;
}
export interface ISimpleDictionary
{
	id: number;
	name: string;
}
export interface ISpaceUse extends ISimpleDictionary
{
}
export interface IProjectTemplateItem
{
	id: number;
	projectTypeId: number;
	projectType: IProjectType;
	name: string;
	sortOrder: number;
	durationDays: number;
	computeType: ProjectItemTemplateComputeType;
	description: string;
	renewalProjectTemplateItemType: RenewalProjectTemplateItemType;
	writeAccessToTermsByRoleId: number;
	writeAccessToTermsByRole: IRole;
	parentProjectTemplateItemId: number;
	parentProjectTemplateItem: IProjectTemplateItem;
	isFirst: boolean;
	isLast: boolean;
	stageNumber: number;
	insuranceCertificateProjectTemplateItemType: InsuranceCertificateProjectTemplateItemType;
	deletedOn: Date;
	leaseActivities: ILeaseActivity[];
}
export interface IProjectMilestone
{
	id: number;
	templateItemId: number;
	templateItem: IProjectTemplateItem;
	projectId: number;
	project: IProject;
	name: string;
	startDate: Date;
	endDate: Date;
	actualStartDate: Date;
	actualEndDate: Date;
}
export interface IProjectType
{
	id: number;
	name: string;
	projectTypeEnum: ProjectTypeEnum;
	shouldCreateInsuranceOfCertificateProjectAfterFinish: boolean;
	leaseTermTypes: LeaseTermType[];
}
export interface IProject
{
	id: number;
	name: string;
	projectTypeId: number;
	projectType: IProjectType;
	startDate: Date;
	endDate: Date;
	milestones: IProjectMilestone[];
	projectStateId: number;
	projectState: IProjectTemplateItem;
	leaseId: number;
	lease: ILease;
	renewalOptionNotice: number;
	actualEndDate: Date;
	projectStatus: ProjectStatus;
	projectRenewalRejectionStatus: ProjectRenewalRejectionStatus;
	rejectedDate: Date;
	dateToRevisit: Date;
	cancelledDate: Date;
	deletedOn: Date;
	pendingForInternalApproval: boolean;
	notes: string;
	stageHistoryRecords: IStageHistoryRecord[];
	leaseActivities: ILeaseActivity[];
}
export interface IStageHistoryRecord
{
	id: number;
	stageHistoryRecordType: StageHistoryRecordType;
	leaseId: number;
	lease: ILease;
	projectTemplateItem: IProjectTemplateItem;
	projectTemplateItemId: number;
	leaseSnapshot: ILease;
	createdOn: Date;
	createdById: number;
	createdBy: IUser;
	projectId: number;
	project: IProject;
}
export enum StageHistoryRecordType
{
	None = 0,
	StageStart = 1,
	StageEnd = 2,
}
export interface ILandlordProfile
{
	userId: number;
	user: IUser;
	investorType: InvestorType;
	markets: string;
	portfolioSizeOffice: number;
	portfolioSizeIndustrial: number;
	portfolioSizeLand: number;
	portfolioSizeRetail: number;
}
export interface IQuizQuestion
{
	id: number;
	text: string;
	quizId: number;
	quiz: IQuiz;
	stepId: number;
	step: IQuizStep;
	questionType: QuestionType;
	options: IQuizQuestionOption[];
	nextQuestionId: number;
	nextQuestion: IQuizQuestion;
	parentQuizQuestionId: number;
	parentQuizQuestion: IQuizQuestion;
	placeholder: string;
	tooltip: string;
	quizQuestionBadgeType: QuizQuestionBadgeType;
	groupId: number;
}
export interface IQuizStep
{
	id: number;
	name: string;
	nameIconId: string;
	title: string;
	titleIconId: string;
	quizId: number;
	quiz: IQuiz;
	type: QuizStepType;
	previousStepId: number;
	previousStep: IQuizStep;
	nextStepId: number;
	nextStep: IQuizStep;
	questions: IQuizQuestion[];
}
export interface IQuiz
{
	id: number;
	name: string;
	startQuestionId: number;
	quizType: QuizType;
	startQuestion: IQuizQuestion;
	quizUsers: IQuizUser[];
	steps: IQuizStep[];
	questions: IQuizQuestion[];
	answers: IQuizAnswer[];
}
export interface IQuizUser
{
	quizId: number;
	quiz: IQuiz;
	leaseId: number;
	lease: ILease;
	done: boolean;
	note: string;
	lastQuestionId: number;
	companyId: number;
	company: ICompany;
	userId: number;
	user: IUser;
	lastQuestion: IQuizQuestion;
	quizAnswers: IQuizAnswer[];
}
export interface IQuizQuestionOption
{
	id: number;
	text: string;
	quizQuestionId: number;
	quizQuestion: IQuizQuestion;
	nextQuestionId: number;
	nextQuestion: IQuizQuestion;
	answerType: AnswerType;
}
export interface IQuizAnswer
{
	id: number;
	quizId: number;
	quiz: IQuiz;
	quizQuestionId: number;
	nextQuizQuestionId: number;
	nextQuizQuestion: IQuizQuestion;
	quizQuestion: IQuizQuestion;
	companyId: number;
	company: ICompany;
	leaseId: number;
	lease: ILease;
	customAnswerText: string;
	uploadedFileId: number;
	uploadedFile: IFile;
	selectedOptionId: number;
	selectedOption: IQuizQuestionOption;
	createdOn: Date;
	createdById: number;
	answerDate: Date;
	answerNumber: number;
	quizUser: IQuizUser;
	selectedAddress: IAddress;
	selectedAddressId: number;
	userId: number;
	user: IUser;
	quizAnswerFiles: IQuizAnswerFile[];
}
export interface IQuizAnswerFile
{
	quizAnswerId: number;
	quizAnswer: IQuizAnswer;
	fileId: number;
	file: IFile;
	createdOn: Date;
	createdById: number;
}
export interface IProfile
{
	userId: number;
	firstName: string;
	lastName: string;
	title: string;
	mobilePhone: string;
	additionalEmailAddress: string;
	avatar: IFile;
	avatarSource: IFile;
	avatarOptions: string;
	user: IUser;
	documentsMode: DocumentsMode;
	introsShown: IUserIntro[];
	notificationsDisplay: NotificationsDisplay;
	emailNotifications: EmailNotifications;
	messengerSmsNotifications: MessengerSmsNotifications;
	messengerEmailNotifications: MessengerEmailNotifications;
}
export interface IUserIntro
{
	userId: number;
	introType: IntroType;
	profile: IProfile;
}
export interface IPortfolio
{
	id: number;
	name: string;
	buildings: IBuilding[];
	createdOn: Date;
	createdById: number;
	companyId: number;
	company: ICompany;
	teamMembers: ITeamMember[];
	stateraUserClaims: IStateraUserClaim[];
}
export interface ITeamMember
{
	user: IUser;
	userInvitationRequest: IUserInvitationRequest;
	claimGroups: IStateraClaimGroup[];
}
export interface ILeaseAbstractImportDraft
{
	leaseId: number;
	documentId: number;
	importTaskId: number;
	lease: ILease;
	document: IFile;
	importTask: IImportTask;
	formRecognitionResult: string;
	termExtractionResult: string;
	formRecognitionFileUrl: string;
	termExtractionFileUrl: string;
	createdOn: Date;
	createdById: number;
}
export interface ILease
{
	id: number;
	landArea: number;
	expiration: Date | string;
	expirationDocumentSection: string;
	leaseStatus: LeaseStatus;
	leaseType: LeaseType;
	leaseActionType: LeaseActionType;
	landlordCompanyId: number;
	landlordCompany: ICompany;
	tenantCompanyId: number;
	tenantCompany: ICompany;
	buildingId: number;
	building: IBuilding;
	projects: IProject[];
	leaseFiles: ILeaseFile[];
	createdOn: Date;
	deletedOn: Date;
	createdById: number;
	leaseTermStatuses: ILeaseTermStatus[];
	leaseTermComments: ILeaseTermComment[];
	stageHistoryRecords: IStageHistoryRecord[];
	leaseFloorPlans: ILeaseFloorPlan[];
	leaseFloorPlanRequestStatus: LeaseFloorPlanRequestStatus;
	letterOfIntentId: number;
	letterOfIntent: IFile;
	leaseSignature: ILeaseSignature;
	buildingUnitId: number;
	buildingUnit: IBuildingUnit;
	leaseCategoryId: number;
	leaseCategory: ILeaseCategory;
	amendmentFile: ILeaseAmendmentFile;
	expirationAbstractValue: string;
	renewalNoticeForProjectSchedule: number;
	quizUser: IQuizUser;
	quizAnswers: IQuizAnswer[];
	abstractLeaseId: number;
	abstractLease: ILease;
	abstractLeaseHierarchyId: IHierarchyId;
	spaceUseId: number;
	spaceUse: ISpaceUse;
	nextLeases: ILease[];
	amendmentNumber: number;
	buildingPercentage: string;
	buildingPercentageAbstractValue: string;
	buildingPercentageDocumentSection: string;
	commencementTerm: ICommencementTerm;
	landlordMaintenanceTerm: ILandlordMaintenanceTerm;
	tenantMaintenanceTerm: ITenantMaintenanceTerm;
	selfHelpTerm: ISelfHelpTerm;
	assignmentTerm: IAssignmentTerm;
	estoppelCertificateTerm: IEstoppelCertificateTerm;
	expansionOptionTerm: IExpansionOptionTerm;
	term: ITerm;
	baseRentalRateTerm: IBaseRentalRateTerm;
	tenantSquareFootageTerm: ITenantSquareFootageTerm;
	freeRentTerm: IFreeRentTerm;
	securityDepositTerm: ISecurityDepositTerm;
	realEstateTaxesCamExpensesTerm: IRealEstateTaxesCamExpensesTerm;
	rentalRateAnnualEscalationTerm: IRentalRateAnnualEscalationTerm;
	tenantImprovementsTerm: ITenantImprovementsTerm;
	hvacTerm: IHvacTerm;
	renewalOptionTerm: IRenewalOptionTerm;
	terminationOptionTerm: ITerminationOptionTerm;
	insuranceTerm: IInsuranceTerm;
	holdoverProvisionTerm: IHoldoverProvisionTerm;
	forceMajeureTerm: IForceMajeureTerm;
	spaceUseTerm: ISpaceUseTerm;
	parkingTerm: IParkingTerm;
	conditionOfPremisesTerm: IConditionOfPremisesTerm;
	codeComplianceTerm: ICodeComplianceTerm;
	utilitiesTerm: IUtilitiesTerm;
	signageTerm: ISignageTerm;
	nonDisturbanceTerm: INonDisturbanceTerm;
	hazardousMaterialsTerm: IHazardousMaterialsTerm;
	railTerm: IRailTerm;
	insuranceCertificate: IInsuranceCertificate;
	stateraUserClaims: IStateraUserClaim[];
	leaseUsers: ILeaseUserViewRecord[];
	chatChannels: IChatChannel[];
	thirdPartyLeaseId: number;
	thirdPartyLease: ILease;
	currentLeaseProvided: boolean;
	leaseActivities: ILeaseActivity[];
	leaseMetrics: BaseRentalRateUnitMetrics;
	negotiationProject: IProject;
	calculatedCommencementDate: Date | string;
	calculatedExpirationDate: Date | string;
}
export interface IInsuranceCertificate
{
	id: number;
	leaseId: number;
	lease: ILease;
	hasGeneralLiability: boolean;
	generalLiabilityCoverageLevelEachOccurrence: number;
	damageToRentedPremises: number;
	medExp: number;
	personalAndAdvInjury: number;
	generalAggregate: number;
	productsCompOpAgg: number;
	generalLiabilityCoverageLevelComments: string;
	hasAutoLiability: boolean;
	combinedSingleLimit: number;
	bodilyInjuryPerPerson: number;
	bodilyInjuryPerAccident: number;
	propertyDamage: number;
	autoLiabilityCoverageLevelComments: string;
	hasUmbrellaLiability: boolean;
	umbrellaLiabilityCoverageLevelEachOccurrence: number;
	umbrellaLiabilityCoverageLevelAggregate: number;
	umbrellaLiabilityCoverageLevelComments: string;
	hasWorkersCompensation: boolean;
	diseaseValueForEachAccident: number;
	diseaseValueForEachEmployee: number;
	diseasePolicyLimit: number;
	workersCompensationComments: string;
	descriptionOfOperations: string;
	certificateHolder: string;
	additionalRemark: string;
	insuranceCertificateFiles: IInsuranceCertificateFile[];
	updatedOn: Date;
	updatedById: number;
	lastInsuranceCertificateFile: IInsuranceCertificateFile;
}
export interface IInsuranceCertificateFile
{
	id: number;
	insuranceCertificateId: number;
	insuranceCertificate: IInsuranceCertificate;
	fileId: number;
	file: IFile;
	createdOn: Date;
	createdById: number;
	availableForDeletingByTenant: boolean;
}
export interface ILeaseAmendmentFile
{
	id: number;
	leaseId: number;
	lease: ILease;
	versionedFileId: number;
	versionedFile: IVersionedFile;
	tenantSignDate: Date;
	landlordSignDate: Date;
	landlordSignUrl: string;
	tenantSignUrl: string;
	documentHash: string;
	signedDocumentId: number;
	signedDocument: IFile;
	prepareUrl: string;
	landlordEmail: string;
	landlordName: string;
	tenantEmail: string;
	tenantName: string;
	isExternalTenantEmail: boolean;
	isExternalLandlordEmail: boolean;
	tenantId: number;
	tenant: IUser;
	landlordId: number;
	landlord: IUser;
	expiration: Date | string;
}
export interface ILeaseCategory extends ISimpleDictionary
{
}
export interface ILeaseFile
{
	leaseId: number;
	lease: ILease;
	fileId: number;
	file: IFile;
	leaseFileFolderId: number;
	leaseFileFolder: ILeaseFileFolder;
	createdOn: Date;
	createdById: number;
}
export interface ILeaseFileFolder extends ISimpleDictionary
{
	isShared: boolean;
	leaseFileFolderType: LeaseFileFolderType;
}
export interface IFile
{
	storageGetFileApiPath: string;
	id: number;
	uid: IGuid;
	url: string;
	thumbnailUrl: string;
	name: string;
	parentFolderId: number;
	parentFolder: IFolder;
	documentId: number;
	document: IDocument;
	version: string;
	createdById: number;
	createdBy: IUser;
	createdOn: Date;
	updatedOn: Date;
	deletedOn: Date;
	updatedById: number;
	updatedBy: IUser;
	isDocument: boolean;
	fileTypeId: number;
	fileType: IFileType;
	width: number;
	height: number;
	lease: ILease;
	versionedFile: IVersionedFile;
	pdfVersionedFile: IVersionedFile;
	quizAnswer: IQuizAnswer;
	markingInfo: IDocumentMarkingInfo;
	alerts: IAlert[];
}
export interface IDocumentMarkingInfo
{
	taskId: number;
	task: IImportTask;
	originalDocumentId: number;
	originalDocument: IFile;
	convertedDocumentId: number;
	convertedDocument: IFile;
	type: DocumentType;
	referType: DocumentMarkingReferType;
	refersTo: number;
	markers: IDocumentMarker[];
}
export interface IDocumentMarker
{
	id: number;
	documentMarkingInfoId: number;
	documentMarkingInfo: IDocumentMarkingInfo;
	pageNumber: number;
	boundingBox: number[];
	tags: string[];
	text: string;
}
export enum DocumentType
{
	Lease = 1,
}
export interface IFileType
{
	id: number;
	extension: string;
	description: string;
	fileTypePrivewAttributesId: number;
	fileTypePrivewAttributes: IFileTypePrivewAttributes;
}
export interface IFileTypePrivewAttributes
{
	id: number;
	name: string;
	icon: string;
	iconColor: string;
}
export interface IDocument
{
	id: number;
	documentType: string;
	name: string;
	parentFolderId: number;
	parentFolder: IFolder;
	files: IFile[];
	createdById: number;
	createdBy: IUser;
	createdOn: Date;
	updatedOn: Date;
	updatedById: number;
	updatedBy: IUser;
	deletedOn: Date;
}
export interface IFolder
{
	id: number;
	folderType: FolderType;
	name: string;
	parentFolderId: number;
	parentFolder: IFolder;
	documents: IDocument[];
	files: IFile[];
	createdById: number;
	createdBy: IUser;
	createdOn: Date;
	updatedOn: Date;
	deletedOn: Date;
	updatedById: number;
	updatedBy: IUser;
}
export interface ICounty
{
	countryCode: string;
	stateCode: string;
	name: string;
	state: IState;
}
export interface ICompany
{
	id: number;
	companyType: CompanyType;
	name: string;
	phone: string;
	webSite: string;
	homeMarket: string;
	otherMarkets: string;
	addresses: IAddress[];
	employees: IUser[];
	logoId: number;
	logo: IFile;
	industryId: number;
	industry: IIndustry;
	createdById: number;
	createdBy: IUser;
	address: string;
	featureToggle: ICompanyFeatureToggle;
	portfolios: IPortfolio[];
	quizUsers: IQuizUser[];
	quizAnswers: IQuizAnswer[];
	leaseRepresentativeTeam: ILeaseUserViewRecord[];
}
export interface ICompanyFeatureToggle extends IFeatureToggles
{
	companyId: number;
	company: ICompany;
	isIgnoresGlobals: boolean;
}
export interface IBuilding
{
	id: number;
	name: string;
	addressId: number;
	address: IAddress;
	marketId: number;
	market: IMarket;
	subMarketId: number;
	subMarket: IMarket;
	buildingType: BuildingType;
	totalBuildingSize: number;
	builtYear: number;
	clearHeight: number;
	buildingClass: BuildingClass;
	mostCurrentRealEstateTaxesPSF: number;
	mostCurrentRealEstateTaxesTotal: number;
	operatingExpenses: number;
	totalLandArea: number;
	columnSpacing: string;
	totalParkingSpaces: number;
	mostRecentInteriorConstructionOrUpdateYear: number;
	mostRecentExteriorConstructionOrUpdateYear: number;
	mostRecentInteriorConstructionOrUpdateText: string;
	mostRecentExteriorConstructionOrUpdateText: string;
	railLine: string;
	pinNumber: string;
	totalDocksInterior: number;
	totalDocksExterior: number;
	attachments: IBuildingAttachment[];
	portfolioId: number;
	portfolio: IPortfolio;
	leases: ILease[];
	buildingFiles: IBuildingFile[];
	createdOn: Date;
	deletedOn: Date;
	createdById: number;
	plans: IPlan[];
	floorPlans: IPlan[];
	sitePlans: IPlan[];
	buildingUnits: IBuildingUnit[];
	totalOfficeArea: number;
	renovatedDate: Date;
	greenCertificates: IBuildingCertificate[];
	totalDriveInDoorsSize: string;
	amps: number;
	volts: string;
	phase: number;
	sprinklerSystem: SprinklerSystem;
	utilityCosts: number;
	utilityCostsText: string;
	roofTypeCondition: string;
	mostRecentSalePrice: number;
	saleYear: number;
	chatChannel: IChatChannel;
	constructionTypeId: number;
	constructionType: IConstructionType;
	fund: string;
	assetsTypeId: number;
	assetsType: IAssetsType;
	stories: number;
	driveInDoors: number;
	trailerParking: number;
	geopointLatitude: number;
	geopointLongitude: number;
	teamMembers: ITeamMember[];
	stateraUserClaims: IStateraUserClaim[];
	buildingBudgets: IBuildingBudget[];
}
export interface IAssetsType
{
	id: number;
	name: string;
	parentAssetsTypeId: number;
	parentAssetsType: IAssetsType;
}
export interface IConstructionType
{
	id: number;
	name: string;
	building: IBuilding;
}
export interface IBuildingFile
{
	buildingId: number;
	building: IBuilding;
	fileId: number;
	file: IFile;
	buildingFileFolderId: number;
	buildingFileFolder: IBuildingFileFolder;
	createdOn: Date;
	createdById: number;
}
export interface IBuildingFileFolder extends ISimpleDictionary
{
	isShared: boolean;
	buildingFileFolderType: BuildingFileFolderType;
}
export interface IMarket
{
	id: number;
	parentMarketId: number;
	parentMarket: IMarket;
	name: string;
}
export interface IAddress
{
	id: number;
	name: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	countryCode: string;
	stateCode: string;
	state: IState;
	zipCode: string;
	countyName: string;
	county: ICounty;
	isPrimary: boolean;
	building: IBuilding;
	companyId: number;
	company: ICompany;
}
export enum FloorPlanInvitationKind
{
	PlanProject = 1,
	FloorPlanPro = 2,
}
export enum FloorPlanProType
{
	None = 0,
	Building = 1,
	BuildingUnit = 2,
}
export enum FloorPlanType
{
	None = 0,
	LeaseFloorPlan = 1,
	FloorPlanPro = 2,
}
export enum PlanAnchorCommentType
{
	None = 0,
	Message = 1,
	CostChange = 2,
	AnchorRejection = 3,
}
export interface IPlanAnchorComment
{
	id: number;
	planAnchorCommentType: PlanAnchorCommentType;
	content: string;
	anchorId: number;
	anchor: IPlanAnchor;
	authorId: number;
	author: IUser;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	alert: IAlert;
	deletedOn: Date;
}
export interface IPlanAnchorImageMarker
{
	id: number;
	anchorImageId: number;
	anchorImage: IPlanAnchorImage;
	x: number;
	y: number;
	text: string;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	deletedOn: Date;
}
export interface IPlanAnchorImage
{
	id: number;
	anchorId: number;
	anchor: IPlanAnchor;
	imageId: number;
	image: IFile;
	imageKind: PlanAnchorImageKind;
	markers: IPlanAnchorImageMarker[];
}
export interface IPlanAnchor
{
	id: number;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	statusUpdatedOn: Date;
	statusUpdatedById: number;
	statusUpdatedBy: IUser;
	planId: number;
	plan: IPlan;
	floorPlanId: number;
	floorPlan: IFloorPlan;
	buildingUnitId: number;
	buildingUnit: IBuildingUnit;
	name: string;
	description: string;
	anchorType: PlanAnchorType;
	x: number;
	y: number;
	width: number;
	height: number;
	points: string;
	firstImageId: number;
	firstImage: IFile;
	sortOrder: number;
	buildingArea: BuildingAreaType;
	status: PlanAnchorStatus;
	amount: number;
	comments: IPlanAnchorComment[];
	images: IPlanAnchorImage[];
	deletedOn: Date;
}
export interface IFloorPlan
{
	id: number;
	planId: number;
	plan: IPlan;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	deletedOn: Date;
	anchors: IPlanAnchor[];
	floorPlanType: FloorPlanType;
}
export interface IPlan
{
	id: number;
	kind: PlanKind;
	name: string;
	buildingId: number;
	building: IBuilding;
	buildingUnitId: number;
	buildingUnit: IBuildingUnit;
	pictureId: number;
	picture: IFile;
	angle: number;
	updatedOn: Date;
	updatedById: number;
	createdOn: Date;
	createdById: number;
	planAnchors: IPlanAnchor[];
	floorPlans: IFloorPlan[];
}
export interface ILeaseFloorPlan extends IFloorPlan
{
	leaseId: number;
	lease: ILease;
	totalAnchorAmount: number;
	totalAnchorAmountAcceptanceStatus: PlanAnchorAmountAcceptanceStatus;
}
export interface IBaseRentalRateCustomScheduleTableRow
{
	start: Date | string;
	end: Date | string;
	baseRent: number;
	monthlyRent: number;
	annualRent: number;
}
export interface IRailTerm extends ILeaseTerm
{
	railType: RailTermType;
	railProvider: string;
	serviceProvider: string;
	soleUse: boolean;
	customDisplayValue: string;
	abstract: IRailTerm;
	documentSection: string;
}
export interface ILeaseTerm
{
	leaseTermType: LeaseTermType;
	updatedOn: Date;
	updatedById: number;
	abstractValue: string;
	termStatus: TermStatus;
	statusUpdatedOn: Date;
	statusUpdatedById: number;
	lastAbstractAmendment: number;
	lease: ILease;
	isElectedForNegotiation: boolean;
}
export interface IHazardousMaterialsTerm extends ILeaseTerm
{
	hazardousMaterialsType: HazardousMaterialsTermType;
	customDisplayValue: string;
	abstract: IHazardousMaterialsTerm;
	documentSection: string;
}
export interface INonDisturbanceTerm extends ILeaseTerm
{
	nonDisturbanceTermType: NonDisturbanceTermType;
	customDisplayValue: string;
	abstract: INonDisturbanceTerm;
	documentSection: string;
}
export interface ISignageTerm extends ILeaseTerm
{
	signageType: SignageTermType;
	customDisplayValue: string;
	abstract: ISignageTerm;
	documentSection: string;
}
export interface IUtilitiesTerm extends ILeaseTerm
{
	utilitiesType: UtilitiesTermType;
	tenantMeteredUtilities: string;
	customDisplayValue: string;
	hasGreenLeaseOption: boolean;
	greenLeaseOption: UtilitiesTermGreenLeaseOption;
	abstract: IUtilitiesTerm;
	documentSection: string;
}
export interface ICodeComplianceTerm extends ILeaseTerm
{
	codeComplianceType: CodeComplianceTermType;
	customText: string;
	customDisplayValue: string;
	abstract: ICodeComplianceTerm;
	documentSection: string;
}
export interface IConditionOfPremisesTerm extends ILeaseTerm
{
	conditionOfPremisesType: ConditionOfPremisesTermType;
	customText: string;
	warrantyMonthsCount: number;
	customDisplayValue: string;
	abstract: IConditionOfPremisesTerm;
	documentSection: string;
}
export interface IParkingTerm extends ILeaseTerm
{
	parkingType: ParkingTermType;
	reservedParkingStallsCount: number;
	unreservedParkingStallsCount: number;
	reservedTrailerDropsCount: number;
	unreservedTrailerDropsCount: number;
	customDisplayValue: string;
	abstract: IParkingTerm;
	documentSection: string;
}
export interface ISpaceUseTerm extends ILeaseTerm
{
	spaceUseType: SpaceUseTermType;
	value: string;
	product: string;
	customUseValue: string;
	customDisplayValue: string;
	hasAdditionalInfo: boolean;
	additionalInfo: string;
	customClauseText: string;
	abstract: ISpaceUseTerm;
	documentSection: string;
}
export interface IForceMajeureTerm extends ILeaseTerm
{
	text: string;
	abstract: IForceMajeureTerm;
	documentSection: string;
}
export interface IHoldoverProvisionTerm extends ILeaseTerm
{
	text: string;
	landlordHoldoverRentForFirstDaysInPercent: number;
	landlordHoldoverRentForRemainingDaysInPercent: number;
	landlordHoldoverRentInPercent: number;
	countOfFirstLandlordHoldoverRentDays: number;
	holdoverProvisionType: HoldoverProvisionType;
	abstract: IHoldoverProvisionTerm;
	documentSection: string;
}
export interface IInsuranceTerm extends ILeaseTerm
{
	insuranceTermType: InsuranceTermType;
	text: string;
	abstract: IInsuranceTerm;
	documentSection: string;
}
export interface ITerminationOptionTerm extends ILeaseTerm
{
	terminationOptionType: TerminationOptionType;
	terminationMonth: number;
	amortizationRate: number;
	terminationOptionDollarValue: number;
	howManyMonthsToNotice: number;
	terminationOptionCustomText: string;
	terminationOptionFeeType: TerminationOptionFeeType;
	terminationDate: Date | string;
	abstract: ITerminationOptionTerm;
	customDisplayValue: string;
	leaseTermOptions: ILeaseTermOptions<ITerminationOptionTerm>;
	documentSection: string;
}
export interface ILeaseTermOptions<T>
{
	options: T[];
	selectedLeaseTerm: T;
	selectedLeaseTermIndex: number;
}
export interface IRenewalOptionTerm extends ILeaseTerm
{
	renewalOptionTermType: RenewalOptionTermType;
	renewalOptionsCount: number;
	renewalOptionYearsCount: number;
	renewalOptionHowManyMonthsNotice: number;
	renewalOptionAvailableMonths: number;
	renewalOptionMaxRentPercentage: number;
	renewalOptionMinRentPercentage: number;
	renewalOptionCustomText: string;
	renewalDate: Date | string;
	abstract: IRenewalOptionTerm;
	customDisplayValue: string;
	documentSection: string;
}
export interface IHvacTerm extends ILeaseTerm
{
	hvacTermType: HvacTermType;
	hvacMoneyValue: number;
	hvacPercentValue: number;
	hvacCustomValue: string;
	abstract: IHvacTerm;
	customDisplayValue: string;
	documentSection: string;
}
export interface ITenantImprovementsTerm extends ILeaseTerm
{
	tenantImprovementsType: TenantImprovementsType;
	tenantImprovementsValue: number;
	tenantImprovementsAmortized: number;
	tenantImprovementsAmortizedMonthsCount: number;
	tenantImprovementsPercentageRate: number;
	tenantImprovementsCustom: string;
	tenantImprovementsOptionDescription: string;
	tenantImprovementsAmortizationOption: TenantImprovementsAmortizationOption;
	tenantImprovementsAmortizationAmount: number;
	tenantImprovementsAmortizationTerm: number;
	tenantImprovementsAnnualInterestRate: number;
	abstract: ITenantImprovementsTerm;
	customDisplayValue: string;
	contractorTypeForTenantImprovementsWork: ContractorTypeForTenantImprovementsWork;
	warrantyDurationInMonthsForLandlordContractors: number;
	warrantyDurationTypeForLandlordsContractors: WarrantyDurationTypeForLandlordsContractors;
	documentSection: string;
}
export interface IRentalRateAnnualEscalationTerm extends ILeaseTerm
{
	escalationTermType: EscalationTermType;
	escalationPercentagePerYear: number;
	escalationPsfValuePerYear: number;
	rentalRateEscalationTaxType: RentalRateEscalationTaxType;
	rentalRateAnnualEscalationTermCustomValues: IRentalRateAnnualEscalationTermCustomValue[];
	abstract: IRentalRateAnnualEscalationTerm;
	customDisplayValue: string;
	leaseTermOptions: ILeaseTermOptions<IRentalRateAnnualEscalationTerm>;
	documentSection: string;
}
export interface IRentalRateAnnualEscalationTermCustomValue
{
	id: number;
	stepIncreasePercentage: number;
	stepIncreasePsfValue: number;
	increaseDate: Date | string;
	leaseMonth: number;
	repeatLeaseMonth: number;
	rentalRateAnnualEscalationTermCustomValueType: RentalRateAnnualEscalationTermCustomValueType;
	rentalRateAnnualEscalationTermCustomRepeatType: RentalRateAnnualEscalationTermCustomRepeatType;
}
export interface IRealEstateTaxesCamExpensesTerm extends ILeaseTerm
{
	baseYearForExpenses: Date | string;
	estimatedReTaxes: number;
	estimatedReTaxesType: RealEstateTaxesType;
	estimatedOpEx: number;
	estimatedOpExType: OperatingExpensesType;
	percentagePerYear: number;
	realEstateTaxesCamExpensesTermType: RealEstateTaxesCamExpensesTermType;
	realEstateTaxesCamExpensesCumulativeType: RealEstateTaxesCamExpensesCumulativeType;
	realEstateTaxesCamExpensesText: string;
	realEstateTaxesAbstractValue: string;
	camExpensesAbstractValue: string;
	abstract: IRealEstateTaxesCamExpensesTerm;
	customDisplayValue: string;
	realEstateTaxesCustomDisplayValue: string;
	operatingExpensesCustomDisplayValue: string;
	realEstateTaxesCamExpensesBreakdown: IRealEstateTaxesCamExpensesBreakdown;
	insurance: number;
	propertyManagementFeeType: PropertyManagementFeeType;
	propertyManagementFeePsf: number;
	propertyManagementFeePercentage: number;
	propertyManagementFeePercentageType: PropertyManagementFeePercentageType;
	realEstateTaxesDocumentSection: string;
	operatingExpensesDocumentSection: string;
}
export interface IRealEstateTaxesCamExpensesBreakdown
{
	insurance: number;
	repairsAndMaintenance: number;
	fireAndLifeSafety: number;
	parkingLotMaintenance: number;
	roofRepairs: number;
	landscaping: number;
	snowRemoval: number;
	managementFee: number;
}
export interface ISecurityDepositTerm extends ILeaseTerm
{
	securityDepositTermType: SecurityDepositTermType;
	depositMonths: SecurityDepositMonths;
	monthsCount: number;
	securityDepositMoneyValue: number;
	securityDepositReducingValue: number;
	abstract: ISecurityDepositTerm;
	customDisplayValue: string;
	securityGuarantyOptionType: SecurityGuarantyOptionType;
	amountOfLetterCredit: number;
	burnDownScheduleValues: IBurnDownSchedule[];
	isBurnDownScheduleChecked: boolean;
	documentSection: string;
}
export interface IBurnDownSchedule
{
	dateOfChange: Date | string;
	dollarAmount: number;
	leaseMonth: number;
}
export interface IFreeRentTerm extends ILeaseTerm
{
	freeRentTermType: FreeRentTermType;
	freeRentTaxesType: FreeRentTaxesType;
	freeRentMonthsCount: number;
	specificMonths: number[];
	abstract: IFreeRentTerm;
	customDisplayValue: string;
	leaseTermOptions: ILeaseTermOptions<IFreeRentTerm>;
	documentSection: string;
}
export interface ITenantSquareFootageTerm extends ILeaseTerm
{
	tenantSquareFootageTermType: TenantSquareFootageTermType;
	tenantSquareFootageAbstractValue: number;
	tenantSquareFootageCustomValue: number;
	tenantSquareFootageRestructuredValue: number;
	tenantSquareFootagePhaseInValues: ITenantSquareFootagePhaseInValue[];
	tenantSquareFootageExpandByUnits: ITenantSquareFootageVacantUnit[];
	tenantSquareFootageRelocateByUnits: ITenantSquareFootageVacantUnit[];
	abstract: ITenantSquareFootageTerm;
	leaseTermOptions: ILeaseTermOptions<ITenantSquareFootageTerm>;
	documentSection: string;
}
export interface ITenantSquareFootageVacantUnit
{
	id: number;
	buildingId: number;
	name: string;
	size: number;
}
export interface ITenantSquareFootagePhaseInValue
{
	leaseMonth: number;
	totalSfOccupied: number;
	repeatType: TenantSquareFootagePhaseInRepeatType;
	clientUtcOffset: number;
}
export interface IBaseRentalRateTerm extends ILeaseTerm
{
	baseRentalRateType: BaseRentalRateType;
	baseRentalRateNetValue: number;
	baseRentalRateGrossValue: number;
	baseRentalRateUnitMetrics: BaseRentalRateUnitMetrics;
	baseYear: Date;
	actualYearRate: number;
	baseRentalRateTypeOriginal: BaseRentalRateType;
	abstract: IBaseRentalRateTerm;
	customDisplayValue: string;
	leaseTermOptions: ILeaseTermOptions<IBaseRentalRateTerm>;
	isCustomScheduleTableEnabled: boolean;
	customScheduleTable: IBaseRentalRateCustomScheduleTableRow[];
	documentSection: string;
}
export interface ITerm extends ILeaseTerm
{
	termValue: number;
	termType: TermTypeEnum;
	leaseTermOptions: ILeaseTermOptions<ITerm>;
	abstract: ITerm;
	documentSection: string;
	calculatedTermValue: number;
}
export interface IExpansionOptionTerm extends ILeaseTerm
{
	expansionOption: string;
	expansionOptionTermType: ExpansionOptionTermType;
	expansionDate: Date | string;
	abstract: IExpansionOptionTerm;
	customDisplayValue: string;
	documentSection: string;
}
export interface IEstoppelCertificateTerm extends ILeaseTerm
{
	estoppelCertificate: string;
	estoppelCertificateTermType: EstoppelCertificateTermType;
	tenantDeadlineAfterLandlordRequestInDays: number;
	abstract: IEstoppelCertificateTerm;
	documentSection: string;
}
export interface IAssignmentTerm extends ILeaseTerm
{
	assignment: string;
	assignmentTermType: AssignmentTermType;
	abstract: IAssignmentTerm;
	customDisplayValue: string;
	documentSection: string;
}
export interface ISelfHelpTerm extends ILeaseTerm
{
	selfHelp: string;
	selfHelpTermType: SelfHelpTermType;
	abstract: ISelfHelpTerm;
	documentSection: string;
}
export interface ITenantMaintenanceTerm extends ILeaseTerm
{
	tenantMaintenance: string;
	tenantMaintenanceTermType: TenantMaintenanceTermType;
	abstract: ITenantMaintenanceTerm;
	documentSection: string;
}
export interface ILandlordMaintenanceTerm extends ILeaseTerm
{
	landlordMaintenance: string;
	landlordMaintenanceTermType: LandlordMaintenanceTermType;
	hasOperationCondition: boolean;
	warrantDaysCount: number;
	abstract: ILandlordMaintenanceTerm;
	documentSection: string;
}
export interface ICommencementTerm extends ILeaseTerm
{
	commencement: Date | string;
	hasBeneficialOccupancy: boolean;
	beneficialOccupancyType: BeneficialOccupancyType;
	beneficialOccupancyAcceptableDate: Date | string;
	tenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriod: TenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriodType[];
	beneficialOccupancyCustomText: string;
	abstract: ICommencementTerm;
	documentSection: string;
}
export enum StateraClaimValueAsEnum
{
	None = 0,
	Revoke = 1,
	Read = 2,
	Write = 3,
}
export enum StateraClaimTypeAsEnum
{
	NotSet = 0,
	Collabo = 1,
	Collabo_Comments = 2,
	Collabo_Negotiation = 3,
	Collabo_Negotiation_Cancel = 4,
	Dashboard = 100,
	Dashboard_Landlord = 101,
	Dashboard_Tenant = 102,
	Dashboard_Broker = 103,
	Documents = 200,
	Document_Financial = 201,
	LeaseAnalysis = 300,
	Schedule = 400,
	CertificateOfInsurance = 500,
	Chat = 600,
	Chat_Internal = 601,
	Chat_External = 602,
	FloorPlan = 700,
	SitePlan = 740,
	Buildings = 800,
	Signature = 900,
	Signature_Sign = 901,
	Signature_RequireApproval = 902,
	Notifications = 1000,
	Team = 1102,
	Team_Member = 1103,
	Team_Member_CoBroker = 1104,
	Requests = 1200,
	Financial_Requests = 1201,
	Requests_Inquiry = 1202,
	Requests_Negotiation = 1203,
	Requests_Initiate_Inquiry = 1204,
	Requests_Initiate_Negotiation = 1205,
}
export interface IErrorImportModel
{
	fieldIndex: number;
	fieldName: string;
	message: string;
}
export interface IUpdateProjectNotesViewModel
{
	notes: string;
	projectId: number;
}
export interface IFloorPlanProInvitationViewModel
{
	kind: FloorPlanInvitationKind;
	invitationLink: string;
}
export interface IInviteFloorPlanProViewModel
{
	inviteFloorPlanProKind: InviteFloorPlanProKind;
	planProjectId: number;
	floorPlanId: number;
}
export enum InviteFloorPlanProKind
{
	PlanProject = 1,
	FloorPlanPro = 2,
}
export interface ILeaseTemplateViewModel
{
	taskId: number;
	task: IImportTaskViewModel;
	landlordCompanyId: number;
	originalDocumentId: number;
	originalDocument: IFileViewModel;
	convertedDocumentId: number;
	convertedDocument: IFileViewModel;
	markers: IDocumentMarkerViewModel[];
}
export interface IDocumentMarkerViewModel
{
	id: number;
	documentMarkingInfoId: number;
	documentMarkingInfo: IDocumentMarkingInfoViewModel;
	pageNumber: number;
	boundingBox: number[];
	tags: string[];
	text: string;
}
export interface IDocumentMarkingInfoViewModel
{
	taskId: number;
	task: IImportTaskViewModel;
	originalDocumentId: number;
	originalDocument: IFileViewModel;
	convertedDocumentId: number;
	convertedDocument: IFileViewModel;
	referType: DocumentMarkingReferType;
	refersTo: number;
	markers: IDocumentMarkerViewModel[];
}
export interface ICreatePlanProjectViewModel
{
	parentId: number;
	name: string;
	pictureId: number;
	picture: IFileViewModel;
}
export interface ICreateOrUpdateFloorPlanProViewModel
{
	id: number;
	name: string;
	size: number;
	address: string;
	cityAndState: string;
	planProjectId: number;
	pictureId: number;
	picture: IFileViewModel;
	floorPlanProType: FloorPlanProType;
}
export interface IPlanProjectViewModel
{
	id: number;
	parentId: number;
	name: string;
	pictureId: number;
	picture: IFileViewModel;
	floorPlanPros: IFloorPlanProViewModel[];
	parents: IParentPlanProjectViewModel[];
	updatedOn: Date;
	createdOn: Date;
}
export interface IParentPlanProjectViewModel
{
	id: number;
	name: string;
	createdById: number;
}
export interface IFloorPlanProViewModel extends IFloorPlanViewModel
{
	planProjectId: number;
	floorPlanProType: FloorPlanProType;
	size: number;
	address: string;
	cityAndState: string;
	openingCount: number;
}
export interface IFloorPlanViewModel
{
	id: number;
	planId: number;
	plan: IPlanViewModel;
	anchors: IPlanAnchorViewModel[];
	floorPlanType: FloorPlanType;
	createdById: number;
	createdOn: Date;
	updatedOn: Date;
}
export interface IGlobalFeatureToggleViewModel extends IFeatureToggles
{
	id: number;
}
export interface ICompanyFeatureToggleViewModel extends IFeatureToggles
{
	companyId: number;
	isIgnoresGlobals: boolean;
}
export interface IInquiriesReportViewModel
{
	entries: IInquiriesReportEntry[];
	fields: InquiriesReportExportField[];
	landlordCompanyName: string;
	exportDate: Date;
}
export interface IInquiriesReportEntry
{
	buildingName: string;
	unitName: string;
	type: TenantRequestType;
	inquiryDate: Date;
	tenantCompanyName: string;
	brokerNameAndCompany: string;
	requestedSize: string;
	daysSinceInquiry: string;
	targetOccupancy: string;
	quizNote: string;
}
export interface ILeasingActivityReportViewModel
{
	entries: ILeasingActivityReportEntry[];
	fields: LeasingActivityReportExportField[];
	landlordCompanyName: string;
	exportDate: Date;
}
export interface ILeasingActivityReportEntry
{
	buildingName: string;
	unitName: string;
	proposalDate: Date;
	tenantCompanyName: string;
	proposedBy: string;
	stage: string;
	term: string;
	dealType: ProjectTypeEnum;
	brokerNameAndCompany: string;
	baseRent: string;
	escalations: string;
	freeRent: string;
	tenantImprovements: string;
	size: string;
	cancelReason: string;
	notes: string;
}
export interface IComparisonReportSettingViewModel
{
	id: number;
	discountRate: number;
	leaseId: number;
}
export interface IComparisonReportViewModel
{
	lease: ILeaseViewModel;
	startingRate: string;
	rentIncrease: string;
	freeRent: string;
	serviceType: string;
	opEx: string;
	tiAllowance: string;
	proposedStart: Date;
	proposedEnd: Date;
	termLength: string;
	comparisonReportItems: IComparisonReportItem[];
	leaseTeam: LeaseTeam;
	negotiationProject: IProjectViewModel;
}
export interface IComparisonReportItem
{
	name: string;
	type: ComparisonReportItemType;
	cashFlowMetricDate: Date;
	leaseAnalysisResult: ILeaseAnalysisPerspectiveResult;
	tenantCompany: ICompanyViewModel;
}
export interface ILeaseAnalysisPerspectiveResult
{
	lease: ILeaseViewModel;
	projectTemplateItem: IProjectTemplateItemViewModel;
	unitMetrics: BaseRentalRateUnitMetrics;
	years: ILeaseAnalysisYearPerspective[];
	presentValue: number;
	proposalEffectiveRent: number;
	valueOfConcessions: number;
	freeRentTotal: number;
	freeRent: number;
	totalCashFlow: number;
	averageMonthlyRate: number;
	averageCashFlow: number;
	psfAverageCashFlow: number;
	psfPresentValue: number;
	psfTotalCashFlow: number;
	psfProposalEffectiveRate: number;
	serviceType: string;
	termDisplayValue: string;
	baseRentDisplayValue: string;
	baseRentValue: number;
	freeRentDisplayValue: string;
	escalationsDisplayValue: string;
	tenantImprovementDisplayValue: string;
	operatingExpensesDisplayValue: string;
	valuesDirection: IComparisonSummaryReportValuesDirection;
	rentIncrease: string;
	opEx: string;
}
export enum ComparisonReportItemType
{
	None = 0,
	Budget = 1,
	Market = 2,
	Proposal = 3,
	CurrentLease = 4,
	ThirdPartyLease = 5,
	LatestProposal = 6,
}
export interface IComparisonReportOptionViewModel
{
	leaseYearType: LeaseAnalysisYearType;
	leaseId: number;
	optionType: ComparisonReportOptionType;
}
export enum ComparisonReportOptionType
{
	None = 0,
	DealStages = 1,
	MultipleDeals = 2,
}
export interface IBuildingBudgetVersionViewModel
{
	buildingBudget: IBuildingBudgetViewModel;
	buildingUnitBudgets: IBuildingBudgetViewModel[];
	version: string;
	versionAsDate: Date;
}
export interface IBuildingBudgetViewModel
{
	id: number;
	buildingId: number;
	buildingUnitId: number;
	version: string;
	createdBy: IProfileViewModel;
	leaseTerm: number;
	renewalProbability: number;
	investmentBasis: number;
	investmentDate: Date | string;
	budgetedCapex: number;
	goingInCapRate: number;
	exitCapRate: number;
	yieldOnCash: number;
	hasCapitalItems: boolean;
	capitalItems: string[];
	marketRate: number;
	marketRateGrowths: IMarketRateGrowthViewModel[];
	escalations: number;
	freeRentRenewal: number;
	freeRentNewDeal: number;
	tenantImprovementsNewDeal: number;
	tenantImprovementsRenewal: number;
	downtime: number;
	opEx: number;
	landlordRepRenewal: number;
	tenantRepRenewal: number;
	landlordRepNewDeal: number;
	tenantRepNewDeal: number;
	createdOn: Date;
}
export interface IMarketRateGrowthViewModel
{
	year: number;
	value: number;
}
export interface ILeaseTeamViewModel
{
	tenantTeam: ILeaseTeamUserViewModel[];
	landlordTeam: ILeaseTeamUserViewModel[];
}
export interface ILeaseTeamUserViewModel
{
	user: IProfileViewModel;
	role: LeaseTeamUserRole;
}
export interface ILeaseSettingsViewModel
{
	leaseMetrics: BaseRentalRateUnitMetrics;
}
export interface IQuizLeaseAmendmentChoiceViewModel
{
	leaseId: number;
	leaseCommencementDate: Date | string;
	leaseExpirationDate: Date | string;
	landlordCompany: ICompanyViewModel;
	building: IBuildingViewModel;
	buildingUnit: IBuildingUnitViewModel;
	displayValue: string;
}
export interface ITenantDashboardViewModel
{
	id: number;
	abstractLeaseId: number;
	projectType: ProjectTypeEnum;
	building: IBuildingViewModel;
	buildingUnit: IBuildingUnitViewModel;
}
export enum RequiredSpaceAnswerType
{
	LessSpace = 3,
	MoreSpace = 4,
	SameSpace = 5,
}
export interface IMarketSurveyViewModel
{
	id: number;
	projectId: number;
	searchCriteria: IMarketSurveySearchCriteriaViewModel;
}
export interface IMarketSurveySearchCriteriaViewModel
{
	id: number;
}
export interface ICreateLeaseFloorPlanViewModel
{
	leaseId: number;
}
export interface ITermCustomValueTemplateViewModel
{
	id: number;
	content: string;
	leaseTermType: LeaseTermType;
	usageCount: number;
}
export interface ITenantRequestDeleteViewModel
{
	quizId: number;
	quizAnswers: IQuizAnswerViewModel[];
}
export interface ICreateTourViewModel
{
	shouldCreateTourRequest: boolean;
	dateTimeSlots: IDateTimeSlotEntryViewModel[];
}
export interface IDateTimeSlotEntryViewModel
{
	date: string;
	timeSlots: string[];
}
export interface ITourViewModel
{
	id: number;
	projectId: number;
	leaseId: number;
	dateTimeSlots: IDateTimeSlotEntryViewModel[];
	confirmedDateTimeSlot: ISingleDateTimeSlotViewModel;
	isApprovedByTenant: boolean;
	isApprovedByLandlord: boolean;
	status: TourStatus;
	createdOn: Date;
	createdById: number;
}
export interface ISingleDateTimeSlotViewModel
{
	date: string;
	timeSlot: string;
}
export interface IAvailableUnitsForbiddenResultViewModel
{
	reason: AvailableUnitsForbiddenResultReason;
	description: string;
}
export interface ICompanyPredictionViewModel
{
	id: number;
	name: string;
	logo: IFileViewModel;
}
export interface ITenantRequestViewModel
{
	id: number;
	leaseId: number;
	projectId: number;
	type: TenantRequestType;
	status: TenantRequestStatus;
	tourId: number;
	tour: ITourViewModel;
	tenantCompanyId: number;
	tenantCompanyLogoUrl: string;
	tenantCompanyName: string;
	buildingId: number;
	buildingName: string;
	buildingAddressLine1: string;
	buildingUnitId: number;
	buildingUnitName: string;
	buildingUnitSize: number;
	buildingUnitListingSizeMin: number;
	buildingUnitListingSizeMax: number;
	requestedSize: string;
	isConvertedToDeal: boolean;
	isExistingTenantCompany: boolean;
	createdAt: Date;
	submittedBy: IProfileViewModel;
	quizAnswers: IQuizAnswerViewModel[];
	quizNote: string;
}
export interface ITenantRequestBriefViewModel
{
	id: number;
	leaseId: number;
	projectId: number;
	projectType: ProjectTypeEnum;
	type: TenantRequestType;
	status: TenantRequestStatus;
	tourStatus: TourStatus;
	tenantCompany: ICompanyViewModel;
	submittedBy: IProfileViewModel;
	buildingId: number;
	buildingName: string;
	buildingUnitAddress: string;
	buildingUnitId: number;
	buildingUnitName: string;
	buildingUnitSize: number;
	buildingUnitListingSizeMin: number;
	buildingUnitListingSizeMax: number;
	requestedSize: string;
	targetOccupancy: string;
	isConvertedToDeal: boolean;
	isExistingTenantCompany: boolean;
	createdAt: Date;
}
export interface IQuizItemViewModel
{
	quizItemType: QuizItemTypeAsEnum;
	quizQuestion: IQuizQuestionViewModel;
	quizAnswer: IQuizAnswerViewModel;
	question: string;
	answer: IQuizItemAnswer;
	answers: IQuizItemAnswer[];
	quizItemTable: IQuizItemTable;
}
export interface IQuizItemTable
{
	quizItemRows: IQuizItemRow[];
}
export interface IQuizItemRow
{
	quizItemColumns: IQuizItemColumn[];
}
export interface IQuizItemColumn
{
	value: string;
}
export interface IQuizItemAnswer
{
	answer: string;
	fileUrl: string;
}
export enum QuizItemTypeAsEnum
{
	None = 0,
	Text = 1,
	List = 2,
	File = 3,
	Files = 4,
	Table = 5,
}
export interface ILeaseActivityViewModel
{
	id: number;
	leaseId: number;
	createdById: number;
	createdBy: IProfileViewModel;
	lastReadOn: Date;
	projectId: number;
	projectTemplateItemId: number;
	leaseTeam: LeaseTeam;
}
export interface IPointOfInterestListOptionsViewModel
{
	boundingBox: number[];
}
export interface IPointOfInterestViewModel
{
	id: number;
	kind: PointOfInterestKind;
	status: PointOfInterestStatus;
	name: string;
	description: string;
	geopointLatitude: number;
	geopointLongitude: number;
	geoJSON: string;
}
export interface IVerifyPhoneNumberResultViewModel
{
	success: boolean;
}
export interface ITwoFactorAuthSettingViewModel
{
	isEnabled: boolean;
}
export interface IAddressPropertiesViewModel
{
	states: IState[];
	countries: ICountry[];
	markets: IMarket[];
	subMarkets: IMarket[];
	counties: ICounty[];
}
export interface ILeaseHistoryRecordViewModel
{
	leaseTermHistoryRecords: ILeaseTermHistoryRecordViewModel[];
	lease: ILeaseViewModel;
}
export interface ILeaseTermHistoryRecordViewModel
{
	termName: string;
	leaseTermType: LeaseTermType;
	abstractValue: string;
	leaseTermCustomTable: ILeaseTermCustomTableViewModel;
	termHistoryModels: ILeaseTermHistoryChangeRecordViewModel[];
	isElectedForNegotiationAtActualStage: boolean;
	valueOrStatusChangedSincePreviousStage: boolean;
	additionalValueTable: ILeaseTermCustomTableViewModel;
	additionalValue: string;
}
export interface ILeaseTermHistoryChangeRecordViewModel
{
	displayValue: string;
	createdOn: Date;
	createdById: number;
	additionalValueTable: ILeaseTermCustomTableViewModel;
	additionalValue: string;
	createdOnAsString: string;
	leaseTeam: LeaseTeam;
	role: string;
	projectTemplateItemType: RenewalProjectTemplateItemType;
	projectTemplateItemName: string;
	leaseTermType: LeaseTermType;
	optionType: number;
	isMultipleOptions: boolean;
	shouldShowAcceptanceMark: boolean;
	leaseTermCustomTable: ILeaseTermCustomTableViewModel;
	hasLeaseTermCustomTable: boolean;
	previewLeaseTermTable: ILeaseTermCustomTableViewModel;
	hasPreviewLeaseTermTable: boolean;
	isAcceptedValue: boolean;
	acceptedDate: Date;
	isLastNonAcceptedValue: boolean;
	isModifiedValue: boolean;
	termStatus: TermStatus;
	isElectedForNegotiation: boolean;
}
export interface IBuildingFileFolderViewModel
{
	id: number;
	name: string;
	isShared: boolean;
	buildingFileFolderType: BuildingFileFolderType;
}
export interface ILeaseFileFolderViewModel
{
	id: number;
	name: string;
	isShared: boolean;
	leaseFileFolderType: LeaseFileFolderType;
}
export interface ITenantFileViewModel
{
	leaseId: number;
	lease: ILeaseViewModel;
	fileId: number;
	file: IFileViewModel;
	folderId: number;
	folder: ITenantFileFolderViewModel;
	attachedToEntityId: number;
	isReadOnly: boolean;
	createdOn: Date;
}
export interface IWhoSignLeaseSignatureViewModel
{
	whoSignLeaseSignature: WhoSignLeaseSignature;
	firstName: string;
	lastName: string;
	email: string;
	leaseId: number;
}
export enum WhoSignLeaseSignature
{
	None = 0,
	CurrentUser = 1,
	ExternalUser = 2,
}
export interface ILeaseSignViewModel
{
	id: number;
	documentHash: string;
	embeddedSigningUrlForTenant: string;
	embeddedSigningUrlForLandlord: string;
	documentId: number;
	document: IFileViewModel;
	isTenantSigned: boolean;
	isLandlordSigned: boolean;
	isExternalTenantEmail: boolean;
	isExternalLandlordEmail: boolean;
	landlordEmail: string;
	tenantEmail: string;
	tenantId: number;
	landlordId: number;
	hasTenantSigner: boolean;
	hasLandlordSigner: boolean;
}
export interface IWhoSignLeaseAmendmentViewModel
{
	whoSignLeaseAmendment: WhoSignLeaseAmendment;
	firstName: string;
	lastName: string;
	email: string;
	leaseId: number;
}
export enum WhoSignLeaseAmendment
{
	None = 0,
	CurrentUser = 1,
	ExternalUser = 2,
}
export interface IAssetsTypeViewModel
{
	id: number;
	name: string;
	childAssetsTypes: IAssetsTypeViewModel[];
}
export interface IElectedTermForNegotiationViewModel
{
	leaseTermType: LeaseTermType;
	leaseTermConfiguration: ILeaseTermConfigurationViewModel;
	isElected: boolean;
	isElectedByDefault: boolean;
	canBeDeleted: boolean;
}
export interface ILeaseTermConfigurationViewModel
{
	id: number;
	description: string;
	termName: string;
	termGroup: string;
	tooltip: string;
	type: TermType;
	format: string;
	max: number;
	min: number;
	sortOrder: number;
	groupSortOrder: number;
	isHidden: boolean;
	isRequired: boolean;
	isUsedInDashboardPage: boolean;
	isUsedInTermPage: boolean;
	leaseTermType: LeaseTermType;
}
export interface IColaboTreeItemViewModel
{
	itemId: string;
	itemParentId: string;
	name: string;
	leaseId: number;
	project: IProjectViewModel;
	chatChannels: IChatChannelViewModel[];
	sortOrder: number;
	rejectedDate: Date;
}
export interface IChatChannelViewModel
{
	id: number;
	entityId: number;
	channelType: ChatChannelType;
}
export interface IPortfolioBuildingViewModel
{
	id: number;
	name: string;
	attachments: IBuildingAttachmentViewModel[];
	address: IAddressViewModel;
	totalBuildingSize: number;
	unitsNumber: number;
	availableUnitsNumber: number;
	availableSquareFeet: number;
	teamMembers: ITeamMemberViewModel[];
}
export interface IBuildingAttachmentViewModel
{
	id: number;
	buildingId: number;
	buildingUnitId: number;
	fileId: number;
	file: IFileViewModel;
	externalUrl: string;
	sortOrder: number;
	buildingAttachmentType: BuildingAttachmentType;
}
export interface ITeamMemberViewModel
{
	userId: number;
	leaseUserId: number;
	fullName: string;
	firstName: string;
	lastName: string;
	avatar: IFileViewModel;
	emailAddress: string;
	companyName: string;
	roles: string[];
	lastActivityDateTime: Date;
	userInvitationRequest: IUserInvitationRequestViewModel;
	isDeletable: boolean;
}
export interface ILeaseUserViewModel
{
	id: number;
	portfolioId: number;
	buildingId: number;
	leaseId: number;
	userId: number;
	leaseUserRole: LeaseUserRole;
	relatedToCompanyId: number;
	relatedToCompany: ICompanyViewModel;
	relatedToCompanyType: CompanyType;
}
export interface IPortfolioInvitationUserViewModel
{
	portfolioInvitationId: number;
	userId: number;
	acceptanceStatus: PortfolioInvitationUserAcceptanceStatus;
}
export interface IPortfolioInvitationInfoViewModel
{
	invitationUser: IPortfolioInvitationUserViewModel;
	building: IBuildingWithAvailableUnitsViewRecord;
	buildingUnit: IBuildingUnitViewModel;
	availableBuildingUnits: IBuildingUnitViewModel[];
}
export interface IPortfolioInvitationViewModel
{
	kind: PortfolioInvitationKind;
	companyId: number;
	inviteeEmailAddress: string;
	additionalNotes: string;
	buildingId: number;
	buildingUnitId: number;
	isConsumed: boolean;
}
export interface IUserInvitationInfoViewModel
{
	userInvitationRequestKind: UserInvitationRequestKind;
	user: IProfileViewModel;
	leases: ILeaseViewModel[];
	portfolios: IPortfolioViewModel[];
	buildings: IBuildingViewModel[];
	projects: IProjectViewModel[];
	leaseUserRole: LeaseUserRole;
	invitedByUser: IProfileViewModel;
	invitedToCompany: ICompanyViewModel;
	relatedToCompany: ICompanyViewModel;
	shouldResetPassword: boolean;
	isInvitationAccepted: boolean;
}
export interface IAcceptInvitationViewModel
{
	userPassword: string;
	protectedToken: string;
}
export interface IUserInvitationViewModel
{
	userInvitationRequestId: number;
	userId: number;
	leaseIds: number[];
	portfolioIds: number[];
	buildingIds: number[];
	leaseUserRole: LeaseUserRole;
	invitedByUserId: number;
	relatedToCompanyId: number;
	claimIds: number[];
	claimGroupIds: number[];
}
export interface IUserInvitationRequestViewModel
{
	id: number;
	requestKind: UserInvitationRequestKind;
	requestStatus: UserInvitationRequestStatus;
	leaseIds: number[];
	portfolioIds: number[];
	buildingIds: number[];
	firstName: string;
	lastName: string;
	companyName: string;
	companyId: number;
	emailAddress: string;
	role: string;
	requestedBy: number;
}
export interface IStateraUserClaimsSimplifiedViewModel
{
	userId: number;
	lastUpdatedTicks: number;
	lastUpdatedTicksAsString: string;
	groups: IStateraClaimsGroupViewModel[];
}
export interface IStateraClaimsGroupViewModel
{
	entityIds: {};
	claims: {};
}
export enum StateraClaimsGroupKind
{
	CompanyGroup = 1,
	PortfolioGroup = 2,
	BuildingGroup = 3,
	LeaseGroup = 4,
}
export interface IStateraUserClaimsViewModel
{
	userId: number;
	stateraCompanyClaims: IStateraCompanyClaimViewModel[];
	stateraPortfolioClaims: IStateraPortfolioClaimViewModel[];
	stateraBuildingClaims: IStateraBuildingClaimViewModel[];
	stateraLeaseClaims: IStateraLeaseClaimViewModel[];
	lastUpdatedTicks: number;
	lastUpdatedTicksAsString: string;
}
export interface IStateraLeaseClaimViewModel
{
	leaseId: number;
	stateraClaims: IStateraClaimViewModel[];
}
export interface IStateraClaimViewModel
{
	stateraClaimType: StateraClaimTypeAsEnum;
	stateraClaimValue: StateraClaimValueAsEnum;
}
export interface IStateraBuildingClaimViewModel
{
	buildingId: number;
	stateraClaims: IStateraClaimViewModel[];
}
export interface IStateraPortfolioClaimViewModel
{
	portfolioId: number;
	stateraClaims: IStateraClaimViewModel[];
}
export interface IStateraCompanyClaimViewModel
{
	companyId: number;
	stateraClaims: IStateraClaimViewModel[];
}
export interface IInquiryInsightsTopLocationsReportViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	locations: IInquiryInsightsTopLocationsLocationViewModel[];
}
export interface IInquiryInsightsTopLocationsLocationViewModel
{
	city: string;
	inquiriesCount: number;
}
export interface IInquiryInsightsTopBuildingsReportViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	buildings: IInquiryInsightsTopBuildingsBuildingViewModel[];
}
export interface IInquiryInsightsTopBuildingsBuildingViewModel
{
	name: string;
	attachments: IBuildingAttachmentViewModel[];
	inquiriesCount: number;
}
export interface IInquiryInsightsInquiryDeleteTopReasonsReportViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	reasons: IInquiryInsightsInquiryDeleteTopReasonsReasonViewModel[];
}
export interface IInquiryInsightsInquiryDeleteTopReasonsReasonViewModel
{
	text: string;
	inquiriesCount: number;
}
export interface IInquiryInsightsInquiriesWithToursConvertedToDealReportViewModel
{
	periods: IInquiryInsightsInquiriesWithToursConvertedToDealPeriodViewModel[];
}
export interface IInquiryInsightsInquiriesWithToursConvertedToDealPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	inquiriesWithToursConvertedToDealCount: number;
	inquiriesCount: number;
}
export interface IInquiryInsightsAverageSizeRequestedReportViewModel
{
	periods: IInquiryInsightsAverageSizeRequestedPeriodViewModel[];
}
export interface IInquiryInsightsAverageSizeRequestedPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	averageSizeRequested: number;
	items: IInquiryInsightsAverageSizeRequestedPeriodItemViewModel[];
}
export interface IInquiryInsightsAverageSizeRequestedPeriodItemViewModel
{
	sizeMin: number;
	sizeMax: number;
	inquiriesCount: number;
}
export interface IInquiryInsightsInquiriesActivityReportViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	inquiriesTotalCount: number;
	inquiriesWithNoActivityCount: number;
	inquiriesConvertedToDealCount: number;
	inquiriesConvertedToTourCount: number;
	inquiriesWithToursCompletedCount: number;
}
export interface IInquiryInsightsInquiriesSubmittedByReportViewModel
{
	periods: IInquiryInsightsInquiriesSubmittedByPeriodViewModel[];
}
export interface IInquiryInsightsInquiriesSubmittedByPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	inquiriesCountByExistingTenants: number;
	inquiriesCountByProspectiveTenants: number;
}
export interface IInquiryInsightsInquiryDynamicsReportViewModel
{
	periods: IInquiryInsightsInquiryDynamicsPeriodViewModel[];
}
export interface IInquiryInsightsInquiryDynamicsPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	inquiriesCount: number;
}
export interface IInquiryInsightsKeyStatisticsReportViewModel
{
	activeBuildingUnitListingsSquareFeet: number;
	activeBuildingUnitListingsCount: number;
	receivedInquiriesCount: number;
	completedToursCount: number;
}
export interface IKeyStatisticsReportViewModel
{
	totalSquareFeet: number;
	uniquePortfoliosCount: number;
	uniqueBuildingsCount: number;
	activeDealsCount: number;
	totalOccupancy: number;
	totalOccupancyPercentage: number;
	totalVacancies: number;
	totalVacanciesPercentage: number;
	squareFeetExpiringThisYear: number;
	weightedAverageLeaseTerm: number;
}
export interface IAnalyticsFiltersViewModel
{
	portfolios: IAnalyticsFilterPortfolioViewModel[];
	buildings: IAnalyticsFilterBuildingViewModel[];
	locationStates: IAnalyticsFilterLocationStateViewModel[];
	locationCities: IAnalyticsFilterLocationCityViewModel[];
	locationMarkets: IAnalyticsFilterLocationMarketViewModel[];
	locationSubMarkets: IAnalyticsFilterLocationSubMarketViewModel[];
	locationZipCodes: IAnalyticsFilterLocationZipCodeViewModel[];
	locationAddresses: IAnalyticsFilterLocationAddressViewModel[];
	dealTypes: IAnalyticsFilterDealTypeViewModel[];
	tenantCompanies: IAnalyticsFilterTenantCompanyViewModel[];
	industries: IAnalyticsFilterIndustryViewModel[];
	spaceUses: IAnalyticsFilterSpaceUseViewModel[];
	leaseExpirations: IAnalyticsFilterLeaseExpirationViewModel[];
	squareFeetRange: IAnalyticsFilterSquareFeetRangeViewModel;
}
export interface IAnalyticsFilterSquareFeetRangeViewModel
{
	min: number;
	max: number;
}
export interface IAnalyticsFilterLeaseExpirationViewModel
{
	name: string;
	fromDate: Date | string;
	untilDate: Date | string;
}
export interface IAnalyticsFilterSpaceUseViewModel
{
	portfolioIds: number[];
	spaceUseId: number;
	name: string;
}
export interface IAnalyticsFilterIndustryViewModel
{
	portfolioIds: number[];
	industryId: number;
	name: string;
}
export interface IAnalyticsFilterTenantCompanyViewModel
{
	portfolioIds: number[];
	tenantCompanyId: number;
	companyName: string;
}
export interface IAnalyticsFilterDealTypeViewModel
{
	portfolioIds: number[];
	projectIds: number[];
	projectTypes: ProjectTypeEnum[];
	name: string;
}
export interface IAnalyticsFilterLocationAddressViewModel
{
	portfolioId: number;
	addressId: number;
	buildingPictureThumbnailUrl: string;
	buildingPictureUrl: string;
	name: string;
}
export interface IAnalyticsFilterLocationZipCodeViewModel
{
	portfolioIds: number[];
	zipCode: string;
}
export interface IAnalyticsFilterLocationSubMarketViewModel
{
	portfolioIds: number[];
	subMarketId: number;
	name: string;
}
export interface IAnalyticsFilterLocationMarketViewModel
{
	portfolioIds: number[];
	buildingIds: number[];
	marketId: number;
	name: string;
}
export interface IAnalyticsFilterLocationCityViewModel
{
	portfolioIds: number[];
	name: string;
}
export interface IAnalyticsFilterLocationStateViewModel
{
	portfolioIds: number[];
	stateCode: string;
	countryCode: string;
	name: string;
}
export interface IAnalyticsFilterBuildingViewModel
{
	portfolioIds: number[];
	buildingId: number;
	name: string;
}
export interface IAnalyticsFilterPortfolioViewModel
{
	portfolioId: number;
	name: string;
}
export interface IActiveDealsByStageReportViewModel
{
	leaseIds: number[];
	groups: IActiveDealsByStageReportStageGroupViewModel[];
}
export interface IActiveDealsByStageReportStageGroupViewModel
{
	name: string;
	leaseIds: number[];
	stages: IActiveDealsByStageReportStageViewModel[];
}
export interface IActiveDealsByStageReportStageViewModel
{
	leaseId: number;
	projectId: number;
	buildingId: number;
	buildingUnitId: number;
	buildingAddress: string;
	buildingUnitName: string;
	tenantSquareFootageTerm: ITenantSquareFootageTermViewModel;
	tenantCompany: ICompanyViewModel;
	stageName: string;
	stageStartDate: Date | string;
	inActiveLease: boolean;
}
export interface IDealsByStageReportViewModel
{
	dealType: DealsByStageReportDealType;
	leases: IDealsByStageReportLeaseViewModel[];
	stages: IDealsByStageReportLeaseStageViewModel[];
}
export interface IDealsByStageReportLeaseStageViewModel
{
	stageId: number;
	stageName: string;
	daysTakenByTenant: number;
	daysTakenByLandlord: number;
	averageDaysTakenByTenant: number;
	averageDaysTakenByLandlord: number;
	table: IDealsByStageReportLeaseStageTableItemViewModel[];
	dealCountsInfo: IDealCountsInfo;
}
export interface IDealsByStageReportLeaseStageTableItemViewModel
{
	leaseId: number;
	leaseStatus: string;
	projectId: number;
	chatChannelId: number;
	buildingId: number;
	buildingUnitId: number;
	buildingAddress: string;
	buildingUnitName: string;
	tenantSquareFootageTerm: ITenantSquareFootageTermViewModel;
	tenantCompany: ICompanyViewModel;
	totalDaysInStage: number;
	daysTakenByTenant: number;
	daysTakenByLandlord: number;
	totalDaysInStageVsBenchmark: number;
	totalDaysInStageVsAverage: number;
	inActiveLease: boolean;
}
export interface IDealsByStageReportLeaseViewModel
{
	leaseId: number;
	status: string;
	stages: IDealsByStageReportLeaseStageViewModel[];
}
export interface IOccupancyAndVacancyReportViewModel
{
	grouping: OccupancyAndVacancyReportGroupingOption;
	periods: IOccupancyAndVacancyReportPeriodViewModel[];
	vacantUnits: number;
}
export interface IOccupancyAndVacancyReportPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	occupancy: number;
	occupancyPercentage: number;
	vacancies: number;
	vacanciesPercentage: number;
	items: IOccupancyAndVacancyReportPeriodItemViewModel[];
	table: IOccupancyAndVacancyReportTableItemViewModel[];
}
export interface IOccupancyAndVacancyReportTableItemViewModel
{
	leaseId: number;
	projectId: number;
	chatChannelId: number;
	buildingId: number;
	buildingUnitId: number;
	buildingAddress: string;
	buildingUnitName: string;
	squareFeet: number;
	tenantCompany: ICompanyViewModel;
	leaseStatus: OccupancyAndVacancyReportLeaseStatus;
	nextLeaseStatus: OccupancyAndVacancyReportLeaseStatus;
	leaseStatusDisplay: string;
	nextLeaseStatusDisplay: string;
	nextLeaseCommencementDate: Date | string;
	leaseExpirationDate: Date | string;
	renewalIndex: number;
	inActiveLease: boolean;
}
export enum OccupancyAndVacancyReportLeaseStatus
{
	Vacant = 0,
	InLease = 1,
	Renewed = 2,
	Restructured = 3,
	Renewal = 4,
	Restructure = 5,
}
export interface IOccupancyAndVacancyReportPeriodItemViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	occupancy: number;
	occupancyPercentage: number;
	vacancies: number;
	vacanciesPercentage: number;
	leaseIds: number[];
}
export interface IExpirationsReportViewModel
{
	grouping: ExpirationsReportGroupingOption;
	periods: IExpirationsReportPeriodViewModel[];
	expiringLeasesCount: number;
}
export interface IExpirationsReportPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	totalSquareFeet: number;
	totalSquareFeetPercentage: number;
	totalNumberOfUnitsExpiring: number;
	totalNumberOfUniqueTenantExpiring: number;
	items: IExpirationsReportPeriodItemViewModel[];
	table: IExpirationsReportPeriodTableItemViewModel[];
}
export interface IExpirationsReportPeriodTableItemViewModel
{
	leaseId: number;
	projectId: number;
	buildingId: number;
	buildingUnitId: number;
	buildingAddress: string;
	buildingUnitName: string;
	squareFeet: number;
	tenantCompany: ICompanyViewModel;
	leaseExpirationDate: Date | string;
	inActiveLease: boolean;
}
export interface IExpirationsReportPeriodItemViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	totalSquareFeet: number;
	totalSquareFeetPercentage: number;
	totalNumberOfUnitsExpiring: number;
	totalNumberOfUniqueTenantExpiring: number;
	leaseIds: number[];
}
export interface IExternalSignatureViewModel
{
	leaseId: number;
	externalSignatureType: ExternalSignatureType;
	email: string;
	firstName: string;
	embeddedSigningUrl: string;
	address: string;
	otherCompanyName: string;
	companyName: string;
	userFullName: string;
	userProfile: IProfile;
	leaseTeam: LeaseTeam;
	projectType: string;
	buildingAddress: string;
}
export enum ExternalSignatureType
{
	LetterOfIntent = 1,
	LeaseAmendment = 2,
}
export interface IMarkReadAllAlertsViewModel
{
	leaseId: number;
}
export interface ILeaseAnalysisProjectViewModel
{
	name: string;
	optionName: string;
	lease: ILeaseViewModel;
	project: IProjectViewModel;
}
export interface IPost2faCodeViewModel
{
	code: string;
}
export interface IAuthenticateResultViewModel
{
	requiresTwoFactor: boolean;
	userStatus: UserStatus;
}
export interface IVerifyPhoneNumberViewModel
{
	token: string;
	enable2Fa: boolean;
}
export interface IInsuranceCertificateParamsViewModel
{
	leaseViewModel: ILeaseViewModel;
	insuranceCertificateViewModel: IInsuranceCertificateViewModel;
}
export interface IInsuranceCertificateViewModel
{
	id: number;
	leaseId: number;
	hasGeneralLiability: boolean;
	generalLiabilityCoverageLevelEachOccurrence: number;
	damageToRentedPremises: number;
	medExp: number;
	personalAndAdvInjury: number;
	generalAggregate: number;
	productsCompOpAgg: number;
	generalLiabilityCoverageLevelComments: string;
	hasAutoLiability: boolean;
	combinedSingleLimit: number;
	bodilyInjuryPerPerson: number;
	bodilyInjuryPerAccident: number;
	propertyDamage: number;
	autoLiabilityCoverageLevelComments: string;
	hasUmbrellaLiability: boolean;
	umbrellaLiabilityCoverageLevelEachOccurrence: number;
	umbrellaLiabilityCoverageLevelAggregate: number;
	umbrellaLiabilityCoverageLevelComments: string;
	hasWorkersCompensation: boolean;
	diseaseValueForEachAccident: number;
	diseaseValueForEachEmployee: number;
	diseasePolicyLimit: number;
	workersCompensationComments: string;
	descriptionOfOperations: string;
	certificateHolder: string;
	additionalRemark: string;
	insuranceCertificateFiles: IInsuranceCertificateFileViewModel[];
	lastInsuranceCertificateFile: IInsuranceCertificateFileViewModel;
}
export interface IInsuranceCertificateFileViewModel
{
	id: number;
	availableForDeletingByTenant: boolean;
	fileViewModel: IFileViewModel;
}
export interface ILeaseRequestFileViewModel
{
	leaseRequestId: number;
	fileId: number;
	leaseRequest: ILeaseRequestViewModel;
	file: IFileViewModel;
}
export interface ILeaseRequestViewModel
{
	id: number;
	leaseId: number;
	lease: ILeaseViewModel;
	requestType: LeaseRequestType;
	requestStatus: LeaseRequestStatus;
	financialRequest: IFinancialRequestViewModel;
	requestFiles: ILeaseRequestFileViewModel[];
}
export interface IRestructureProjectParamsViewModel
{
	leaseViewModel: ILeaseViewModel;
	clientUtcOffset: number;
}
export interface IQuizRangeAnswerViewModel
{
	from: number;
	to: number;
}
export interface IBriefDealSummaryViewModel
{
	leaseId: number;
	projectTemplateItemName: string;
	stageNumber: number;
	proposalDate: Date;
	startDateOfDeal: Date;
	userWhoProposed: IProfileViewModel;
	term: ITermViewModel;
	tenantSquareFootageTerm: ITenantSquareFootageTermViewModel;
	baseRentalRateTerm: IBaseRentalRateViewModel;
	freeRentTerm: IFreeRentTermViewModel;
	tenantImprovementsTerm: ITenantImprovementsViewModel;
}
export interface ICancelledRequestViewModel
{
	leaseId: number;
}
export interface ISuiteViewModel
{
	buildingUnit: IBuildingUnitViewModel;
	lease: ILeaseViewModel;
	project: IProjectViewModel;
	tenantCompany: ICompanyViewModel;
	landlordCompany: ICompanyViewModel;
	unitMetrics: BaseRentalRateUnitMetrics;
	endingBaseRate: number;
	annualBaseRate: number;
	endingAnnualBaseRate: number;
	baseRentalSchedule: IBaseRentalScheduleViewModel;
	leaseStartDate: Date | string;
	leaseEndDate: Date | string;
	renewalNoticeDate: Date | string;
	expansionNoticeDate: Date | string;
	terminationNoticeDate: Date | string;
	tenantTeamMembers: ITeamMemberViewModel[];
}
export interface IDealViewModel
{
	id: number;
	leaseId: number;
	lease: ILeaseViewModel;
	avatarUrl: string;
	expiration: Date | string;
	tenantCompanyName: string;
	unitName: string;
	baseRentalRateTerm: IBaseRentalRateViewModel;
	tenantSquareFootageTerm: ITenantSquareFootageTermViewModel;
	projects: IProjectViewModel[];
	teamMembers: ITeamMemberViewModel[];
	negotiationProject: IProjectViewModel;
	projectNotes: string;
	projectState: IProjectTemplateItemViewModel;
	parentProjectState: IProjectTemplateItemViewModel;
	fullTenantName: string;
}
export interface ILandlordDashboardFilterViewModel
{
	assetFilter: IAssetFilter;
	dealsFilter: IDealsFilter;
	inquiriesFilter: IInquiriesFilter;
	leaseExpirationFilters: ILeaseExpirationFilter[];
	requestFilter: IRequestFilter;
	pageSize: number;
	page: number;
	landlordDashboardSortBy: LandlordDashboardSortBy;
	searchText: string;
	fundFilters: IFundFilter[];
}
export interface IFundFilter
{
	fund: string;
}
export enum LandlordDashboardSortBy
{
	NewestToOldest = 0,
	Alphabet = 1,
	Size = 2,
}
export interface IRequestFilter
{
}
export interface ILeaseExpirationFilter
{
	startLeaseExpirationDate: Date;
	endLeaseExpirationDate: Date;
}
export interface IInquiriesFilter
{
	inquiry: Date;
}
export interface IDealsFilter
{
	projectTemplateIds: number[];
	projectTypes: number[];
}
export interface IAssetFilter
{
	portfolioIds: number[];
	assetIds: number[];
}
export interface IRenewalProjectParamsViewModel
{
	leaseViewModel: ILeaseViewModel;
	projectId: number;
	approvingAppointment: IApprovingAppointmentViewModel;
	isAcceptTiNegotiationByTenantOrLandlord: boolean;
	isAcceptTiOptionAfterFloorPlan: boolean;
	isAcceptedUnsolicitedOffer: boolean;
	clientUtcOffset: number;
	rejectionLeaseViewModel: IRejectionLeaseViewModel;
}
export interface IStageHistoryRecordViewModel
{
	id: number;
	lease: ILeaseViewModel;
	projectTemplateItem: IProjectTemplateItemViewModel;
	leaseSnapshot: ILeaseViewModel;
	createdOn: Date;
	profileViewModel: IProfileViewModel;
}
export interface IImportPointsOfInterestTaskFieldMappingViewModel
{
	name: string;
	description: string;
	geopointLatitude: number;
	geopointLongitude: number;
	geoJSON: string;
}
export interface IImportPointsOfInterestTaskPayloadViewModel
{
	kind: PointOfInterestKind;
	status: PointOfInterestStatus;
	fieldMappings: IImportPointsOfInterestTaskFieldMappingViewModel;
}
export interface IImportBuildingsTaskFieldMappingViewModel
{
	name: number;
	totalBuildingSize: number;
	builtYear: number;
	buildingClass: number;
	leedCertification: number;
	amps: number;
	volts: number;
	phase: number;
	constructionType: number;
	clearHeight: number;
	totalOfficeArea: number;
	renovatedDate: number;
	totalLandArea: number;
	columnSpacing: number;
	totalParkingSpaces: number;
	totalDocksInterior: number;
	totalDocksExterior: number;
	sprinklerSystem: number;
	utilityCosts: number;
	utilityCostsText: number;
	mostCurrentRealEstateTaxesPsf: number;
	mostCurrentRealEstateTaxesTotal: number;
	operatingExpenses: number;
	mostRecentInteriorConstructionOrUpdateYear: number;
	mostRecentExteriorConstructionOrUpdateYear: number;
	mostRecentInteriorConstructionOrUpdateText: number;
	mostRecentExteriorConstructionOrUpdateText: number;
	railLine: number;
	pinNumber: number;
	roofTypeCondition: number;
	mostRecentSalePrice: number;
	saleYear: number;
	addressLine1: number;
	addressLine2: number;
	zipCode: number;
	city: number;
	countryCode: number;
	stateCode: number;
	countyName: number;
	market: number;
	subMarket: number;
}
export interface IImportBuildingsTaskPayloadViewModel
{
	landlordId: number;
	portfolioId: number;
	fieldMappings: IImportBuildingsTaskFieldMappingViewModel;
}
export interface IImportTaskViewModel
{
	id: number;
	taskType: ImportTaskType;
	taskStatus: ImportTaskStatus;
	fileId: number;
	file: IFileViewModel;
	payload: any;
	createdOn: Date;
	createdById: number;
	taskLogs: IImportTaskLogViewModel[];
}
export interface IImportTaskLogViewModel
{
	id: number;
	importTaskId: number;
	importTask: IImportTaskViewModel;
	message: any;
	createdOn: Date;
	createdById: number;
}
export interface IRejectionLeaseViewModel
{
	leaseId: number;
	projectRenewalRejectionStatus: ProjectRenewalRejectionStatus;
	dateToRevisit: Date;
	comment: string;
}
export interface IMarketRentalRateViewModel
{
	subMarketId: number;
	squareFootageRangeId: number;
	lowRate: number;
	highRate: number;
	avgRate: number;
}
export interface ITenantFileFolderViewModel
{
	id: number;
	name: string;
	isShared: boolean;
	tenantFileFolderType: TenantFileFolderType;
}
export interface IAttachedFolderViewModel
{
	id: number;
	name: string;
	isShared: boolean;
}
export interface IAttachedFileViewModel
{
	attachedToEntityId: number;
	fileId: number;
	file: IFileViewModel;
	folderId: number;
	folder: IAttachedFolderViewModel;
}
export interface ICalendlySettingsViewModel
{
	url: string;
	text: string;
	color: string;
	textColor: string;
	branding: boolean;
}
export interface IConfigurationViewModel
{
	googleAnalyticsTrackingId: string;
	calendlySettingsViewModel: ICalendlySettingsViewModel;
	intercomSettingsViewModel: IIntercomSettingsViewModel;
}
export interface IIntercomSettingsViewModel
{
	apiBase: string;
	appId: string;
}
export interface IConstructionTypeViewModel
{
	id: number;
	name: string;
}
export interface IProjectStatisticViewModel
{
	daysActive: number;
	daysSinceLastActivity: number;
	complete: number;
	daysLeftForRenewal: number;
	daysLeftForLeaseExpiration: number;
	suggestedTime: number;
	currentStatus: number;
	differenceBetweenSuggestedTimeAndCurrentDay: number;
	currentStage: string;
	daysInCurrentStage: number;
	daysSinceNextStage: number;
	hasAbstractLease: boolean;
	sixMonthsPriorToLeaseExpiration: Date | string;
	renewalNoticeDate: Date | string;
	expansionNoticeDate: Date | string;
	terminationNoticeDate: Date | string;
	commencementDate: Date | string;
	expirationDate: Date | string;
}
export interface IProjectStatisticInfoViewModel
{
	projectId: number;
	leaseId: number;
	projectType: IProjectTypeViewModel;
	projectState: IProjectTemplateItemViewModel;
	name: string;
	startDate: Date;
	endDate: Date;
	actualEndDate: Date;
	milestones: IProjectMilestoneViewModel[];
}
export interface ITiNegotiationByTenantOrLandlordViewModel
{
	isAccept: boolean;
	lease: ILeaseViewModel;
}
export interface IApprovingAppointmentViewModel
{
	appointmentViewModel: IAppointmentViewModel;
	leaseViewModel: ILeaseViewModel;
}
export interface IPreviousLeaseTermViewModel
{
	leaseTermConfiguration: ILeaseTermConfiguration;
	leaseId: number;
}
export interface IVersionedFileUnreadCommentsCount
{
	versionedFileId: number;
	unreadCount: number;
}
export interface IVersionedFileCommentViewModel
{
	id: number;
	content: string;
	versionedFileId: number;
	versionedFile: IVersionedFileViewModel;
	authorId: number;
	author: IProfileViewModel;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	fileId: number;
	isRead: boolean;
	versionedFileCommentType: VersionedFileCommentType;
}
export interface ILeaseAmendmentFileViewModel
{
	id: number;
	leaseId: number;
	lease: ILeaseViewModel;
	versionedFileId: number;
	versionedFile: IVersionedFileViewModel;
	tenantSignDate: Date;
	landlordSignDate: Date;
	signUrl: string;
	documentHash: string;
	signedDocumentId: number;
	signedDocument: IFileViewModel;
	isExternalTenantEmail: boolean;
	isExternalLandlordEmail: boolean;
	tenantId: number;
	landlordId: number;
}
export interface IVersionedFileViewModel
{
	id: number;
	name: string;
	versionedFileType: VersionedFileType;
	currentEditorId: number;
	currentEditor: IProfileViewModel;
	fileVersion: IFileViewModel;
	pdfFileVersion: IFileViewModel;
	comments: IVersionedFileCommentViewModel[];
	isSent: boolean;
	version: number;
	leaseTeam: LeaseTeam;
	isDeleted: boolean;
}
export interface IComparisonSummaryReportItemsViewModel
{
	items: ILeaseAnalysisTenantPerspectiveResult[];
	projectTemplateItem: IProjectTemplateItemViewModel;
	proposalDate: Date;
}
export interface IComparisonSummaryReportViewModel
{
	items: IComparisonSummaryReportItemsViewModel[];
	currentLeaseResults: IComparisonSummaryReportAbstractLease;
	thirdPartyLeaseResults: IComparisonSummaryReportAbstractLease;
}
export interface ILeaseAnalysisLandlordPerspectiveResult extends ILeaseAnalysisPerspectiveResult
{
	totalNetOperatingIncome: number;
	totalDealCost: number;
	yieldOnCash: number;
	marketRentGrowth: number;
	sameStoreMarketGrowth: number;
	downtime: number;
	landlordCommission: number;
	landlordCommissionTotal: number;
	landlordCommissionPsf: number;
	tenantCommission: number;
	tenantCommissionTotal: number;
	tenantCommissionPsf: number;
}
export interface ILeaseAnalysisTenantPerspectiveResult extends ILeaseAnalysisPerspectiveResult
{
}
export interface IAppointmentViewModel
{
	id: number;
	appointmentType: AppointmentType;
	text: string;
	startDate: Date;
	endDate: Date;
	allDay: boolean;
	approved: boolean;
	entityId: number;
	updatedOn: Date;
	updatedById: number;
}
export interface IFloorPlanRequestedViewModel
{
	leaseId: number;
	isFloorPlanRequested: boolean;
}
export interface ICountUnreadMessagesViewModel
{
	count: number;
	firstMessageDate: Date;
}
export interface IAlertSettingTemplateViewModel
{
	id: number;
	name: string;
	alertType: AlertType;
	isRequiresAction: boolean;
}
export interface ISetEmailNotificationViewModel
{
	isEmail: boolean;
	alertSettingTemplateId: number;
}
export interface ILeaseAnalysisOptionsViewModel
{
	lease: ILeaseViewModel;
	leaseYearType: LeaseAnalysisYearType;
	optionName: string;
	optionIndex: number;
}
export interface IAlertGroupViewModel
{
	lastAlert: IAlertViewModel;
}
export interface IAlertViewModel
{
	id: number;
	createdOn: Date;
	createdById: number;
	createdBy: IProfileViewModel;
	userId: number;
	user: IProfileViewModel;
	text: string;
	isRead: boolean;
	readOn: Date;
	leaseId: number;
	projectId: number;
	projectType: ProjectTypeEnum;
	buildingId: number;
	leaseTermType: LeaseTermType;
	leaseUserRole: AlertLeaseUserRole;
	alertSettingTemplate: IAlertSettingTemplateViewModel;
	url: string;
	path: string;
	emailText: string;
	appointment: IAppointmentViewModel;
	alertCreatorType: AlertCreatorType;
	baseUrl: string;
	leaseRequestId: number;
	landlordCompanyName: string;
	tenantCompanyName: string;
	leaseTenantCompany: ICompanyViewModel;
	leaseLandlordCompany: ICompanyViewModel;
	leaseBuilding: IBuildingViewModel;
	leaseBuildingUnit: IBuildingUnitViewModel;
	chatChannelType: ChatChannelType;
	termCommentType: TermCommentType;
	versionedFileCommentType: VersionedFileCommentType;
}
export interface IChatMessageViewModel
{
	id: number;
	message: string;
	icon: string;
	authorDisplayName: string;
	authorAvatarUrl: string;
	data: string;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	chatChannelId: number;
	isRead: boolean;
	versionedFile: IVersionedFileViewModel;
	messageKind: ChatMessageKind;
	floorPlanAnchor: IPlanAnchorViewModel;
	messageType: MessageType;
}
export interface ILeaseTermCommentViewModel
{
	id: number;
	profile: IChatProfileViewModel;
	message: string;
	createdOn: Date;
	updatedOn: Date;
	termName: string;
	leaseTermType: LeaseTermType;
	leaseId: number;
	termCommentType: TermCommentType;
	isRead: boolean;
}
export interface ILeaseFloorPlanCommentViewModel
{
	id: number;
	content: string;
	leaseId: number;
	floorPlanId: number;
	leaseFloorPlan: ILeaseFloorPlanViewModel;
	authorId: number;
	author: IProfileViewModel;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	fileId: number;
	isRead: boolean;
	messageKind: ChatMessageKind;
}
export interface ILeaseFloorPlanViewModel extends IFloorPlanViewModel
{
	leaseId: number;
	lease: ILeaseViewModel;
	totalAnchorAmount: number;
	totalAnchorAmountAcceptanceStatus: PlanAnchorAmountAcceptanceStatus;
}
export interface IPlanAnchorImageMarkerViewModel
{
	id: number;
	anchorImageId: number;
	x: number;
	y: number;
	text: string;
	anchorImage: IPlanAnchorImageViewModel;
	createdOn: Date;
}
export interface IPlanAnchorImageViewModel
{
	id: number;
	anchorId: number;
	imageId: number;
	imageKind: PlanAnchorImageKind;
	anchor: IPlanAnchorViewModel;
	image: IFileViewModel;
	markers: IPlanAnchorImageMarkerViewModel[];
}
export interface IPlanAnchorCommentViewModel
{
	id: number;
	content: string;
	anchorId: number;
	anchor: IPlanAnchorViewModel;
	authorId: number;
	author: IProfileViewModel;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
	fileId: number;
	isRead: boolean;
	planAnchorCommentType: PlanAnchorCommentType;
}
export interface IPlanAnchorViewModel
{
	id: number;
	planId: number;
	buildingUnitId: number;
	buildingUnit: IBuildingUnitViewModel;
	floorPlanId: number;
	name: string;
	description: string;
	anchorType: PlanAnchorType;
	x: number;
	y: number;
	width: number;
	height: number;
	points: number[];
	firstImageId: number;
	firstImage: IFileViewModel;
	sortOrder: number;
	buildingArea: BuildingAreaType;
	amount: number;
	status: PlanAnchorStatus;
	comments: IPlanAnchorCommentViewModel[];
	images: IPlanAnchorImageViewModel[];
	statusUpdatedBy: IProfileViewModel;
	statusUpdatedById: number;
	statusUpdatedOn: Date;
	createdOn: Date;
	createdById: number;
	updatedOn: Date;
	updatedById: number;
}
export interface IPlanViewModel
{
	id: number;
	kind: PlanKind;
	name: string;
	buildingId: number;
	building: IBuildingViewModel;
	buildingUnitId: number;
	buildingUnit: IBuildingUnitViewModel;
	pictureId: number;
	picture: IFileViewModel;
	angle: number;
	updatedOn: Date;
	updatedById: number;
	anchors: IPlanAnchorViewModel[];
}
export interface IChatFileViewModel
{
	fileUid: IGuid;
}
export interface IFileTypePrivewAttributesViewModel
{
	id: number;
	name: string;
	icon: string;
	iconColor: string;
}
export interface IFileTypeViewModel
{
	id: number;
	extension: string;
	description: string;
	fileTypePrivewAttributesId: number;
	fileTypePrivewAttributes: IFileTypePrivewAttributesViewModel;
}
export interface IChatTreeItem
{
	itemId: string;
	itemParentId: string;
	name: string;
	pictureUrl: string;
	chatChannelId: number;
	iconClass: string;
	leaseId: number;
	lease: ILeaseViewModel;
	leaseProject: IProjectViewModel;
}
export interface IChatProfileViewModel
{
	id: number;
	displayName: string;
	firstName: string;
	lastName: string;
	avatarUrl: string;
	companyName: string;
}
export interface IIndustryViewModel
{
	id: number;
	name: string;
}
export interface ISpaceUseViewModel
{
	id: number;
	name: string;
}
export interface IBuildingFileViewModel
{
	buildingId: number;
	building: IBuildingViewModel;
	fileId: number;
	file: IFileViewModel;
	folderId: number;
	folder: IBuildingFileFolderViewModel;
	attachedToEntityId: number;
}
export interface IProjectTemplateItemViewModel
{
	id: number;
	projectTypeId: number;
	projectType: IProjectTypeViewModel;
	name: string;
	sortOrder: number;
	durationDays: number;
	description: string;
	renewalProjectTemplateItemType: RenewalProjectTemplateItemType;
	parentProjectTemplateItem: IProjectTemplateItemViewModel;
	writeAccessToTermsByRole: string;
	writeAccessToTermsByRoleId: number;
	parentProjectTemplateName: string;
	parentProjectTemplateStageNumber: number;
	stageNumber: number;
	insuranceCertificateProjectTemplateItemType: InsuranceCertificateProjectTemplateItemType;
}
export interface IProjectMilestoneViewModel
{
	id: number;
	templateItemId: number;
	templateItem: IProjectTemplateItemViewModel;
	projectId: number;
	project: IProjectViewModel;
	name: string;
	startDate: Date;
	endDate: Date;
	actualStartDate: Date;
	actualEndDate: Date;
}
export interface IProjectTypeViewModel
{
	id: number;
	name: string;
	projectTypeEnum: ProjectTypeEnum;
	shouldCreateInsuranceOfCertificateProjectAfterFinish: boolean;
	leaseTermTypes: LeaseTermType[];
}
export interface IProjectViewModel
{
	id: number;
	name: string;
	projectTypeId: number;
	projectType: IProjectTypeViewModel;
	startDate: Date;
	endDate: Date;
	milestones: IProjectMilestoneViewModel[];
	projectStateId: number;
	projectState: IProjectTemplateItemViewModel;
	leaseId: number;
	lease: ILeaseViewModel;
	renewalOptionNotice: number;
	actualEndDate: Date;
	projectStatus: ProjectStatus;
	projectRenewalRejectionStatus: ProjectRenewalRejectionStatus;
	rejectedDate: Date;
	pendingForInternalApproval: boolean;
}
export interface ITenantDetailViewModel
{
	id: number;
	buildingId: number;
	leaseId: number;
	buildingName: string;
	displayName: string;
	avatarUrl: string;
	industryName: string;
	spaceUse: string;
	companyName: string;
	companyAddress: string;
	companyWebsite: IHyperlinkViewModel;
	rentalRate: number;
	squareFootage: number;
	buildingPercentage: string;
	sequrityDeposit: string;
	tenantStatus: TenantStatus;
	documents: IAttachedFileViewModel[];
	project: IProjectViewModel;
	freeRent: number;
	rentalRateAnnualEscalation: number;
	tenantImprovements: number;
	landlordMaintenance: string;
	tenantMaintenance: string;
	selfHelp: string;
	assignment: string;
	renewalOption: string;
	expansionOption: string;
	estoppelCertificate: string;
	commencement: Date | string;
	expiration: Date | string;
	term: number;
	realEstateTaxes: number;
	lease: ILeaseViewModel;
	otherMarkets: string;
	tenantTeamMembers: ITeamMemberViewModel[];
	landlordCompany: ICompanyViewModel;
	tenantCompany: ICompanyViewModel;
}
export interface IHyperlinkViewModel
{
	url: string;
	text: string;
}
export interface IMilestoneViewModel
{
	id: number;
	name: string;
	date: Date;
}
export interface ICompactTenantViewModel
{
	id: number;
	leaseId: number;
	avatarUrl: string;
	expiration: Date;
	tenantCompanyName: string;
	unitName: string;
	projectState: IProjectTemplateItemViewModel;
	parentProjectState: IProjectTemplateItemViewModel;
	baseRentalRateTerm: IBaseRentalRateViewModel;
	tenantSquareFootageTerm: ITenantSquareFootageTermViewModel;
	projects: IProjectViewModel[];
	fullTenantName: string;
}
export interface IStartupInfoViewModel
{
	id: number;
	displayName: string;
	firstName: string;
	lastName: string;
	primaryEmail: string;
	avatarUrl: string;
	avatarSourceUrl: string;
	role: string;
	avatarOptions: IAvatarOptions;
	lists: ILists;
	documentsMode: DocumentsMode;
	logotypeSettings: ILogotypeSettings;
	introsShown: IUserIntroViewModel[];
	lastLeaseId: number;
	twoFactorEnabled: boolean;
	phoneNumber: string;
	companyName: string;
	companyId: number;
	monthsCountToDetermineRestructureOrRenewalProject: number;
	teamAlignmentRoles: ITeamAlignmentRoleViewModel[];
	userHmacHash: string;
	userStatus: UserStatus;
	infoFlags: any /* Statera.Models.ViewModels.InfoFlag */;
}
export interface ITeamAlignmentRoleViewModel
{
	leaseId: number;
	role: string;
}
export interface IUserIntroViewModel
{
	userId: number;
	introType: IntroType;
	profile: IProfileViewModel;
}
export interface ILogotypeSettings
{
	width: number;
	height: number;
}
export interface IRenameViewModel
{
	key: string;
	newName: string;
}
export interface IRegisterViewModel
{
	emailAddress: string;
	password: string;
	confirmPassword: string;
	role: string;
	firstName: string;
	lastName: string;
	companyName: string;
	companyId: number;
	mobilePhone: string;
	skipConfirmationStatusCheck: boolean;
}
export interface IQuizUserViewModel
{
	answers: IQuizAnswerViewModel[];
	leaseId: number;
	leaseViewModel: ILeaseViewModel;
	company: ICompanyViewModel;
}
export interface IQuizQuestionViewModel
{
	id: number;
	quizId: number;
	text: string;
	questionType: QuestionType;
	options: IQuizQuestionOptionViewModel[];
	nextQuestionId: number;
	previousQuestionId: number;
	answers: IQuizAnswerViewModel[];
	placeholder: string;
	tooltip: string;
	parentQuestionId: number;
	quizQuestionBadgeType: QuizQuestionBadgeType;
	groupId: number;
	answer: IQuizAnswerViewModel;
	displayValue: string;
}
export interface IQuizQuestionOptionViewModel
{
	id: number;
	text: string;
	quizQuestionId: number;
	nextQuestionId: number;
	answerType: AnswerType;
}
export interface IQuizAnswerViewModel
{
	id: number;
	quizId: number;
	quizQuestionId: number;
	quizQuestion: IQuizQuestionViewModel;
	customAnswerText: string;
	uploadedFileId: number;
	uploadedFileUrl: string;
	selectedOptionId: number;
	questionOptionViewModel: IQuizQuestionOptionViewModel;
	selectedOptionText: string;
	answerDate: Date;
	answerNumber: number;
	selectedAddressId: number;
	selectedAddress: IAddressViewModel;
	quizAnswerFiles: IFileViewModel[];
	leaseId: number;
	company: ICompanyViewModel;
	createdById: number;
	multipleChoices: IQuizQuestionOptionViewModel[];
	multipleNumberAnswers: { [index: string]: number };
	rangeNumber: IQuizRangeAnswerViewModel;
	userInvitationRequests: IUserInvitationRequestViewModel[];
	selectCitiesAnswer: ISelectCitiesAnswerViewModel;
	idealUnitSizeAnswer: IIdealUnitSizeAnswerViewModel;
	leaseAmendmentsAnswer: ILeaseAmendmentsAnswerViewModel;
	buildingSpecsAnswer: IBuildingSpecsAnswerViewModel;
	leaseTermAnswer: ILeaseTermAnswerViewModel;
	specificUseForBuildingAnswer: ISpecificUseForBuildingAnswer;
	dateOrDateRangeAnswer: IDateOrDateRangeAnswerViewModel;
	tourAnswer: ITourAnswerViewModel;
}
export interface ITourAnswerViewModel
{
	tourId: number;
}
export interface IDateOrDateRangeAnswerViewModel
{
	specificDate: Date;
	from: Date;
	to: Date;
}
export interface ISpecificUseForBuildingAnswer
{
	result: string[];
}
export interface ILeaseTermAnswerViewModel
{
	from: number;
	to: number;
}
export interface IBuildingSpecsAnswerViewModel
{
	clearHeight: number;
	minimumDocks: number;
	minDriveDoorsRamps: number;
	minimumPower: number;
	minimumOfficeArea: number;
}
export interface ILeaseAmendmentsAnswerViewModel
{
	leaseAmendmentsAnswerType: LeaseAmendmentsAnswerType;
	leaseId: number;
	displayValue: string;
}
export enum LeaseAmendmentsAnswerType
{
	Skip = 1,
	Upload = 2,
	ChooseLease = 3,
}
export interface IIdealUnitSizeAnswerViewModel
{
	idealUnitSizeAnswerType: IdealUnitSizeAnswerType;
	from: number;
	to: number;
}
export enum IdealUnitSizeAnswerType
{
	SameSize = 1,
	SizeRange = 2,
}
export interface ISelectCitiesAnswerViewModel
{
	cities: string[];
	selectCitiesAnswerType: SelectCitiesAnswerType;
}
export enum SelectCitiesAnswerType
{
	CurrentCity = 1,
	OtherCities = 2,
}
export interface IQuizViewModel
{
	id: number;
	name: string;
	quizType: QuizType;
	steps: IQuizStepViewModel[];
	note: string;
	isDone: boolean;
}
export interface IQuizStepViewModel
{
	name: string;
	nameIconId: string;
	title: string;
	titleIconId: string;
	type: QuizStepType;
	questions: IQuizQuestionViewModel[];
	isFinished: boolean;
}
export interface IProfileViewModel
{
	id: number;
	primaryEmail: string;
	firstName: string;
	lastName: string;
	title: string;
	displayName: string;
	mobilePhone: string;
	additionalEmailAddress: string;
	role: string;
	avatar: IFileViewModel;
	documentsMode: DocumentsMode;
	company: ICompanyViewModel;
	userStatus: UserStatus;
	notificationsDisplay: NotificationsDisplay;
	emailNotifications: EmailNotifications;
	messengerSmsNotifications: MessengerSmsNotifications;
	messengerEmailNotifications: MessengerEmailNotifications;
	alertsPreferenceViewModel: IAlertsPreferenceViewModel;
	emailConfirmed: boolean;
	mobilePhoneConfirmed: boolean;
	investorType: InvestorType;
	markets: string;
	portfolioSizeOffice: number;
	portfolioSizeIndustrial: number;
	portfolioSizeLand: number;
	portfolioSizeRetail: number;
	introsShown: IUserIntroViewModel[];
}
export interface IAlertsPreferenceViewModel
{
	id: number;
	userId: number;
	leaseCommencementIntervalType: AlertIntervalType;
	leaseCommencementIntervalValue: number;
	leaseExpirationIntervalType: AlertIntervalType;
	leaseExpirationIntervalValue: number;
	leaseRentEscalationIntervalType: AlertIntervalType;
	leaseRentEscalationIntervalValue: number;
	leaseRenewalOptionIntervalType: AlertIntervalType;
	leaseRenewalOptionIntervalValue: number;
	leaseTerminationOptionIntervalType: AlertIntervalType;
	leaseTerminationOptionIntervalValue: number;
	inquiriesAndRequestsIntervalType: AlertIntervalType;
	inquiriesAndRequestsIntervalValue: number;
}
export interface IPortfolioViewModel
{
	id: number;
	name: string;
	buildings: IBuildingViewModel[];
	company: ICompanyViewModel;
	teamMembers: ITeamMemberViewModel[];
}
export interface IPasswordResetViewModel
{
	id: string;
	token: string;
	password: string;
	confirmPassword: string;
}
export interface IPasswordResetRequestViewModel
{
	emailAddress: string;
}
export interface ILoginViewModel
{
	emailAddress: string;
	password: string;
	isPersistent: boolean;
}
export interface ILeaseAbstractImportDraftViewModel
{
	documentId: number;
	leaseId: number;
	importTaskId: number;
	document: IFileViewModel;
	lease: ILeaseViewModel;
	createdOn: Date;
	createdById: number;
}
export interface ILeaseViewModel
{
	id: number;
	amendmentNumber: number;
	expiration: Date | string;
	expirationDocumentSection: string;
	calculatedExpirationDate: Date | string;
	leaseStatus: LeaseStatus;
	leaseType: LeaseType;
	leaseActionType: LeaseActionType;
	buildingPercentage: string;
	landlordCompanyId: number;
	landlordCompany: ICompanyViewModel;
	tenantCompanyId: number;
	tenantCompany: ICompanyViewModel;
	buildingId: number;
	building: IBuildingViewModel;
	leaseFloorPlanRequestStatus: LeaseFloorPlanRequestStatus;
	buildingUnitId: number;
	buildingUnit: IBuildingUnitViewModel;
	expirationAbstractValue: Date | string;
	expirationOriginalAbstractValue: Date | string;
	buildingPercentageAbstractValue: string;
	buildingPercentageOriginalAbstractValue: string;
	buildingPercentageDocumentSection: string;
	renewalNoticeForProjectSchedule: number;
	commencementTerm: ICommencementTermViewModel;
	landlordMaintenanceTerm: ILandlordMaintenanceTermViewModel;
	tenantMaintenanceTerm: ITenantMaintenanceTermViewModel;
	selfHelpTerm: ISelfHelpTermViewModel;
	assignmentTerm: IAssignmentTermViewModel;
	estoppelCertificateTerm: IEstoppelCertificateTermViewModel;
	expansionOptionTerm: IExpansionOptionTermViewModel;
	term: ITermViewModel;
	baseRentalRateTerm: IBaseRentalRateViewModel;
	tenantSquareFootageTerm: ITenantSquareFootageTermViewModel;
	freeRentTerm: IFreeRentTermViewModel;
	securityDepositTerm: ISecurityDepositTermViewModel;
	realEstateTaxesCamExpensesTerm: IRealEstateTaxesCamExpensesViewModel;
	rentalRateAnnualEscalationTerm: IRentalRateAnnualEscalationTermViewModel;
	tenantImprovementsTerm: ITenantImprovementsViewModel;
	hvacTerm: IHvacTermViewModel;
	renewalOptionTerm: IRenewalOptionTermViewModel;
	terminationOptionTerm: ITerminationOptionTermViewModel;
	insuranceTerm: IInsuranceTermViewModel;
	holdoverProvisionTerm: IHoldoverProvisionTermViewModel;
	forceMajeureTerm: IForceMajeureTermViewModel;
	spaceUseTerm: ISpaceUseTermViewModel;
	parkingTerm: IParkingTermViewModel;
	conditionOfPremisesTerm: IConditionOfPremisesTermViewModel;
	codeComplianceTerm: ICodeComplianceTermViewModel;
	utilitiesTerm: IUtilitiesTermViewModel;
	signageTerm: ISignageTermViewModel;
	nonDisturbanceTerm: INonDisturbanceTermViewModel;
	hazardousMaterialsTerm: IHazardousMaterialsTermViewModel;
	railTerm: IRailTermViewModel;
	isAbstractLease: boolean;
	abstractLease: ILeaseViewModel;
	abstractLeaseId: number;
	leaseUsers: ILeaseUserViewModel[];
	thirdPartyLease: ILeaseViewModel;
	thirdPartyLeaseId: number;
	hasThirdPartyLease: boolean;
	currentLeaseProvided: boolean;
	leaseMetrics: BaseRentalRateUnitMetrics;
}
export interface IFileViewModel
{
	id: number;
	uid: IGuid;
	url: string;
	thumbnailUrl: string;
	name: string;
	fileTypeId: number;
	fileType: IFileTypeViewModel;
	width: number;
	height: number;
	versionedFileId: number;
	versionedFile: IVersionedFileViewModel;
	createdById: number;
	createdBy: IProfileViewModel;
	createdOn: Date;
}
export interface ICountyViewModel
{
	countryCode: string;
	stateCode: string;
	name: string;
}
export interface ICopyViewModel
{
	sourceKey: string;
	destinationKey: string;
	operationType: TreeOperationType;
}
export interface ICompanyViewModel
{
	id: number;
	name: string;
	phone: string;
	companyType: CompanyType;
	email: string;
	webSite: string;
	homeMarket: string;
	otherMarkets: string;
	addresses: IAddressViewModel[];
	logoId: number;
	logo: IFileViewModel;
	spaceUseId: number;
	spaceUse: ISpaceUseViewModel;
	industryId: number;
	industry: IIndustryViewModel;
	address: string;
}
export interface IBuildingUnitListingViewModel
{
	id: number;
	buildingUnitId: number;
	buildingUnit: IBuildingUnitViewModel;
	availableOn: Date;
	sizeMin: number;
	sizeMax: number;
	priceStructure: BuildingUnitListingPriceStructure;
	priceUnitMetrics: BuildingUnitListingPriceUnitMetrics;
	priceType: BuildingUnitListingPriceType;
	shouldIncludeRegionalMapInMarketingBrochure: boolean;
	priceMin: number;
	priceMax: number;
	estimatedReTaxes: number;
	estimatedOpEx: number;
	insurance: number;
	notes: string;
	availableSpaceExpression: string;
	askingPriceExpression: string;
}
export interface IBuildingUnitViewModel
{
	id: number;
	buildingId: number;
	building: IBuildingViewModel;
	name: string;
	description: string;
	size: number;
	totalOfficeArea: number;
	totalDocksInterior: number;
	totalDocksExterior: number;
	amps: number;
	volts: string;
	phase: number;
	columnSpacing: string;
	clearHeight: number;
	driveInDoors: number;
	totalDriveInDoorsSize: string;
	sprinklerSystem: SprinklerSystem;
	listing: IBuildingUnitListingViewModel;
	floorPlans: IPlanViewModel[];
	attachments: IBuildingAttachmentViewModel[];
	lease: ILeaseViewModel;
	company: ICompanyViewModel;
	companyEmployees: IProfileViewModel[];
}
export interface IBuildingViewModel
{
	id: number;
	name: string;
	marketId: number;
	market: IMarketViewModel;
	subMarketId: number;
	subMarket: IMarketViewModel;
	buildingType: BuildingType;
	totalBuildingSize: number;
	builtYear: number;
	clearHeight: number;
	totalDocksInterior: number;
	totalDocksExterior: number;
	buildingClass: BuildingClass;
	mostCurrentRealEstateTaxesPSF: number;
	mostCurrentRealEstateTaxesTotal: number;
	operatingExpenses: number;
	totalLandArea: number;
	columnSpacing: string;
	totalParkingSpaces: number;
	mostRecentInteriorConstructionOrUpdateYear: number;
	mostRecentExteriorConstructionOrUpdateYear: number;
	mostRecentInteriorConstructionOrUpdateText: string;
	mostRecentExteriorConstructionOrUpdateText: string;
	railLine: string;
	pinNumber: string;
	attachments: IBuildingAttachmentViewModel[];
	portfolioId: number;
	leases: ILeaseViewModel[];
	buildingFiles: IBuildingFileViewModel[];
	floorPlans: IPlanViewModel[];
	sitePlans: IPlanViewModel[];
	teamMembers: ITeamMemberViewModel[];
	address: IAddressViewModel;
	totalOfficeArea: number;
	renovatedDate: Date;
	greenCertificates: IBuildingCertificateViewModel[];
	totalDriveInDoorsSize: string;
	amps: number;
	volts: string;
	phase: number;
	sprinklerSystem: SprinklerSystem;
	utilityCosts: number;
	utilityCostsText: string;
	roofTypeCondition: string;
	mostRecentSalePrice: number;
	saleYear: number;
	buildingUnits: IBuildingUnitViewModel[];
	constructionType: IConstructionTypeViewModel;
	fund: string;
	stories: number;
	driveInDoors: number;
	trailerParking: number;
	geopointLatitude: number;
	geopointLongitude: number;
}
export interface IBuildingCertificateViewModel
{
	certificationType: GreenCertification;
	customValue: string;
}
export interface IAvatarViewModel
{
	sourceImageUrl: string;
	avatarOptions: IAvatarOptions;
}
export interface IMarketViewModel
{
	id: number;
	parentMarketId: number;
	parentMarket: IMarketViewModel;
	name: string;
}
export interface IAddressViewModel
{
	id: number;
	name: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	stateCode: string;
	countryCode: string;
	countyName: string;
	zipCode: string;
	isPrimary: boolean;
	displayString: string;
}
export interface IFinancialRequestViewModel
{
	message: string;
}
export interface ILeaseTermCustomTableViewModel
{
	headerRow: ILeaseTermCustomRowViewModel;
	rows: ILeaseTermCustomRowViewModel[];
	tableName: string;
}
export interface ILeaseTermCustomRowViewModel
{
	current: boolean;
	total: boolean;
	cells: ILeaseTermCustomCellViewModel[];
}
export interface ILeaseTermCustomCellViewModel
{
	displayValue: string;
}
export interface ITermViewModel extends ILeaseTermViewModel
{
	termValue: number;
	abstract: ITermViewModel;
	termType: TermTypeEnum;
	leaseTermOptions: ILeaseTermOptionsViewModel<ITermViewModel>;
	hasMultiplyOptions: boolean;
	isModifiedValue: boolean;
	termDifferenceValue: IPeriod;
	termDifferenceValueInMonths: number;
	documentSection: string;
	displayValue: string;
}
export interface ILeaseTermOptionsViewModel<T>
{
	options: T[];
	selectedLeaseTerm: T;
	selectedLeaseTermIndex: number;
}
export interface IBaseRentalScheduleViewModel
{
	baseRentalRateUnitMetrics: BaseRentalRateUnitMetrics;
	rows: IBaseRentalScheduleRowViewModel[];
}
export interface IBaseRentalScheduleRowViewModel
{
	start: Date | string;
	end: Date | string;
	baseRent: number;
	monthlyRent: number;
	annualRent: number;
	isFreeRentMonth: boolean;
}
export interface IRentalRateAnnualEscalationTermCustomResult
{
	leaseMonth: number;
	date: Date | string;
	rsfValue: number;
	percentValue: number;
	stepIncreasingPercent: number;
	stepIncreasingRsf: number;
	monthlyAmount: number;
	unitMetrics: BaseRentalRateUnitMetrics;
	rentalRateAnnualEscalationTermCustomValueType: RentalRateAnnualEscalationTermCustomValueType;
}
export interface IInsuranceTermViewModel extends ILeaseTermViewModel
{
	insuranceTermType: InsuranceTermType;
	text: string;
	abstract: IInsuranceTermViewModel;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IRentalRateAnnualEscalationTermCustomValueViewModel
{
	id: number;
	stepIncreasePercentage: number;
	stepIncreasePsfValue: number;
	increaseDate: Date | string;
	leaseMonth: number;
	repeatLeaseMonth: number;
	rentalRateAnnualEscalationTermCustomValueType: RentalRateAnnualEscalationTermCustomValueType;
	rentalRateAnnualEscalationTermCustomRepeatType: RentalRateAnnualEscalationTermCustomRepeatType;
}
export interface IRentalRateAnnualEscalationTermViewModel extends ILeaseTermViewModel
{
	escalationTermType: EscalationTermType;
	escalationPercentagePerYear: number;
	escalationPsfValuePerYear: number;
	rentalRateEscalationTaxType: RentalRateEscalationTaxType;
	rentalRateAnnualEscalationTermCustomValues: IRentalRateAnnualEscalationTermCustomValueViewModel[];
	rentalRateAnnualEscalationTermCustomResults: IRentalRateAnnualEscalationTermCustomResult[];
	abstract: IRentalRateAnnualEscalationTermViewModel;
	leaseTermCustomTable: ILeaseTermCustomTableViewModel;
	customDisplayValue: string;
	documentSection: string;
	leaseTermOptions: ILeaseTermOptionsViewModel<IRentalRateAnnualEscalationTermViewModel>;
	hasPreviewTables: boolean;
	hasCustomTables: boolean;
	hasMultiplyOptions: boolean;
	displayValue: string;
	detailedDisplayValue: string;
	rentIncreasesDisplayValue: string;
}
export interface IRenewalOptionTermViewModel extends ILeaseTermViewModel
{
	renewalDate: Date | string;
	renewalOptionTermType: RenewalOptionTermType;
	renewalOptionsCount: number;
	renewalOptionYearsCount: number;
	renewalOptionHowManyMonthsNotice: number;
	renewalOptionAvailableMonths: number;
	renewalOptionMaxRentPercentage: number;
	renewalOptionMinRentPercentage: number;
	renewalOptionCustomText: string;
	abstract: IRenewalOptionTermViewModel;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface ITerminationOptionTermViewModel extends ILeaseTermViewModel
{
	terminationDate: Date | string;
	terminationOptionType: TerminationOptionType;
	terminationMonth: number;
	amortizationRate: number;
	howManyMonthsToNotice: number;
	terminationOptionCustomText: string;
	terminationOptionFeeType: TerminationOptionFeeType;
	terminationOptionDollarValue: number;
	abstract: ITerminationOptionTermViewModel;
	customDisplayValue: string;
	leaseTermOptions: ILeaseTermOptionsViewModel<ITerminationOptionTermViewModel>;
	documentSection: string;
	hasMultiplyOptions: boolean;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IHvacTermViewModel extends ILeaseTermViewModel
{
	hvacTermType: HvacTermType;
	hvacMoneyValue: number;
	hvacPercentValue: number;
	hvacCustomValue: string;
	abstract: IHvacTermViewModel;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IRealEstateTaxesCamExpensesViewModel extends ILeaseTermViewModel
{
	baseYearForExpenses: Date | string;
	estimatedReTaxes: number;
	estimatedReTaxesType: RealEstateTaxesType;
	estimatedOpEx: number;
	estimatedOpExType: OperatingExpensesType;
	percentagePerYear: number;
	realEstateTaxesCamExpensesTermType: RealEstateTaxesCamExpensesTermType;
	realEstateTaxesCamExpensesCumulativeType: RealEstateTaxesCamExpensesCumulativeType;
	realEstateTaxesCamExpensesText: string;
	realEstateTaxesAbstractValue: string;
	camExpensesAbstractValue: string;
	abstract: IRealEstateTaxesCamExpensesViewModel;
	baseRentalRateTermType: BaseRentalRateType;
	customDisplayValue: string;
	realEstateTaxesCustomDisplayValue: string;
	operatingExpensesCustomDisplayValue: string;
	realEstateTaxesCamExpensesBreakdown: IRealEstateTaxesCamExpensesBreakdownViewModel;
	leaseTermCustomTable: ILeaseTermCustomTableViewModel;
	isTermAcceptance: boolean;
	hasPreviewTables: boolean;
	insurance: number;
	propertyManagementFee: number;
	propertyManagementFeeType: PropertyManagementFeeType;
	propertyManagementFeePsf: number;
	propertyManagementFeePercentage: number;
	propertyManagementFeePercentageType: PropertyManagementFeePercentageType;
	realEstateTaxesDocumentSection: string;
	operatingExpensesDocumentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IRealEstateTaxesCamExpensesBreakdownViewModel
{
	insurance: number;
	repairsAndMaintenance: number;
	fireAndLifeSafety: number;
	parkingLotMaintenance: number;
	roofRepairs: number;
	landscaping: number;
	snowRemoval: number;
	managementFee: number;
	total: number;
}
export interface ISecurityDepositTermViewModel extends ILeaseTermViewModel
{
	securityDepositTermType: SecurityDepositTermType;
	depositMonths: SecurityDepositMonths;
	monthsCount: number;
	securityDepositMoneyValue: number;
	securityDepositReducingValue: number;
	abstract: ISecurityDepositTermViewModel;
	customDisplayValue: string;
	securityGuarantyOptionType: SecurityGuarantyOptionType;
	amountOfLetterCredit: number;
	isBurnDownScheduleChecked: boolean;
	burnDownScheduleValues: IBurnDownScheduleViewModel[];
	leaseTermCustomTable: ILeaseTermCustomTableViewModel;
	documentSection: string;
	hasCustomTables: boolean;
	hasBurnDownScheduleValues: boolean;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IBurnDownScheduleViewModel
{
	dateOfChange: Date | string;
	dollarAmount: number;
	leaseMonth: number;
}
export interface IFreeRentTermViewModel extends ILeaseTermViewModel
{
	freeRentTermType: FreeRentTermType;
	freeRentTaxesType: FreeRentTaxesType;
	freeRentMonthsCount: number;
	specificMonths: number[];
	abstract: IFreeRentTermViewModel;
	customDisplayValue: string;
	documentSection: string;
	leaseTermOptions: ILeaseTermOptionsViewModel<IFreeRentTermViewModel>;
	hasMultiplyOptions: boolean;
	isModifiedValue: boolean;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IBaseRentalRateViewModel extends ILeaseTermViewModel
{
	baseRentalRateType: BaseRentalRateType;
	baseRentalRateNetValue: number;
	baseRentalRateGrossValue: number;
	baseRentalRateUnitMetrics: BaseRentalRateUnitMetrics;
	baseYear: Date;
	actualYearRate: number;
	baseRentalRateTypeOriginal: BaseRentalRateType;
	abstract: IBaseRentalRateViewModel;
	hasPreviewTables: boolean;
	hasMultiplyOptions: boolean;
	isModifiedValue: boolean;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
	schedule: IBaseRentalScheduleViewModel;
	multipleOptionsSchedules: IBaseRentalScheduleViewModel[];
	multipleOptionsScheduleTables: ILeaseTermCustomTableViewModel[];
	leaseTermCustomTable: ILeaseTermCustomTableViewModel;
	leaseTermOptions: ILeaseTermOptionsViewModel<IBaseRentalRateViewModel>;
	isCustomScheduleTableEnabled: boolean;
	customScheduleTable: IBaseRentalRateCustomScheduleTableRow[];
	serviceTypeDisplayValue: string;
}
export interface ITenantSquareFootagePhaseInResultViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	totalSf: number;
}
export interface ITenantSquareFootagePhaseInValueViewModel
{
	leaseMonth: number;
	totalSfOccupied: number;
	repeatType: TenantSquareFootagePhaseInRepeatType;
	clientUtcOffset: number;
}
export interface ITenantSquareFootageTermViewModel extends ILeaseTermViewModel
{
	tenantSquareFootageTermType: TenantSquareFootageTermType;
	tenantSquareFootageAbstractValue: number;
	tenantSquareFootageCustomValue: number;
	tenantSquareFootageRestructuredValue: number;
	tenantSquareFootagePhaseInValues: ITenantSquareFootagePhaseInValueViewModel[];
	tenantSquareFootagePhaseInResults: ITenantSquareFootagePhaseInResultViewModel[];
	tenantSquareFootageExpandByUnits: ITenantSquareFootageVacantUnitViewModel[];
	tenantSquareFootageRelocateByUnits: ITenantSquareFootageVacantUnitViewModel[];
	abstract: ITenantSquareFootageTermViewModel;
	leaseTermCustomTable: ILeaseTermCustomTableViewModel;
	hasCustomTables: boolean;
	hasMultiplyOptions: boolean;
	isModifiedValue: boolean;
	leaseTermOptions: ILeaseTermOptionsViewModel<ITenantSquareFootageTermViewModel>;
	buildingUnitSize: number;
	documentSection: string;
	tenantSquareFootageValue: number;
	displayValue: string;
	detailedDisplayValue: string;
	isExpansion: boolean;
}
export interface ITenantSquareFootageVacantUnitViewModel
{
	id: number;
	buildingId: number;
	name: string;
	size: number;
}
export interface ILeaseTermViewModel
{
	leaseTermType: LeaseTermType;
	value: string;
	displayValue: string;
	detailedDisplayValue: string;
	abstractValue: string;
	amendmentNumber: number;
	termStatus: TermStatus;
	updatedOn: Date;
	updatedById: number;
	isCurrentTermValue: boolean;
	isElectedForNegotiation: boolean;
	hasCustomTables: boolean;
	hasPreviewTables: boolean;
	hasMultiplyOptions: boolean;
}
export interface ITenantImprovementsViewModel extends ILeaseTermViewModel
{
	tenantImprovementsType: TenantImprovementsType;
	tenantImprovementsValue: number;
	tenantImprovementsAmortized: number;
	tenantImprovementsAmortizedMonthsCount: number;
	tenantImprovementsPercentageRate: number;
	tenantImprovementsCustom: string;
	tenantImprovementsOptionDescription: string;
	tenantImprovementsAmortizationOption: TenantImprovementsAmortizationOption;
	tenantImprovementsAmortizationAmount: number;
	tenantImprovementsAmortizationTerm: number;
	tenantImprovementsAnnualInterestRate: number;
	abstract: ITenantImprovementsViewModel;
	customDisplayValue: string;
	contractorTypeForTenantImprovementsWork: ContractorTypeForTenantImprovementsWork;
	warrantyDurationInMonthsForLandlordContractors: number;
	warrantyDurationTypeForLandlordsContractors: WarrantyDurationTypeForLandlordsContractors;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IRailTermViewModel extends ILeaseTermViewModel
{
	abstract: IRailTermViewModel;
	railType: RailTermType;
	railProvider: string;
	serviceProvider: string;
	soleUse: boolean;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IHazardousMaterialsTermViewModel extends ILeaseTermViewModel
{
	abstract: IHazardousMaterialsTermViewModel;
	hazardousMaterialsType: HazardousMaterialsTermType;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface INonDisturbanceTermViewModel extends ILeaseTermViewModel
{
	abstract: INonDisturbanceTermViewModel;
	customDisplayValue: string;
	nonDisturbanceTermType: NonDisturbanceTermType;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface ISignageTermViewModel extends ILeaseTermViewModel
{
	abstract: ISignageTermViewModel;
	signageType: SignageTermType;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IUtilitiesTermViewModel extends ILeaseTermViewModel
{
	abstract: IUtilitiesTermViewModel;
	utilitiesType: UtilitiesTermType;
	customDisplayValue: string;
	tenantMeteredUtilities: string;
	hasGreenLeaseOption: boolean;
	greenLeaseOption: UtilitiesTermGreenLeaseOption;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface ICodeComplianceTermViewModel extends ILeaseTermViewModel
{
	abstract: ICodeComplianceTermViewModel;
	codeComplianceType: CodeComplianceTermType;
	customText: string;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IConditionOfPremisesTermViewModel extends ILeaseTermViewModel
{
	abstract: IConditionOfPremisesTermViewModel;
	conditionOfPremisesType: ConditionOfPremisesTermType;
	customText: string;
	warrantyMonthsCount: number;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
}
export interface IParkingTermViewModel extends ILeaseTermViewModel
{
	abstract: IParkingTermViewModel;
	parkingType: ParkingTermType;
	reservedParkingStallsCount: number;
	unreservedParkingStallsCount: number;
	reservedTrailerDropsCount: number;
	unreservedTrailerDropsCount: number;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface ISpaceUseTermViewModel extends ILeaseTermViewModel
{
	abstract: ISpaceUseTermViewModel;
	spaceUseType: SpaceUseTermType;
	product: string;
	customUseValue: string;
	customDisplayValue: string;
	hasAdditionalInfo: boolean;
	additionalInfo: string;
	customClauseText: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IForceMajeureTermViewModel extends ILeaseTermViewModel
{
	text: string;
	abstract: IForceMajeureTermViewModel;
	documentSection: string;
	displayValue: string;
}
export interface IHoldoverProvisionTermViewModel extends ILeaseTermViewModel
{
	text: string;
	abstract: IHoldoverProvisionTermViewModel;
	holdoverProvisionType: HoldoverProvisionType;
	landlordHoldoverRentForFirstDaysInPercent: number;
	landlordHoldoverRentForRemainingDaysInPercent: number;
	landlordHoldoverRentInPercent: number;
	countOfFirstLandlordHoldoverRentDays: number;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IExpansionOptionTermViewModel extends ILeaseTermViewModel
{
	expansionDate: Date | string;
	expansionOption: string;
	expansionOptionTermType: ExpansionOptionTermType;
	abstract: IExpansionOptionTermViewModel;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IEstoppelCertificateTermViewModel extends ILeaseTermViewModel
{
	estoppelCertificate: string;
	estoppelCertificateTermType: EstoppelCertificateTermType;
	tenantDeadlineAfterLandlordRequestInDays: number;
	abstract: IEstoppelCertificateTermViewModel;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface IAssignmentTermViewModel extends ILeaseTermViewModel
{
	assignment: string;
	assignmentTermType: AssignmentTermType;
	abstract: IAssignmentTermViewModel;
	customDisplayValue: string;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface ISelfHelpTermViewModel extends ILeaseTermViewModel
{
	selfHelp: string;
	selfHelpTermType: SelfHelpTermType;
	abstract: ISelfHelpTermViewModel;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface ITenantMaintenanceTermViewModel extends ILeaseTermViewModel
{
	tenantMaintenance: string;
	tenantMaintenanceTermType: TenantMaintenanceTermType;
	abstract: ITenantMaintenanceTermViewModel;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface ILandlordMaintenanceTermViewModel extends ILeaseTermViewModel
{
	landlordMaintenance: string;
	landlordMaintenanceTermType: LandlordMaintenanceTermType;
	hasOperationCondition: boolean;
	warrantDaysCount: number;
	abstract: ILandlordMaintenanceTermViewModel;
	documentSection: string;
	displayValue: string;
	detailedDisplayValue: string;
}
export interface ICommencementTermViewModel extends ILeaseTermViewModel
{
	commencement: Date | string;
	abstract: ICommencementTermViewModel;
	termRemainderValuePeriod: IPeriod;
	hasBeneficialOccupancy: boolean;
	beneficialOccupancyType: BeneficialOccupancyType;
	beneficialOccupancyAcceptableDate: Date | string;
	tenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriod: TenantUtilitiesNotBeResponsibleDuringBeneficialOccupancyPeriodType[];
	beneficialOccupancyCustomText: string;
	documentSection: string;
	periodAsString: string;
	displayValue: string;
}
export interface IEngagementWithEmailsViewModel
{
	engagementWithEmailsPeriods: IEngagementWithEmailsPeriodViewModel[];
}
export interface IEngagementWithEmailsPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	emailsClicked: number;
	emailsOpened: number;
	noEngagement: number;
}
export interface IListingViewsEngagementReportViewModel
{
	listingViewsEngagementPeriods: IListingViewsEngagementPeriodViewModel[];
	inquiriesSubmittedPeriods: IInquiriesSubmittedPeriodViewModel[];
	toursScheduledPeriods: IToursScheduledPeriodViewModel[];
}
export interface IToursScheduledPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	toursScheduledCount: number;
}
export interface IInquiriesSubmittedPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	inquiriesSubmittedCount: number;
}
export interface IListingViewsEngagementPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	viewsCount: number;
}
export interface IListingSharesAndViewsReportViewModel
{
	listingSharesPeriods: IListingSharesPeriodViewModel[];
	listingViewsPeriods: IListingViewsPeriodViewModel[];
}
export interface IListingViewsPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	viewsCount: number;
}
export interface IListingSharesPeriodViewModel
{
	startDate: Date | string;
	endDate: Date | string;
	sharesCount: number;
}
export interface ITimeSpan
{
	zero: ITimeSpan;
	maxValue: ITimeSpan;
	minValue: ITimeSpan;
	nanosecondsPerTick: number;
	ticksPerMicrosecond: number;
	ticksPerMillisecond: number;
	ticksPerSecond: number;
	ticksPerMinute: number;
	ticksPerHour: number;
	ticksPerDay: number;
	ticks: number;
	days: number;
	hours: number;
	milliseconds: number;
	microseconds: number;
	nanoseconds: number;
	minutes: number;
	seconds: number;
	totalDays: number;
	totalHours: number;
	totalMilliseconds: number;
	totalMicroseconds: number;
	totalNanoseconds: number;
	totalMinutes: number;
	totalSeconds: number;
}
export interface IDateTimeOffset
{
	minValue: IDateTimeOffset;
	maxValue: IDateTimeOffset;
	unixEpoch: IDateTimeOffset;
	utcNow: IDateTimeOffset;
	dateTime: Date;
	utcDateTime: Date;
	localDateTime: Date;
	date: Date;
	day: number;
	dayOfWeek: DayOfWeek;
	dayOfYear: number;
	hour: number;
	millisecond: number;
	microsecond: number;
	nanosecond: number;
	minute: number;
	month: number;
	offset: ITimeSpan;
	totalOffsetMinutes: number;
	second: number;
	ticks: number;
	utcTicks: number;
	timeOfDay: ITimeSpan;
	year: number;
	now: IDateTimeOffset;
}
export enum DayOfWeek
{
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
}
export interface IGuid
{
	empty: IGuid;
}
export interface IIEqualityComparer<T>
{
}
