import { Injectable } from '@angular/core';

import { AlertLeaseUserRole, AlertType, ChatChannelType, TermCommentType } from '@statera/sdk/common';

import moment from 'moment';

import { Alert } from './alert.model';

@Injectable()
export class AlertTransformer {
  transformAlert(alert: Alert): Alert {
    if (!alert) {
      return null;
    }

    alert = this.transformAlertTitle(alert);
    alert = this.transformAlertText(alert);

    alert = this.transformAlertChatMessageText(alert);
    alert = this.transformAlertTermCommentText(alert);

    return alert;
  }

  transformAlertTitle(alert: Alert): Alert {
    if (!alert || !alert.leaseBuilding || !alert.leaseBuilding.address) {
      return alert;
    }

    const { address: buildingAddress } = alert.leaseBuilding;

    const addressParts = [
      buildingAddress.addressLine1,
      buildingAddress.addressLine2,
      buildingAddress.city,
      buildingAddress.stateCode,
    ];

    const address = addressParts.filter(Boolean).join(', ');

    let title = `${address}`;
    if (alert.leaseBuildingUnit) {
      title = `${title}, ${alert.leaseBuildingUnit.name}`;
    }

    return <Alert>{
      ...alert,
      title: title,
    };
  }

  transformAlertText(alert: Alert): Alert {
    if (!alert || !alert.text) {
      return null;
    }

    let text = alert.text;

    let tenantCompanyName = 'Tenant Company';
    if (alert.leaseTenantCompany) {
      tenantCompanyName = alert.leaseTenantCompany.name;
    }

    let landlordCompanyName = 'Landlord Company';
    if (alert.leaseLandlordCompany) {
      landlordCompanyName = alert.leaseLandlordCompany.name;
    }

    const address: Array<string> = [];
    if (alert.leaseBuilding) {
      let buildingAddress: string;
      buildingAddress = alert.leaseBuilding.address && alert.leaseBuilding.address.displayString;
      if (!buildingAddress) {
        buildingAddress = alert.leaseBuilding.name;
      }
      address.push(buildingAddress || `Building #${alert.leaseBuilding.id}`);
    }
    let buildingUnitName: string;
    if (alert.leaseBuildingUnit) {
      buildingUnitName = alert.leaseBuildingUnit.name;
      address.push(buildingUnitName || `Unit #${alert.leaseBuildingUnit.id}`);
    }

    let visitDate = '';
    if (alert.appointment && alert.appointment.startDate && alert.appointment.endDate) {
      const date = moment(alert.appointment.startDate).format('MMM D, YYYY');

      const startDate = new Date(alert.appointment.startDate);
      const endDate = new Date(alert.appointment.endDate);

      // Workaround: Special case
      if (startDate.getHours() === 11 && startDate.getMinutes() === 30 && endDate.getHours() === 12) {
        visitDate = `${date} 11:30 AM - 12:00 AM`;
      } else {
        const startHour = moment(alert.appointment.startDate).format('h:mm a');
        const endHour = moment(alert.appointment.endDate).format('h:mm a');

        visitDate = `${date} ${startHour} - ${endHour}`;
      }
    }

    const leaseId = alert.leaseId && alert.leaseId.toString();

    const regExp = /@Raw\b\("(?<innerHtml>.*)"\)/gmi;
    const regExpMatches = regExp.exec(text);

    if (regExpMatches && 0 < regExpMatches.length && regExpMatches.groups && regExpMatches.groups.innerHtml) {
      const fullMath = regExpMatches[0];
      const innerHtml = regExpMatches.groups.innerHtml;

      text = text.replace(fullMath, innerHtml);
    }

    const model = {
      '@Model.TenantCompanyName': tenantCompanyName,
      '@Model.LandlordCompanyName': landlordCompanyName,
      '@Model.LeaseId': leaseId,
      '@Model.VisitDate': visitDate,
      '@Model.Url': '',
      '@Model.BaseUrl': '',
      '@Model.BuildingAddress': address && address.length ? address.join(', ') : '',
    };

    const modelKeys = Object.keys(model);
    for (const modelKey of modelKeys) {
      text = text.split(modelKey).join(model[modelKey]);
    }

    return <Alert>{
      ...alert,
      text: text,
    };
  }

  transformAlertChatMessageText(alert: Alert): Alert {
    if (!alert || !alert.alertSettingTemplate || alert.alertSettingTemplate.alertType !== AlertType.Message) {
      return alert;
    }

    let text = `Received a new chat message: ${alert.text}`;

    const chatChannelType = this._getChatChannelType(alert);
    if (chatChannelType) {
      text = `Received a new ${chatChannelType} chat message: ${alert.text}`;
    }

    const sender = this._getSender(alert);
    if (sender) {
      text = `Received a new chat message from ${sender}: ${alert.text}`;
    }

    if (chatChannelType && sender) {
      text = `Received a new ${chatChannelType} chat message from ${sender}: ${alert.text}`;
    }

    return {...alert, text};
  }

  transformAlertTermCommentText(alert: Alert): Alert {
    if (!alert || !alert.alertSettingTemplate || alert.alertSettingTemplate.alertType !== AlertType.TermComment) {
      return alert;
    }

    let text = `Received a new term comment: ${alert.text}`;

    const chatChannelType = this._getChatChannelType(alert);
    if (chatChannelType) {
      text = `Received a new ${chatChannelType} term comment: ${alert.text}`;
    }

    const sender = this._getSender(alert);
    if (sender) {
      text = `Received a new term comment from ${sender}: ${alert.text}`;
    }

    if (chatChannelType && sender) {
      text = `Received a new ${chatChannelType} term comment from ${sender}: ${alert.text}`;
    }

    return {...alert, text};
  }

  private _getChatChannelType(alert: Alert): string {
    if (alert.chatChannelType != null) {
      let chatChannelType = 'internal';
      if (alert.chatChannelType === ChatChannelType.Lease) {
        chatChannelType = 'external';
      }

      return chatChannelType;
    }

    if (alert.leaseTermType != null) {
      let chatChannelType = 'internal';
      if (alert.termCommentType === TermCommentType.BothTeams) {
        chatChannelType = 'external';
      }

      return chatChannelType;
    }

    return null;
  }

  private _getSender(alert: Alert): string {
    if (!alert || !alert.createdBy) {
      return null;
    }

    let sender = alert.createdBy.displayName;
    if (alert.leaseUserRole) {
      sender = `${sender} (${AlertLeaseUserRole[alert.leaseUserRole]})`;
    }

    return sender;
  }
}
