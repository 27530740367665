export enum TourProcessState {
  // Create initial tour proposal
  Initial,
  // Reply rejecting options
  ReplyReject,
  // Reply accepting one of the options
  ReplyAccept,
  // Edit scheduled tour
  Edit,
}
